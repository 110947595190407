import React from "react"
import { useTranslation } from "react-i18next"
import GamesCatalogWrapper from "../../../components/GamesCatalogWrapper"
import themes from "../../../themes"
import { getBrandFromUrl } from "../../../helpers"

const CatalogSection = () => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <section
      className="py-5  about-section  text-white"
      id="games-catalog-game-page"
      style={{
        backgroundColor: themeColors?.primary,
        color: "white",
      }}
    >
      <div className="container-max-width">
        <div className="xs:px-6">
          <div className="text-white">
            <h1 className="xl:my-7 xl:text-3xl xs:text-3xl mb-5">
              {t("Catalog")}
            </h1>
          </div>
          <div className="mb-10">
            <GamesCatalogWrapper />
          </div>
        </div>
      </div>
    </section>
  )
}
export default CatalogSection
