export const SET_LOGGED_IN = "SET_LOGGED_IN"
export const POST_LOGIN = "POST_LOGIN"
export const POST_LOGIN_FULFILLED = "POST_LOGIN_FULFILLED"
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR"
export const GO_BACK_REGISTER_FORM_VIEW = "GO_BACK_REGISTER_FORM_VIEW"
export const POST_REGISTER = "POST_REGISTER"
export const POST_REGISTER_CHECK_FULFILLED = "POST_REGISTER_CHECK_FULFILLED"
export const POST_SEND_VERIFICATION_CODE_FULFILLED =
  "POST_SEND_VERIFICATION_CODE_FULFILLED"
export const POST_REGISTER_FULFILLED = "POST_REGISTER_FULFILLED"
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR"
export const POST_LOGOUT_FULFILLED = "POST_lOGOUT_FULFILLED"
export const GET_USER = "GET_USER"
export const GET_USER_FULFILLED = "GET_USER_FULFILLED"
export const GET_WALLET_FULFILLED = "GET_WALLET_FULFILLED"

export const GET_USER_ERROR = "GET_USER_ERROR"
export const DELETE_USER = "DELETE_USER"
export const GET_USER_AFFILIATE_FULFILLED = "GET_USER_AFFILIATE_FULFILLED"
export const GET_USER_DEPOSIT_BONUS_FULFILLED =
  "GET_USER_DEPOSIT_BONUS_FULFILLED"
export const POST_USER_METADATA_WALLET_FULFILLED =
  "POST_USER_METADATA_WALLET_FULFILLED"
export const POST_USER_BILLING_ADDRESS_FULFILLED =
  "POST_USER_BILLING_ADDRESS_FULFILLED"
export const DISMISS_DEPOSIT_POPUP_FULFILLED = "DISMISS_DEPOSIT_POPUP_FULFILLED"
export const POST_AUTH_FULFILLED = "POST_AUTH_FULFILLED"

export const GET_GAMES_FULFILLED = "GET_GAMES_FULFILLED"

export const SITE_LOAD_COMPLETE = "SITE_LOAD_COMPLETE"

export const DISMISS_LEVEL_REWARD_POPUP_FULFILLED =
  "DISMISS_LEVEL_REWARD_POPUP_FULFILLED"
