import React from "react"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Button from "../Button"
import GamesView from "../GameView"
import gamesCategories from "./GamesCategories"
import PageLink from "../PageLink"
import themes from "../../themes"
import { getBrandFromUrl } from "../../helpers"

const GamesCatalogMobile = ({ countryCode }) => {
  const gamesCategoriesForMobile = [
    gamesCategories[0],
    gamesCategories[1],
    gamesCategories[2],
    gamesCategories[3],
    gamesCategories[4],
  ]
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <MediaQuery minWidth={300} maxWidth={959}>
      <section
        className="text-white h-full flex flex-col justify-start space-y-24 games-catalog-card-mobile-section"
        style={{
          backgroundColor: themeColors?.primary,
          color: "white",
        }}
      >
        <div className="container-max-width h-auto w-full ">
          {gamesCategoriesForMobile.map((element) => {
            if (element.mobile) {
              return (
                <div
                  className="flex-col justify-between h-auto"
                  key={element.category}
                >
                  <div className="flex justify-between mb-2 ">
                    <div className="w-full flex justify-between items-center games-catalog-card-mobile-game-title">
                      <div className="flex">
                        <img
                          fetchpriority="low"
                          src={element.imageSource}
                          alt="visa-asset"
                          className="games-catalog-card-mobile-game-icon h-7 w-7 mr-2"
                        />
                        <h1 className="text-center text-xl">
                          {t(element.title)}
                        </h1>
                      </div>
                      <div>
                        <Button.Tertiary>
                          <PageLink
                            classNames="font-medium text-tertiary"
                            to={`/category/${element.tags}/`}
                            textStyle="tertiary"
                          >
                            {t("See All")}
                          </PageLink>
                        </Button.Tertiary>
                      </div>
                    </div>
                  </div>

                  <div className="h-auto grid mobile:grid-cols-3 mobile-big-screen:grid-cols-3 tablet:grid-cols-4 justify-content-center games-panel-mobile">
                    <MediaQuery minWidth={300} maxWidth={750}>
                      <GamesView
                        mobile
                        tag={element.tags}
                        display={6}
                        countryCode={countryCode}
                      />
                    </MediaQuery>
                    <MediaQuery minWidth={751} maxWidth={840}>
                      <GamesView
                        mobile
                        tag={element.tags}
                        display={8}
                        countryCode={countryCode}
                      />
                    </MediaQuery>
                    <MediaQuery minWidth={841} maxWidth={959}>
                      <GamesView
                        mobile
                        tag={element.tags}
                        display={10}
                        countryCode={countryCode}
                      />
                    </MediaQuery>
                  </div>
                </div>
              )
            }
            return <div />
          })}
        </div>
      </section>
    </MediaQuery>
  )
}

GamesCatalogMobile.propTypes = {
  countryCode: PropTypes.string.isRequired,
}

export default GamesCatalogMobile
