import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import Hero from "./sections/Hero"
import Stats from "./sections/Stats"
// import LegalNotice from "./sections/LegalNotice"
import WelcomePopup from "../../components/WelcomePopup"
import About from "./sections/About"
import BannerSection from "./sections/BannerSection"
import LevelRewardPopup from "../../components/LevelRewardPopup"
import Header from "../../components/Header"
import DepositPopup from "../../components/DepositPopup"
import useAnalyticsEventTracker from "../../utils/googleAnalyticsTracker"
import "./style.css"
import LogoSpinner from "../../components/LogoSpinner"
import { getBrandFromUrl, promptBrand } from "../../helpers"
import themes from "../../themes"

const HomePage = () => {
  const eventTracker = useAnalyticsEventTracker("homepage")
  const { state } = useLocation()
  // const navigate = useNavigate()
  // state
  const { loggedIn } = useSelector((states) => states.auth)
  const { showDepositBonusPopup, showLevelRewardPopup } = useSelector(
    (states) => states.user
  )
  const { isSiteLoadComplete } = useSelector((states) => states.auth)

  // const handleCloseLegalNotice = () => {
  //   navigate("/")
  // }

  useEffect(() => {
    eventTracker("open home page")
  }, [])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <div>
      {!isSiteLoadComplete && (
        <div
          className="absolute  left-0 z-100"
          style={{
            zIndex: "999",
            backgroundColor: themeColors?.tintedPurple,
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            className=" div-above absolute flex  items-center justify-center"
            style={{
              zIndex: "999",
              backgroundColor: themeColors?.tintedPurple,
              width: "100%",
              height: "100vh",
            }}
          >
            <div className="flex justify-center items-center div-loader">
              <LogoSpinner />
            </div>
          </div>
        </div>
      )}
      {isSiteLoadComplete && (
        <div>
          <Header title={`${promptBrand("name")} | Home`} />
          <Hero />
          <BannerSection />
          <Stats home />
          <About />
          {state?.welcomeModal && <WelcomePopup activeService="welcome" />}
          {/* {state?.legalNotice && (
            <LegalNotice onCloseLegalNotice={handleCloseLegalNotice} />
          )} */}
          {loggedIn && (
            <>
              {showDepositBonusPopup && <DepositPopup />}
              {showLevelRewardPopup && <LevelRewardPopup />}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default HomePage
