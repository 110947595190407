import sha256 from "crypto-js/sha256"
import ReactPixel from "@bettercart/react-facebook-pixel"
import pixels from "./pixels"
import { logger } from "../helpers"

class MetaEventService {
  static eventTypes = {
    Purchase: "Purchase",
    UserKYCUpdate: "UserKYCUpdate",
    CompleteRegistration: "CompleteRegistration",
  }

  static env = process.env.REACT_APP_CONTEXT

  static computeExternalId(email) {
    return sha256(`${this.env}-${email}`).toString()
  }

  static initializePixels(email) {
    const options = {
      autoConfig: true,
      debug: this.env !== "production",
    }
    logger(this.env)
    logger(this.env !== "production")

    logger({ email })
    if (email) {
      const externalId = this.computeExternalId(email)
      logger({ email, externalId })
      pixels.forEach((pixel) => {
        if (this.env === "production") {
          ReactPixel.init(
            pixel.id,
            { external_id: externalId, em: sha256(email) },
            options
          )
        }
        logger({
          action: "Pixel initialization - logged In",
          name: pixel.name,
          pixelID: pixel.id,
          externalId,
          email,
        })
      })
    } else {
      pixels.forEach((pixel) => {
        if (this.env === "production") {
          ReactPixel.init(pixel.id, options)
        }

        logger({
          action: "Pixel initialization - not Logged in",
          name: pixel.name,
          pixelID: pixel.id,
          email,
        })
      })
    }
  }

  static pageView() {
    if (this.env === "production") {
      ReactPixel.pageView()
    }
    logger(this.env)
    logger({ eventType: "Page View" })
  }

  // Send the meta event for purchase

  static sendPurchaseEvent(amount, currency, eventID) {
    if (eventID === undefined)
      throw new Error(
        "When trying to send purchase event, eventID is undefined"
      )

    if (this.env === "production") {
      ReactPixel.track(this.eventTypes.Purchase, {}, { eventID })
    }
    logger({ eventType: "Purchase", eventID })
  }

  // send the meta event for KYC update
  // I send the events just with the name of the event without the ventID to be able to use it in react synchronous code of fallback of register/updateKYC
  static sendKYCUpdateEvent(eventID) {
    if (eventID === undefined)
      throw new Error(
        "When trying to send purchase event, eventID is undefined"
      )
    if (this.env === "production") {
      ReactPixel.trackCustom(this.eventTypes.UserKYCUpdate, {}, { eventID })
    }

    logger(this.env)
    logger({ eventType: this.eventTypes.UserKYCUpdate, eventID })
  }

  static sendCompleteRegistrationEvent(eventID) {
    if (eventID === undefined)
      throw new Error(
        "When trying to send purchase event, eventID is undefined"
      )
    if (this.env === "production") {
      ReactPixel.track(this.eventTypes.CompleteRegistration, {}, { eventID })
    }

    logger(this.env)
    logger({ eventType: this.eventTypes.CompleteRegistration, eventID })
  }
}

export default MetaEventService
