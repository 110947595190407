import React from "react"
import PropTypes from "prop-types"

const spinnerSizeStyle = (size) => (size === "small" ? "w-4 h-4" : "w-8 h-8")
const spinnerThemeStyle = (theme) =>
  theme === "light" ? "text-white" : "text-black"

const Spinner = ({ size, theme }) => (
  <div className="flex justify-center items-center div-above">
    <div
      className={`spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full ${spinnerSizeStyle(
        size
      )} ${spinnerThemeStyle(theme)}`}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)
Spinner.defaultProps = {
  theme: "light",
}

Spinner.propTypes = {
  size: PropTypes.string.isRequired,
  theme: PropTypes.string,
}

export default Spinner
