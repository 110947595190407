import React from "react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import Header from "../../components/Header"
import { getBrandFromUrl, promptBrand } from "../../helpers"
import themes from "../../themes"

const listStyle = css`
  list-style: disc;
`

const PrivacyPolicy = () => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <>
      <Header title={`${promptBrand("name")} | Privacy Policy`} />
      <section
        className=" text-white md:pb-32 xs:pb-20  w-full policies-custom-style"
        style={{ backgroundColor: themeColors?.primary }}
      >
        <div className="container-max-width">
          <div className="mx-auto   sm:px-7 lg:px-0 xl-start:p-10 xs:px-6">
            <h1 className="text-2xl font-bold mb-10">Privacy Policy</h1>
            <p>
              The following statement sets out our policy relating to the
              collection, storage and use of personal information in the course
              of its business.
            </p>
            <p>In this policy: </p>
            <p className="my-2">
              &#34;we &#34;, &#34;us &#34; or &#34;our &#34; means &nbsp;
              {promptBrand("site")}, which is operated by Carbon Games N.V.
              registration no. 161315, with sub-license GLH - 365/JAZ which
              operates under the Curacao License no. 365/JAZ.
            </p>
            <p className="my-2">
              We take seriously our obligation to safeguard personal information
              about our clients. This Privacy Policy is in addition to Our Terms
              un Use and should be read in conjunction with the Terms un Use. By
              using this website, opening a Account or placing a wager with Us
              via website, you agree to be bound by the terms of this Privacy
              Policy.
            </p>
            <p className="my-2">
              We review our Privacy Policy from time to time and reserve the
              right, at our discretion, to amend this policy at any time without
              any notice other than posting the amended Privacy Policy on the
              Our website or apps. Amendments to our Privacy Policy will be
              affected immediately once posted on our website or apps. Your
              continued use of our services following the posting of an amended
              Privacy Policy will be deemed as acceptance of those amendments.
            </p>
            <p className="my-2">
              It is your responsibility to ensure that you keep up-to-date with
              the current Privacy Policy. You should review this page
              periodically so that you are updated on any changes to the policy.
            </p>
            <h1 className="text-xl font-bold my-5">Personal Information</h1>
            <p className="my-3">
              Types of Personal Information we collect and hold. We collect a
              variety of personal information in the course of our business. The
              types of personal information that we may collect and hold
              includes, without limitation:
            </p>
            <ul css={listStyle}>
              <li className="my-1">
                Identification information such as your name, date of birth,
                residential and postal address, gender, email address, telephone
                numbers.
              </li>
              <li className="my-1">Financial information.</li>
              <li className="my-1">
                Copies of identification documents such as drivers license,
                passport, birth certificate and/or utility bills.
              </li>
              <li className="my-1">Personally, submitted preferences.</li>
              <li className="my-1">
                Responses to competitions, other promotions and surveys.
              </li>
              <li className="my-1">Profession, occupation or job title.</li>
            </ul>
            <h1 className="text-md font-bold my-3">
              How we collect Personal Information
            </h1>
            <p className="my-3">
              We will collect personal information only by lawful and fair
              means.
            </p>
            <p className="my-3">
              We collect your personal information directly from you unless it
              is unreasonable or impracticable to do so. We may collect this
              information when you:
            </p>
            <ul css={listStyle}>
              <li className="my-1">Register for an account with Us.</li>
              <li className="my-1">Access or use our website or apps;.</li>
              <li className="my-1">Use our products and services.</li>
              <li className="my-1">
                Participate in our competitions, giveaways and other promotions.
              </li>
              <li className="my-1">
                Contact us directly via any medium including: SMS, MMS, instant
                messaging, email, social media platforms, postal mail and
                telephone.
              </li>
              <li className="my-1">
                Provide feedback through our website/app feedback links.
              </li>
              <li className="mt-1">
                It may also be necessary to collect your personal information
                from third parties, including:
              </li>
              <ul css={listStyle} className="pl-5">
                <li className="my-1">
                  Credit reporting agencies, law enforcement agencies and other
                  government entities.
                </li>
                <li className="my-1">Identify verification providers.</li>
              </ul>
            </ul>
            <p className="my-3">
              Personal information may also be collected by cookies when you use
              our website and apps. See Cookies below for further information.
            </p>
            <p className="my-3 font-bold">
              Why we collect, hold, use and disclose personal information ?
            </p>
            <p className="my-3">
              We collect and hold, use and disclose personal information for
              purposes including the following:
            </p>
            <ul css={listStyle} className="pl-5">
              <li className="my-1">
                to allow you to register as a client of Ours
              </li>
              <li className="my-1">to provide products and services to you </li>
              <li className="my-1">
                to enable us to perform our obligations to you under Terms of
                Use, and to ensure that you perform your obligations under Terms
                of use
              </li>
              <li className="my-1">
                for communicating with you, including sending you information
                about our products and services
              </li>
              <li className="my-1">
                for planning, research, promotion and marketing of our goods and
                services, including conducting competitions or promotions
              </li>
              <li className="my-1">
                to create aggregate data about clients through demographic
                profiling and statistical analysis of our database to optimize
                our products and services and /or allow for more efficient
                operation of our business
              </li>
              <li className="my-1">
                to maintain a credit information file on you and to carry out a
                credit assessment on you
              </li>
              <li className="my-1">to establish your bona fides</li>
              <li className="my-1">
                for the investigation of suspected unlawful, fraudulent or other
                improper activity connected with the use of our products and
                services
              </li>
              <li className="my-1">
                to comply with our legal and statutory obligations, including
                our obligations relating to identity verification and reporting
                under the Anti-Money Laundering and Counter Terrorism
                legislation and other applicable laws.
              </li>
            </ul>
            <p className="my-3 font-bold">
              To whom Personal Information is disclosed
            </p>
            <p className="my-3">
              By registering with Us and providing personal information you
              consent to your personal information being used by us and other
              entities in the Our group of companies.
            </p>
            <p className="my-3">
              We share personal information with other entities in Our group of
              companies in a strictly controlled manner and do not sell personal
              information to other companies.
            </p>
            <p className="my-3">
              We may disclose your personal information to third parties as
              follows:
            </p>
            <ul css={listStyle}>
              <li className="my-1">to a credit reporting agency</li>
              <li className="my-1">
                to our contractors and external service providers associated
                with the operation of our business and provision of our services
                including, without limitation, associated data centres, web
                hosting providers, payment service providers, identification
                verification service providers, advertising agencies, mailing
                houses, printers, call centres, market research analysts, IT
                consultants, professional advisors and consultants
              </li>
              <li className="my-1">
                to law enforcement agencies to assist in the prevention of
                criminal activities
              </li>
              <li className="my-1">
                to government and regulatory authorities and other organizations
                as required or authorized by law or otherwise
              </li>
              <li className="my-1">
                controlling bodies where such controlling bodies request
                information to protect and maintain the integrity of services
                provided or where we consider any betting or gaming activity to
                be suspicious or unusual
              </li>
              <li className="my-1">
                to a successor entity in the event of a business transition,
                such as a merger, corporate reorganization or to a purchaser of
                part of or all of Our assets.
              </li>
            </ul>
            <p className="my-3">
              Should the information be transferred to a third party, we will
              use reasonable endeavors to ensure that the information disclosed
              is protected by the third party under contractual arrangements.
            </p>
            <p className="my-3 font-bold">Security of Personal Information</p>
            <p className="my-3">
              We will take reasonable steps to protect the personal information
              we collect and ensure that it is accurate, complete and
              up-to-date.
            </p>
            <p className="my-3">
              Your information is held on secure servers. We may also store
              personal information in telephone recordings and in hard copy or
              paper files.
            </p>
            <p className="my-3">
              Our employees, agents, contractors are required to maintain the
              confidentiality of all personal information.
            </p>
            <p className="my-3">
              You are responsible for ensuring that you keep your username,
              password and account information confidential. If you suspect that
              your details may no longer be confidential, you should notify Us
              immediately, whereupon new details may be given.
            </p>
            <p className="my-3">
              You are responsible for the security of and access to your own
              computer/device. You should ensure that you always log out of your
              account after each use of the website/app.
            </p>
            <p className="my-3">
              We understand the importance of security and techniques needed to
              secure information. We store all of the Personal Information we
              receive directly from you in the protected database residing
              within our secure network behind active state-of-the-art firewall
              software. As well as we do not store or process credentials of
              payment cards.
            </p>
            <p className="my-3">
              Our Services support SSL Versions at least TLS 1.2, but mostly TLS
              1.3 with 256-bit encryption. HTTP/2 and QUIC protocols are
              supported as well.
            </p>
            <p className="font-bold my-3">
              Access to and correcting Personal Information
            </p>
            <p className="my-3">
              We take reasonable steps to ensure that the personal information
              we collect about you is accurate, up-to-date and complete. We also
              take reasonable steps to ensure that the personal information we
              use or disclose is accurate up-to-date, complete and relevant.
            </p>
            <p className="my-3">
              If we are satisfied that your personal information should be
              corrected we will take reasonable steps to correct it – this may
              include contacting you to seek your most current information.
            </p>
            <p className="my-3">
              You may request access to and correction of the personal
              information we hold about you. We will require you to verify your
              identity and to specify what information you require.
            </p>
            <p className="my-3">
              You may also update or correct some of your personal information
              via the “My Account section of the website/apps.
            </p>
            <p className="my-3 font-bold">Unsolicited personal information</p>
            <p className="my-3">
              Where we receive unsolicited personal information, we will
              determine whether or not it would have been permissible to collect
              that personal information if it had been solicited. If we
              determine that collection would not have been permissible, to the
              extent permitted by law, we will destroy or de-identify that
              personal information as soon as practicable.
            </p>
            <p className="font-bold my-3">Non-Personal Information</p>
            <p className="my-3">
              Like most websites and apps, we may collect non-personal
              information from your use of our websites and apps in order to
              assist with the administration of our websites and apps, to track
              site usage and to enhance your user experience.
            </p>
            <p className="my-3">
              Once logged in your IP address is tracked against your user ID for
              the purposes of preventing fraud, identifying improper activity
              connected with the use of our products and services, and creating
              an audit trail. We collect and store non-personal information that
              we collect through cookies, log files or web beacons to create a
              profile of our users. A profile is stored information that we keep
              on individual users that detail their viewing preferences.
            </p>
            <p className="my-3">
              This profile is used to tailor a user’s visit to the site, and to
              direct pertinent marketing promotions to users.
            </p>
            <h1 className="text-xl font-bold my-5">Cookies</h1>
            <p className="my-3">
              Cookies are small pieces of data which are stored by a website
              through your browser, to enable an improved experience whilst
              using or browsing a particular website/app or to remember your
              preferences or navigation history/activity (such as pages you have
              visited within a particular site or typical activity) at a
              particular site.
            </p>
            <p className="my-3">
              We may user cookies to remember your preferences when you have
              visited our site and pages you like to regularly visit and to
              ensure that you receive the most up to date information which is
              relevant to you and your experience with Us. We also use cookies
              for internal management purposes and to enable essential activity
              to ensure that your account works correctly when accessing our
              services.
            </p>
            <p className="my-3">
              We will not use these cookies to store information such as account
              details or transaction history. You can delete cookies using your
              browsers own Clear History function. You can reset your browser to
              refuse all cookies or to indicate when a cookie is being sent.
              However, some website and app features or services may not
              function properly without cookies.
            </p>
            <p className="my-3">
              Most web browsers allow some control of cookies through the
              browser settings. It is important to note however that if you
              block cookies from Our site or apps there are a number of
              functions that may not work and you will need to reset your
              preferences each time you log in.
            </p>
            <h1 className="text-xl font-bold my-10">Third Party cookies</h1>
            <p className="my-3">
              We may use third-parties to serve ads on our website/apps. These
              companies may place or recognize cookies, action tags and other
              technology to measure advertising effectiveness. We may use
              cookies to remarket to you across the internet.
            </p>
            <p className="my-3">
              The use of cookies allows Us to inform, optimize, and serve ads
              based on someone’s past visits to our website/apps.
            </p>
            <p className="my-3">
              To specifically opt-out of interest-based ad targeting click here
              <a
                href="https://www.allaboutcookies.org/manage-cookies/opt-out-cookies.html"
                target="_blanc"
              >
                Cookies references
              </a>
              and follow the instructions. Find out more about cookiesTo find
              out more about cookies, including how to see what cookies have
              been set and how to manage and delete them
            </p>
            <h1 className="text-xl font-bold my-10">Complaints</h1>
            <p>
              If you have a complaint relating to how we handle personal
              information, please contact our support at{" "}
              <a href="mailto:complaints@carbonteam.io">
                complaints@carbonteam.io
              </a>
            </p>
            <h1 className="text-xl font-bold my-10">Dispute Resolution</h1>
            <p className="my-3">
              1. The acceptance or settlement of a bet which you have made using
              the Services will be considered more than thirty days after the
              date of the original transaction
            </p>
            <p className="my-3">
              2. a game which You have played using the Services will be
              considered more than twelve weeks after the date on which the
              relevant transaction or game play took place.
            </p>
            <p className="my-3">
              3. Should You wish to make a complaint regarding the Services, as
              a first step You should, as soon as reasonably practicable,
              contact
              <a href="mailto:support@carbonteam.io">
                support@carbonteam.io
              </a>{" "}
              about Your complaint, which will be escalated as necessary within
              our Support team until resolution.
            </p>
            <p className="my-3">
              4. You acknowledge that our random number generator will determine
              the outcome of the games played through the Services and You
              accept the outcomes of all such a game. You further agree that in
              the unlikely event of a disagreement between the result that
              appears on Your screen and the game server used by the Operator,
              the result that appears on the game server will prevail, and You
              acknowledge and agree that our records will be the final authority
              in determining the terms and circumstances of Your participation
              in the relevant online gaming activity and the results of this
              participation.
            </p>
            <p className="my-3">
              When we wish to contact You, we may do so using any of Your
              Contact Details. Notices will be deemed to have been properly
              served and received by You immediately after an email is sent or
              after we have communicated with You directly by telephone
              (including where we leave You a voicemail), or three days after
              the date of posting of any letter. In proving the service of any
              notice, it will be sufficient to prove, in the case of a letter,
              that such letter was properly addressed, stamped and placed in the
              post; in the case of an email, that such email was sent to the
              specified email address (if any) in Your Contact Details at the
              time that any such email was sent.
            </p>
            <p className="py-3">
              If there is no reaction on your complaint or notice from us or in
              your opinion the issue is not yet resolved, you can file a
              complaint or notice to the licensing and supervision organization
              contacting:&nbsp; &nbsp;
              <a href="mailto:complaints@carbonteam.io" className="mx-5">
                complaints@carbonteam.io
              </a>
            </p>
            {/* <h1 className="text-lg font-bold my-5">Social Media</h1>
          <p>
            The company uses a variety of social media platforms to which you
            can connect freely. The company shares news, promotions,
            information, changes, and more on its social media accounts. All
            interactions with the company&#39;s social media accounts are only
            possible in accordance with the terms, including the privacy policy.
            If you do not wish to comply with the company&#39;s terms, you may
            not interact in any way, shape, or form with the company&#39;s
            social media accounts. The company does not use any social media
            account besides the accounts mentioned in the privacy policy. Any
            account claiming to be associated with the company in any form which
            is not mentioned in the privacy policy is an imposter and not
            affiliated with the company and its third-party partners in any way.
            The company will take legal steps against any impersonation and
            misinterpretation of its trademarks—further information regarding
            our intellectual property and trademarks you can find in our terms
            of service.
          </p> */}
            {/* <h1 className="text-lg font-bold my-5">Twitter</h1> */}
            {/* // TODO insert the necessary links  */}
            {/* <p>You may access Carbon&#39;s Twitter account via:</p> */}
            {/* <p>Link </p> */}
            {/* <h1 className="text-lg font-bold my-5">Telegram</h1> */}
            {/* // TODO insert the necessary links  */}
            {/* <p>You may access Carbon&#39;s Telegram account via:</p> */}
            {/* <p>Link </p> */}
            {/* <h1 className="text-lg font-bold my-5">Contact</h1> */}
            {/* // TODO insert the necessary links  */}
            {/* <p>
            If you have any questions, issues, suggestions, or feedback, feel
            free to contact our Carbon support team and our Carbon compliance
            team any time:
          </p>
          <p>By e-mail: ... </p>
          <p>Or by clicking there ....</p> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
