import React, { useState } from "react"

import { useQuery } from "react-query"

/* components */
import Spinner from "../../components/Spinner"

// Utilities
import { logger } from "../../helpers"
import { getNodaUrlWidget } from "../../actions/wallet"
import getAmount from "./helpers"

/* style */
import "./style.css"

/* NodaOpenBankingHero */
const NodaOpenBanking = () => {
  const amount = getAmount()

  logger(amount)

  const currency = "EUR"
  const [Nodaurl, setNodaurl] = useState("")

  /* useQuery */
  const { isLoading, refetch } = useQuery({
    queryKey: ["getNodaUrl", amount],
    queryFn: () => getNodaUrlWidget(currency, amount),
    refetchOnMount: "always",
    onSuccess: ({ data: nodaUrlResponse }) => {
      logger(nodaUrlResponse)
      setNodaurl(nodaUrlResponse?.url)
    },

    onError: (error) => {
      logger(error)
      refetch()
    },
  })

  return isLoading ? (
    <div className="w-full iframe-max-height container-background-style  ">
      <div className="container-max-width-switchere h-full  flex  justify-center  py-5">
        <div className="container-max-width-switchere  div-above h-full  w-full   py-5 flex justify-center items-center">
          <Spinner />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full iframe-max-height container-background-style  ">
      <div className="container-max-width-switchere h-full  flex  justify-center  py-5">
        <div className="container-max-width-switchere  iframe-max-height w-full   py-5">
          <iframe
            title="noda"
            src={Nodaurl}
            className="w-full iframe-max-height-noda z-0"
          />
        </div>
      </div>
    </div>
  )
}

export default NodaOpenBanking
