import React from "react"

import Hero from "./Hero"

import Stats from "../Home/sections/Stats"

const CategoryPage = () => (
  <div className="min-h-screen">
    <Hero />
    <Stats />
  </div>
)

export default CategoryPage
