import React, { useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const pageStyle = (currentPage, pager, themeColors) => css`
  background-color: ${currentPage === pager
    ? themeColors.lightTertiary
    : "transparent"};
`

const currentPageStyle = (currentPage, number, themeColors) => css`
  background-color: ${currentPage === number
    ? themeColors.lightTertiary
    : "transparent"};
`

const hoverBackgroundStyle = (themeColors) => css`
  &:hover {
    background-color: ${themeColors.lightTertiary};
  }
`

const Pagination = ({ numberOfRecords, paginate, numberOfRecordsPerPage }) => {
  const [pager, setPager] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  let numberOfPages = Math.floor(numberOfRecords / numberOfRecordsPerPage)
  if (numberOfPages % numberOfRecordsPerPage > 0) {
    numberOfPages += 1
  }

  const handleStepper = (direction) => {
    if (direction === 1) {
      if (currentPage > numberOfPages - 1) return
      if (currentPage + 1 === numberOfPages) {
        setCurrentPage(currentPage + 1)
        paginate(currentPage + 1)
        return
      }
      if (currentPage < pager + 2 || numberOfPages < 6) {
        setCurrentPage(currentPage + 1)
      } else {
        setPager(pager + 3)
        setCurrentPage(pager + 3)
      }
    } else if (direction === -1) {
      if (currentPage < 2) return
      if (currentPage > pager || numberOfPages < 6) {
        setCurrentPage(currentPage - 1)
        paginate(currentPage - 1)
      } else {
        setPager(pager - 3)
        setCurrentPage(pager - 1)
        paginate(currentPage - 1)
      }
    }
  }

  const handlePager = () => {
    if (numberOfPages - pager < 1) {
      setPager(pager + 1)
      setCurrentPage(pager + 2)

      paginate(pager + 1)
      return
    }
    if (pager + 4 > numberOfPages) return
    if (pager + 2 === numberOfPages) return
    if (pager + 2 < numberOfPages) {
      setPager(pager + 2)
      setCurrentPage(pager + 2)
      paginate(pager + 2)
    }
  }

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber)
    paginate(pageNumber)
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <div className="flex justify-center mobile:absolute mobile:justify-around items-center w-full relative">
      <nav className=" flex border-2 rounded-md divide-x-2">
        {numberOfPages > 1 && (
          <div>
            <button
              onClick={() => {
                handleStepper(-1)
              }}
              type="button"
              className="h-full py-1 px-2 disabled:hover:hyphens-none disabled:opacity-30"
              disabled={currentPage === 1}
              css={hoverBackgroundStyle(themeColors)}
            >
              <ChevronLeftIcon
                className="h-6 w-6 text-purpleHeart"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
        {numberOfPages > 6 ? (
          <ul className="flex divide-x-2">
            <li
              className="px-2 text-center  text-purpleHeart"
              css={pageStyle(currentPage, pager, themeColors)}
            >
              <button
                onClick={() => {
                  changePage(pager)
                }}
                type="button"
                className="p-1 h-full"
              >
                {pager}
              </button>
            </li>
            <li
              className={`px-2 text-center ${
                currentPage === pager + 1 ? "bg-lightTertiary" : ""
              } text-purpleHeart`}
            >
              <button
                onClick={() => {
                  changePage(pager + 1)
                }}
                type="button"
                className="p-1 h-full"
              >
                {pager + 1}
              </button>
            </li>
            {pager + 2 !== numberOfPages && (
              <li
                className={`px-2 text-center ${
                  currentPage === pager + 2 ? "bg-lightTertiary" : ""
                } text-purpleHeart`}
              >
                <button
                  onClick={() => {
                    changePage(pager + 2)
                  }}
                  type="button"
                  className="p-1 h-full"
                >
                  {pager + 2}
                </button>
              </li>
            )}
            <li className="px-2 text-purpleHeart active:bg-lightTertiary">
              <button
                onClick={() => {
                  handlePager()
                }}
                type="button"
                className="h-full p-1"
              >
                ...
              </button>
            </li>
            <div
              className={`px-2 text-center ${
                currentPage === numberOfPages ? "bg-lightTertiary" : ""
              } text-purpleHeart`}
            >
              <button
                onClick={() => changePage(numberOfPages)}
                type="button"
                className="p-1 h-full"
              >
                {numberOfPages}
              </button>
            </div>
          </ul>
        ) : (
          <ul className="flex divide-x-2">
            {Array.from({ length: numberOfPages }, (_, index) => index + 1).map(
              (number) => (
                <li
                  className="px-2 text-center  text-purpleHeart"
                  css={currentPageStyle(currentPage, number, themeColors)}
                >
                  <button
                    type="button"
                    onClick={() => changePage(number)}
                    className="h-full p-1"
                  >
                    {number}
                  </button>
                </li>
              )
            )}
          </ul>
        )}
        {numberOfPages > 1 && (
          <div>
            <button
              onClick={() => {
                handleStepper(1)
              }}
              type="button"
              className="h-full py-1 px-2 disabled:hover:hyphens-none disabled:opacity-30"
              disabled={currentPage === numberOfPages}
              css={hoverBackgroundStyle(themeColors)}
            >
              <ChevronRightIcon
                className=" h-6 w-6 text-purpleHeart"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </nav>
    </div>
  )
}

Pagination.propTypes = {
  numberOfRecords: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  numberOfRecordsPerPage: PropTypes.number.isRequired,
}

export default Pagination
