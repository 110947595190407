import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MediaQuery from "react-responsive"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClone, faCheck } from "@fortawesome/free-solid-svg-icons"
import { useQuery } from "react-query"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
// import { faCopy as regularCopy } from "@fortawesome/free-regular-svg-icons"
import { Tabs } from "../../components/Tabs"
import {
  copyTextToClipboard,
  promptBrand,
  imageDistributed,
  getBrandFromUrl,
} from "../../helpers"
import { Modal } from "../../components/Modal"
import { toast } from "../../components/Toast"
import Input from "../../components/Input"
import Tooltip from "../../components/Tooltip"
import IconsButton from "../../components/IconsButton"
import themes from "../../themes"
import { getAffiliateInformation } from "../../actions/user"
// import referralAsset from "../../assets/images/affiliateModal/referral-asset.png"
// import referrerAsset from "../../assets/images/affiliateModal/referrer-asset.png"
import Button from "../../components/Button"

const serviceName = "affiliate"

const tabIndexes = {
  referral: 0,
  referrer: 2,
}

const borderStyle = (themeColors) => css`
  border-width: 4px; /* Equivalent to Tailwind's border-4 */
  border-style: solid; /* You need to define the style to use width in CSS */
  border-color: ${themeColors?.specialTertiary}; /* Dynamically set from themeColors */
`

const gradientStyle = (themeColors) => css`
  background: linear-gradient(
    to right,
    ${themeColors?.mediumPurple},
    ${themeColors?.customIndigo}
  );
  border: none;
`

const ReferralTab = () => {
  const { username } = useSelector((state) => state.auth)
  const { t } = useTranslation()

  const { referrals, numberBet, deposits } = useSelector(
    (state) => state.user.affiliateInformation
  )

  const [onClickOnCopyIcon, setOnclickOnCopyIcon] = useState(false)

  const referralUrl = `https://${promptBrand("site")}/#/?ref=${username}`

  const handleClickOnIcon = () => {
    setOnclickOnCopyIcon(true)
    copyTextToClipboard(referralUrl)
    setTimeout(() => {
      setOnclickOnCopyIcon(false)
    }, 1000)
  }
  const roundedDeposits = (deposits ?? 0).toFixed(2)

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tabs.Body.Item>
      <div>
        <div className="mt-2 text-sm">
          <p className="text-gray-700 text-sm">
            {t(
              "Referring your friends to our Casino is one of the easiest and fastest ways to earn free gift and make more money."
            )}
          </p>
          <p className=" test-sm" style={{ color: themeColors?.tertiary }}>
            {t("Get everyone in on the fun!")}
          </p>
        </div>
        <div className="mb-5">
          <div className="mt-6 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center ">
            <div
              className="desktop:w-[57%] xs:w-full flex flex-col  rounded-xl desktop:mr-2 xs:mb-2 desktop:mb-0 px-5 py-4 "
              style={{
                backgroundColor: themeColors?.lightTertiary,
              }}
              css={borderStyle(themeColors)}
            >
              <h1 className="text-specialPrimary font-bold">
                {t("Referral link")}
              </h1>
              <div className="my-5 relative">
                <Input
                  disabled
                  label={referralUrl}
                  disabledInputBackground
                  customStyle
                />
                <div className="flex flex-row justify-between items-center absolute -right-0 bottom-0">
                  {onClickOnCopyIcon ? (
                    <Tooltip content="Address copied !">
                      <span>
                        <IconsButton onClick={handleClickOnIcon}>
                          <span>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Copy address !">
                      <span>
                        <IconsButton onClick={handleClickOnIcon}>
                          <span>
                            <FontAwesomeIcon
                              icon={faClone}
                              className=" text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="my-5 text-sm text-gray-700">
                <p>
                  {t(
                    "Referred friends must verify their accounts and make their first deposit to recieve an award of $5.00 . You will recieve $10.00 when the referred friend has played through their first deposit. Bonuses expire after 7 days so make sure you use them."
                  )}
                </p>
              </div>
              <MediaQuery minWidth={375} maxWidth={767}>
                <div className="refereer-info-affiliate-mobile mb-3">
                  <p
                    className=" text-white rounded-2xl text-center desktop:text-sm xs:text-xs md:w-24  py-1 -to-r border-0 "
                    css={gradientStyle(themeColors)}
                  >
                    <span>{referrals}</span> <br />{" "}
                    <span>{t("Referrals")}</span>
                  </p>
                  <p
                    className="text-white rounded-2xl text-center desktop:text-sm xs:text-xs md:w-24  py-1 -to-r border-0 "
                    css={gradientStyle(themeColors)}
                  >
                    <span>{numberBet}</span> <br /> <span>{t("Bets")}</span>
                  </p>
                  <p
                    className="text-white rounded-2xl text-center desktop:text-sm xs:text-xs md:w-24  py-1 -to-r border-0 "
                    css={gradientStyle(themeColors)}
                  >
                    <span>{roundedDeposits}$</span> <br />{" "}
                    <span>{t("Deposits")}</span>
                  </p>
                </div>
              </MediaQuery>
            </div>
            <MediaQuery minWidth={768}>
              <div
                className="desktop:w-1/2 xs:w-full flex flex-col   rounded-xl desktop:mr-3 xs:mb-2 desktop:mb-0 px-5 py-4"
                style={{
                  backgroundColor: themeColors?.lightTertiary,
                }}
                css={borderStyle(themeColors)}
              >
                <div className="flex justify-center items-center mb-3">
                  <img
                    fetchpriority="low"
                    src={imageDistributed("referralAsset")}
                    className="w-auto h-44 my-4"
                    alt="buy-crypto-asset"
                  />
                </div>
                <div className="mb-3 flex justify-around refereer-info-affiliate-mobile">
                  <p
                    className=" text-white rounded-2xl text-center desktop:text-sm xs:text-xs  py-1  border-0"
                    css={gradientStyle(themeColors)}
                  >
                    <span>{referrals}</span> <br />{" "}
                    <span>{t("Referrals")}</span>
                  </p>
                  <p
                    className="text-white rounded-2xl text-center desktop:text-sm xs:text-xs  py-1 border-0"
                    css={gradientStyle(themeColors)}
                  >
                    <span>{numberBet}</span> <br /> <span>{t("Bets")}</span>
                  </p>
                  <p
                    className="text-white rounded-2xl text-center desktop:text-sm xs:text-xs py-1  border-0"
                    css={gradientStyle(themeColors)}
                  >
                    <span>{roundedDeposits}$</span> <br />{" "}
                    <span>{t("Deposits")}</span>
                  </p>
                </div>
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

const ReferrerTab = () => {
  const navigate = useNavigate()
  const { referrer } = useSelector((state) => state.user)
  const { t } = useTranslation()

  const handleRedirectToDEpositTab = () => {
    navigate("?service=cashier&tab=deposit")
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tabs.Body.Item>
      <div>
        <div className="mt-2 text-sm">
          <p className="text-gray-700 text-sm">
            {t(
              "Referring your friends to our Casino is one of the easiest and fastest ways to earn free gift and make more money."
            )}
          </p>
          <p className="test-sm" style={{ color: themeColors?.tertiary }}>
            {t("Get everyone in on the fun!")}
          </p>
        </div>
        <div className="mb-5">
          <div className="mt-6 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center ">
            <div
              className="desktop:w-[58%] xs:w-full flex flex-col rounded-xl desktop:mr-2 xs:mb-2 desktop:mb-0 px-5 py-4"
              style={{
                backgroundColor: themeColors?.lightTertiary,
              }}
              css={borderStyle(themeColors)}
            >
              <h1 className="text-specialPrimary font-bold">
                {t("Your Referrer")}
              </h1>
              <div className="my-5">
                <Input
                  disabled
                  label={
                    referrer === "" ? t("You have not been referred") : referrer
                  }
                  disabledInputBackground
                />
              </div>
              <div className="mt-3">
                <p className="text-sm text-gray-700">
                  {t("Do you want to become a referrer ?")}{" "}
                  <a
                    href="https://help.casinocarbon.com/en/articles/6420337-affiliates-explained"
                    className="ml-3"
                    rel="noreferrer"
                    style={{ color: themeColors?.tertiary }}
                    target="_blank"
                  >
                    {t("Learn more")}
                  </a>
                </p>
                <p className="text-gray-700 text-sm">
                  {t(
                    "In order to receive your Referral bonus you need to verify your account and make your first deposit"
                  )}
                </p>
              </div>
              <MediaQuery minWidth={375} maxWidth={767}>
                <div className="mb-3 mt-7">
                  <Button.Primary
                    fullWidth
                    onClick={handleRedirectToDEpositTab}
                  >
                    {t("Make a Deposit")}
                  </Button.Primary>
                </div>
              </MediaQuery>
            </div>
            <MediaQuery minWidth={768}>
              <div
                className="desktop:w-1/2 xs:w-full flex flex-col  rounded-xl desktop:mr-3 xs:mb-2 desktop:mb-0 px-5 py-4 "
                style={{
                  backgroundColor: themeColors?.lightTertiary,
                }}
                css={borderStyle(themeColors)}
              >
                <div className="flex justify-center items-center mb-3">
                  <img
                    fetchpriority="low"
                    src={imageDistributed("referrerAsset")}
                    className="w-auto h-44 my-4"
                    alt="buy-crypto-asset"
                  />
                </div>
                <div className="mb-3">
                  <Button.Primary
                    fullWidth
                    onClick={handleRedirectToDEpositTab}
                  >
                    {t("Make a Deposit")}
                  </Button.Primary>
                </div>
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

const AffiliateModal = ({ activeService, handleCloseModal, activeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useQuery(
    ["all"],
    async () => {
      await dispatch(getAffiliateInformation())
    },
    {
      onError: () => {
        toast.error("An error has occurred", "Please try again later.")
      },
    }
  )

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="xs:h-[680px] xs:overflow-auto modal-affiliate-view ">
        <MediaQuery maxWidth={959}>
          <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
            <h1
              className=" text-xl leading-5 font-medium px-7"
              style={{ color: themeColors?.tertiary }}
            >
              {t("Affiliate")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className="flex justify-between items-center">
            <h1 className="pl-10" style={{ color: themeColors?.tertiary }}>
              {t("Affiliate")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </div>
        </MediaQuery>
        <div>
          <Tabs selectedIndex={tabIndexes[activeTab] || tabIndexes.referral}>
            <div>
              <Tabs.Header>
                <div>
                  <Tabs.Header.Item>{t("Referral")}</Tabs.Header.Item>
                  <Tabs.Header.Item>{t("Referrer")}</Tabs.Header.Item>
                </div>
              </Tabs.Header>
              <Tabs.Body>
                <div>
                  <ReferralTab />
                  <ReferrerTab />
                </div>
              </Tabs.Body>
            </div>
          </Tabs>
        </div>
      </div>
    </Modal>
  )
}

AffiliateModal.defaultProps = {
  activeService: "affiliate",
  activeTab: tabIndexes.referral,
}

AffiliateModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
}
export default AffiliateModal
