import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { useQuery } from "react-query"
import GameCard from "./GameCard"
import { categories, gamesAssets } from "../constants/games"
import { logger } from "../helpers"
import { getGamesCatalog } from "../actions/game"
import { GET_GAMES_FULFILLED } from "../actions/types"

const GamesView = ({
  tag,
  search,
  mobile = false,
  display,
  countryCode,
  selectedProvider,
}) => {
  const dispatch = useDispatch()
  useQuery({
    queryKey: ["getGamesCatalog"],
    queryFn: () => getGamesCatalog(),
    refetchOnMount: true,
    onSuccess: ({ data: gamesCatalogData }) => {
      dispatch({
        type: GET_GAMES_FULFILLED,
        payload: gamesCatalogData,
      })
    },
    onError: (error) => {
      logger(error)
    },
  })
  const searchString = String(search || "")

  const { games } = useSelector((state) => state.games)

  let gamesList = []
  if (Array.isArray(games)) {
    if (tag.toLowerCase() !== "all") {
      gamesList = games.filter((game) =>
        game.categories ? game?.categories.includes(tag) === true : true
      )
    } else {
      gamesList = games
    }
  }
  gamesList.filter((game) =>
    game.restricted_countries
      ? !game?.restricted_countries.includes(countryCode)
      : true
  )

  const filteredGames = gamesList.filter(
    (game) => !game.restricted_countries.includes(countryCode)
  )
  gamesList = filteredGames

  const categorySelected = categories.find(
    (category) => category.tag === gamesList[0]?.categories[0]
  )

  if (
    gamesList &&
    categorySelected &&
    Object.prototype.hasOwnProperty.call(
      categorySelected,
      "coeff-session-played"
    )
  ) {
    gamesList.sort((gameA, gameB) => {
      const resultA =
        categorySelected["coeff-session-played"] * gameA.session_played_count +
        categorySelected["coeff-score"] * gameA.score
      const resultB =
        categorySelected["coeff-session-played"] * gameB.session_played_count +
        categorySelected["coeff-score"] * gameB.score

      return resultB - resultA // sort in descending order
    })
  }

  const gamesFiltered = (providerSelected) => {
    let gamesListFilteredByProvider = []
    if (providerSelected === undefined) {
      gamesListFilteredByProvider = gamesList
    } else if (providerSelected.label === "All Providers") {
      gamesListFilteredByProvider = gamesList
    } else {
      gamesListFilteredByProvider = gamesList.filter((game) =>
        game.provider.includes(providerSelected.label)
      )
    }
    return gamesListFilteredByProvider
  }

  const gamesByTag = useMemo(() => {
    if (!search) {
      return gamesFiltered(selectedProvider)
    }
    if (tag.toLowerCase() === "all") {
      return gamesFiltered(selectedProvider).filter((game) => {
        const titleString = String(game.name || "")
        return (
          titleString.toLowerCase().includes(searchString.toLowerCase()) ===
          true
        )
      })
    }
    return gamesFiltered(selectedProvider).filter((game) => {
      const titleString = String(game.name || "")
      return (
        titleString.toLowerCase().includes(searchString.toLowerCase()) === true
      )
    })
  }, [tag, search, selectedProvider])

  return gamesByTag.slice(0, display).map((game) => {
    if (mobile) {
      return (
        <GameCard
          key={game.slug}
          slug={game.slug}
          description={game.description}
          title={game.name}
          className="md:mb-5   desktop:my-0 xs:m-1  games-catalog-card"
          imageSource={gamesAssets[game.slug]}
        />
      )
    }

    return (
      <GameCard
        key={game.slug}
        slug={game.slug}
        description={game.description}
        title={game.name}
        className="desktop:mb-2 desktop:my-0 xs:m-2 games-catalog-card"
        imageSource={gamesAssets[game.slug]}
      />
    )
  })
}
GamesView.defaultProps = {
  search: "",
  display: 0,
  countryCode: "ZN",
}

GamesView.propTypes = {
  tag: PropTypes.string.isRequired,
  search: PropTypes.string,
  display: PropTypes.number,
  countryCode: PropTypes.string,
  selectedProvider: PropTypes.string,
}

export default GamesView
