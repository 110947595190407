import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSearchParams, Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MediaQuery from "react-responsive"
import { useIntercom } from "react-use-intercom"
import { faUserPlus, faSignIn } from "@fortawesome/free-solid-svg-icons"
import {
  brandLogo,
  formatter,
  getBrandFromUrl,
  imageDistributed,
} from "../helpers"
import NotificationSpace from "./NotificationSpace"
import { postLogOut } from "../actions/auth"
import Button from "./Button"
import { DropdownMenu } from "./DropdownMenu"
import affiliateIcon from "../assets/icons/affiliate-icon.png"
import helpCenterIcon from "../assets/icons/help-center-icon.png"
import cashierIcon from "../assets/icons/cashier-icon.png"
import accountIcon from "../assets/icons/account-icon.png"
import liveSupportIcon from "../assets/icons/live-support-icon.png"
import historyIcon from "../assets/icons/history-icon.png"
import hamburgerMenu from "../assets/icons/hamburgerMenu.svg"
import bonusCodeIcon from "../assets/icons/bonus-code-icon.svg"
// import bellIcon from "../assets/icons/bill-icon.png"
import searchIcon from "../assets/icons/search-icon.png"
import logOutIcon from "../assets/icons/icon-log-out.svg"
import HamburgerMenu from "./HamburgerMenu"
import PageLink from "./PageLink"
import themes from "../themes"

// TODO : rename HamburgerMenu to Menu ,and its children else
// TODO : change maxWidth for mobile and desktop and use in all platform breakpoints

const Navbar = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { show: showIntercom } = useIntercom()
  const { username } = useSelector((state) => state.auth)
  const { balance } = useSelector((state) => state.wallet)
  const { loggedIn } = useSelector((state) => state.auth)
  const [searchParams, setSearchParams] = useSearchParams()
  const [shown, setShown] = useState(false)
  const [showNotificationSpace, setShowNotificationSpace] = useState(false)
  const [gamePage, setGamePage] = useState(false)
  const [onPaymentPage, setOnPaymentPage] = useState(false)

  const handleShowNotifications = () => {
    setShowNotificationSpace(!showNotificationSpace)
  }
  const handleOpenAccountModal = () => {
    searchParams.set("service", "account")
    setSearchParams(searchParams)
  }
  const handleOpenAffiliateModal = () => {
    searchParams.set("service", "affiliate")
    setSearchParams(searchParams)
    setShown(false)
  }
  const handleOpenLoginModal = () => {
    searchParams.set("service", "login")
    setSearchParams(searchParams)
    setShown(false)
  }
  const handleOpenRegisterModal = () => {
    searchParams.set("service", "register")
    setSearchParams(searchParams)
    setShown(false)
  }
  const handleOpenCashierModal = () => {
    searchParams.set("service", "cashier")
    setSearchParams(searchParams)
  }

  const handleOpenBonusCodeModal = () => {
    searchParams.set("service", "bonus-code")
    setSearchParams(searchParams)
  }

  const handleLogOut = async () => {
    const result = await dispatch(await postLogOut())
    if (result && result.status === 200) {
      searchParams.set("service", "login")
      setSearchParams(searchParams)
      window.location.reload()
    }
  }

  const handleOpenDepositModal = () => {
    searchParams.set("service", "cashier")
    searchParams.set("tab", "depsoit")
    setSearchParams(searchParams)
  }

  const handleOpenHistoryModal = () => {
    searchParams.set("service", "history")
    setSearchParams(searchParams)
  }

  const handleShowMobileNavbar = () => {
    setShown(!shown)
  }
  const checkThePageGame = () => {
    if (location.pathname.slice(1, 6) === "games") {
      setGamePage(true)
    } else {
      setGamePage(false)
    }
  }
  const handleSelectOnPaymentPages = () => {
    if (location.pathname.includes("/cashier")) {
      setOnPaymentPage(true)
    } else {
      setOnPaymentPage(false)
    }
  }

  useEffect(() => {
    checkThePageGame()
    handleSelectOnPaymentPages()
  }, [location.pathname])

  const handleCloseMobileNavbar = () => setShown(false)
  const notificationArray = JSON.parse(localStorage.getItem("notifications"))

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  const languageSelected = i18n.language?.split("-")[0]

  return (
    <nav
      className="py-4 my-0 h-18 text-white  shadow-2xl  fixed desktop:top-3 left-0 right-0 z-40 w-full desktop:rounded-2xl mx-auto max-width-navbar navbar-custom-index"
      style={{
        backgroundColor: themeColors?.lightPrimaryTint,
        color: "white",
      }}
    >
      <div className="flex justify-between items-center sm:px-7  desktop:px-3 xs:px-6">
        <div className="flex justify-between items-center">
          <h1 className="text-md md:text-md px-3">
            <PageLink to="/">
              {" "}
              <img
                fetchpriority="high"
                src={brandLogo()}
                alt="logo"
                width="150"
              />
            </PageLink>
          </h1>
          {loggedIn && (
            <div className="text-white xs:hidden md:block">
              <span
                className="pr-9 pl-5 py-[10px] rounded-full relative -right-7 -z-10 shadow-[0_4px_4px_4px_rgba(0, 0, 0, 0.25)]"
                style={{ backgroundColor: themeColors?.darkenBlue }}
              >
                {/* {formatter.format(balance)} */}
                {gamePage ? t("Game In Progress") : formatter.format(balance)}
              </span>
              {onPaymentPage ? (
                ""
              ) : (
                <Button.Primary size="small" onClick={handleOpenCashierModal}>
                  {t("Deposit")}
                </Button.Primary>
              )}
            </div>
          )}
        </div>
        <div
          className={`desktop:hidden ${loggedIn ? " hidden" : " block"}`}
          onClick={handleShowMobileNavbar}
          role="button"
          onKeyDown={Function.prototype}
          tabIndex={0}
        >
          <img fetchpriority="high" src={hamburgerMenu} alt="hamburger-menu" />
        </div>

        {loggedIn ? (
          <div className="flex items-center desktop:pr-2">
            <div className="flex ">
              <Link
                to="/category/all"
                type="button"
                className="relative xs:mr-3 flex justify-center items-center desktop:mr-4"
              >
                <img
                  fetchpriority="high"
                  src={searchIcon}
                  alt="notification-bell-icon"
                  className="w-6 h-6"
                />
              </Link>
              <button
                type="button"
                className="relative xs:mr-3 flex justify-center items-center desktop:mr-4"
                onClick={handleShowNotifications}
              >
                <img
                  fetchpriority="high"
                  src={imageDistributed("billIcon")}
                  alt="notification-bell-icon"
                  className="w-8 h-8"
                />
              </button>
            </div>
            <NotificationSpace
              onClose={handleShowNotifications}
              openDepositModal={handleOpenDepositModal}
              isShown={showNotificationSpace}
              notificationsArray={notificationArray}
              userName={username}
            />
            <MediaQuery minWidth={300} maxWidth={959}>
              <HamburgerMenu
                user={username}
                handleLogout={handleLogOut}
                balance={balance}
              >
                <div>
                  <HamburgerMenu.Item onClick={handleOpenCashierModal}>
                    <span
                      type="button"
                      className="w-full text-left px-5  items-center inline-flex"
                    >
                      <img
                        fetchpriority="high"
                        src={cashierIcon}
                        alt="cashierIcon"
                        className="w-7"
                      />

                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Cashier")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Cash out your earnings")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item
                    onClick={handleOpenAccountModal}
                    isStripped
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex  items-center "
                    >
                      <img
                        fetchpriority="high"
                        src={accountIcon}
                        alt="accountIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Account")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("View your profile")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item onClick={handleOpenAffiliateModal}>
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center "
                    >
                      <img
                        fetchpriority="high"
                        src={affiliateIcon}
                        alt="affiliateIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Affiliate")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Open affiliate panel")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item
                    onClick={handleOpenBonusCodeModal}
                    isStripped
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center "
                    >
                      <img
                        fetchpriority="high"
                        src={bonusCodeIcon}
                        alt="affiliateIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Bonus code")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Get and activate bonus code")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item onClick={handleOpenHistoryModal}>
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        src={historyIcon}
                        alt="historyIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("History")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("View previous transactions")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item isStripped>
                    <span
                      type="button"
                      className="w-full h-full text-left px-5 inline-block"
                    >
                      <a
                        // href="https://help.casinocarbon.com/en/"
                        href={`${
                          languageSelected === "fr"
                            ? "https://help.casinocarbon.com/fr/"
                            : "https://help.casinocarbon.com/en/"
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex w-full"
                      >
                        <img
                          fetchpriority="high"
                          src={helpCenterIcon}
                          alt="helpCenterIcon"
                          className="w-auto h-8 aspect-square"
                        />
                        <span className="flex flex-col ml-4">
                          <span className=" font-bold text-[15px]">
                            {t("Help Center")}
                          </span>
                          <span className="text-white text-[10px]">
                            {t("Find answers to your questions")}
                          </span>
                        </span>
                      </a>
                    </span>
                  </HamburgerMenu.Item>
                  <HamburgerMenu.Item onClick={showIntercom} isLastItem>
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        src={liveSupportIcon}
                        alt="liveSupportIcon"
                        className="z-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Live Support")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Speak to customer support")}
                        </span>
                      </span>
                    </span>
                  </HamburgerMenu.Item>
                </div>
              </HamburgerMenu>
            </MediaQuery>
            <MediaQuery minWidth={960}>
              <DropdownMenu
                items={[{ label: username }]}
                type={DropdownMenu.types.DARK}
                isMenu
              >
                <div>
                  <DropdownMenu.Item
                    onClick={handleOpenCashierModal}
                    type={DropdownMenu.types.DARK}
                    className="rounded-t-2xl"
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5  items-center inline-flex"
                    >
                      <img
                        fetchpriority="high"
                        src={cashierIcon}
                        alt="cashierIcon"
                        className="w-7"
                      />

                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Cashier")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Cash out your earnings")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={handleOpenAccountModal}
                    type={DropdownMenu.types.DARK}
                    isStripped
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        src={accountIcon}
                        alt="accountIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Account")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("View your profile")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={handleOpenAffiliateModal}
                    type={DropdownMenu.types.DARK}
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center "
                    >
                      <img
                        fetchpriority="high"
                        src={affiliateIcon}
                        alt="affiliateIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Affiliate")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Open affiliate panel")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={handleOpenBonusCodeModal}
                    type={DropdownMenu.types.DARK}
                    isStripped
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center "
                    >
                      <img
                        fetchpriority="high"
                        src={bonusCodeIcon}
                        alt="affiliateIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Bonus code")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Get and activate bonus code")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    type={DropdownMenu.types.DARK}
                    onClick={handleOpenHistoryModal}
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        src={historyIcon}
                        alt="historyIcon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("History")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("View previous transactions")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item type={DropdownMenu.types.DARK} isStripped>
                    <span
                      type="button"
                      className="w-full h-full text-left px-5 inline-block"
                    >
                      <a
                        // href="https://help.casinocarbon.com/en/"
                        href={`${
                          languageSelected === "fr"
                            ? "https://help.casinocarbon.com/fr/"
                            : "https://help.casinocarbon.com/en/"
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex w-full"
                      >
                        <img
                          fetchpriority="high"
                          src={helpCenterIcon}
                          alt="helpCenterIcon"
                          className="w-auto h-8 aspect-square"
                        />
                        <span className="flex flex-col ml-4">
                          <span className=" font-bold text-[15px]">
                            {t("Help Center")}
                          </span>
                          <span className="text-white text-[10px]">
                            {t("Find answers to your questions")}
                          </span>
                        </span>
                      </a>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={showIntercom}
                    type={DropdownMenu.types.DARK}
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        src={liveSupportIcon}
                        alt="liveSupportIcon"
                        className="z-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Live Support")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Speak to customer support")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={handleLogOut}
                    type={DropdownMenu.types.DARK}
                    isStripped
                  >
                    <span
                      type="button"
                      className="w-full text-left px-5 inline-flex items-center"
                    >
                      <img
                        fetchpriority="high"
                        loading="lazy"
                        decoding="async"
                        src={logOutIcon}
                        alt="logout-icon"
                        className="w-7"
                      />
                      <span className="flex flex-col ml-4">
                        <span className=" font-bold text-[15px]">
                          {t("Logout")}
                        </span>
                        <span className="text-white text-[10px]">
                          {t("Come back soon for more fun")}
                        </span>
                      </span>
                    </span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item type="dark" className="rounded-b-2xl">
                    <span className="w-full text-left px-5 inline-flex items-center text-specialDarkenBlue h-7" />
                  </DropdownMenu.Item>
                </div>
              </DropdownMenu>
            </MediaQuery>
          </div>
        ) : (
          <div className="xs:hidden md:block">
            <Button.Secondary size="small" onClick={handleOpenRegisterModal}>
              {t("Sign Up")}
            </Button.Secondary>
            <Button.Primary size="small" onClick={handleOpenLoginModal}>
              {t("Sign In")}
            </Button.Primary>
          </div>
        )}

        <div
          id="popup"
          className={`z-50 fixed w-full flex justify-center inset-0 ${
            shown ? "xs:block xl:hidden" : "hidden"
          }`}
        >
          <div
            onClick={handleCloseMobileNavbar}
            className="w-full  z-0 absolute inset-0 bg-overlayPurple  cursor-auto"
            role="button"
            tabIndex={0}
            onKeyDown={Function.prototype}
          >
            &nbsp;
          </div>
          <div
            className=" absolute top-0 right-0 w-9/12 h-screen text-black pt-5"
            style={{ backgroundColor: themeColors?.secondary }}
          >
            <h1 className="text-md md:text-md flex justify-center items-center">
              <img
                fetchpriority="high"
                src={brandLogo()}
                alt="logo"
                width="135"
                height="60"
                className="mx-auto"
              />
            </h1>
            <div className="py-8 flex flex-col">
              <button
                type="button"
                className="w-full py-5 text-white text-left px-4 flex items-center"
                onClick={handleOpenRegisterModal}
              >
                {" "}
                <FontAwesomeIcon icon={faUserPlus} className="mr-3 w-10 h-7" />
                {t("Sign Up")}
              </button>
              <button
                type="button"
                className="w-full text-white bg-lightPrimary py-5 text-left px-4 flex items-center"
                onClick={handleOpenLoginModal}
              >
                {" "}
                <FontAwesomeIcon icon={faSignIn} className="mr-3 w-10 h-7" />
                {t("Sign In")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
