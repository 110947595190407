import React from "react"
import PropTypes from "prop-types"
import { Menu } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const dropDownTypes = {
  BIG: "big",
  SMALL: "small",
  DARK: "dark",
  CUSTOM: "custom",
}

const dropdownButtonStyle = {
  small: "text-white px-2 border border-tertiary bg-opacity-20 py-2 px-[10px] ",
  custom:
    "text-white px-2 border border-tertiary bg-opacity-20 py-2 px-[10px] ",
  big: "border border-tertiary bg-opacity-20 px-11 py-3 text-xl font-medium text-white  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
  dark: "px-4 py-2",
}

const dropdownMenuItemsStyle = {
  small: "w-28 bg-white rounded-md top-11",
  custom: "w-32 bg-white rounded-md top-12 rounded-md",
  big: "w-56 bg-white rounded-md",
  dark: "w-72 rounded-2xl top-16",
}

const dropdownStyle = {
  small: "w-32",
  big: "w-56",
  custom: "w-auto mx-auto",
  // dark: "w-40",
}

export const DropDownItem = ({
  onClick,
  children,
  type,
  isStripped,
  className,
}) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <Menu.Item onClick={onClick}>
      {({ active }) =>
        type === "dark" ? (
          <button
            type="button"
            className={`flex  items-center w-full py-4 text-md px-1  ${className}`}
            style={{
              backgroundColor: isStripped
                ? themeColors?.darkenTintBlue
                : themeColors?.specialDarkenBlue,
              borderBlockColor: isStripped ? themeColors?.lightDarken : "",
            }}
          >
            {children}
          </button>
        ) : (
          <button
            type="button"
            className={`${
              active ? "bg-lightPurple text-white rounded-md" : "text-gray-900"
            }  flex  items-center w-full py-3 text-md px-1`}
          >
            {children}
          </button>
        )
      }
    </Menu.Item>
  )
}
DropDownItem.defaultProps = {
  onClick: Function.prototype,
  type: dropDownTypes.BIG,
  isStripped: false,
  className: "",
}

DropDownItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf(["small", "big", "dark", "custom"]),
  isStripped: PropTypes.bool,
  className: PropTypes.string,
}

// TODO: to drop this. Replace with better behavior.

export const CustomDropdownButtonBehavior = ({ type, open }) => {
  if (type !== "dark" && !open) {
    return (
      <ChevronDownIcon
        className=" h-5 w-5 text-violet-200 hover:text-violet-100"
        aria-hidden="true"
      />
    )
  }
  if (type !== "dark" && open) {
    return (
      <ChevronUpIcon
        className=" h-5 w-5 text-violet-200 hover:text-violet-100"
        aria-hidden="true"
      />
    )
  }
  return <span />
}
CustomDropdownButtonBehavior.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
}

export const DropdownMenu = ({
  items,
  children,
  type,
  selectedLanguage,
  isMenu,
}) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  const dropdownButtonBackground = {
    small: themeColors?.lightPrimaryTint,
    custom: themeColors?.lightPrimaryTint,
    big: "",
    dark: themeColors?.darkenBlue,
  }
  return (
    <div className={`text-center  ${dropdownStyle[type]}`}>
      <Menu as="div" className="relative inline-block text-center ">
        {({ open }) => (
          <>
            <Menu.Button
              className={`flex items-center w-full justify-center    rounded-full ${dropdownButtonStyle[type]}`}
              style={{ backgroundColor: dropdownButtonBackground[type] }}
            >
              {isMenu ? (
                <span className="font-bold text-xl">{items[0].label}</span>
              ) : (
                <div>
                  {Object.keys(selectedLanguage).length !== 0 ? (
                    <span className="flex justify-between items-center">
                      {selectedLanguage.imageSource && (
                        <img
                          fetchpriority="high"
                          src={selectedLanguage.imageSource}
                          className="w-6"
                          alt="flag"
                        />
                      )}
                      <span
                        className={`font-normal mx-2 self-center ${
                          type === "custom" ? "whitespace-nowrap" : ""
                        }`}
                      >
                        {selectedLanguage.label
                          ? selectedLanguage.label
                          : selectedLanguage.title?.slice(
                              0,
                              selectedLanguage.title?.indexOf(" ")
                            )}
                      </span>
                    </span>
                  ) : (
                    <span className="flex justify-between">
                      <img
                        fetchpriority="high"
                        src={items[0].imageSource}
                        className="w-6"
                        alt="flag"
                      />
                      <span className="font-normal mx-2">{items[0].label}</span>
                    </span>
                  )}
                </div>
              )}
              <CustomDropdownButtonBehavior open={open} type={type} />
            </Menu.Button>
            <Menu.Items
              className={`absolute z-50  right-0  md:mt-2   md:h-auto  origin-top-right divide-y divide-gray-100shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownMenuItemsStyle[type]}`}
            >
              {children}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  )
}

DropdownMenu.defaultProps = {
  type: "big",
  selectedLanguage: {},
  isMenu: false,
}

DropdownMenu.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf(["big", "small", "custom"]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      imageSource: PropTypes.string,
    })
  ).isRequired,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string,
    imageSource: PropTypes.string,
    title: PropTypes.string,
  }),
  isMenu: PropTypes.bool,
}

DropdownMenu.Item = DropDownItem
DropdownMenu.types = dropDownTypes
