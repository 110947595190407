import { combineReducers } from "redux"

import auth from "./auth"
import user from "./user"
import wallet from "./wallet"
import games from "./games"

export default combineReducers({
  auth,
  user,
  wallet,
  games,
})
