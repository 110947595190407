import React, { useEffect, useState } from "react"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"
import { DropdownMenu } from "./DropdownMenu"
import PageLink from "./PageLink"
import enFlag from "../assets/images/flags/flag-en.png"
import frFlag from "../assets/images/flags/flag-fr.png"
import spFlag from "../assets/images/flags/flag-sp.png"
import itFlag from "../assets/images/flags/flag-it.png"
import deFlag from "../assets/images/flags/flag-de.png"
import netherlandFlag from "../assets/images/flags/flag-nl.png"
import bitEncryptionAsset from "../assets/images/home/bit-encryption.png"
import ageRestrictionImage from "../assets/images/home/age-restriction.png"
import curacaoIcon from "../assets/images/home/gaming-curacao-icon.png"
import mastercardAsset from "../assets/images/home/visa-asset.png"
import visaAsset from "../assets/images/home/visa-vector-asset.png"
import { brandLogo, promptBrand, getBrandFromUrl } from "../helpers"
import themes from "../themes"

// TODO : add real links to the the footer section social media icons

const languages = [
  { label: "EN", key: "en", imageSource: enFlag },
  { label: "FR", key: "fr", imageSource: frFlag },
  { label: "SP", key: "sp", imageSource: spFlag },
  { label: "IT", key: "it", imageSource: itFlag },
  { label: "NL", key: "nl", imageSource: netherlandFlag },
  { label: "DE", key: "de", imageSource: deFlag },
]

const Footer = () => {
  const { i18n, t } = useTranslation()
  const { show: showIntercom } = useIntercom()
  const [languageSelected, setLanguageSelected] = useState({})

  const handleSelectLanguage = (language) => {
    setLanguageSelected(language)
    i18n.changeLanguage(language.key)
  }

  useEffect(() => {
    setLanguageSelected(
      languages.find((lng) => lng.key === i18n.language?.split("-")[0])
    )
  }, [])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  const languageSelectedIs = i18n.language?.split("-")[0]

  return (
    <footer
      className=" pt-14 desktop:pb-8 pb-0 text-white 2xl:px-22"
      style={{ backgroundColor: themeColors?.lightPrimaryTint, color: "white" }}
    >
      <div className="about-section container-max-width">
        <div className=" sm:px-4 desktop:px-0 about-section xs:px-6 md:px-[45px] desktop:px-auto pad-dpr">
          <div className="flex desktop:flex-row xs:flex-col w-full mobile-big-screen:flex-col tablet:flex-row">
            <div className="flex xs:flex-col desktop:w-1/2 xs:w-full mobile-big-screen:w-full tablet:w-1/2 mobile-big-screen:pr-3 tablet:pr-3">
              <div className="flex flex-col desktop:w-[70%] w-full ">
                <div className="flex flex-row justify-center items-center">
                  <img
                    fetchpriority="high"
                    src={brandLogo()}
                    alt="logo"
                    className="md:w-45 xs:w-[220px]  md:mb-0 xs:mb-6 max-w-none mobile:w-[150px] desktop:w-[220px] logo-footer"
                  />
                  <div className="md:my-0 md:mb-0 xs:my-3 xs:mb-8 mobile-big-screen:mt-0">
                    <DropdownMenu
                      items={languages}
                      type="small"
                      selectedLanguage={languageSelected}
                    >
                      {languages.map((item) => (
                        <DropdownMenu.Item
                          onClick={() => handleSelectLanguage(item)}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              fetchpriority="high"
                              src={item.imageSource}
                              className="w-6"
                              alt="flag"
                            />
                            <span className="font-medium text-wl mx-2 text-black">
                              {item.label}
                            </span>
                          </div>
                        </DropdownMenu.Item>
                      ))}
                    </DropdownMenu>
                  </div>
                </div>
                <div className="flex flex-row xs:justify-center mobile-big-screen:justify-center tablet:justify-start mobile-big-screen:mb-5 tablet:mt-3 tablet:hidden desktop:hidden mb-3 footer-top-left-icon items-center">
                  <img
                    fetchpriority="low"
                    src={ageRestrictionImage}
                    alt="age-restriction"
                    className="w-10 mr-3"
                  />
                  {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://licensing.gaming-curacao.com/validator/?lh=43ad2ccdeca9f16234043c2f02f5023a"
                    target="_blank"
                    className=""
                  >
                    <img
                      fetchpriority="low"
                      src={curacaoIcon}
                      alt="curacao-license"
                      className="w-10 mr-2 max-w-none"
                    />
                  </a>
                  <img
                    fetchpriority="low"
                    src={bitEncryptionAsset}
                    alt="bit-encryption"
                    className="w-15 h-10 bit-encryption-image max-w-full"
                  />
                </div>
                <p className="text-xs desktop:mt-3 text-justify">
                  {promptBrand("site")} is operated by Carbon Games N.V. (Reg
                  no. 161315), Abraham de Veerstraat 9, Willemstad, P.O. Box
                  3421, Curaçao, which having all the rights to operate the
                  gaming software and/or offer games of chance under the Curacao
                  License no. 365/JAZ. Payments are processed by Aryahi Limited
                  (ΗΕ 435973) registered in Cyprus, acting as the payments agent
                  as per agreement between the two companies.
                </p>
                <div className="flex items-center xs:mb-3 mt-2 mobile:hidden mobile-big-screen:hidden">
                  <img
                    fetchpriority="low"
                    src={mastercardAsset}
                    alt="mastercard-asset"
                    className="desktop:w-auto desktop:h-5 mr-3 xs:w-auto xs:h-5"
                  />
                  <img
                    fetchpriority="low"
                    src={visaAsset}
                    alt="visa-asset"
                    className="desktop:w-auto desktop:h-5 mr-3 xs:w-auto xs:h-5"
                  />
                </div>
              </div>
              <MediaQuery minWidth={960}>
                <div className="flex flex-col desktop:w-[50%] xs:w-full desktop:pl-10 xs:mb-5 policies-section-footer desktop:mt-4">
                  <p className="font-bold text-xl">Policies</p>
                  <p className="mt-1 footer-link">
                    <PageLink to="terms/terms-and-conditions">
                      Terms and Conditions
                    </PageLink>
                  </p>
                  <p className="mt-1 footer-link">
                    <PageLink to="policy/privacy-policy">
                      Privacy Policy
                    </PageLink>
                  </p>
                  <p className="mt-1 footer-link">
                    <PageLink to="policy/aml-policy">Aml Policy</PageLink>
                  </p>
                  <p className="mt-1 footer-link">
                    <PageLink to="policy/gaming-policy">
                      Responsible Gaming
                    </PageLink>
                  </p>
                </div>
              </MediaQuery>
            </div>
            <div className="flex desktop:flex-row xs:flex-col desktop:w-1/2 xs:w-full tablet:w-1/2">
              <MediaQuery minWidth={960}>
                <div className="flex-col desktop:w-[30%] xs:w-full desktop:pl-12 help-section-footer xs:mb-5 desktop:mt-4">
                  <p className="text-xl font-bold">Help</p>
                  <p className="mt-1 footer-link">
                    <span
                      onClick={showIntercom}
                      onKeyDown={Function.prototype}
                      role="button"
                      tabIndex={0}
                    >
                      {" "}
                      Live Support
                    </span>
                  </p>
                  <p className="mt-1 footer-link">
                    {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      href={`${
                        languageSelectedIs === "fr"
                          ? "https://help.casinocarbon.com/fr/"
                          : "https://help.casinocarbon.com/en/"
                      }`}
                      target="_blank"
                      className="flex w-full"
                    >
                      Help Center
                    </a>
                  </p>
                </div>
              </MediaQuery>
              <div className="flex flex-col desktop:w-1/2 xs:w-full ">
                <div className="flex flex-row xs:justify-center mobile-big-screen:justify-start tablet:justify-start mobile-big-screen:hidden mobile:hidden tablet:mt-3 footer-top-left-icon items-center">
                  <img
                    fetchpriority="low"
                    src={ageRestrictionImage}
                    alt="age-restriction"
                    className="w-10 mr-3"
                  />
                  {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://licensing.gaming-curacao.com/validator/?lh=43ad2ccdeca9f16234043c2f02f5023a"
                    target="_blank"
                    className=""
                  >
                    <img
                      fetchpriority="low"
                      src={curacaoIcon}
                      alt="curacao-license"
                      className="w-10 mr-2 max-w-none"
                    />
                  </a>
                  <img
                    fetchpriority="low"
                    src={bitEncryptionAsset}
                    alt="bit-encryption"
                    className="w-15 h-10 bit-encryption-image max-w-full"
                  />
                </div>
                <p className="text-xs mt-5 tablet:mt-[33px] footer-right-tex mobile-big-screen:mt-9 desktop:w-[115%] text-justify">
                  {t(
                    `This website offers gaming with risk experience. To be a user of our site you must be over 18 y.o. We are not responsible for the violation of your local laws related to i-gaming. Play resposibly and have fun on ${promptBrand(
                      "name"
                    )}.`
                  )}
                </p>
                <div className="flex justify-between mt-2">
                  <div className="flex items-center xs:mb-3 mt-2 desktop:hidden tablet:hidden mobile-big-screen">
                    <img
                      fetchpriority="low"
                      src={mastercardAsset}
                      alt="mastercard-asset"
                      className="desktop:w-auto desktop:h-5 mr-3 xs:w-auto xs:h-5"
                    />
                    <img
                      fetchpriority="low"
                      src={visaAsset}
                      alt="visa-asset"
                      className="desktop:w-auto desktop:h-5 mr-3 xs:w-auto xs:h-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MediaQuery minWidth={601} maxWidth={959}>
            <div className="flex flex-row justify-center mobile-big-screen:text-[10px] tablet:text-sm font-bold mt-5 w-[99%] mx-auto">
              <PageLink
                to="terms/terms-and-conditions"
                classNames="text-tertiary border-r-2 border-tertiary px-2"
              >
                Terms and Conditions
              </PageLink>
              <PageLink
                to="/privacy-policy"
                classNames="text-tertiary border-r-2 border-tertiary px-2"
              >
                Privacy Policy
              </PageLink>
              <PageLink
                to="/aml-policy"
                classNames="text-tertiary border-r-2 border-tertiary px-2"
              >
                Aml Policy
              </PageLink>
              <PageLink
                to="/gaming-policy"
                classNames="text-tertiary border-r-2 border-tertiary px-2"
              >
                Responsible Gaming
              </PageLink>
              <span
                className="text-tertiary border-r-2 border-tertiary px-2"
                onClick={showIntercom}
                onKeyDown={Function.prototype}
                role="button"
                tabIndex={0}
              >
                {" "}
                Live Support
              </span>
              {/*  eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={`${
                  languageSelectedIs === "fr"
                    ? "https://help.casinocarbon.com/fr/"
                    : "https://help.casinocarbon.com/en/"
                }`}
                target="_blank"
                className="text-tertiary pl-1"
              >
                Help Center
              </a>
            </div>
          </MediaQuery>
        </div>
      </div>
      <p className="container-max-width text-secondary">for kinguin</p>
      <div className="px-0  w-50 mt-5 py-5 text-center   bottom-0 border-t border-optional xs:text-sm desktop:text-base">
        <p>
          Copyright &copy; 2022-All Rights Reserved by {promptBrand("name")}
        </p>
      </div>
    </footer>
  )
}

export default Footer
