import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/free-solid-svg-icons"
import IconsButton from "./IconsButton"

export const ModalHeader = ({ handleCloseModal, tertiaryIcon }) => (
  <div className=" dark:bg-gray-600 rounded-tl-md rounded-tr-md py-4 px-2 flex items-center justify-end">
    <button
      type="button"
      onClick={handleCloseModal}
      aria-label="close modal"
      className=" w-12 rounded-md focus:outline-none"
    >
      <IconsButton>
        <FontAwesomeIcon
          icon={faX}
          className={`${tertiaryIcon ? "text-tertiary" : "text-black"}`}
        />
      </IconsButton>
    </button>
  </div>
)
ModalHeader.defaultProps = {
  tertiaryIcon: false,
}
ModalHeader.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  tertiaryIcon: PropTypes.bool,
}

export const Modal = ({
  children,
  activeModal,
  closeModal,
  canBeClosed,
  canBeClosedByClickingOutside,
}) => {
  useEffect(() => {
    if (activeModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [activeModal])

  const handleCloseButton = () => {
    if (canBeClosed) {
      closeModal()
    }
  }

  const handleClickOutside = () => {
    if (canBeClosedByClickingOutside) handleCloseButton()
  }

  return (
    activeModal && (
      <div
        id="popup"
        className="z-50 modal-custom-index fixed w-full flex justify-center inset-0"
      >
        <div
          onClick={handleClickOutside}
          className="w-full absolute inset-0 bg-overlayPurple  cursor-auto xs:hidden xl:block"
          role="button"
          tabIndex={0}
          onKeyDown={Function.prototype}
        >
          &nbsp;
        </div>
        <div className=" min-h-screen w-full ">
          <div className="z-50 bg-white dark:bg-gray-800 desktop:rounded-3xl shadow   xs:w-full modal-container  lg:max-h-[90vh] desktop:absolute desktop:top-[53%] desktop:left-[50%] desktop:-translate-x-[53%] desktop:-translate-y-[50%] custom-modal-desktop">
            {children}
          </div>
        </div>
      </div>
    )
  )
}

Modal.defaultProps = {
  activeModal: false,
  closeModal: Function.prototype,
  canBeClosed: true,
  canBeClosedByClickingOutside: true,
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  activeModal: PropTypes.bool,
  closeModal: PropTypes.func,
  canBeClosed: PropTypes.bool,
  canBeClosedByClickingOutside: PropTypes.bool,
}

Modal.Header = ModalHeader
