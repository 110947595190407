import React, { useEffect } from "react"
import * as Sentry from "@sentry/react"
import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import ReactGA from "react-ga"
import { Provider, useDispatch, useSelector } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import { isLoggedIn } from "axios-jwt"

import {
  HashRouter as Router,
  Routes,
  Route,
  useSearchParams,
  useLocation,
  // useNavigate,
} from "react-router-dom"

import { useIntercom, IntercomProvider } from "react-use-intercom"
import { ThemeProvider } from "@material-tailwind/react"

import { toast, ToastContainer } from "../Toast"
import ModalServices from "../../features/ModalServices"
import MetaEventService from "../../services/MetaEventService"

// Containers
import PageContainer from "../../containers/PageContainer"
import CashierContainer from "../../containers/CashierContainer"
import TermsContainer from "../../containers/TermsContainer"

// Pages
import HomePage from "../../features/Home"
import GamePage from "../../features/GamePage"
import CategoryPage from "../../features/CategoryPage"

import {
  NodaOpenBanking as NodaOpenBankingGateway,
  NodaCreditCard as NodaCreditCardGatway,
  Oxprocessing as CryptoGateway,
  Paysage as PaysageGateway,
  Switchere as SwitchereGateway,
} from "../../features/PaymentGateways"

import {
  Privacy as PrivacyPolicy,
  Gaming as GamingPolicy,
  Aml as AmlPolicy,
} from "../../features/Policy"

import { TermsAndConditions, FairBets } from "../../features/Terms"

// Utilities
import { setLoggedIn, siteLoadComplete } from "../../actions/auth"
import store from "../../store"
import {
  getUser,
  getNotifications,
  postUserAffiliation,
} from "../../actions/user"

import ScrollToTop from "../ScrollTotop"
import PolicyContainer from "../../containers/PolicyContainer"
import PrivateRoute from "../PrivateRoute"
import CreditCardswitch from "../CreditCardswitch"
import NodaContainer from "../../containers/NodaContainer"
import AdresseFormCard from "../AdresseFormCard"
import { handleEnjectImageLoad, logger } from "../../helpers"
// import themes from "../../themes"
// eslint-disable-next-line import/no-extraneous-dependencies
// const defaultTheme = require("tailwindcss/defaultTheme")

// App will be the main component of the routing management
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <ThemeProvider>
              <IntercomProvider
                appId={process.env.REACT_APP_INTERCOM_APP_ID}
                shouldInitialize={
                  process.env.REACT_APP_CONTEXT === "production"
                }
              >
                <ToastContainer />
                <ProvidedApp />
              </IntercomProvider>
            </ThemeProvider>
          </ScrollToTop>
        </Router>
      </Provider>
    </QueryClientProvider>
  )
}

// TODO :using the nested routes
// TODO :improve the architecture and fix the router des legal text

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const ProvidedApp = () => {
  const location = useLocation
  const dispatch = useDispatch()
  const { username, email, phoneNumber, loggedIn, canBeReferred } = useSelector(
    (state) => state.auth
  )
  const { intercomHash } = useSelector((state) => state.user)
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom()

  useEffect(() => {
    MetaEventService.initializePixels(email)
  }, [email])

  useEffect(() => {
    MetaEventService.pageView()
  }, [location.pathname])

  useEffect(() => {
    i18n.use(initReactI18next)
    // OUR_TRACKING_ID
    if (process.env.REACT_APP_CONTEXT === "production") {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(setLoggedIn())
    }
  }, [dispatch])

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUser())

      dispatch(getNotifications())
      setInterval(async () => {
        dispatch(getNotifications())
      }, 10000)
    }
  }, [dispatch, loggedIn])

  useEffect(() => {
    setTimeout(() => {
      dispatch(siteLoadComplete())
    }, 5000)
  }, [])

  useEffect(() => {
    handleEnjectImageLoad(dispatch)
  }, [])

  // Launching correct instance of intercom
  useEffect(() => {
    if (intercomHash) {
      shutdownIntercom()
      bootIntercom({
        userHash: intercomHash,
        email,
        phone: phoneNumber,
        name: username,
        customAttributes: { custom_attribute_key: "Hi there !" },
      })
    } else {
      shutdownIntercom()
      bootIntercom({
        customAttributes: { custom_attribute_key: "Hi there !" },
      })
    }
  }, [intercomHash, dispatch])

  const postReferer = async (referrerCode, leadId) => {
    if (canBeReferred) {
      const response = await postUserAffiliation(referrerCode, leadId)
      if (response) {
        logger("Referrer send to Server !")
      } else {
        throw new Error("Error posting Referrer to server !")
      }
    }
  }

  const referrerCode = searchParams.get("ref")

  const leadId = searchParams.get("lead_id")
  // console.log("In app.js, the lead ID is :", leadId, typeof leadId)

  useEffect(() => {
    if (loggedIn) {
      searchParams.delete("service", "register")
      setSearchParams(searchParams)
      if (referrerCode) {
        const refValue = localStorage.getItem("referrer")
        const leadID = localStorage.getItem("lead_id")
        postReferer(refValue, leadID)
      }
    }
  }, [referrerCode, loggedIn, leadId])

  useEffect(() => {
    if (!loggedIn) {
      if (leadId) {
        localStorage.setItem("lead_id", leadId)
        searchParams.set("service", "register")
        setSearchParams(searchParams)
      }
      // const referrerCode = searchParams.get("ref")
      if (referrerCode) {
        localStorage.setItem("referrer", referrerCode)
        searchParams.set("service", "register")
        setSearchParams(searchParams)
        // postReferer(referrerCode)
      }
    }
  }, [loggedIn])

  useEffect(() => {
    if (loggedIn) {
      const notificationPayment = searchParams.get("notification")
      if (notificationPayment === "email-is-verified") {
        toast.success(
          t("Email verified successfully!"),
          t("Congratulations. Your email is verified successfully!")
        )
      }
    }
  }, [loggedIn])

  return (
    <>
      <ModalServices />
      <SentryRoutes>
        <Route path="/" element={<PageContainer />}>
          {/* default route */}
          <Route index element={<HomePage />} />

          {/* games */}
          <Route path="games/:slug" element={<GamePage />} />

          {/* category */}
          <Route path="category/:category" element={<CategoryPage />} />

          {/* terms */}
          <Route path="terms/" element={<TermsContainer />}>
            {/* default route => terms */}
            <Route index element={<FairBets />} />
            {/* route */}
            <Route path="fair-bets" element={<FairBets />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Route>

          {/* policy */}
          <Route path="policy/" element={<PolicyContainer />}>
            {/* default route => policy */}
            <Route index element={<PrivacyPolicy />} />
            {/* route */}
            <Route path="gaming-policy" element={<GamingPolicy />} />
            <Route path="aml-policy" element={<AmlPolicy />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Route>

          {/* Private Route => loggedIn */}
          <Route element={<PrivateRoute />}>
            {/* cashier */}
            <Route path="cashier/" element={<CashierContainer />}>
              {/* default route => cashier */}
              <Route index element={<CryptoGateway />} />
              {/* route */}
              <Route path="noda/" element={<NodaContainer />}>
                <Route index element={<NodaOpenBankingGateway />} />
                <Route path="cc" element={<NodaCreditCardGatway />} />
                <Route path="billingaddress" element={<AdresseFormCard />} />
              </Route>
              <Route path="Paysage" element={<PaysageGateway />} />
              <Route path="oxprocessing" element={<CryptoGateway />} />
              <Route path="switchere" element={<SwitchereGateway />} />
              <Route path="creditcard" element={<CreditCardswitch />} />
            </Route>
          </Route>

          {/* Not Found route => HomePage  */}
          <Route path="*" element={<HomePage />} />
        </Route>
      </SentryRoutes>
    </>
  )
}

export default App
