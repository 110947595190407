import { useEffect } from "react"
import { Menu } from "@headlessui/react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { CustomDropdownButtonBehavior } from "../../../components/DropdownMenu"
import Button from "../../../components/Button"
import {
  WINS,
  BETS,
  WITHDRAWALS,
  BONUSES,
  DEPOSITS,
  TIPS,
  ALL,
} from "./constants/constants"
import themes from "../../../themes"
import { getBrandFromUrl } from "../../../helpers"

const paymentsType = [DEPOSITS, WITHDRAWALS, BETS, WINS, BONUSES, TIPS, ALL]

const borderStyle = (themeColors) => css`
  border: 1px solid ${themeColors.tertiary};
`
const backgroundStrippedColorStyle = (index, themeColors) => css`
  background-color: ${index % 2 > 0 ? themeColors.lightPink : "transparent"};
`

const PaymentsTable = ({
  tableHeaders,
  tableRows,
  hasActions,
  onActionTriggered,
  actionName,
  onTypeChange,
  selectedType,
  changeType,
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const tabParam = searchParams.get("tab") || "deposits"
  const selectedTab = tabParam.charAt(0).toUpperCase() + tabParam.slice(1)

  useEffect(() => {
    changeType(selectedTab)
  }, [searchParams.get("tab")])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <div className="flex flex-col ">
      <div className="sm:-mx-6 lg:-mx-8 min-h-max">
        <div className="  py-2 inline-block min-w-full sm:px-6 enlarged-padding-wrapper lg:px-8">
          <div
            className="overflow-auto rounded-xl z-50 payment-table-wrapper"
            css={borderStyle(themeColors)}
          >
            <table className="min-w-full bg-gray-200 rounded-xl">
              <thead
                className="z-10 border-b w-full rounded-xl"
                style={{ backgroundColor: themeColors?.lightPink }}
              >
                <tr className="rounded-3xl">
                  {tableHeaders?.map((header) => (
                    <th
                      key={header}
                      scope="col"
                      className="text-md font-bold text-specialPrimary px-6 py-1 text-center w-[25%]"
                    >
                      {t(header)}
                    </th>
                  ))}

                  {hasActions && (
                    <th
                      scope="col"
                      className="text-md font-bold text-specialPrimary px-6 py-1 text-center w-[25%]"
                    >
                      {t("Action")}
                    </th>
                  )}

                  {searchParams.get("tab") === "all" && (
                    <th
                      scope="col"
                      className="text-specialPrimary px-6 py-1 text-center "
                    >
                      <Menu as="div" className="relative inline-block z-50">
                        {({ open }) => (
                          <>
                            <Menu.Button className="flex items-center gap-4 justify-around">
                              <span className="text-md font-bold text-specialPrimary px-6 py-1 text-center w-[25%]">
                                {t(selectedType)}
                              </span>
                              <CustomDropdownButtonBehavior
                                open={open}
                                type="small"
                              />
                            </Menu.Button>
                            <Menu.Items className="absolute bg-lightPink rounded-md top-full right-0 z-50">
                              {paymentsType.map((type) => (
                                <Menu.Item
                                  key={type}
                                  onClick={() => onTypeChange(type)}
                                >
                                  {({ active }) => (
                                    <span
                                      className={`${
                                        active
                                          ? "bg-lightPurple text-white"
                                          : "text-gray-900"
                                      }  flex  items-center  w-full py-1 text-capitalize px-2 rounded-md cursor-pointer`}
                                    >
                                      {t(type)}
                                    </span>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </>
                        )}
                      </Menu>
                    </th>
                  )}
                </tr>
              </thead>
              {tableRows?.rowsData?.length ? (
                <tbody className="text-capitalize">
                  {tableRows.rowsData.map((row, index) => (
                    <tr
                      key={tableRows.rowsMetaData[index].id}
                      className="border-b text-capitalize border-gray-200 py-2 whitespace-nowrap"
                      css={backgroundStrippedColorStyle(index, themeColors)}
                    >
                      {Object.keys(row).map((cell) => (
                        <td className="text-md text-capitalize px-6 py-3 text-center w-[25%] mobile:text-xs">
                          {cell === "amount" ? `$${row[cell]}` : t(row[cell])}
                        </td>
                      ))}
                      {hasActions &&
                        (row.status === "Verification" ? (
                          <td className="text-md  text-specialPrimary px-6 py-1 text-center w-[25%]">
                            <Button.Tertiary
                              onClick={() =>
                                onActionTriggered(tableRows.rowsMetaData[index])
                              }
                            >
                              <span className="mobile:text-xs text-sm">
                                {t(actionName)}
                              </span>
                            </Button.Tertiary>
                          </td>
                        ) : (
                          <td className="text-md   px-6 py-1 text-center w-[25%]">
                            <span className="text-sm">{t("No action")}</span>
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr className="bg-white w-full text-center py-16 h-[250px]">
                  <td colSpan={5}>
                    <h2
                      className="text-xl font-bold"
                      style={{ color: themeColors?.tertiary }}
                    >
                      {" "}
                      {t("No transaction made yet !")}
                    </h2>
                    <p>
                      {t(
                        "We are waiting for you to make your first transaction !"
                      )}
                    </p>
                  </td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentsTable.defaultProps = {
  actionName: "",
  onTypeChange: Function.prototype,
  onActionTriggered: Function.prototype,
  hasActions: false,
  changeType: Function.prototype,
}

PaymentsTable.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.shape().isRequired,
  hasActions: PropTypes.bool,
  onActionTriggered: PropTypes.func,
  actionName: PropTypes.string,
  onTypeChange: PropTypes.func,
  selectedType: PropTypes.string.isRequired,
  changeType: PropTypes.func,
}

export default PaymentsTable
