import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import MediaQuery from "react-responsive"
import { useQuery } from "react-query"
import { useDispatch } from "react-redux"
import Dialog from "./Dialog"
import Spinner from "./Spinner"
import Button from "./Button"
import { toast } from "./Toast"
// import logo from "../assets/images/casino-carbon-logo.svg"
// import bonusActivatedAsset from "../assets/images/bonusCode/acivated-bonus-asset.png"
import {
  getLevelReward,
  activateLevelReward,
  getUser,
  dismissLevelRewardPopup,
} from "../actions/user"
import { DISMISS_LEVEL_REWARD_POPUP_FULFILLED } from "../actions/types"
import {
  brandLogo,
  logger,
  imageDistributed,
  getBrandFromUrl,
} from "../helpers"
import themes from "../themes"

const LevelRewardPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [userLevel, setUserLevel] = useState()
  const [levelRewardAmount, setLevelRewardAmount] = useState()
  const [levelRewardWager, setLevelRewardWager] = useState()
  const { isLoading } = useQuery({
    queryKey: ["getLevelReward"],
    queryFn: () => getLevelReward(),
    refetchOnMount: true,
    onSuccess: ({ data: levelRewardData }) => {
      const { amount, wager, level_slug: name } = levelRewardData
      // console.log("the data of from popup reward data is: ", levelRewardData)
      setUserLevel(name)
      setLevelRewardAmount(amount)
      setLevelRewardWager(wager)
    },

    onError: (error) => {
      logger(error)
    },
  })

  const onClickToActivateLevelReward = async () => {
    try {
      const responseRewardActivated = await activateLevelReward()
      if (responseRewardActivated.status === 201) {
        toast.success(
          t("Level Reward Activated !"),
          `${t(
            "Congratulations, you have successfully activated your level reward of"
          )} ${levelRewardAmount}$ !`
        )
        dispatch(getUser())
      }
    } catch (e) {
      if (e.status === 403) {
        toast.error(
          t("Wager not completed !"),
          t("You have a bonus wager not reached")
        )
        dispatch({ type: DISMISS_LEVEL_REWARD_POPUP_FULFILLED })
      }
    }
  }

  const onClickDismissLevelRewardPopup = async () => {
    const dismissLevelRewardResult = await dismissLevelRewardPopup()
    if (dismissLevelRewardResult.status === 200) {
      dispatch({ type: DISMISS_LEVEL_REWARD_POPUP_FULFILLED })
    }
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Dialog>
      <div className=" h-full w-full py-2 ">
        {isLoading ? (
          <div className=" h-[200px] w-full flex justify-center items-center ">
            <Spinner />
          </div>
        ) : (
          <div className="flex-col   justify-center items-center  xl:pt-5">
            <MediaQuery maxWidth={750}>
              <div className="flex justify-start items-center mb-20  pl-5">
                <img
                  fetchpriority="high"
                  loading="lazy"
                  decoding="async"
                  src={brandLogo()}
                  alt="logo-mobile-version"
                  className="w-36 h-auto"
                />
              </div>
            </MediaQuery>

            <h1 className="text-center text-2xl font-bold mb-2">
              {t("Congratulations !")}
            </h1>
            <div className="text-center text-base mobile:px-8">
              <span className="inline-block mt-2 mb-1">
                {t("You have reached level")}{" "}
                <span className="font-bold">{userLevel}</span>.
              </span>{" "}
              <br />
              <span
                className=""
                style={{
                  color: themeColors?.tertiary,
                }}
              >
                {t("We'll reward you with a bonus gift of")}
                <span className="font-bold"> ${levelRewardAmount}</span>
              </span>
            </div>
            <div className="xs:mb-5 xl:mb-auto text-center text-xs flex justify-center items-center">
              <span className="mt-1">
                {" "}
                {t("This reward has a wager of")} {levelRewardWager}x
              </span>
            </div>
            <div className="flex flex-col gap-4 justify-center items-center mt-4 mb-5 py-5">
              <img
                fetchpriority="high"
                loading="lazy"
                decoding="async"
                src={imageDistributed("levelRewardPopup")}
                alt="logo-mobile-version"
                className="w-42 h-auto"
              />
            </div>
            <div className=" ml-2 w-full text-center py-2 mb-2 flex justify-center items-center px-2 ">
              <Button.Secondary
                size="small"
                onClick={onClickDismissLevelRewardPopup}
              >
                <span className="text-sm ">{t("Decline Gift")}</span>
              </Button.Secondary>
              <Button.Primary
                size="small"
                onClick={onClickToActivateLevelReward}
              >
                <span className="text-sm "> {t("Continue")}</span>
              </Button.Primary>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default LevelRewardPopup
