const carbonGamingTheme = {
  colors: {
    primary: "#271255",
    primaryLight: "#123123",
    primaryDark: "#123123",
    secondary: "#2E1967",
    tertiary: "#A564FA",
    optional: "#412d74",
    mediumPurple: "rgba(170,105,254,1)",
    customIndigo: "rgba(69,5,165,1)",
    heliotrope: " #C96AFF",
    purpleHeart: "#5508CA",
    pickPurple: "#A666F9",
    lightPurple: "#CFAAFF",
    darkPurple: "#4C0EAA",
    overlayPurple: "rgba(11, 4, 68, .5)",
    validGreen: "#029F07",
    lightGreen: "rgb(7,184,43)",
    lightPrimary: "rgba(45, 44, 130, 0.25)",
    lightTertiary: "rgba(165, 100, 250, 0.12)",
    specialTertiary: "rgba(165, 100, 250, 0.12)",
    specialPrimary: "rgba(38, 61, 144, 1)",
    specialFontBlue: " #627EEA",
    tintedPurple: "#29145C",
    lightPrimaryTint: "rgba(43, 22, 96, 1)",
    darkenBlue: "#271255",
    specialDarkenBlue: "#1F015B",
    darkenTintBlue: "#220C65",
    lightDarken: "rgba(45, 44, 130, 0.5)",
    customPurpleOverlay: "rgba(127, 17, 224, .7)",
    customBlue: "rgba(43, 22, 96, 1)",
    lightPink: "rgba(244, 236, 254, 1)",
    lightPurpled: "rgba(211, 195, 232, 1)",
    blued: "rgba(45, 44, 130, 1)",
    bluedGradient: "rgba(45, 44, 130, .75)",
    greenBl: "rgba(39, 227, 195, 0.45)",
    greenBlGradient: "rgba(39, 227, 195, 0.2)",
    customGray: "rgba(104, 123, 131, 1)",
    personalPurple: "#150b30",
  },
}

const redemptionsBitsTheme = {
  colors: {
    primary: "#002244", // Dark Blue
    primaryLight: "#24478F", // Dark Periwinkle
    primaryDark: "#000F1F", // Midnight Blue
    secondary: "#003366", // Dark Azure
    tertiary: "#5D57FF", // Dark Cerulean
    optional: "#0E4D92", // Yale Blue
    mediumPurple: "rgba(82, 71, 255, 1)", // Very Dark Blue
    customIndigo: "rgba(28, 0, 112, 1)", // Dark Indigo
    heliotrope: "#333399", // Deep Blue
    purpleHeart: "#1E1E90", // Dark Royal Blue
    pickPurple: "2E2EFF", // Duke Blue
    lightPurple: "#C5D08C", // Dark Pastel Blue
    darkPurple: "#00008B", // Dark Blue
    overlayPurple: "rgba(0, 0, 39, 0.5)", // Very Dark Blue Overlay
    validGreen: "#013220", // Dark Green
    lightGreen: "rgb(0, 51, 102)", // Dark Blue Green
    lightPrimary: "rgba(0, 34, 68, 0.25)", // Very Dark Blue Light
    lightTertiary: "#e2eafc", // Dark Cerulean Light
    specialTertiary: "rgba(39, 73, 109, 0.12)", // Special Dark Cerulean
    specialPrimary: "rgba(0, 0, 139, 1)", // Dark Blue
    specialFontBlue: "#1E3A5F", // Dark Slate Blue
    tintedPurple: "#0D3B66", // Dark Slate Blue Tinted
    lightPrimaryTint: "rgba(0, 51, 102, 1)", // Dark Blue Tint
    darkenBlue: "#000033", // Very Dark Blue
    specialDarkenBlue: "#0D1B2A", // Dark Blue Grey
    darkenTintBlue: "#000066", // Very Dark Blue
    lightDarken: "rgba(0, 0, 139, 0.5)", // Dark Blue Light
    customPurpleOverlay: "rgba(25, 25, 112, 0.7)", // Deep Blue Overlay
    customBlue: "#20385E", // Dark Blue
    lightPink: "#e2eafc", // Very Dark Blue
    lightPurpled: "rgba(71, 71, 135, 1)", // Dark Lavender
    blued: "rgba(0, 0, 139, 1)", // Dark Blue
    bluedGradient: "rgba(0, 0, 139, 0.75)", // Dark Blue Gradient
    greenBl: "rgba(0, 51, 102, 0.45)", // Dark Blue Green Gradient
    greenBlGradient: "rgba(0, 51, 102, 0.2)", // Light Dark Blue Green Gradient
    customGray: "rgba(49, 79, 79, 1)", // Dark Slate Grays,
    personalPurple: "#023e8a",
  },
}

export default {
  "www.carbongaming.net": carbonGamingTheme,
  "www.redemptionbits.com": redemptionsBitsTheme,
}
