import {
  GET_WALLET_FULFILLED,
  GET_USER_DEPOSIT_BONUS_FULFILLED,
  POST_USER_METADATA_WALLET_FULFILLED,
} from "../actions/types"

const initialState = {
  ethWalletAddress: "",
  balance: 0,
  fixedFees: 3,
  percentageFees: 0.03,
  depositWagered: 0,
  amountWagered: 0,
  bonusActive: false,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_USER_DEPOSIT_BONUS_FULFILLED:
      return {
        ...state,
        depositWagered: payload.deposit_wagered,
        amountWagered: payload.amount_wagered,
      }
    case GET_WALLET_FULFILLED:
      return {
        ...state,
        ...payload,
      }
    case POST_USER_METADATA_WALLET_FULFILLED:
      return {
        ...state,
        balance: payload.balance,
      }
    default:
      return state
  }
}
