import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const Table = ({ data }) => {
  const sortedData = data.sort((a, b) => b.payout - a.payout)
  sortedData.forEach((item, index) => {
    const obj = item
    obj.id = index + 1
  })

  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <div
      className="w-full lg:px-6"
      style={{ background: themeColors?.primary }}
    >
      <div className="shadow xs:px-0  md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto ">
        <table
          className="w-full text-white  "
          style={{ background: themeColors?.primary }}
        >
          <thead
            className="text-white border border-secondary  rounded"
            style={{ background: themeColors?.secondary }}
          >
            <tr className="h-16 w-full  font-bold border-1">
              <th className="font-medium text-left xl:px-8 xs:px-2 ">
                {t("Game")}
              </th>
              <th className="font-medium text-center xl:px-10 xs:px-2  ">
                {t("User")}
              </th>
              <th className="font-medium text-center xl:px-10  table-responsive table-responsive-sm ">
                {t("Date Time")}
              </th>
              <th className="font-medium text-center xl:px-10  table-responsive table-responsive-sm ">
                {t("Wager")}
              </th>
              <th className="font-medium text-center xl:px-10  table-responsive table-responsive-sm ">
                {t("Multiples")}
              </th>
              <th className="font-medium text-center xl:px-10 xs:px-2">
                {t("Payout")}
              </th>
            </tr>
          </thead>
          <tbody
            className="w-full  text-white"
            style={{ background: themeColors?.primary }}
          >
            {sortedData?.map((user) => (
              <tr
                className={`h-16  leading-none border border-secondary `}
                style={{
                  background:
                    data.indexOf(user) % 2 !== 0 ? themeColors?.secondary : "",
                }}
                key={user.id}
              >
                <td className="pl-4">
                  <div className="flex items-center">
                    <div className="xl:pl-4 xs:pl-0">
                      <p className="font-regular  xl:text-current xs:text-left xl:text-sm xs:text-xs">
                        {user.game}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="xl:pl-4 xs:pl-0 ">
                  <p className="xl:text-sm xs:text-xs font-regular leading-none text-center">
                    {user.user}
                  </p>
                </td>
                <td className=" table-responsive table-responsive-sm ">
                  <p className="font-regular text-center xl:text-sm">
                    {user.dateTime}
                  </p>
                </td>
                <td className="pl-12 table-responsive  table-responsive-sm ">
                  <p className="font-regular xl:text-sm">{`$${user.wager}`}</p>
                </td>
                <td className="px-12 table-responsive text-center  table-responsive-sm text-validGreen">
                  <p className="font-regular ">{user.multiples}x</p>
                </td>
                <td className="xl:pl-4 xs:pl-0 xl:text-left xs:text-center">
                  <p className="font-regular text-center xl:text-sm xs:text-xs">
                    {`$${user.payout}`}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      game: PropTypes.string,
      user: PropTypes.string,
      dateTime: PropTypes.string,
      wager: PropTypes.number,
      multiple: PropTypes.number,
      payout: PropTypes.number,
    })
  ).isRequired,
}

export default Table
