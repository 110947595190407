import axios from "axios"
import { applyAuthTokenInterceptor } from "axios-jwt"

const backendUrls = {
  production: "https://api.casinocarbon.com",
  "deploy-preview": "https://api-staging.casinocarbon.com",
  development: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
}

export const BASE_URL = backendUrls[process.env.REACT_APP_CONTEXT]

// 1. Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create({ baseURL: BASE_URL })

const requestRefresh = async (refresh) => {
  // Notice that this is the global axios instance, not the axiosInstance!  <-- important
  const token = await axios.post(`${BASE_URL}/user/auth/token/refresh/`, {
    refresh,
  })
  return token.data.access
}

// 3. Apply interceptor
applyAuthTokenInterceptor(axiosInstance, { requestRefresh })
export default axiosInstance
