import React from "react"
import PropTypes from "prop-types"

const borderStyleWhenSelected = (activeItem, id) =>
  activeItem === id
    ? "xl:border-r-[3px] xl:border-tertiary xl:border-b-0 xs:border-tertiary xs:border-b-[3px]"
    : "xl:border-r-[3px] xl:border-transparent xl:border-transparent"

export const SideNavItem = ({ id, onClick, children, activeItem }) => (
  <li className="relative">
    <span
      className={`flex items-center text-sm xs:py-0 md:my-4 px-4 h-12  xs:my-3  ${borderStyleWhenSelected(
        activeItem,
        id
      )}`}
      href="/"
      id={id}
      data-mdb-ripple="true"
      data-mdb-ripple-color="dark"
      onClick={onClick}
      onKeyDown={Function.prototype}
      role="button"
      tabIndex={0}
    >
      {children}
    </span>
  </li>
)

SideNavItem.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  activeItem: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export const SideNav = ({ children }) => (
  <div className=" h-full w-fit bg-white px-1  flex justify-start ">
    <ul className="relative lg:flex lg:flex-col xs:flex xs:flex-row vertical-nav-custom ">
      {children}
    </ul>
  </div>
)
SideNav.defaultProps = {}
SideNav.propTypes = {
  children: PropTypes.element.isRequired,
}

SideNav.Item = SideNavItem
