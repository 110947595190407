import React from "react"
import PropTypes from "prop-types"
// import { useNavigate } from "react-router-dom"
import themes from "../../../themes"

import LeaderBoard from "../../../components/Leaderboard"

const About = ({ game, themeColors }) => {
  const title = game?.name || "Game Name"
  const gameProvider = game?.provider || "Game Provider"

  return (
    <section
      className="py-12  about-section text-white "
      style={{
        backgroundColor: themeColors?.primary,
        color: "white",
      }}
    >
      <div className="container-max-width">
        <div>
          <div className="xs:px-6 xl:px-0">
            <p
              className="text-2xl font-medium  md:mb-0 xs:-mb-3"
              style={{ color: themeColors?.tertiary }}
            >
              {gameProvider}
            </p>
            <h1 className="text-3xl desktop:text-[50px] md:text-lg xs:text-3xl font-bold uppercase mt-4 md:mb-5 xs:mb-3 tracking-wide">
              <span className="font-light">{title}</span>
              &nbsp;
            </h1>
          </div>
        </div>
        <LeaderBoard slug={game?.slug || " "} />
      </div>
    </section>
  )
}
About.defaultProps = {
  themeColors: themes["www.carbongaming.net"].colors,
}
About.propTypes = {
  game: PropTypes.string.isRequired,
  themeColors: PropTypes.shape(),
}

export default About
