import React from "react"
import { useQuery } from "react-query"

import GamesCatalogDesktop from "./GamesCatalogDesktop"
import GamesCatalogMobile from "./GamesCatalogMobile"
import { getCountryCode } from "../../actions/user"
import { logger } from "../../helpers"
import Spinner from "../Spinner"

const GamesCatalogWrapper = () => {
  const { isLoading, data: countryCode } = useQuery(
    ["userLocation"],

    () => getCountryCode(),
    {
      refetchOnMount: false,
      onError: () => {
        logger("error occurred with getting user Location !")
      },
    }
  )

  return isLoading ? (
    <div className="w-[100%] h-full  flex justify-center items-center pt-10 pb-10">
      <Spinner theme="light" />
    </div>
  ) : (
    <div>
      <GamesCatalogDesktop countryCode={countryCode} />
      <GamesCatalogMobile countryCode={countryCode} />
    </div>
  )
}

export default GamesCatalogWrapper
