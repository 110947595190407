import React from "react"
import ReactDOM from "react-dom/client"

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"

import * as Sentry from "@sentry/react"

import "./services/i18"
import "tw-elements"
import "./index.css"
import "react-phone-number-input/style.css"
import App from "./components/app/App"
import reportWebVitals from "./reportWebVitals"

if (process.env.REACT_APP_CONTEXT !== "development") {
  Sentry.init({
    dsn: "https://9cb827c9547d4b6886eac601153ec14a@o1331647.ingest.sentry.io/6672252",
    environment: process.env.REACT_APP_CONTEXT,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error", "assert"],
      }),
      Sentry.replayIntegration(),
    ],

    attachStacktrace: true,
    tracesSampler: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <p>An error has occurred. Please contact support@carbonteam.io</p>
      }
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
