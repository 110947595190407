import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

const CheckPassword = ({ passwordErrorType, watchPassword, errors }) => {
  const { t } = useTranslation()
  return (
    <div className="my-5">
      {errors ? (
        <div className="my-3 text-sm text-white">
          <p className="">
            {t(
              "To help keep your information safe, your password must contain:"
            )}
          </p>
          <p className="text-xs my-1">
            {passwordErrorType ? (
              <FontAwesomeIcon
                icon={faX}
                className="w-3 h-3 mr-2 text-red-700"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="w-3 h-3 mr-2 text-green-700"
              />
            )}
            {t("A minimum of 8 characters")}
          </p>
          <p className="text-xs my-1">
            {/* regex for checking special characters */}
            {!watchPassword?.match(/[^\w\s]|(?:<=\s)[^\w\s]|[^\w\s](?=\s)/) ? (
              <FontAwesomeIcon
                icon={faX}
                className="w-3 h-3 mr-2 text-red-700"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 mr-2 text-green-700"
              />
            )}
            {t("Include at least one special characters")}
          </p>
          <p className="text-xs">
            {/* regex for checking numbers */}
            {!watchPassword?.match(/[0-9]/) ? (
              <FontAwesomeIcon
                icon={faX}
                className="w-3 h-3 mr-2 text-red-700"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 mr-2 text-green-700"
              />
            )}
            {t("Include at least one number (0-9)")}
          </p>
        </div>
      ) : (
        <div className="my-3 text-sm text-white">
          <p className="">
            {t(
              "To help keep your information safe, your password must contain:"
            )}
          </p>
          <p>
            <FontAwesomeIcon
              icon={faCheck}
              className="w-3 h-3 mr-2 text-green-700"
            />
            {t("A minimum of 8 characters")}
          </p>
          <p>
            <FontAwesomeIcon
              icon={faCheck}
              className="w-3 h-3 mr-2 text-green-700"
            />
            {t("Include at least one special characters")}
          </p>
          <p>
            <FontAwesomeIcon
              icon={faCheck}
              className="w-3 h-3 mr-2 text-green-700"
            />
            {t("Include at least one number (0-9)")}
          </p>
        </div>
      )}
    </div>
  )
}

CheckPassword.propTypes = {
  errors: PropTypes.bool.isRequired,
  passwordErrorType: PropTypes.bool.isRequired,
  watchPassword: PropTypes.string.isRequired,
}
export default CheckPassword
