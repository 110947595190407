import React from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
// import ControlArrowButton from "./ControlArrowButton"

export const CarouselItem = ({
  imageSource,
  children,
  isActive,
  mobileImageSource,
}) => (
  <div
    className={`carousel-item  relative float-left w-full mobile:h-[235px] mobile-big-screen:h-[350px] ${
      isActive ? "active" : ""
    }`}
  >
    <MediaQuery minWidth={320} maxWidth={599}>
      <img
        fetchpriority="high"
        src={mobileImageSource}
        className="block w-full h-auto mobile:h-[220px] mobile-big-screen:h-[350px] object-none rounded-3xl"
        alt="..."
      />
    </MediaQuery>
    <MediaQuery minWidth={600}>
      <img
        fetchpriority="high"
        src={imageSource}
        className=" w-full h-auto mobile:h-[250px] mobile-big-screen:h-[350px]"
        alt="..."
      />
    </MediaQuery>
    <div className="carousel-caption  md:block absolute tablet:text-right desktop:text-right xs:text-left w-full desktop:pr-52 tablet:pr-36 mobile:h-[300px] mobile:-mt-3">
      {children}
    </div>
  </div>
)
CarouselItem.defaultProps = {
  isActive: false,
}
CarouselItem.propTypes = {
  imageSource: PropTypes.string.isRequired,
  mobileImageSource: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
}

export const ControlArrowButton = ({ type, text, id }) => (
  <button
    className={`carousel-control-${type} h-min w-min absolute top-1/2 bottom-0  p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline ${
      type === "prev" ? "left-5 mobile:left-1" : "right-5 mobile:right-1"
    }`}
    type="button"
    data-bs-target={`#${id}`}
    data-bs-slide={type}
  >
    <span
      className={`carousel-control-${type}-icon inline-block bg-no-repeat`}
      aria-hidden="true"
    />
    <span className="visually-hidden">{text}</span>
  </button>
)
ControlArrowButton.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export const Carousel = ({ children, id }) => (
  <div
    id={id}
    className="carousel slide relative rounded-3xl"
    data-bs-ride="carousel"
  >
    <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-2 mobile:mb-3">
      <button
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide-to="0"
        className="active"
        // aria-current="true"
        aria-label="Slide 1"
      />
      <button
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide-to="1"
        aria-label="Slide 2"
      />
      <button
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide-to="2"
        aria-label="Slide 3"
      />
      {/* <button
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide-to="3"
        aria-label="Slide 4"
      /> */}
    </div>
    <div className="carousel-inner relative w-full overflow-hidden rounded-3xl">
      {children}
    </div>
    {/* <ControlArrowButton id={id} type="prev" text="Previous" />
    <ControlArrowButton id={id} type="next" text="Next" /> */}
    <button
      className="carousel-control-prev h-min w-min absolute top-1/2 mobile:top-[45%] bottom-0  p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-5 mobile:left-1"
      type="button"
      data-bs-target={`#${id}`}
      data-bs-slide="prev"
    >
      <span
        className="carousel-control-prev-icon inline-block bg-no-repeat"
        aria-hidden="true"
      />
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="h-min w-min absolute top-1/2 mobile:top-[45%] bottom-0  p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-5 mobile:right-1"
      type="button"
      data-bs-target={`#${id}`}
      data-bs-slide="next"
    >
      <span
        className="carousel-control-next-icon inline-block bg-no-repeat"
        aria-hidden="true"
      />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
)
Carousel.defaultProps = {
  id: "",
}
Carousel.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string,
}

Carousel.Iem = CarouselItem
