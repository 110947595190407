import React, { useEffect } from "react"
// import * as Sentry from "@sentry/react"
import PropTypes from "prop-types"
import { logger } from "../helpers"
// import { logger } from "../helpers"

// TODO: Add explicit logger with data

const CustomIframe = ({ title, width, height, frameBorder, allow, src }) => {
  const fetchResource = (srcUrl, iframeName) => {
    fetch(srcUrl)
      .then((response) => {
        if (!response.ok) {
          // Error case
          logger(`Failed to fetch resource: ${iframeName}`)
        }
      })
      .catch((error) => {
        console.error("Fetching resource failed:", title, error)
        throw new Error(`Failed to fetch resource: ${iframeName}`)
      })
  }

  useEffect(() => {
    fetchResource(src, title)
  }, [src])

  return (
    <iframe
      id={title}
      title={title}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allow={allow}
      src={src}
    >
      Can&#39;t load widget
    </iframe>
  )
}

CustomIframe.defaultProps = {
  title: "",
  width: "",
  height: "",
  frameBorder: "none",
  allow: "camera",
}

CustomIframe.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  frameBorder: PropTypes.string,
  allow: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default CustomIframe
