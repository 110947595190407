import api from "../api"

/* get the url */
const getGameUrl = async (slug, real, lang) => {
  try {
    let response = null
    if (!real) {
      response = await api.get(`/games/${slug}/play/demo`, {
        params: {
          lang,
        },
      })
    } else {
      response = await api.get(`/games/${slug}/play`, {
        params: {
          lang,
        },
      })
    }

    return response
  } catch (err) {
    return err.response
  }
}

export const getGameStatistics = async (slug) => {
  if (slug) {
    try {
      const response = await api.get(`/games/${slug}/stats`)
      return response
    } catch (err) {
      return err.response
    }
  }

  const response = {
    status: 200,
    statistics: [
      {
        game: "Dream Catcher",
        user: "Rolan17",
        dateTime: "09:24:15",
        wager: 225,
        multiples: 250,
        payout: 56250,
      },
      {
        game: "JetX",
        user: "Anthony17",
        dateTime: "12:45:30",
        wager: 180,
        multiples: 200,
        payout: 36000,
      },
      {
        game: "Sweet Bonanza",
        user: "Fred11",
        dateTime: "18:15:10",
        wager: 175,
        multiples: 300,
        payout: 52500,
      },
      {
        game: "Lightning Roulette",
        user: "RaunJih2",
        dateTime: "21:10:55",
        wager: 225,
        multiples: 500,
        payout: 112500,
      },
      {
        game: "Fruit Boost",
        user: "Raji99",
        dateTime: "14:55:20",
        wager: 200,
        multiples: 350,
        payout: 70000,
      },
      {
        game: "Dream Catcher",
        user: "Kamilin",
        dateTime: "07:30:45",
        wager: 300,
        multiples: 315,
        payout: 94500,
      },
      {
        game: "JetX",
        user: "Senax1",
        dateTime: "10:05:05",
        wager: 450,
        multiples: 400,
        payout: 180000,
      },
      {
        game: "Sweet Bonanza",
        user: "Muryi",
        dateTime: "16:40:35",
        wager: 100,
        multiples: 250,
        payout: 25500,
      },
    ],
  }
  return response
}

/* get the games */
export const getGamesCatalog = async () => {
  try {
    let gameCatalog = JSON.parse(sessionStorage.getItem("games-catalog"))
    if (!gameCatalog) {
      const games = await api.get(`/games/`)
      if (games) {
        sessionStorage.setItem("games-catalog", JSON.stringify(games))
        gameCatalog = games
      } else {
        throw new Error("API response is missing data.")
      }
    }
    return gameCatalog
  } catch (error) {
    throw new Error(`Error fetching games catalog: ${error.response}`)
  }
}

export default getGameUrl
