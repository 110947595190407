import React from "react"
import PropTypes from "prop-types"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import Input from "./Input"
import Button from "./Button"
import twoFactorCodeAuth from "../model/twoFactorCodeSchema"

const Prompt22FAPINCard = ({ onSubmit, onCancel, title }) => {
  const { t } = useTranslation()
  const formOptions = { resolver: yupResolver(twoFactorCodeAuth) }
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)
  const handleCancel = () => {
    onCancel()
  }
  const handleCode = (data) => {
    onSubmit(parseInt(data.authCode, 10))
  }
  return (
    <div className="fixed  inset-0 bg-overlayPurple bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="w-full h-full md:flex md:justify-center md:items-center xs:py-16">
        <div className="block xl:p-6 md:rounded-md xs:rounded-none shadow-lg bg-white xl:w-[500px] md:w-11/12 xs:w-screen xs:p-3">
          <h1 className="text-gray-500 font-medium text-xl my-3">{t(title)}</h1>
          <p className="mb-3">
            {t(
              "Enter the 6-digits code from your authenticator application to continue."
            )}
          </p>
          <div className="my-2">
            <Controller
              name="authCode"
              control={control}
              render={({ field, formState }) => (
                <Input
                  label={t("Enter Two-Factor Code")}
                  error={formState.errors?.authCode}
                  {...field}
                />
              )}
            />
            <span className="text-red-700 text-xs">
              {errors?.authCode && t(errors.authCode.message)}
            </span>
            <hr className="border-[2px] border-primary bg-primary  hover:border-primary duration-500" />
            <div className="flex justify-end mt-2">
              <Button.Secondary size="small" onClick={handleCancel}>
                <span className="text-black">Cancel</span>
              </Button.Secondary>
              <Button.Primary
                size="small"
                onClick={handleSubmit(handleCode)}
                onSubmit={handleSubmit(handleCode)}
              >
                Continue
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
Prompt22FAPINCard.defaultProps = {
  title: "Two-Factor Authentication",
}
Prompt22FAPINCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
}
export default Prompt22FAPINCard
