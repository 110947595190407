import React, { useState } from "react"
import PropTypes from "prop-types"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MediaQuery from "react-responsive"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
/* import { useDispatch } from "react-redux" */
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Modal } from "../../components/Modal"
import Input from "../../components/Input"
import Button from "../../components/Button"
import { toast } from "../../components/Toast"
import formSchema from "../../model/recoveryEmailSchema"
import { sendRecoveryEmail } from "../../actions/auth"
// import recoveryEmailAsset from "../../assets/images/modalAssets/recovery-email-modal-asset.png"
// import successRecoveryAsset from "../../assets/images/modalAssets/register-modal-asset.png"
import SloganText from "../../components/SloganText"
import PageLink from "../../components/PageLink"
import { brandLogo, getThemeColors, imageDistributed } from "../../helpers"

const serviceName = "recovery-email"
const styleFormModal = css({
  marginTop: "2rem !important",
  marginBottom: "7rem !important",
})

const borderStyle = (themeColors) => css`
  border: 1px solid ${themeColors.tertiary}; /* Dynamic border color */
`

const SuccessfulRecoveryEmailView = ({ email, closeModal }) => {
  const { t } = useTranslation()
  const themeColors = getThemeColors()
  return (
    <div
      className="w-auto h-full z-50 desktop:rounded-3xl register-modal-right-view modal-register-view blurry"
      style={{
        backgroundColor: themeColors?.secondary,
        color: "white",
      }}
      css={borderStyle(themeColors)}
    >
      <MediaQuery minWidth={320} maxWidth={959}>
        <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
          <h1 className="xs:text-black desktop:text-tertiary text-xl leading-5 font-medium px-7">
            {t("Account Recovery")}
          </h1>
          <MediaQuery maxWidth={959}>
            <Modal.Header handleCloseModal={closeModal} />
          </MediaQuery>
          <MediaQuery minWidth={959}>
            <Modal.Header handleCloseModal={closeModal} tertiaryIcon />
          </MediaQuery>
        </div>
        <div className="flex justify-center items-center mb-5 py-5">
          <img
            fetchpriority="low"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-mobile-version"
            className="w-60 h-auto"
          />
        </div>
      </MediaQuery>
      <MediaQuery minWidth={960}>
        <div className="px-5">
          <div className=" h-5 py-3 ">
            <Modal.Header handleCloseModal={closeModal} tertiaryIcon />
          </div>
          <div className="flex flex-col">
            <img
              fetchpriority="high"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-version-svg"
              className="aspect-auto w-[317px] h-[100px]"
            />
            <SloganText />
          </div>
        </div>
      </MediaQuery>
      <div className="desktop:text-center">
        <div className="xs:w-4/5 desktop:w-3/5 flex mx-auto mb-3">
          <MediaQuery maxWidth={959}>
            <Button.Tertiary theme="light">
              <a
                className="text-sm font-bold "
                style={{ color: themeColors?.tertiary }}
                href="https://help.casinocarbon.com/en//articles/6420259-how-to-reset-password"
                target="_blank"
                rel="noreferrer"
              >
                {t("Need help ?")}
              </a>
            </Button.Tertiary>
          </MediaQuery>
        </div>
        <p className="text-white text-base xs:w-4/5 desktop:w-3/5 mx-auto font-semibold">
          {t("You received a link on")} {email},{" "}
          {t("you can reset your password via that link.")}
        </p>
        <p className="text-white text-base xs:w-4/5 desktop:w-3/5 mx-auto mt-5 font-semibold desktop:text-center">
          {t("Our recovery E-mail expires in")}{" "}
          <span className="font-bold">{t("24 hours")}</span>,{" "}
          {t(
            "if you don't see it in your Inbox please check your spam or junk folder before submitting a new request."
          )}
        </p>
      </div>
      <MediaQuery minWidth={960}>
        <div className="flex justify-center">
          <img
            fetchpriority="low"
            src={imageDistributed("registerModalAssetImage")}
            alt="step-icon-1"
            className=" xs:w-44 desktop:w-auto desktop:h-56"
          />
        </div>
      </MediaQuery>
      <div className="flex desktop:justify-center desktop:pb-12 xs:mt-7 w-full">
        <MediaQuery minWidth={320} maxWidth={959}>
          <div className="xs:w-4/5 mx-auto">
            <Button.Primary size="small" onClick={closeModal} fullWidth>
              {t("Play Now")}
            </Button.Primary>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className=" inline-block">
            <Button.Primary size="medium" onClick={closeModal}>
              {t("Play Now")}
            </Button.Primary>
          </div>
        </MediaQuery>
      </div>
    </div>
  )
}
SuccessfulRecoveryEmailView.defaultProps = {
  closeModal: Function.prototype,
}
SuccessfulRecoveryEmailView.propTypes = {
  email: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
}

const FormView = ({ setSuccessRecoveryEmail, setEmail }) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const formOptions = { resolver: yupResolver(formSchema) }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const handleCloseModal = () => {
    searchParams.delete("service")
    setSearchParams(searchParams)
  }

  const notificationSuccessRecovery = () =>
    toast.success(
      t("Successful recovery"),
      t("The email verification has been sent.Please check your email.")
    )

  const handleSendEmail = async (data) => {
    const sendRecoveryEmailResponse = await sendRecoveryEmail(data.email)
    if (sendRecoveryEmailResponse.response) {
      reset()
    } else {
      notificationSuccessRecovery()
      reset()
      setSuccessRecoveryEmail()
      setEmail(data.email)
    }
  }

  const themeColors = getThemeColors()

  return (
    <div className="flex relative">
      <div
        className="desktop:w-[42%] flex flex-col items-center justify-center  bg-secondary py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block  blurry"
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex flex-col">
          <img
            fetchpriority="low"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-version-svg"
            className="aspect-auto"
          />

          <SloganText />
        </div>
        <div className="flex justify-center mb-2">
          <img
            fetchpriority="low"
            src={imageDistributed("recoveryEmailAsset")}
            alt="step-icon-1"
            className="aspect-auto my-1 p-7"
          />
        </div>
        <p className="text-center font-medium">
          <span className="text-md" style={{ color: themeColors?.tertiary }}>
            2.500.120.454
          </span>
          <br />
          <span className="text-white text-sm opacity-40">
            {t("Bets Wagered")}
          </span>
        </p>
      </div>
      <div
        className="desktop:w-[58%] desktop:pb-24 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view"
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
          <h1
            className="xs:text-black  text-xl leading-5 font-medium px-7"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Account Recovery")}
          </h1>
          <MediaQuery maxWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </MediaQuery>
          <MediaQuery minWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </MediaQuery>
        </div>
        <MediaQuery maxWidth={959}>
          <div className="flex justify-center items-center my-5 py-5">
            <img
              fetchpriority="low"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-mobile-version"
              className="w-60 h-auto"
            />
          </div>
        </MediaQuery>
        <div className="pt-15 pb-18 px-7 text-md ">
          <h1 className="text-white text-xl leading-5 font-medium">
            {t("Recover your account within seconds.")}
          </h1>
          <div>
            <Button.Tertiary theme="light">
              <a
                className="text-sm font-bold"
                style={{ color: themeColors?.tertiary }}
                href="https://help.casinocarbon.com/en/articles/6420259-how-to-reset-password"
                target="_blank"
                rel="noreferrer"
              >
                {t("Need help ?")}
              </a>
            </Button.Tertiary>
          </div>
          <form className="mt-5 my-10 mb-3" css={styleFormModal}>
            <div className="mb-6">
              <Controller
                control={control}
                name="email"
                render={({ field, formState }) => (
                  <Input
                    autoCapitalize="none"
                    type="text"
                    id="email-forgot-password"
                    label={t("Email Address")}
                    error={formState.errors?.email}
                    {...field}
                  />
                )}
              />

              <span className="text-red-700 text-xs">
                {errors?.email && t(errors.email.message)}
              </span>
            </div>
            <div className="pb-6">
              <div className="">
                <Button.Primary
                  fullWidth
                  onClick={handleSubmit(handleSendEmail)}
                  onSubmit={handleSubmit(handleSendEmail)}
                >
                  {t("Send Recovery Email")}
                </Button.Primary>
              </div>
            </div>
          </form>
          <div className="mb-10 pb-20">
            <p className="text-xs text-white">
              {t("This site is protected by reCAPTCHA and the Google")}{" "}
              <PageLink to="/policy/privacy-policy" classNames="tertiary">
                {" "}
                {t("Privacy Policy")}
              </PageLink>{" "}
              {t("and")}{" "}
              <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                {t("Terms of Service")}
              </PageLink>{" "}
              {t(
                "apply. By accessing the site I attest that I am at least 18 years old and have read the"
              )}{" "}
              <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                {t("Terms of Service")}
              </PageLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

FormView.defaultProps = {
  setSuccessRecoveryEmail: Function.prototype,
  setEmail: Function.prototype,
}

FormView.propTypes = {
  setSuccessRecoveryEmail: PropTypes.func,
  setEmail: PropTypes.func,
}

const RecoveryEmailModal = ({ activeService, handleCloseModal }) => {
  const [email, setEmail] = useState("")

  const [recoveryEmailIsSuccessful, setRecoveryEmailIsSuccessful] =
    useState(false)

  const setSuccessEmailRecovery = () => {
    setRecoveryEmailIsSuccessful(true)
  }

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      {recoveryEmailIsSuccessful ? (
        <SuccessfulRecoveryEmailView
          email={email}
          closeModal={handleCloseModal}
        />
      ) : (
        <FormView
          setEmail={setEmail}
          setSuccessRecoveryEmail={setSuccessEmailRecovery}
        />
      )}
    </Modal>
  )
}
RecoveryEmailModal.defaultProps = {
  activeService: "recovery-email",
}

RecoveryEmailModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
}

export default RecoveryEmailModal
