import React from "react"
import PropTypes from "prop-types"
import { Checkbox as MaterialCheckbox } from "@material-tailwind/react"

const Checkbox = ({ onChange, id, disabled, checked, label }) => (
  <MaterialCheckbox
    label={label}
    className="form-control
    w-5
    h-5
    p-1
    font-normal
    bg-white
    text-tertiary
    border-1 border-tertiary 
    checked:bg-tertiary
    focus:outline-0
    focus:outline-hidden
    hover:outline-0
    hover:outline-hidden
    outline-0
    rounded
    transition
    ease-in-out
    before:w-10 before:h-10
    "
    id={id}
    disabled={disabled}
    checked={checked}
    onChange={onChange}
  />
)

Checkbox.defaultProps = {
  onChange: Function.prototype,
  id: "",
  label: "",
  disabled: false,
  checked: false,
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

export default Checkbox
