import React from "react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import Header from "../../components/Header"
import { getBrandFromUrl, promptBrand } from "../../helpers"
import themes from "../../themes"

const listStyle = css`
  list-style: disc;
`

const ResponsibleGamingPolicy = () => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <>
      <Header title={`${promptBrand("name")} | Gaming Policy`} />
      <section
        className=" text-white md:pb-32 xs:pb-20  w-full policies-custom-style"
        style={{ backgroundColor: themeColors?.primary }}
      >
        <div className="container-max-width">
          <div className="mx-auto  pt-40 sm:px-7 lg:px-0 xl-start:p-10 xs:px-6">
            <h1 className="text-2xl font-bold mb-10">
              Responsible Gaming Policy
            </h1>
            <p>
              {promptBrand("site")}is a brand name of Crypto Casino, Reg No
              161315, having its registered at Abraham de Veerstraat 9,
              Willemstad, P.O. Box 3421, Curaçao, licensed to conduct online
              gaming operations by the government of Curacao under license
              365/JAZ.
            </p>
            <h1 className="text-lg font-bold my-5">
              Gambling with responsibility:
            </h1>
            <p>
              Gaming is a fun and exciting entertainment. Gambling is an
              attractive option if you want to try your luck. Most of our
              visitors enjoy this kind of entertainment without any problems,
              but there are a small number of people who lose a control over
              their gambling activity. Play responsibly!
            </p>
            <h1 className="text-lg font-bold my-5">Compulsive gambler:</h1>
            <p>
              Signs indicating a disorder include the person’s inability to stop
              gambling, borrowing money for gambling, changes in the character
              of the player, risking with employment, family for the sake of
              gambling.
            </p>
            <h1 className="text-lg font-bold my-5">Definitions</h1>
            <div>
              <p>
                <span className="font-semibold">Website :</span> refers to
                {promptBrand("site")}
              </p>
              <p className="my-1">
                <span className="font-semibold">Company:</span> refers to Carbon
                Games N.V., incorporated and registered in Curacao with company
                number 161315 whose registered office is at Abraham de
                Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao.
              </p>
              <p className="my-1">
                <span className="font-semibold">WE or US:</span> refers to the
                company
              </p>
              <p className="my-1">
                <span className="font-semibold">Country:</span> Refers to
                Curacao
              </p>
              <p className="my-1">
                <span className="font-semibold">Ethereum</span>: refers to the
                currency used on {promptBrand("site")}.
              </p>
              <p className="my-1">
                <span className="font-semibold">Ethereum-Safe:</span> refers to
                your on-side wallet in which your ethereum are stored.
              </p>
              <p className="my-1">
                <span className="font-semibold">Terms:</span> refers to this
                Agreement (Terms of Service) in addition to the Privacy Policy,
                KYC-AML-Policy, and Responsible Gambling Policy
              </p>
              <p className="my-1">
                <span className="font-semibold"> Account: </span> refers to a
                player&#39;s user account created on {promptBrand("site")}under
                this agreement.
              </p>
              <p className="my-1">
                <span className="font-bold">User:</span> refers to any natural
                individual using services on {promptBrand("site")}under the
                terms.
              </p>

              <p className="my-1">
                <span className="font-semibold">AML: </span> refers to
                Anti-Money Laundering, further information in our KYC-AML policy
              </p>
              <p className="my-1">
                <span className="font-semibold">KYC:</span> refers to Know Your
                Customer, further information in our KYC-AML policy
              </p>

              <p className="my-1">
                <span className="font-semibold">Support:</span> refers to the
                {promptBrand("site")}Support Team and the carbonteam Compliance
                Team
              </p>

              <p className="my-1">
                <span className="font-semibold">Third-party partner:</span>{" "}
                refers to all of the company&#39;s business associates.
              </p>
              <p className="my-2">
                For us, gambling is an excellent experience of entertainment,
                fun, and excitement but you have to consider the possibility of
                negative side effects being caused by the participation in
                gambling services, which may apply to some of our users and
                customers such as pathological gambling.
              </p>
            </div>
            <h1 className="text-lg font-bold my-5">Information and Contact</h1>
            <p>
              Our support will help you via E-Mail at anytime without any
              additional charges for you. The Carbon support team aims to
              respond to your request as quickly as possible.
            </p>
            <ul css={listStyle}>
              <li className="my-1">
                By E-Mail:{" "}
                <a href="mailto: support@casinocarbon.com">
                  support@casinocarbon.com
                </a>
              </li>
              <p>
                As the essential tool in the prevention of gambling addictions
                and other adverse side effects is knowledge, we wish to share
                our most valuable tricks, information, guidelines and tips
                established together with some of the best specialists in
                addiction prevention, responsible internet usage and doctors,
                which recognised the potential danger of pathological gambling
                in medical science as a severe sickness and risk to modern
                society.
              </p>
              <li className="my-1">
                Set yourself a deposit limitThink about how much money you could
                afford to spend on gambling services before you deposit or
                participate in games.
              </li>
              <li className="my-1">
                Limit the time you spend on gambling websitesGames.
              </li>
              <li className="my-1">
                Do not attempt to win back every loss right away. You have to
                accept that you can not win whenever you participate in gambling
                services.
              </li>
              <li className="my-1">
                Play smart and responsible. So, do not participate in gambling
                services when you are under the influence of drugs, alcohol or
                strong medication that affects your choices and decisions.
              </li>
              <li className="my-1">
                Take regular breaks and not sit in front of your electronic for
                hours.
              </li>
              <li className="my-1">
                You need to spend enough time talking with your friends and
                family.
              </li>
              <li className="my-1">
                Ask for aid early if you feel that you get close to addiction or
                regret participating in gambling services.
              </li>
              <li className="my-1">Have fun and play for fun</li>
            </ul>
            <h1 className="text-lg font-bold my-5">Minor Protection</h1>
            <p>
              To use our Service, you have to be 18 years of age or older. To
              avoid abuse, keep your login data and password safe from any
              minors near you Generally, we recommend a filter program to
              prevent minors, especially children, from accessing any content on
              the internet, which is not healthy or made for them. Additionally,
              we recommend that all parents install a filter program or plug in
              to prevent minors and children from accessing content and websites
              that are not made for them.
            </p>
            <h1 className="text-lg font-bold my-5">Limits</h1>
            <p>
              Loss limit allows a player to set the maximum loss limit for
              selected period. There is always a possibility to change the loss
              limit, but it takes effect accordingly:
            </p>
            <ol css={listStyle}>
              <li>
                Changing the limit to more restrictive takes effect immediately
              </li>
              <li>
                Changing the limit to less restrictive takes effect 48 hours
                after the change.
              </li>
            </ol>
            <p className="my-3">
              Please write to{" "}
              <a href="mailto: support@casinocarbon.com">
                support@casinocarbon.com
              </a>{" "}
              in order to self-exclude.
            </p>
            <h1 className="text-lg font-bold my-5">Self-Exclusion</h1>
            <p>
              For those customers who wish to restrict their gambling, we
              provide a voluntary self-exclusion policy, which enables you to
              close your Account or restrict your ability to place bets or game
              on the website for a minimum period of six months. Once your
              account has been self-excluded you will be unable to reactivate
              the account under any circumstances until the expiry of the period
              chosen under this paragraph. At the expiry of the self-exclusion
              period, you will be entitled to re-commence use of the Services by
              contacting&nbsp; &nbsp;
              <a href="mailto: support@casinocarbon.com">
                support@casinocarbon.com
              </a>
            </p>
            <h1 className="text-lg font-bold my-5">Temporary Break</h1>
            <p>
              If you fear you might get addicted to gambling or wish for a
              temporary break from all gambling-related services for a different
              reason, we would like to support you in that choice. A temporary
              break from all gambling services may help you realize if you are
              already addicted or help you in other forms. You can not log into
              your account on the website for 7 (seven) days during a temporary
              break. Please keep in mind that the temporary break is permanent
              for 7 (seven) days or the time you agreed with the support. The
              temporary exclusion can and will not be changed in any way or
              form. By asking for a temporary break, you accept it as it is and
              will not circumvent it in any form or way.
            </p>
            <h1 className="text-lg font-bold my-5">Actions by the company</h1>
            <p>
              Due to the company&#39;s responsibility to assist in the
              prevention of gambling addictions and ending severe harmful side
              effects, the company may be forced to make some decisions. Please
              understand that these actions and decisions may go against your
              will and restrict your account or deny you access to the website
              if the company&#39;s staff identifies severe gambling addiction
              cases.
            </p>
            <h1 className="text-lg font-bold my-5">Contact</h1>
            <p>
              If you have any questions, issues, suggestions, problems, or
              feedback, feel free to contact our Carbon Support Team and our
              CarbonCompliance Team any time:
            </p>
            <p className="pb-5">
              By E-mail:{" "}
              <a href="mailto:support@casinocarbon.com">
                support@casinocarbon.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ResponsibleGamingPolicy
