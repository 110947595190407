import React from "react"
import PropTypes from "prop-types"
// import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { Input as InputMT } from "@material-tailwind/react"

const Input = ({
  type,
  disabled,
  id,
  onChange,
  isRequired,
  onKeyDown,
  success,
  error,
  label,
  value,
  autoCapitalize,
  disabledInputBackground,
  smallFont,
  isCustom,
  customStyle,
}) => (
  <InputMT
    autoCapitalize={autoCapitalize}
    variant={isCustom ? "standard" : "outlined"}
    id={id}
    type={type}
    disabled={disabled}
    name={id}
    required={isRequired}
    onChange={onChange}
    success={success}
    error={error}
    label={label}
    labelProps={{
      className: `peer-focus:before:border-tertiary peer-focus:after:border-tertiary peer-focus:text-tertiary peer-focus:font-black peer-focus:text-md  ${
        isCustom ? "absolute left-2 peer-focus:left-auto text-tertiary" : ""
      } `,
    }}
    color="tertiary"
    className={`focus:ring-0 focus:border-tertiary bg-[#fff] ${
      disabledInputBackground ? "disabled:bg-[#fff]" : ""
    } ${smallFont ? "text-[10px]" : ""} ${
      isCustom ? "custom-input-search" : ""
    } ${customStyle ? "custom-input-style" : ""} `}
    value={value}
    onKeyDown={
      type === "number"
        ? (evt) =>
            (evt.key === "e" && evt.preventDefault()) ||
            (evt.key === "-" && evt.preventDefault()) ||
            (evt.key === "+" && evt.preventDefault())
        : onKeyDown
    }
  />
)
Input.defaultProps = {
  type: "text",
  disabled: false,
  id: "",
  onChange: Function.prototype,
  onKeyDown: Function.prototype,
  isRequired: false,
  success: false,
  error: false,
  value: "",
  autoCapitalize: "",
  disabledInputBackground: false,
  smallFont: false,
  isCustom: false,
  customStyle: false,
}
Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "radio", "checkbox", "number", "password"]),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  onKeyDown: PropTypes.func,
  success: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  autoCapitalize: PropTypes.string,
  disabledInputBackground: PropTypes.bool,
  smallFont: PropTypes.bool,
  isCustom: PropTypes.bool,
  customStyle: PropTypes.bool,
}

export default Input
