import React from "react"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import Banner from "../../../components/Banner"
import Button from "../../../components/Button"
// import aboutUsAsset from "../../../assets/images/home/about-us-asset.png"
import evolutionProviderAsset from "../../../assets/images/gamesProvider/evolution-provider-asset.png"
import belatraProviderAsset from "../../../assets/images/gamesProvider/belatra-provider-asset.png"
import playsonProviderAsset from "../../../assets/images/gamesProvider/playson-provider-asset.png"
import kalambaProviderAsset from "../../../assets/images/gamesProvider/kalamba-provider-asset.png"
import {
  promptBrand,
  imageDistributed,
  getBrandFromUrl,
} from "../../../helpers"
import themes from "../../../themes"

// TODO : the secondary button is hidden until make decision about it

const About = () => {
  const { t, i18n } = useTranslation()
  const languageSelected = i18n.language?.split("-")[0]

  const navigateToGamesCatalog = () => {
    // scroll to view and offset it
    const gamesCatalog = document.getElementById("games-catalog-id")
    if (gamesCatalog) {
      gamesCatalog.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
      })
      if (window.screen.width > 700) {
        window.scrollBy(0, 250)
      }
    }
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return (
    <section
      className=" md:pb-8 xs:pb-9  text-white w-full  h-auto "
      style={{
        background: `url(${imageDistributed(
          "aboutUsBackground"
        )}) top/contain no-repeat, ${themeColors.primary}`,
        color: "white",
      }}
    >
      <div className="container-max-width sm:px-7 lg:px-0 xl-start:p-10 xs:px-6 about-section h-[100%] pt-10">
        <div className="py-2 h-320 pb-10  ">
          <Banner>
            <div className="bg-secondary rounded-3xl py-5 flex justify-center  about-us-bg w-full ">
              <div className="flex w-auto h-[180%]  justify-end items-center px-2 relative mb-10 ">
                <div className="w-3/3  h-full pl-10 mobile:pl-5 flex-col justify-center	items-center z-20 relative">
                  <div className="w-full h-auto py-5 nft-banner-title  text-3xl desktop:text-2xl tablet:text-2xl mobile:text-xl">
                    {t("Discover our online casino!")}
                  </div>
                  <div className=" w-full md:w-[75%] desktop-md-screen:w-[85%]   nft-banner-body  sm:text-xl md:text-xl  lg:text-xl xl:text-xl pr-5 h-auto xs:w-[100%]">
                    <p className="w-full lg:w-[100%] text-[18px]">
                      {t(
                        `${promptBrand(
                          "name"
                        )} intends to become the market leader in online gambling while leveraging the ETH blockchain.`
                      )}
                    </p>
                    {languageSelected === "fr" && (
                      <p className="tablet:w-[70%] xs:w-[100%] text-[18px] mobile:w-[100%]">
                        {promptBrand("name")} sera composé de de casino en ligne
                        ainsi que de paris sportifs. Unique sur le marché, le
                        {promptBrand("name")} propose des bénéfices exclusifs à
                        leurs détenteurs avec un bonus de 100% jusqu&#39;à 300€.
                      </p>
                    )}
                    {languageSelected !== "fr" ? (
                      <p className="tablet:w-[70%] xs:w-[100%] text-[18px]">
                        {t(
                          "Unique on the market, the  offers exclusive benefits to its holders as 100% bonus of winnings will be distributed to the winners up to 300€."
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <MediaQuery minWidth={768} maxWidth={959}>
                    <div className=" w-[150px] md:w-[250px]  ">
                      <img
                        fetchpriority="low"
                        src={imageDistributed("aboutUsAsset")}
                        className=" z-10 w-auto h-[200px] block absolute -bottom-14 tablet:-bottom-14 -right-3 "
                        alt="about-us-banner-asset"
                      />
                    </div>
                  </MediaQuery>
                </div>
                <MediaQuery minWidth={960}>
                  <div className="h-full w-full   block ">
                    <img
                      fetchpriority="low"
                      src={imageDistributed("aboutUsAsset")}
                      className="z-10 w-auto absolute h-[330px] desktop-md-screen:h-[300px] desktop-md-screen:top-[15%] lg:-top-[-1%] right-3"
                      alt="about-us-banner-asset"
                    />
                  </div>
                </MediaQuery>
              </div>
            </div>
          </Banner>
        </div>
        <div className="my-5">
          <Banner>
            <div
              className="rounded-[1.75rem] py-4  xs:px-4 desktop:flex  desktop:items-center xs:flex xs:flex-col xs:justify-between xs:items-center cursor-pointer custom-desktop-style"
              style={{
                backgroundColor: themeColors?.secondary,
                color: "white",
              }}
              onKeyDown={Function.prototype}
              role="button"
              tabIndex={0}
            >
              <MediaQuery minWidth={375} maxWidth={959}>
                <h1 className="text-xl pl-5">{t("Our Top Providers")}</h1>
              </MediaQuery>
              <MediaQuery minWidth={1103}>
                <h1 className="text-[27px] pl-5">{t("Our Top Providers")}</h1>
              </MediaQuery>
              <div className="flex justify-between items-center xs:flex  xs:my-5 desktop:my-auto mobile-custom-style">
                <img
                  fetchpriority="low"
                  src={evolutionProviderAsset}
                  alt="evolution-provider"
                  className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-6 specific-style"
                />
                <img
                  fetchpriority="low"
                  src={belatraProviderAsset}
                  alt="belatra-games-provider"
                  className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-6 specific-style"
                />
                <img
                  fetchpriority="low"
                  src={playsonProviderAsset}
                  alt="playson-provider"
                  className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-6 specific-style"
                />
                <img
                  fetchpriority="low"
                  src={kalambaProviderAsset}
                  alt="kalamba-provider"
                  className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-6 specific-style"
                />
              </div>
              <div className="flex justify-center xs:my-6 desktop:my-auto desktop:pr-6">
                <MediaQuery minWidth={320} maxWidth={1199}>
                  <Button.Secondary
                    className="xs:text-sm tablet:text-base"
                    onClick={navigateToGamesCatalog}
                  >
                    {t("Browse Games")}
                  </Button.Secondary>
                </MediaQuery>
                <MediaQuery minWidth={1200}>
                  <Button.Secondary
                    className="xs:text-sm tablet:text-base"
                    onClick={navigateToGamesCatalog}
                  >
                    {t("Browse Games")}
                  </Button.Secondary>
                </MediaQuery>
              </div>
            </div>
          </Banner>
        </div>
      </div>
    </section>
  )
}
export default About
