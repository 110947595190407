import React, { useState, useEffect } from "react"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const SloganText = () => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const [themeColors, setThemeColors] = useState(
    themes["www.carbongaming.net"].colors
  )

  useEffect(() => {
    setThemeColors(themes[brand]?.colors)
  }, [brand])

  return (
    <>
      <MediaQuery minWidth={960} maxWidth={1050}>
        <p
          className=" text-[10px] relative -top-4 -right-12 mt-2"
          style={{ color: themeColors?.tertiary }}
        >
          {t("Fastest growing")}
          <span className=" text-[10px]"> {t("crypto casino")}</span>
        </p>
      </MediaQuery>
      <MediaQuery minWidth={1051} maxWidth={1160}>
        <p
          className=" text-xs relative -top-4 -right-14 my-2"
          style={{ color: themeColors?.tertiary }}
        >
          {t("Fastest growing")}
          <span className="text-xs"> {t("crypto casino")}</span>
        </p>
      </MediaQuery>
      <MediaQuery minWidth={1161} maxWidth={1260}>
        <p
          className=" text-xs relative -top-4 -right-16"
          style={{ color: themeColors?.tertiary }}
        >
          {t("Fastest growing")}
          <span className="text-xs"> {t("crypto casino")}</span>
        </p>
      </MediaQuery>
      <MediaQuery minWidth={1261} maxWidth={1359}>
        <p
          className=" text-xs relative -top-4 -right-20"
          style={{ color: themeColors?.tertiary }}
        >
          {t("Fastest growing")}
          <span className="text-xs"> {t("crypto casino")}</span>
        </p>
      </MediaQuery>
      <MediaQuery minWidth={1360}>
        <p
          className=" text-sm relative -top-5 left-24"
          style={{ color: themeColors?.tertiary }}
        >
          {t("Fastest growing")}
          <span className="text-sm"> {t("crypto casino")}</span>
        </p>
      </MediaQuery>
    </>
  )
}

export default SloganText
