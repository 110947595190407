import React, { useState } from "react"

import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"

// components
import Spinner from "../../components/Spinner"

// Utilities
import { getPaysageCheckoutUrl } from "../../actions/wallet"
import { logger } from "../../helpers"
import getAmount from "./helpers"

// assets

// style
import "./style.css"

const Paysage = () => {
  const amount = getAmount()
  logger(amount)

  const [paysageUrl, setPaysageurl] = useState("")
  const { i18n } = useTranslation()

  /* useQuery */
  const { isLoading, refetch } = useQuery({
    queryKey: ["getPaysageCheckoutUrl", amount],
    queryFn: () =>
      getPaysageCheckoutUrl(amount, "EUR", i18n.language?.split("-")[0]),
    refetchOnMount: "always",
    onSuccess: ({ data: paysageUrlResponse }) => {
      setPaysageurl(paysageUrlResponse?.redirect_url)
    },
    onError: (error) => {
      logger(error)
      refetch()
    },
  })

  return isLoading ? (
    <div className="container-max-width-switchere  iframe-max-height h-full  w-full   py-5 flex justify-center items-center">
      <Spinner />
    </div>
  ) : (
    <div className="container-max-width-switchere h-full  w-full   py-5">
      <iframe
        title="paysage"
        src={paysageUrl}
        className="w-full h-[500px] z-0 paysage-widget-container"
      />
    </div>
  )
}

export default Paysage
