import * as Yup from "yup"
import { isValidPhoneNumber } from "../components/PhoneNumberInput"

export default Yup.object().shape({
  username: Yup.string()
    .required("A username is required.")
    .max(255, "The username is too long.")
    .matches(/^\w*$/, "The username should be alphanumerical characters only."),
  email: Yup.string()
    .required("An email address is required.")
    .max(255, "The email address is too long.")
    .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "The email address is not valid.")
    .matches(/^\S*$/, "The email address cannot contain whitespaces."),
  password: Yup.string()
    .required("Password is mandatory.")
    .min(8, "Password must be at least 8 characters long.")
    .test(
      "oneNumber",
      "The password should include at least one number.",
      (value) => [/[0-9]/].every((pattern) => pattern.test(value))
    )
    .test(
      "specialCharacter",
      "The password should include at least one special character.",
      (value) =>
        [
          /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{6,}$/,
        ].every((pattern) => pattern.test(value))
    ),
  passwordConfirmation: Yup.string()
    .required("Password is mandatory.")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
  bonusCode: Yup.string(),
  phoneNumber: Yup.string()
    .required("The phone number is required.")
    .min(4, "The phone number should be at least 4 digits.")
    .test("Valid phone number.", "The phone number is not valid", (value) => {
      if (value) return isValidPhoneNumber(value)
      return ""
    }),
  termsAndConditions: Yup.bool()
    .oneOf([true], "You need to accept the terms and conditions.")
    .default(false),
  over18: Yup.bool().oneOf([true], "You need to be over 18.").default(false),
})
