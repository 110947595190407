import React, { useState } from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation } from "@fortawesome/free-solid-svg-icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import bonusCodeShema from "../../model/bonusCodeShema"
import { getUser } from "../../actions/user"
import { postBonusCode } from "../../actions/wallet"
import { Modal } from "../../components/Modal"
import { Tabs } from "../../components/Tabs"
import { imageDistributed, getThemeColors } from "../../helpers"
import Input from "../../components/Input"
import Button from "../../components/Button"
import Spinner from "../../components/Spinner"
import { toast } from "../../components/Toast"

const serviceName = "bonus-code"

const borderStyle = (themeColors) => css`
  border-width: 4px; /* Equivalent to Tailwind's border-4 */
  border-style: solid; /* You need to define the style to use width in CSS */
  border-color: ${themeColors?.specialTertiary}; /* Dynamically set from themeColors */
`

const ActivateBonusCode = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParam, setSearchParam] = useSearchParams()

  const [errorCodeMessage, setErrorCodeMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const formOptions = { resolver: yupResolver(bonusCodeShema) }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const submitPostcode = async (data) => {
    setIsLoading(true)
    const response = await postBonusCode(data?.bonusCode)
    if (response?.status === 200) {
      toast.success(
        t("Bonus Code Activated !"),
        t("Congratulations, you have successfully activated your Bonus Code !")
      )
      setErrorCodeMessage(false)
      reset()
      dispatch(getUser())
      setIsLoading(false)
      searchParam.delete("service")
      setSearchParam(searchParam)
    } else if (
      response.status === 403 ||
      response?.data?.code === "coupon-type-incorrect"
    ) {
      toast.error(
        t("Coupon code activation not allowed !"),
        t(
          "The activation of this coupon code is not allowed. Please try with an active one!"
        )
      )
    } else {
      reset()
      setErrorMessage(response?.data?.message)
      setErrorCodeMessage(true)
    }
    setIsLoading(false)
  }

  const themeColors = getThemeColors()

  return (
    <div className="mt-6 flex flex-row xs:flex xs:flex-col xs:justify-center">
      <div className="mb-5 w-full">
        <form>
          <div className="mt-6 flex flex-row xs:flex xs:flex-col xs:justify-center xs:items-center ">
            <div
              className="xl:w-1/2 xs:w-full flex flex-col  rounded-xl xl:mr-3 xs:mb-2 xl:mb-0 px-5 py-4 "
              style={{
                backgroundColor: themeColors?.lightTertiary,
              }}
              css={borderStyle(themeColors)}
            >
              <h1 className="text-specialPrimary font-bold">
                {t("Activate your Bonus Code")}{" "}
              </h1>
              <div className="my-4 relative">
                <Controller
                  control={control}
                  name="bonusCode"
                  render={({ field, formState }) => (
                    <Input
                      autoCapitalize="none"
                      type="text"
                      id="bonus-code"
                      label=" Insert your code"
                      {...field}
                      error={formState.errors?.bonusCode && errorCodeMessage}
                    />
                  )}
                />
                <span className="text-red-700 text-xs">
                  {(errors?.bonusCode && errors.bonusCode.message) ||
                    errorCodeMessage}
                </span>
              </div>
              <div>
                {errorCodeMessage ? (
                  <p className="text-red-700 text-sm flex items-center">
                    <FontAwesomeIcon
                      icon={faExclamation}
                      className=" text-white w-3 h-3 bg-red-800 rounded-full p-1 mr-3"
                    />
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3">
                <p className="text-gray-700 text-sm">
                  {t("Having an Issue with your Bonus Code?")}{" "}
                  <a
                    href="https://help.casinocarbon.com/en/"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {t("Contact Support")}
                  </a>
                </p>
                <p className="text-sm text-gray-700">
                  {t("By clicking Redeem Code, you agree to the Bonus Code")}{" "}
                  <Link
                    to="terms/terms-and-conditions"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {t("Terms and Conditions")}{" "}
                  </Link>{" "}
                  {t("as applicable.")}{" "}
                </p>
              </div>
              <MediaQuery maxWidth={767}>
                <div className="mb-3 mt-7">
                  {isLoading ? (
                    <Spinner theme="dark" />
                  ) : (
                    <Button.Primary
                      fullWidth
                      onClick={handleSubmit(submitPostcode)}
                      onSubmit={handleSubmit(submitPostcode)}
                    >
                      {t("Redeem Code")}{" "}
                    </Button.Primary>
                  )}
                </div>
              </MediaQuery>
            </div>
            <MediaQuery minWidth={768}>
              <div
                className="xl:w-1/2 xs:w-full flex flex-col rounded-xl xl:mr-3 xs:mb-2 xl:mb-0 px-5 py-4 "
                style={{
                  backgroundColor: themeColors?.lightTertiary,
                }}
                css={borderStyle(themeColors)}
              >
                <div className="flex justify-center items-center mb-2">
                  <img
                    fetchpriority="low"
                    className="w-auto h-56"
                    src={imageDistributed("bonusCodeAsset")}
                    alt="activate-bonus-asset"
                  />
                </div>
                <div className="mb-3">
                  {isLoading ? (
                    <Spinner theme="dark" />
                  ) : (
                    <Button.Primary
                      fullWidth
                      onClick={handleSubmit(submitPostcode)}
                      onSubmit={handleSubmit(submitPostcode)}
                    >
                      {t("Redeem Code")}{" "}
                    </Button.Primary>
                  )}
                </div>
              </div>
            </MediaQuery>
          </div>
        </form>
      </div>
    </div>
  )
}

const BonusCodeModal = ({ activeService, handleCloseModal }) => {
  const { t } = useTranslation()
  const themeColors = getThemeColors()
  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="xs:h-[680px] xs:overflow-auto modal-bonus-code-view">
        <MediaQuery maxWidth={959}>
          <div className="flex justify-between items-center xl:h-auto xs:h-16 xs:bg-white  xs:w-full">
            <h1
              className="text-xl leading-5 font-medium px-7"
              style={{ color: themeColors?.tertiary }}
            >
              {t("Bonus Code")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className="flex justify-between items-center">
            <h1 className="pl-10" style={{ color: themeColors?.tertiary }}>
              {t("Bonus Code")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </div>
        </MediaQuery>
        <div>
          <Tabs>
            <div>
              <Tabs.Body>
                <div className="p-5 border-t border-gray-300">
                  <div>
                    <div className="mt-2 text-sm">
                      <p className="text-gray-700 text-sm">
                        {t(
                          "The fun and rewards commence the moment you enter our casino, you can collect balance, chips and spins The only person determining the size of your bonus is you! Our casino has several Welcome Bonuses, each designed for different types of players. Sports, Poker, Casino – you choose"
                        )}
                      </p>
                      <p
                        className="font-bold test-sm"
                        style={{ color: themeColors?.tertiary }}
                      >
                        {t(
                          "To cash in your bonus, make sure to redeem the codes provided by our casino."
                        )}
                      </p>
                    </div>
                    <div className="mb-5">
                      <ActivateBonusCode />
                    </div>
                  </div>
                </div>
              </Tabs.Body>
            </div>
          </Tabs>
        </div>
      </div>
    </Modal>
  )
}

BonusCodeModal.defaultProps = {
  activeService: "bonus-code",
}

BonusCodeModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
}
export default BonusCodeModal
