import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { css } from "@emotion/react"
import MediaQuery from "react-responsive"
/** @jsxImportSource @emotion/react */
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEye,
  faEyeSlash,
  // faGift,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import {
  postLogin,
  postRegister,
  postRegisterCheck,
  goBackToRegisterFormView,
  sendPhoneVerificationCode,
} from "../../actions/auth"
import otpFormSchema from "../../model/otpSchema"
import { Modal } from "../../components/Modal"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Checkbox from "../../components/Checkbox"
import formSchema from "../../model/RegisterShema"
// import registerModalAsset from "../../assets/images/modalAssets/register-modal-asset-new.png"
// import registerModalAssetSuccess from "../../assets/images/modalAssets/register-modal-asset-.png"
import { toast } from "../../components/Toast"
import Spinner from "../../components/Spinner"
import Tooltip from "../../components/Tooltip"
import IconsButton from "../../components/IconsButton"
import SloganText from "../../components/SloganText"
import PhoneNumberInput from "../../components/PhoneNumberInput"
import OTPInput from "../../components/OTPInput"
import CountDown from "../../components/CountDown"
import PageLink from "../../components/PageLink"
// import registerModalPhoneVerificationAsset from "../../assets/images/modalAssets/register-modal-phone-number-verification-asset.png"
// import arrowLeftDirection from "../../assets/icons/arrow-left-direction.svg"
import CheckPassword from "../../components/CheckPassword"
import MetaEventService from "../../services/MetaEventService"
import {
  brandLogo,
  getThemeColors,
  imageDistributed,
  promptBrand,
} from "../../helpers"

// TODO: what is the neceessity of checkbox : subscribe to our email inbox

const serviceName = "register"
const LinkStyle = css`
  text-decoration: underline !important;
`

const PhoneNumberVerificationView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { username, email, phoneNumber, password, bonusCode, requestID } =
    useSelector((state) => state.auth)

  const [searchParams, setSearchParams] = useSearchParams()
  const handleCloseModal = () => {
    searchParams.delete("service")
    setSearchParams(searchParams)
  }
  const formOptions = { resolver: yupResolver(otpFormSchema) }
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)

  const [resendSMSIsActive, setResendSMSIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleResendSMSAction = () => {
    setResendSMSIsActive(true)
  }

  const handleResendSmsCode = () => {
    setResendSMSIsActive(false)

    dispatch(sendPhoneVerificationCode(phoneNumber, "sms"))
  }

  const handleReceiveCall = () => {
    setResendSMSIsActive(false)

    dispatch(sendPhoneVerificationCode(phoneNumber, "call"))
  }

  const handleClickAfterVerificationPhone = async ({ otpValue }) => {
    setIsLoading(true)
    const result = await dispatch(
      postRegister(
        username,
        email,
        phoneNumber,
        password,
        bonusCode,
        otpValue,
        requestID
        // brand
      )
    )
    if (result.status === 201) {
      toast.success(
        t("Successfully Registered !"),
        t(
          `Congratulations, you are now a member of ${promptBrand(
            "name"
          )}' squad !`
        )
      )

      MetaEventService.sendCompleteRegistrationEvent(result.data?.meta_event_id)
      if (result.data.coupon) {
        toast.success(
          t("Bonus Code Activated !"),
          t("Congratulations, you have successfully activated your Bonus!")
        )
      }
      if (result.data?.referrer) {
        toast.success(
          t(`Welcome to our ${promptBrand("name")} Squad !`),
          t(`You have been referred by `) +
            result.data.referrer +
            t(` Nice Journey`)
        )
      }
      const loginResult = await dispatch(postLogin(username, password))
      if (loginResult.status !== 200) {
        toast.error(
          "We can't sign you in",
          "We ecountered a problem while signing you in, please try again later"
        )
        return
      }

      navigate("/", {
        state: { welcomeModal: true },
      })
    } else if (
      result.status === 400 &&
      result.data.code === "phone-verification-check-invalid-code"
    ) {
      toast.error(t("Verification error"), t("This OTP Code is invalid."))
    } else if (
      result.status === 422 &&
      result.data.code === "phone-verification-code-expired"
    ) {
      toast.error(
        t("Expired phone verification code."),
        t("Sorry, the phone verification code is expired. Please try again.")
      )
    } else if (
      result.status === 404 &&
      result.data.code ===
        "phone-verification-send-code-destination-not-supported"
    ) {
      toast.error(
        t("Unsupported Phone number destination."),
        t(
          "Sorry, the phone number you try to use is not supported. Please try again with another one."
        )
      )
    } else if (result.status === 403 && result.data.code === "brand-mismatch") {
      toast.error(
        t("An error has occurred !"),
        t("We have an unexpected problem ! Try again please ! ")
      )
    }
    setIsLoading(false)
  }

  const handleReturnBackToFormView = () => {
    dispatch(goBackToRegisterFormView())
  }

  const themeColors = getThemeColors()
  const borderStyle = (colors) => css`
    border: 1px solid ${colors.tertiary};
  `

  return (
    <div className="flex relative w-full ">
      <div
        className="desktop:w-[42%] flex flex-col items-center justify-center py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block  blurry"
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex flex-col">
          <img
            fetchpriority="high"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-version-svg"
            className="aspect-auto"
          />
          <SloganText />
        </div>
        <div className="flex justify-center">
          <img
            fetchpriority="high"
            src={imageDistributed("phoneVerificationView")}
            alt="register-modal-phone-number-verification-asset"
            className=" aspect-auto my- p-4"
          />
        </div>
        <p className="text-center  font-bold">
          <span className="mr-1" style={{ color: themeColors?.tertiary }}>
            100%
          </span>
          <span className="text-white">{t("Bonus Up to")} </span>
          <span className="" style={{ color: themeColors?.tertiary }}>
            300€
          </span>
          <span className="text-white ml-1">{t("more")}</span>
        </p>
        <p className="text-center font-medium">
          <span className="text-md" style={{ color: themeColors?.tertiary }}>
            2.500.120.454
          </span>
          <br />
          <span className="text-white text-sm opacity-40">
            {t("Bets Wagered")}
          </span>
        </p>
      </div>
      <div
        className="desktop:w-[58%] pb-3 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view"
        style={{
          backgroundColor: themeColors?.secondary,
          // color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="w-full">
          <div className="">
            <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
              <div
                className="xs:text-black desktop:text-tertiary text-xl leading-5 font-medium px-7 flex cursor-pointer"
                onClick={handleReturnBackToFormView}
                onKeyDown={Function.prototype}
                tabIndex={0}
                role="button"
              >
                <img
                  fetchpriority="low"
                  src={imageDistributed("arrowLeftDirectionAsset")}
                  alt="arrow-left-direction"
                  className="w-3 mr-2"
                />
                <span className="desktop:text-white xs:text-black">
                  {t("Back")}
                </span>
              </div>
              <MediaQuery maxWidth={959}>
                <Modal.Header handleCloseModal={handleCloseModal} />
              </MediaQuery>
              <MediaQuery minWidth={959}>
                <Modal.Header
                  handleCloseModal={handleCloseModal}
                  tertiaryIcon
                />
              </MediaQuery>
            </div>
            <div className="relative bottom-5">
              <MediaQuery maxWidth={959}>
                <div className="flex justify-center items-center my-5 py-5">
                  <img
                    fetchpriority="high"
                    src={brandLogo()}
                    alt="logo-mobile-version"
                    className="w-60 h-auto"
                  />
                </div>
              </MediaQuery>
              <MediaQuery minWidth={956}>
                <p
                  className="w-full text-2xl leading-5 font-medium mt-5 mb-0 px-7"
                  style={{ color: themeColors?.tertiary }}
                >
                  {t("Phone Verification")}
                </p>
              </MediaQuery>
              <p className="w-full text-xl text-white leading-5 font-medium mt-7 mb-0 px-7">
                {t("Place your bet within minutes.")}
              </p>
              <p className="px-7 mt-1 ">
                <Button.Tertiary>
                  {" "}
                  <span
                    className="text-sm font-bold w-full"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {t("Need help ?")}
                  </span>
                </Button.Tertiary>
              </p>
            </div>
          </div>

          <div className="py-15 px-7 text-md">
            <div className="mt-2 mb-3">
              <div>
                <p className="text-white">
                  {t("A 6-digits code has been sent to")}{" "}
                  <span
                    className=" text-sm font-bold"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {phoneNumber}
                  </span>
                </p>
                <div className="mt-5">
                  <form>
                    <Controller
                      control={control}
                      name="otpValue"
                      render={({ field, formState }) => (
                        <OTPInput
                          code={field.value}
                          error={formState.errors?.otpValue}
                          {...field}
                        />
                      )}
                    />
                    <span className="text-red-700 text-xs mt-1">
                      {errors?.otpValue && t(errors.otpValue.message)}
                    </span>
                    <div>
                      <p className="text-white text-sm mt-3 mobile:text-xs flex items-center">
                        {t("The OTP will expire in")}{" "}
                        <span className="text-tertiary font-bold ml-3">
                          <CountDown
                            onFinishCountDown={handleResendSMSAction}
                            isMounted={resendSMSIsActive}
                            duration={300}
                          />
                        </span>{" "}
                      </p>
                      <div className="text-sm flex items-center flex-wrap mobile:my-3">
                        <p className="text-white xs:text-xs">
                          {t("Didn’t receive the code ?")}
                        </p>
                        <p className="text-white  flex items-center">
                          <p className="lg:ml-2 xs:ml-[4px]">
                            <Button.Tertiary
                              disabled={!resendSMSIsActive}
                              onClick={handleResendSmsCode}
                            >
                              <span
                                className="w-full font-bold text-sm"
                                style={{ color: themeColors?.tertiary }}
                              >
                                {t("Resend SMS")}
                              </span>
                            </Button.Tertiary>{" "}
                          </p>
                          {t("or")}{" "}
                          <p className="lg:ml-2 xs:ml-[4px] desktop:ml-4">
                            <Button.Tertiary
                              disabled={!resendSMSIsActive}
                              onClick={handleReceiveCall}
                            >
                              <span
                                className="w-full font-bold text-sm"
                                style={{ color: themeColors?.tertiary }}
                              >
                                {t("Receive call")}
                              </span>
                            </Button.Tertiary>
                          </p>
                        </p>
                      </div>
                      <div className="mt-5">
                        {isLoading ? (
                          <div className="pb-5">
                            <Spinner theme="dark" />
                          </div>
                        ) : (
                          <Button.Primary
                            fullWidth
                            onClick={handleSubmit(
                              handleClickAfterVerificationPhone
                            )}
                          >
                            {t("Continue")}
                          </Button.Primary>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FormView = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const {
    username: savedUsername,
    email: savedEmail,
    phoneNumber: savedPhoneNumber,
  } = useSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState(false)

  // const [showBonusCodeInput, setShowBonusCodeInput] = useState(false)
  // const handleShowBonusCodeInput = () =>
  //   setShowBonusCodeInput(!showBonusCodeInput)

  const [showPasswordIn, setShowPasswordIn] = useState(false)
  const [showConfirmPasswordIn, setShowConfirmPasswordIn] = useState(false)

  const handleShowConfirmPasswordInModal = () => {
    setShowConfirmPasswordIn(!showConfirmPasswordIn)
  }
  const handleShowPasswordInModal = () => {
    setShowPasswordIn(!showPasswordIn)
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const handleRedirectToLogin = () => {
    searchParams.set("service", "login")
    setSearchParams(searchParams)
  }

  const handleCloseModal = () => {
    searchParams.delete("service")
    setSearchParams(searchParams)
  }

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: "all",
    defaultValues: {
      username: savedUsername,
      email: savedEmail,
      phoneNumber: savedPhoneNumber,
    },
  }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm(formOptions)

  useEffect(() => {
    reset() // initialize the form
  }, [reset])

  const handleRegisterErrors = (body) => {
    if (body) {
      if (Object.prototype.hasOwnProperty.call(body, "email")) {
        toast.error(t("Validation error"), t("This email is already used."))
      }
      if (Object.prototype.hasOwnProperty.call(body, "username")) {
        toast.error(t("Validation error"), t("This username is already used."))
      }
      if (Object.prototype.hasOwnProperty.call(body, "phone_number")) {
        toast.error(
          t("Validation error"),
          t("This phone number is already used.")
        )
      }
      if (body.code === "referrer-user-does-not-exist") {
        toast.error(
          t("Referrer not found !"),
          t("Please verify that the referrer exists.")
        )
      }
    }
  }
  const passwordErrorType = Object.values(errors?.password ?? [])
  const watchPassword = watch("password")

  const onFormSubmit = async ({
    username,
    email,
    phoneNumber,
    password,
    bonusCode,
  }) => {
    setIsLoading(true)
    const result = await dispatch(
      postRegisterCheck(username, email, phoneNumber, password, bonusCode)
    )

    if (result) {
      const { status, data } = result

      if (status === 400) {
        handleRegisterErrors(data)
      } else if (
        status === 404 &&
        data.code === "phone-verification-send-code-destination-not-supported"
      ) {
        toast.error(
          t("Unsupported Phone number destination."),
          t(
            "Sorry, the phone number you try to use is not supported. Please try again with another one."
          )
        )
      } else if (
        status === 409 &&
        data.code === "phone-verification-code-already-sent"
      ) {
        toast.error(
          "Phone verification",
          "The phone number verification code has already been sent. One every five minutes."
        )
      }
    } else {
      toast.error(
        t("Unexpected Error"),
        t("An error occurred. Please try again.")
      )
    }

    setIsLoading(false)
  }

  const handleCheckBoxOnchange = (field) => (e) => {
    field.onChange(e.target.checked)
  }

  const themeColors = getThemeColors()
  const borderStyle = (colors) => css`
    border: 1px solid ${colors.tertiary};
  `

  return (
    <div className="flex relative w-full">
      <div
        className="desktop:w-[42%] flex flex-col items-center justify-center   py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block border blurry"
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex flex-col">
          <img
            fetchpriority="high"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-version-svg"
            className="aspect-auto"
          />
          <SloganText />
        </div>
        <div className="flex justify-center">
          <img
            fetchpriority="high"
            src={imageDistributed("registerModalAsset")}
            alt="step-icon-1"
            className=" aspect-auto my-4 p-4"
          />
        </div>
        <p className="text-center  font-bold">
          <span className=" mr-1" style={{ color: themeColors?.tertiary }}>
            100%
          </span>
          <span className="text-white">{t("Bonus Up to")} </span>
          <span className="" style={{ color: themeColors?.tertiary }}>
            300€
          </span>
          <span className="text-white ml-1">{t("more")}</span>
        </p>
        <p className="text-center font-medium">
          <span className="text-md" style={{ color: themeColors?.tertiary }}>
            2.500.120.454
          </span>
          <br />
          <span className="text-white text-sm opacity-40">
            {t("Bets Wagered")}
          </span>
        </p>
      </div>
      <div
        className="desktop:w-[58%] pb-3 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view"
        style={{
          backgroundColor: themeColors?.secondary,
          // color: "white",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="w-full">
          <div className="">
            <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
              <h1
                className="xs:text-black text-xl leading-5 font-medium px-7"
                style={{ color: themeColors?.tertiary }}
              >
                {t("Sign Up")}
              </h1>
              <MediaQuery maxWidth={959}>
                <Modal.Header handleCloseModal={handleCloseModal} />
              </MediaQuery>
              <MediaQuery minWidth={959}>
                <Modal.Header
                  handleCloseModal={handleCloseModal}
                  tertiaryIcon
                />
              </MediaQuery>
            </div>
            <div className="relative bottom-5">
              <MediaQuery maxWidth={959}>
                <div className="flex justify-center items-center my-5 py-5">
                  <img
                    fetchpriority="high"
                    src={brandLogo()}
                    alt="logo-mobile-version"
                    className="w-60 h-auto"
                  />
                </div>
              </MediaQuery>
              <MediaQuery minWidth={956}>
                <p className="w-full text-xl text-white leading-5 font-medium mt-2 mb-0 px-7">
                  {t("Place your bet within minutes.")}
                </p>
              </MediaQuery>
              <div className="px-7">
                <Button.Tertiary theme="light" onClick={handleRedirectToLogin}>
                  <span
                    className="text-sm font-bold"
                    style={{ color: themeColors?.tertiary }}
                  >
                    {t("Already have an account?")}
                  </span>
                </Button.Tertiary>
              </div>
            </div>
          </div>

          <div className="py-15 px-7 text-md">
            <form className="mt-2 mb-3">
              <div
                className={`relative ${
                  errors?.username ? "desktop:mb-1 xs:mb-3" : "mb-4"
                }`}
              >
                <Controller
                  control={control}
                  name="username"
                  render={({ field, formState }) => (
                    <Input
                      autoCapitalize="none"
                      type="text"
                      id="user-name"
                      label={t("Username")}
                      {...field}
                      error={formState.errors?.username}
                    />
                  )}
                />
                <MediaQuery minWidth={960}>
                  <span className="text-red-700 text-xs ">
                    {errors?.username && t(errors.username.message)}
                  </span>
                </MediaQuery>
                <MediaQuery maxWidth={959}>
                  {errors?.username && (
                    <div className="absolute top-2 right-2 ">
                      <Tooltip content={errors?.username?.message} type="check">
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="text-red-700 w-6 h-6  cursor-pointer "
                          />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </MediaQuery>
              </div>
              <div
                className={`relative ${
                  errors?.email ? "desktop:mb-1 xs:mb-3" : "mb-4"
                }`}
              >
                <Controller
                  control={control}
                  name="email"
                  render={({ field, formState }) => (
                    <Input
                      type="text"
                      id="user-email"
                      label={t("Email Address")}
                      error={formState.errors?.email}
                      {...field}
                    />
                  )}
                />
                <MediaQuery minWidth={960}>
                  <span className="text-red-700 text-xs">
                    {errors?.email && t(errors.email.message)}
                  </span>
                </MediaQuery>
                <MediaQuery maxWidth={959}>
                  {errors?.email && (
                    <div className="absolute top-2 right-2">
                      <Tooltip content={errors?.email?.message} type="check">
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="text-red-700 w-6 h-6 cursor-pointer"
                          />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </MediaQuery>
              </div>
              <div className="py-1 mb-3">
                <div>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field, formState }) => (
                      <PhoneNumberInput
                        {...field}
                        error={formState.errors?.phoneNumber}
                      />
                    )}
                  />
                  <span className="text-red-700 text-xs ">
                    {errors?.phoneNumber && t(errors.phoneNumber.message)}
                  </span>
                </div>
              </div>
              <div
                className={`relative ${
                  errors?.password ? "desktop:mb-1 xs:mb-3" : "mb-4"
                }`}
              >
                <Controller
                  control={control}
                  name="password"
                  render={({ field, formState }) => (
                    <Input
                      autoCapitalize="none"
                      type={showPasswordIn ? "text" : "password"}
                      id="password-register"
                      label={t("Password")}
                      error={formState.errors?.password}
                      {...field}
                    />
                  )}
                />
                <MediaQuery maxWidth={959}>
                  {errors?.email && (
                    <div className="absolute top-2 right-2">
                      <Tooltip content={errors?.password?.message} type="check">
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="text-red-700 w-6 h-6 cursor-pointer"
                          />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </MediaQuery>
                <div
                  className={`absolute ${
                    errors?.password
                      ? "xs:bottom-[-6px] xs:right-7 desktop:right-2 desktop:top-0"
                      : " bottom-[-8px] right-0"
                  }`}
                >
                  {showPasswordIn ? (
                    <Tooltip content="Hide password">
                      <span>
                        <IconsButton onClick={handleShowPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEye}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Show password">
                      <span>
                        <IconsButton onClick={handleShowPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <MediaQuery minWidth={960}>
                  <span className="text-red-700 text-xs">
                    {errors?.password && t(errors.password.message)}
                  </span>
                </MediaQuery>
              </div>
              <div
                className={`relative ${
                  errors?.password ? "desktop:mb-1 xs:mb-3" : "mb-4"
                }`}
              >
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  render={({ field, formState }) => (
                    <Input
                      autoCapitalize="none"
                      type={showConfirmPasswordIn ? "text" : "password"}
                      label={t("Confirm Password")}
                      id="password-confirmation"
                      {...field}
                      error={formState.errors?.passwordConfirmation}
                    />
                  )}
                />
                <MediaQuery maxWidth={959}>
                  {errors?.passwordConfirmation && (
                    <div className="absolute top-2 right-2">
                      <Tooltip
                        content={errors?.passwordConfirmation?.message}
                        type="check"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="text-red-700 w-6 h-6 cursor-pointer"
                          />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </MediaQuery>
                <div
                  className={`absolute ${
                    errors?.passwordConfirmation
                      ? "xs:bottom-[-6px] xs:right-7 desktop:right-2 desktop:top-0"
                      : " bottom-[-8px] right-0"
                  }`}
                >
                  {showConfirmPasswordIn ? (
                    <Tooltip content="Hide password">
                      <span>
                        <IconsButton onClick={handleShowConfirmPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEye}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Show password">
                      <span>
                        <IconsButton onClick={handleShowConfirmPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <MediaQuery minWidth={960}>
                  <span className="text-red-700 text-xs">
                    {errors?.passwordConfirmation &&
                      t(errors.passwordConfirmation.message)}
                  </span>
                </MediaQuery>
              </div>
              {/* <div className="flex justify-between items-center xs:flex-col py-1 mb-2 xs:items-start">
                {showBonusCodeInput ? (
                  <p className="w-full">
                    <Controller
                      control={control}
                      name="bonusCode"
                      render={({ field, formState }) => (
                        <Input
                          autoCapitalize="none"
                          label="Insert your bonus code"
                          type="text"
                          id="bonus-code-input"
                          {...field}
                          error={formState.errors?.bonusCode}
                        />
                      )}
                    />
                  </p>
                ) : (
                  <p className="pl-1">
                    <Button.Tertiary onClick={handleShowBonusCodeInput}>
                      <FontAwesomeIcon
                        icon={faGift}
                        className="text-white mr-3"
                      />
                      <span className="text-sm xs:flex xs:mb-2 text-white">
                        {t("Already have a promo code ?")}
                      </span>
                    </Button.Tertiary>
                  </p>
                )}
              </div> */}
              <MediaQuery minWidth={320} maxWidth={959}>
                <CheckPassword
                  errors={errors?.password}
                  passwordErrorType={
                    passwordErrorType.includes("min") ||
                    passwordErrorType.includes("required")
                  }
                  watchPassword={watchPassword}
                />
              </MediaQuery>

              <div>
                <div className="flex justify-start items-center mb-2 h-6 relative -left-[11px] xs:relative xs:-left-[9px]">
                  <Controller
                    control={control}
                    name="over18"
                    render={({ field }) => (
                      <Checkbox
                        id="over-18"
                        {...field}
                        checked={field.value}
                        onChange={handleCheckBoxOnchange(field)}
                        label={
                          <div
                            className="px-3 desktop-md-screen:text-sm xs:text-[12px] cursor-pointer"
                            style={{
                              color: errors?.over18 ? "red" : "white",
                            }}
                          >
                            {t("I confirm I am over the age of")}{" "}
                            <span
                              className=""
                              style={{ color: themeColors?.tertiary }}
                            >
                              {t("18 years")}
                            </span>
                          </div>
                        }
                      />
                    )}
                  />
                </div>

                <div className="flex justify-start items-center mb-5 h-6 relative -left-[11px] xs:relative xs:-left-[9px]">
                  <Controller
                    control={control}
                    name="termsAndConditions"
                    render={({ field }) => (
                      <Checkbox
                        id="terms-and-conditions"
                        {...field}
                        checked={field.value}
                        onChange={handleCheckBoxOnchange(field)}
                        label={
                          <div
                            className="px-3 flex justify-start items-center desktop-md-screen:text-sm xs:text-[12px] w-full cursor-pointer"
                            style={{
                              color: errors?.termsAndConditions
                                ? "red"
                                : "white",
                            }}
                          >
                            {t("I have read and accept the")}{" "}
                            <PageLink
                              to="terms/terms-and-conditions"
                              classNames=" desktop-md-screen:text-sm xs:text-[12px] ml-2 underline"
                              css={LinkStyle}
                              textStyle="tertiary"
                            >
                              {t("Terms of Service")}
                            </PageLink>
                          </div>
                        }
                      />
                    )}
                  />
                </div>
                <div className="mt-3">
                  {isLoading ? (
                    <div className="pb-5">
                      <Spinner theme="dark" />
                    </div>
                  ) : (
                    <Button.Primary
                      fullWidth
                      onClick={handleSubmit(onFormSubmit)}
                    >
                      {t("Continue")}
                    </Button.Primary>
                  )}
                </div>
              </div>
            </form>
            <div className="mb-7">
              <p className="text-xs text-white">
                {t("This site is protected by reCAPTCHA and the Google")}{" "}
                <PageLink to="/policy/privacy-policy" textStyle="tertiary">
                  {" "}
                  {t("Privacy Policy")}
                </PageLink>{" "}
                {t("and")}{" "}
                <PageLink to="terms/terms-and-conditions" textStyle="tertiary">
                  {t("Terms of Service")}
                </PageLink>{" "}
                {t(
                  "apply. By accessing the site I attest that I am at least 18 years old and have read the"
                )}{" "}
                <PageLink to="terms/terms-and-conditions" textStyle="tertiary">
                  {t("Terms of Service")}
                </PageLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
FormView.defaultProps = {
  setUsername: Function.prototype,
  setPassword: Function.prototype,
}
FormView.propTypes = {
  setUsername: PropTypes.func,
  setPassword: PropTypes.func,
}

const RegisterModal = ({ activeService, handleCloseModal }) => {
  const { registerDataChecked, registered } = useSelector((state) => state.auth)
  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      {!registerDataChecked && !registered && <FormView />}

      {registerDataChecked && !registered && <PhoneNumberVerificationView />}
    </Modal>
  )
}

RegisterModal.defaultProps = {
  activeService: null,
}

RegisterModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
}
export default RegisterModal
