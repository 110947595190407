import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
}
ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ScrollToTop
