import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resources from "../utils/language"

const languages = ["en", "fr", "sp"]

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.languages,
  load: "languageOnly",
  fallbackLng: "en",
  whitelist: languages,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})
export default i18n
