import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MediaQuery from "react-responsive"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import formSchema from "../../model/changePasswordSchema"
import { forgetPassword } from "../../actions/auth"
import { Modal } from "../../components/Modal"
import Input from "../../components/Input"
import { toast } from "../../components/Toast"
import Button from "../../components/Button"
import Tooltip from "../../components/Tooltip"
import IconsButton from "../../components/IconsButton"
// import resetPasswordAsset from "../../assets/images/modalAssets/reset-password-modal-asset.png"
// import successResetPasswordAsset from "../../assets/images/modalAssets/register-modal-asset.png"
import SloganText from "../../components/SloganText"
import CheckPassword from "../../components/CheckPassword"
import PageLink from "../../components/PageLink"
import { brandLogo, getThemeColors, imageDistributed } from "../../helpers"

// TODO: extract in ui kit : show/hide password
// TODO: extract in ui kit : validation errors component to standardize the process

const serviceName = "set-new-password"

const borderStyle = (themeColors) => css`
  border: 1px solid ${themeColors.tertiary};
`

const ResetPasswordSuccessfulView = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const handleCloseModal = () => {
    searchParams.delete("service")
    searchParams.delete("token")
    setSearchParams(searchParams)
  }
  const handleRedirectToLogin = () => {
    searchParams.delete("service")
    searchParams.delete("token")
    searchParams.set("service", "login")
    setSearchParams(searchParams)
  }

  const themeColors = getThemeColors()

  return (
    <div
      className="w-auto h-full z-50 desktop:rounded-3xl register-modal-right-view reset-password-view"
      style={{
        backgroundColor: themeColors?.secondary,
        color: "white",
      }}
      css={borderStyle(themeColors)}
    >
      <MediaQuery minWidth={320} maxWidth={959}>
        <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
          <h1
            className="xs:text-black text-xl leading-5 font-medium px-7"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Account Recovery")}
          </h1>
          <MediaQuery maxWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </MediaQuery>
          <MediaQuery minWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </MediaQuery>
        </div>
        <div className="flex justify-center items-center mb-5 py-5">
          <img
            fetchpriority="low"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-mobile-version"
            className="w-60 h-auto"
          />
        </div>
      </MediaQuery>
      <MediaQuery minWidth={960}>
        <div className="px-5">
          <div className=" h-5 py-3 ">
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
          <div>
            <img
              fetchpriority="low"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-website"
              className="h-13 w-auto"
            />
            <MediaQuery minWidth={960} maxWidth={1350}>
              <p
                className="text-xs relative -top-4 left-12"
                style={{ color: themeColors?.tertiary }}
              >
                {t("Fastest growing")}
                <span className="text-sm">{t("crypto casino")}</span>
              </p>
            </MediaQuery>
            <MediaQuery minWidth={1350}>
              <p
                className="  text-sm relative -top-5 left-16 mt-2 inline-block"
                style={{ color: themeColors?.tertiary }}
              >
                {t("Crypto Fastest")}
                <span className="text-sm">{t("crypto casino")}</span>
              </p>
            </MediaQuery>
          </div>
        </div>
      </MediaQuery>
      <div className="text-center desktop:my-5">
        <h1 className="text-white desktop:text-3xl text-5xl xs:text-2xl font-bold desktop:mb-1 mb-3 desktop:w-3/5 xs:w-4/5 mx-auto text-center">
          {t("Password updated successfully !")}
        </h1>
        <p className="text-sm text-white desktop:w-3/5 xs:w-4/5 mx-auto text-center">
          {t(
            "Your password has been updated, make sure to keep your login details private and not share them ."
          )}
        </p>
      </div>
      <div className="flex justify-center">
        <img
          fetchpriority="low"
          src={imageDistributed("registerModalAssetImage")}
          alt="step-icon-1"
          className=" xs:w-44 desktop:w-auto desktop:h-56"
        />
      </div>
      <div className="flex justify-center xs:w-4/5 mx-auto desktop:pb-7">
        <MediaQuery minWidth={320} maxWidth={959}>
          <Button.Primary
            size="small"
            fullWidth
            onClick={handleRedirectToLogin}
          >
            {t("Play Now")}
          </Button.Primary>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <Button.Primary size="medium" onClick={handleRedirectToLogin}>
            {t("Play Now")}
          </Button.Primary>
        </MediaQuery>
      </div>
    </div>
  )
}

const FormView = ({ handleCloseModal, setSuccessResetPassword }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [showPasswordIn, setShowPasswordIn] = useState(false)
  const [showConfirmPasswordIn, setShowConfirmPasswordIn] = useState(false)
  const handleShowPasswordInModal = () => {
    setShowPasswordIn(!showPasswordIn)
  }
  const handleShowConfirmPasswordInModal = () => {
    setShowConfirmPasswordIn(!showConfirmPasswordIn)
  }
  const token = searchParams.get("token")

  const notifyOnSuccessReset = () => {
    toast.success(
      t("Successful reset !"),
      t("The new password is ready to use.")
    )
  }
  const formOptions = { resolver: yupResolver(formSchema), mode: "all" }

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm(formOptions)

  useEffect(() => {
    reset() // initialize the form
  }, [reset])

  const passwordErrorType = Object.values(errors?.password ?? [])

  const watchPassword = watch("password")

  const onSubmit = async (data) => {
    const result = await forgetPassword(data.password, token)
    if (result === 200) {
      reset()
      notifyOnSuccessReset()
      setSuccessResetPassword()
    } else {
      toast.error(t("An error has occurred."), t("Please try again later."))
    }
  }

  const themeColors = getThemeColors()

  return (
    <div className="flex relative">
      <div
        className="desktop:w-[42%] flex flex-col items-center justify-center py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block border "
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
          // borderColor: themeColors?.lightTertiary,
          // borderWidth: "1px",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex flex-col">
          <img
            fetchpriority="low"
            loading="lazy"
            decoding="async"
            src={brandLogo()}
            alt="logo-version-svg"
            className="aspect-auto"
          />
          <SloganText />
        </div>
        <div className="flex justify-center mb-2">
          <img
            fetchpriority="low"
            src={imageDistributed("resetPasswordAsset")}
            alt="step-icon-1"
            className=" aspect-auto my-1 p-7"
          />
        </div>
        <p className="text-center font-medium">
          <span className=" text-md" style={{ color: themeColors?.tertiary }}>
            2.500.120.454
          </span>
          <br />
          <span className="text-white text-sm opacity-40">
            {t("Bets Wagered")}
          </span>
        </p>
      </div>
      <div
        className="desktop:w-[58%] desktop:pb-24 xs:pb-12 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view "
        style={{
          backgroundColor: themeColors?.secondary,
          color: "white",
          // borderColor: themeColors?.lightTertiary,
          // borderWidth: "1px",
        }}
        css={borderStyle(themeColors)}
      >
        <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
          <h1
            className="xs:text-black  text-xl leading-5 font-medium px-7"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Account Recovery")}
          </h1>
          <MediaQuery maxWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </MediaQuery>
          <MediaQuery minWidth={959}>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </MediaQuery>
        </div>
        <MediaQuery maxWidth={959}>
          <div className="flex justify-center items-center my-5 py-5">
            <img
              fetchpriority="low"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-mobile-version"
              className="w-60 h-auto"
            />
          </div>
        </MediaQuery>
        <div className="py-15 px-7 text-md ">
          <h1 className="text-white text-xl leading-5 font-medium xs:hidden">
            {t("Recover your account within seconds.")}
          </h1>
          <div className="mb-5">
            <Button.Tertiary theme="light">
              <a
                className="text-sm font-bold"
                style={{ color: themeColors?.tertiary }}
                href="https://help.casinocarbon.com/en/articles/6420259-how-to-reset-password"
                target="_blank"
                rel="noreferrer"
              >
                {t("Need help ?")}
              </a>
            </Button.Tertiary>
          </div>
          <form className="mt-5 desktop:mt-7 xs:my-5 xs:mb-3">
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="password"
                render={({ field, formState }) => (
                  <Input
                    autoCapitalize="none"
                    type={showPasswordIn ? "text" : "password"}
                    label={t("New Password")}
                    id="reset-password"
                    {...field}
                    error={formState.errors?.password}
                  />
                )}
              />
              <div className="absolute bottom-0 right-0 ">
                {showPasswordIn ? (
                  <Tooltip content="Hide password">
                    <span>
                      <IconsButton onClick={handleShowPasswordInModal}>
                        <span>
                          <FontAwesomeIcon
                            icon={faEye}
                            className=" text-black w-5"
                          />
                        </span>
                      </IconsButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip content="Show password">
                    <span>
                      <IconsButton onClick={handleShowPasswordInModal}>
                        <span>
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className=" text-black w-5"
                          />
                        </span>
                      </IconsButton>
                    </span>
                  </Tooltip>
                )}
              </div>
              <p className="absolute text-red-500 text-xs">
                {errors?.password && errors.password.message}
              </p>
            </div>
            <div className="mb-6 relative">
              <Controller
                control={control}
                name="passwordConfirmation"
                render={({ field, formState }) => (
                  <Input
                    autoCapitalize="none"
                    type={showConfirmPasswordIn ? "text" : "password"}
                    label={t("Confirm New Password")}
                    id="password-confirmation"
                    {...field}
                    error={formState.errors?.passwordConfirmation}
                  />
                )}
              />
              <div className="absolute bottom-0 right-0 ">
                {showConfirmPasswordIn ? (
                  <Tooltip content="Hide password">
                    <span>
                      <IconsButton onClick={handleShowConfirmPasswordInModal}>
                        <span>
                          <FontAwesomeIcon
                            icon={faEye}
                            className=" text-black w-5"
                          />
                        </span>
                      </IconsButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip content="Show password">
                    <span>
                      <IconsButton onClick={handleShowConfirmPasswordInModal}>
                        <span>
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className=" text-black w-5"
                          />
                        </span>
                      </IconsButton>
                    </span>
                  </Tooltip>
                )}
              </div>
              <p className="absolute text-red-500 text-xs">
                {errors?.passwordConfirmation &&
                  errors.passwordConfirmation.message}
              </p>
            </div>
            <CheckPassword
              errors={errors?.password}
              passwordErrorType={
                passwordErrorType.includes("min") ||
                passwordErrorType.includes("required")
              }
              watchPassword={watchPassword}
            />
            <div className="pb-5">
              <div className="">
                <Button.Primary
                  onClick={handleSubmit(onSubmit)}
                  onSubmit={handleSubmit(onSubmit)}
                  fullWidth
                >
                  {t("Set New Password")}
                </Button.Primary>
              </div>
            </div>
          </form>
          <div className="mb-3 text-center">
            <p className="text-xs text-white">
              {t("This site is protected by reCAPTCHA and the Google")}{" "}
              <PageLink to="/policy/privacy-policy" classNames="tertiary">
                {" "}
                {t("Privacy Policy")}
              </PageLink>{" "}
              {t("and")}{" "}
              <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                {t("Terms of Service")}
              </PageLink>{" "}
              {t(
                "apply. By accessing the site I attest that I am at least 18 years old and have read the"
              )}{" "}
              <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                {t("Terms of Service")}
              </PageLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
FormView.defaultProps = {
  handleCloseModal: Function.prototype,
  setSuccessResetPassword: Function.prototype,
}
FormView.propTypes = {
  handleCloseModal: PropTypes.func,
  setSuccessResetPassword: PropTypes.func,
}

const ResetPasswordModal = ({ activeService, handleCloseModal }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [restPasswordSuccessful, setResetPasswordSuccess] = useState(false)

  const setSuccessResetPassword = () => {
    setResetPasswordSuccess(true)
  }

  const handleRedirectToLoginModal = () => {
    searchParams.set("service", "login")
    setSearchParams(searchParams)
  }

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      {restPasswordSuccessful ? (
        <ResetPasswordSuccessfulView
          handleRedirectToLoginPage={handleRedirectToLoginModal}
        />
      ) : (
        <FormView
          setSuccessResetPassword={setSuccessResetPassword}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Modal>
  )
}
ResetPasswordModal.defaultProps = {
  activeService: "set-new-password",
}
ResetPasswordModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
}

export default ResetPasswordModal
