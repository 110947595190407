import React from "react"
import { Outlet } from "react-router-dom"

const NodaContainer = () => (
  <section className="  bg-primary w-full  text-white ">
    <Outlet />
  </section>
)

export default NodaContainer
