import * as Yup from "yup"
import regexZipCode from "../utils/postalCodeData.json"

export default Yup.object().shape({
  firstName: Yup.string()
    .required("The first name is required.")
    .max(255, "The first name is too long.")
    .matches(/\D+$/, "The first name need to be only letter.")
    .matches(/^[^\W_]+$/, "The first name need to be only letter."),
  lastName: Yup.string()
    .required("The last name is required.")
    .max(255, "The last name is too long.")
    .matches(/\D+$/, "The last name need to be only letter.")
    .matches(/^[^\W_]+$/, "The last name need to be only letter."),
  dob: Yup.date()
    .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
    .required("Date of birth is required."),
  country: Yup.object().required("The country is required."),
  addressLine1: Yup.string()
    .required("The address line 1 is required.")
    .max(255, "The address line 1 is too long."),
  city: Yup.string()
    .required("The city is required.")
    .max(25, "The city's name is too long.")
    .matches(
      /^[A-Za-zÀ-ÿ- ]+$/,
      "The city's name should only contain letters, spaces, or hyphens."
    ),
  // state: Yup.string()
  //   .required("The state is required.")
  //   .max(20, "The state's name is too long.")
  //   .matches(/\D+$/, "The state's name need to be only letter.")
  //   .matches(/^[^\W_]+$/, "The state's name need to be only letter."),
  postalCode: Yup.string()
    .required("Postal code is required.")
    .when(["country"], (country, schema) => {
      if (country) {
        const regExp = regexZipCode[country?.value]
        return schema.matches(regExp, {
          message: "Invalid postal code.",
          excludeEmptyString: true,
        })
      }
      return schema
    }),
  gender: Yup.string()
    .required("Gender is required.")
    .oneOf(["M", "F"], "Gender must be either M or F"),
})
