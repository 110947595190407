export default {
  WITHDRAW_INVALID_ETH_ADDRESS: "withdraw-invalid-eth-address",
  WITHDRAW_LESS_THAN_DEPOSITED_HALF_HAS_BEEN_WAGERED:
    "withdraw-less-than-deposited-half-has-been-wagered",
  KYC_LEVEL_1_IS_NOT_COMPLETED: "withdraw-level1-not-complete",
  "2FA_PIN_MISSING": "2fa-pin-missing",
  "2FA_PIN_INVALID": "2fa-pin-invalid",
  CHANGE_PASSWORD_SAME_AS_CURRENT: "change-password-same-as-current",
  "2FA_NOT_ACTIVE": "2fa-not-active",
  WITHDRAW_BONUS_NOT_WAGERED: "withdrawal-bonus-not-wagered",
}
