/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { NodaPaySDK } from "@noda-ui/pay-sdk"
import uuid from "react-uuid"

/* components */
import Spinner from "../../components/Spinner"
// Utilities
import getAmount from "./helpers"
import { logger } from "../../helpers"
import { BASE_URL } from "../../api"
/* style */
import "./style.css"
import "@noda-ui/pay-sdk/lib/noda.css"

/* NodaHero */
const NodaCreditCard = () => {
  const { billingAddress } = useSelector((state) => state.user)

  const { username, email } = useSelector((state) => state.auth)

  const amount = getAmount()

  logger(amount)
  const [nodaPayContainer, setNodaPayContainer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const element = document.getElementById("noda-elements-frame")
    setNodaPayContainer(element)
  }, [])
  const magicString = uuid()

  let env = ""
  if (process.env.REACT_APP_CONTEXT === "deploy-preview") {
    env = "staging"
  } else {
    env = process.env.REACT_APP_CONTEXT
  }
  logger(env)
  const payment = {
    env: env.toUpperCase(),
    apiKey: process.env.REACT_APP_NODA_CREDITCARD_API_KEY,
    paymentId: `${env}-${username}-${magicString}`,
    customerId: magicString,
    email,
    description: "payment card",
    returnUrl: "https://ui.noda.live/processing/result",
    webhookUrl: `${BASE_URL}/payments/nodapay/cc/webhook`,
    amount,
    currency: "EUR",
    BillingAddress: {
      addressLine: billingAddress.addressLine1,
      city: billingAddress.city,
      state: billingAddress.state,
      country: billingAddress.country.value,
      zip: billingAddress.postalCode,
    },
  }

  const settings = {
    isPhoneNumberFieldVisible: true,
  }

  const payload = {
    payment,
    settings,
  }

  logger(payload)

  useEffect(() => {
    if (!nodaPayContainer) return
    setIsLoading(true)
    NodaPaySDK.init(nodaPayContainer)
    NodaPaySDK.update(payload)
    setIsLoading(false)
    return () => {
      NodaPaySDK.unmount()
    }
  }, [nodaPayContainer, payload])

  return isLoading ? (
    <div className="w-full noda-min-height container-background-style  ">
      <div className="container-max-width-switchere h-full  flex  justify-center  py-5">
        <div className="container-max-width-switchere  div-above h-full  w-full   py-5 flex justify-center items-center">
          <Spinner />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full noda-min-height container-background-style  ">
      <div className="container-max-width-switchere h-full  flex  noda-min-height justify-center  py-5">
        <div className="container-max-width-switchere  w-full noda-min-height  py-5 ">
          <div
            id="noda-elements-frame"
            className="h-full w-full   rounded-3xl bg-white   flex  justify-center "
          />
        </div>
      </div>
    </div>
  )
}

export default NodaCreditCard
