import { useSearchParams } from "react-router-dom"

// eslint-disable-next-line import/prefer-default-export
const getAmount = () => {
  const [searchParams] = useSearchParams()

  return Math.max(searchParams.get("amount") || 20, 20)
}

export default getAmount
