import React from "react"
import { useTranslation } from "react-i18next"
import Banner from "./Banner"
import BuyCryptoAvatar from "../assets/images/buyCrypto/buy-crypto-avatar.png"
import "../features/PaymentGateways/style.css"

const BetsWageredBanner = () => {
  const { t } = useTranslation()
  return (
    <div className=" container-max-width-switchere h-36 pb-12 z-10 ">
      <Banner height="32">
        <div className=" container-background-bottom-style rounded-3xl relative  container-switchere-banner-style flex justify-center items-center w-full z-10">
          <div className="  mr-auto h-full  flex flex-col justify-center items-center ml-auto ">
            <div className="container-bottom-banner-title-style text-2xl   md:text-2xl xg:text-xl ">
              2,500,120,454
            </div>
            <div className=" container-bottom-banner-body-style  md:text-xl xs:text-base mb-1">
              {t("Bets Wagered")}
            </div>
          </div>

          <div className=" h-full  ">
            <div className=" h-full   flex justify-center  items-center    ">
              <img
                fetchpriority="low"
                loading="lazy"
                decoding="async"
                src={BuyCryptoAvatar}
                className="h-24"
                alt="BuyCryptoAvatar"
              />
            </div>
          </div>
        </div>
      </Banner>
    </div>
  )
}
export default BetsWageredBanner
