import React from "react"
import PropTypes from "prop-types"

const Alert = ({ children }) => (
  <div className="bg-secondary text-white py-3 rounded-md">
    <div>{children}</div>
  </div>
)

Alert.propTypes = {
  children: PropTypes.element.isRequired,
}
export default Alert
