import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import AccountModal from "./AccountModal"
import AffiliateModal from "./AffiliateModal"
import LoginModal from "./LoginModal"
import RegisterModal from "./RegisterModal"
import RecoveryEmailModal from "./RecoveryEmail"
import CashierModal from "./CashierModal"
import ResetPasswordModal from "./ResetPasswordModal"
import BonusCodeModal from "./BonusCodeModal"
import History from "./Transaction/Transaction"
// import themes from "../../themes"
// import { getThemeColors } from "../../helpers"

const ModalServices = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeService, setActiveService] = useState("")
  const [activeTab, setActiveTab] = useState(null)
  const { loggedIn } = useSelector((state) => state.auth)

  const handleCloseModal = () => {
    searchParams.delete("service")
    searchParams.delete("tab")
    searchParams.delete("token")
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setActiveService(searchParams.get("service"))
    setActiveTab(searchParams.get("tab"))
  }, [searchParams])

  return (
    <>
      {loggedIn && (
        <AccountModal
          activeService={activeService}
          activeTab={activeTab}
          handleCloseModal={handleCloseModal}
        />
      )}
      {!loggedIn && (
        <LoginModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
        />
      )}
      {!loggedIn && (
        <RegisterModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
        />
      )}
      {!loggedIn && (
        <ResetPasswordModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
        />
      )}
      {!loggedIn && (
        <RecoveryEmailModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
        />
      )}
      {loggedIn && (
        <CashierModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
          activeTab={activeTab}
        />
      )}
      {loggedIn && (
        <AffiliateModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
          activeTab={activeTab}
        />
      )}
      {loggedIn && (
        <BonusCodeModal
          activeService={activeService}
          handleCloseModal={handleCloseModal}
        />
      )}
      {loggedIn && (
        <History
          activeService={activeService}
          activeTab={activeTab}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}

export default ModalServices
