import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import MediaQuery from "react-responsive"
import moment from "moment/moment"
import { useSearchParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "../../../components/Toast"
import { Modal } from "../../../components/Modal"
import { Tabs } from "../../../components/Tabs"
import { getBrandFromUrl, logger } from "../../../helpers"
import { getHistory, getUser } from "../../../actions/user"
import PaymentsTable from "./PaymentsTable"
import Spinner from "../../../components/Spinner"
import { putWithdraw } from "../../../actions/wallet"
import themes from "../../../themes"
import {
  WINS,
  BETS,
  WITHDRAWALS,
  BONUSES,
  SPORTSBOOK,
  DEPOSITS,
  TIPS,
  ALL,
} from "./constants/constants"

import {
  formatDepositsTable,
  formatWithdrawalsTable,
  formatBetsTable,
  formatWinsTable,
  formatBonusesTable,
  formatTipsTable,
  formatSportsBookTable,
} from "./utils/TransactionsTableFormatter"
import Pagination from "../../../components/Pagination"

const serviceName = "history"

const tabIndexes = {
  deposits: 0,
  withdrawals: 1,
  bets: 2,
  all: 3,
}

const DepositsTabHistory = ({
  tableHeaders,
  tableRows,
  selectedType,
  isLoading,
  numberOfRecords,
  paginate,
  numberOfRecordsPerPage,
}) => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tabs.Body.Item>
      <div className="my-5">
        <div className="my-3">
          <p className="text-sm xs:text-xs text-gray-800">
            {t(
              "We provides you the history of your different transactions actions. You can make sure you have all the information about this."
            )}
          </p>
          <p
            className="text-sm xs:text-xs"
            style={{ color: themeColors?.tertiary }}
          >
            <span>
              {t(
                "Every transaction is tracked with date and all related data."
              )}
            </span>
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <PaymentsTable
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            selectedType={selectedType}
          />
        )}
        <div>
          <Pagination
            numberOfRecords={numberOfRecords}
            paginate={paginate}
            numberOfRecordsPerPage={numberOfRecordsPerPage}
          />
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

DepositsTabHistory.defaultProps = {
  isLoading: false,
}

DepositsTabHistory.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.shape().isRequired,
  selectedType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  numberOfRecords: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  numberOfRecordsPerPage: PropTypes.number.isRequired,
}

const WithdrawalsTabHistory = ({
  tableHeaders,
  tableRows,
  hasActions,
  onActionTriggered,
  actionName,
  // onTypeChange,
  selectedType,
  isLoading,
  numberOfRecords,
  paginate,
  numberOfRecordsPerPage,
}) => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tabs.Body.Item>
      <div className="my-5">
        <div className="my-3">
          <p className="text-sm xs:text-xs text-gray-800">
            {t(
              "We provides you the history of your different transactions actions. You can make sure you have all the information about this."
            )}
          </p>
          <p
            className="text-sm xs:text-xs"
            style={{ color: themeColors?.tertiary }}
          >
            <span>
              {t(
                "Every transaction is tracked with date and all related data."
              )}
            </span>
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <PaymentsTable
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            hasActions={hasActions}
            onActionTriggered={onActionTriggered}
            actionName={actionName}
            // onTypeChange={onTypeChange}
            selectedType={selectedType}
          />
        )}
        <div>
          <Pagination
            numberOfRecords={numberOfRecords}
            paginate={paginate}
            numberOfRecordsPerPage={numberOfRecordsPerPage}
          />
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

WithdrawalsTabHistory.defaultProps = {
  isLoading: false,
}

WithdrawalsTabHistory.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.shape().isRequired,
  hasActions: PropTypes.bool.isRequired,
  onActionTriggered: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  // onTypeChange: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  numberOfRecords: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  numberOfRecordsPerPage: PropTypes.number.isRequired,
}

const BetsTabHistory = ({
  tableHeaders,
  tableRows,
  selectedType,
  isLoading,
  numberOfRecords,
  paginate,
  numberOfRecordsPerPage,
  changeType,
}) => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tabs.Body.Item>
      <div className="my-5">
        <div className="my-3">
          <p className="text-sm xs:text-xs text-gray-800">
            {t(
              "We provides you the history of your different transactions actions. You can make sure you have all the information about this."
            )}
          </p>
          <p
            className="text-sm xs:text-xs"
            style={{ color: themeColors?.tertiary }}
          >
            <span>
              {t(
                "Every transaction is tracked with date and all related data."
              )}
            </span>
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <PaymentsTable
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            selectedType={selectedType}
            changeType={changeType}
          />
        )}
        <div>
          <Pagination
            numberOfRecords={numberOfRecords}
            paginate={paginate}
            numberOfRecordsPerPage={numberOfRecordsPerPage}
          />
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

BetsTabHistory.defaultProps = {
  isLoading: false,
  changeType: Function.prototype,
}

BetsTabHistory.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.shape().isRequired,
  selectedType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  numberOfRecords: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  numberOfRecordsPerPage: PropTypes.number.isRequired,
  changeType: PropTypes.func,
}

const AllTransactionsTabHistory = ({
  tableHeaders,
  tableRows,
  hasActions,
  onActionTriggered,
  actionName,
  onTypeChange,
  selectedType,
  isLoading,
  numberOfRecords,
  paginate,
  numberOfRecordsPerPage,
  changeType,
}) => {
  const { t } = useTranslation()
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors
  return (
    <Tabs.Body.Item>
      <div className="my-5">
        <div className="my-3">
          <p className="text-sm xs:text-xs text-gray-800">
            {t(
              "We provides you the history of your different transactions actions. You can make sure you have all the information about this."
            )}
          </p>
          <p
            className="text-sm xs:text-xs"
            style={{ color: themeColors?.tertiary }}
          >
            <span>
              {t(
                "Every transaction is tracked with date and all related data."
              )}
            </span>
          </p>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <PaymentsTable
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            hasActions={hasActions}
            onActionTriggered={onActionTriggered}
            actionName={actionName}
            onTypeChange={onTypeChange}
            selectedType={selectedType}
            changeType={changeType}
          />
        )}
        <div>
          <Pagination
            numberOfRecords={numberOfRecords}
            paginate={paginate}
            numberOfRecordsPerPage={numberOfRecordsPerPage}
          />
        </div>
      </div>
    </Tabs.Body.Item>
  )
}

AllTransactionsTabHistory.defaultProps = {
  actionName: "",
  onTypeChange: Function.prototype,
  onActionTriggered: Function.prototype,
  hasActions: false,
  isLoading: false,
  changeType: Function.prototype,
}

AllTransactionsTabHistory.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.shape().isRequired,
  hasActions: PropTypes.bool,
  onActionTriggered: PropTypes.func,
  actionName: PropTypes.string,
  onTypeChange: PropTypes.func,
  selectedType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  numberOfRecords: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  numberOfRecordsPerPage: PropTypes.number.isRequired,
  changeType: PropTypes.func,
}

const History = ({ activeService, handleCloseModal, activeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { username } = useSelector((state) => state.auth)
  const [searchParams, setSearchParams] = useSearchParams()
  const [transactions, setTransactions] = useState([])
  const [formattedTransactionTable, setFormattedTransactionTable] = useState()
  const [chunkedTransactionTable, setChunkedTransactionTable] = useState()

  const tabParam = searchParams.get("tab") || "deposits"

  const selectedTab = tabParam.charAt(0).toUpperCase() + tabParam.slice(1)

  const [selectedType, setSelectedType] = useState("Deposits")

  useEffect(() => {
    setSelectedType(selectedTab)
  }, [tabParam])

  const getFormattedTable = (data) => {
    let formattedData = null
    switch (selectedType) {
      case "Deposits":
        formattedData = formatDepositsTable(data.deposits)
        break
      case "Withdrawals":
        formattedData = formatWithdrawalsTable(data.withdrawals)
        break
      case "Bets":
        formattedData = formatBetsTable({ ...data.bets, ...data.wins })
        break
      case "Wins":
        formattedData = formatWinsTable(data.wins)
        break
      case "Bonuses":
        formattedData = formatBonusesTable(data.bonuses)
        break
      case "Tips":
        formattedData = formatTipsTable(data.tips)
        break
      default:
        formattedData = formatDepositsTable(data.deposits)
    }
    return formattedData
  }

  const { isLoading, refetch } = useQuery({
    queryKey: ["getHistory"],
    queryFn: () => getHistory(),
    refetchOnMount: "always",
    onSuccess: ({ data: historyResponse }) => {
      setTransactions(historyResponse || [])
      // const initialDefaultData = formatDepositsTable(historyResponse.deposits)
      const initialDefaultData = getFormattedTable(historyResponse)
      setFormattedTransactionTable(initialDefaultData)
      setChunkedTransactionTable(() => ({
        ...initialDefaultData,
        tableRows: {
          rowsData: initialDefaultData.tableRows.rowsData.slice(0, 5),
          rowsMetaData: initialDefaultData.tableRows.rowsMetaData.slice(0, 5),
        },
      }))
    },
    onError: (error) => {
      logger(error)
      refetch()
    },
  })

  const handleChangeType = (type) => {
    let formattedData = []
    const isAll = searchParams.get("tab") === "all"
    const combinedTransactions = [
      ...new Set([
        ...(transactions.bets || []).map((bet) => ({
          ...bet,
          created: moment(bet.created, "DD-MM-YYYY HH:mm"),
        })),
        ...(transactions.wins || []).map((win) => ({
          ...win,
          created: moment(win.created, "DD-MM-YYYY HH:mm"),
        })),
      ]),
    ].sort((a, b) => b.created - a.created)

    const allTransactions = [
      ...(transactions.deposits || []).map((deposit) => ({
        ...deposit,
        created: moment(deposit.created, "DD-MM-YYYY HH:mm"),
      })),
      ...(transactions.bets || []).map((bet) => ({
        ...bet,
        created: moment(bet.created, "DD-MM-YYYY HH:mm"),
      })),
      ...(transactions.wins || []).map((win) => ({
        ...win,
        created: moment(win.created, "DD-MM-YYYY HH:mm"),
      })),
      ...(transactions.bonuses || []).map((bonus) => ({
        ...bonus,
        created: moment(bonus.created, "DD-MM-YYYY HH:mm"),
      })),
    ].sort((a, b) => b.created - a.created)

    switch (type) {
      case ALL:
        formattedData = formatDepositsTable(allTransactions, isAll)
        break
      case DEPOSITS:
        formattedData = formatDepositsTable(transactions.deposits, isAll)
        break

      case WITHDRAWALS:
        formattedData = formatWithdrawalsTable(transactions.withdrawals, isAll)
        break

      case BETS:
        formattedData = formatBetsTable(combinedTransactions, isAll)
        break

      case WINS:
        formattedData = formatWinsTable(transactions.wins)
        break

      case BONUSES:
        formattedData = formatBonusesTable(transactions.bonuses)
        break

      case TIPS:
        formattedData = formatTipsTable(transactions.tips, username)
        break

      case SPORTSBOOK:
        formattedData = formatSportsBookTable(transactions.sportsbook)
        break

      default:
        formattedData = formatDepositsTable(transactions.deposits)
        break
    }
    setFormattedTransactionTable(formattedData)
    setChunkedTransactionTable(() => ({
      tableHeaders: formattedData.tableHeaders,
      tableRows: {
        rowsData: formattedData.tableRows.rowsData.slice(0, 5),
        rowsMetaData: formattedData.tableRows.rowsMetaData.slice(0, 5),
      },
    }))
    setSelectedType(type)
  }

  const handlePaginate = (page) => {
    const start = (page - 1) * 5
    const end = start + 5
    setChunkedTransactionTable((prev) => ({
      tableHeaders: prev.tableHeaders,
      tableRows: {
        rowsData: formattedTransactionTable.tableRows.rowsData.slice(
          start,
          end
        ),
        rowsMetaData: formattedTransactionTable.tableRows.rowsMetaData.slice(
          start,
          end
        ),
      },
    }))
  }
  const handleCancelWithdrawal = async (rowMetaData) => {
    const cancelWithdrawResponse = await putWithdraw(rowMetaData.id)
    if (cancelWithdrawResponse.status === 200) {
      toast.success(
        t("Withdrawal cancel"),
        t("The Withdrawal canceled successfully")
      )
      dispatch(getUser())
      refetch()
    } else {
      toast.error(
        t("Withdrawal cancel fail"),
        t("The withdrawal cannot be cancelled")
      )
    }
  }

  const selectTab = (tab) => {
    searchParams.set("tab", tab)
    setSearchParams(searchParams)
    handleChangeType(tab.charAt(0).toUpperCase() + tab.slice(1))
  }

  useEffect(() => {
    setSelectedType(selectedTab)
  }, [tabParam])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="xs:h-[680px] h-[700px] xs:overflow-auto history-modal-view ">
        <MediaQuery maxWidth={959}>
          <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full ">
            <h1 className="xs:text-tertiary desktop:text-black text-xl leading-5 font-medium px-7">
              {t("History")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className="flex justify-between items-center">
            <h1 className="pl-10 " style={{ color: themeColors?.tertiary }}>
              {t("History")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} />
          </div>
        </MediaQuery>
        <div>
          <Tabs selectedIndex={tabIndexes[activeTab] || tabIndexes.deposits}>
            <div className="">
              <Tabs.Header>
                <div>
                  <Tabs.Header.Item onClick={() => selectTab("deposits")}>
                    {t("Deposits")}
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("withdrawals")}>
                    {t("Withdrawals")}
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("bets")}>
                    {t("Bets")}
                  </Tabs.Header.Item>
                  <Tabs.Header.Item onClick={() => selectTab("all")}>
                    {t("Transactions")}
                  </Tabs.Header.Item>
                </div>
              </Tabs.Header>
              <Tabs.Body>
                <div>
                  <DepositsTabHistory
                    tableHeaders={chunkedTransactionTable?.tableHeaders}
                    tableRows={chunkedTransactionTable?.tableRows}
                    hasActions={formattedTransactionTable?.hasActions}
                    selectedType="Deposits"
                    isLoading={isLoading}
                    numberOfRecords={
                      formattedTransactionTable?.tableRows.rowsData.length
                    }
                    paginate={handlePaginate}
                    numberOfRecordsPerPage={5}
                  />
                  <WithdrawalsTabHistory
                    tableHeaders={chunkedTransactionTable?.tableHeaders}
                    tableRows={chunkedTransactionTable?.tableRows}
                    hasActions={formattedTransactionTable?.hasActions}
                    onActionTriggered={handleCancelWithdrawal}
                    actionName="Cancel"
                    // onTypeChange={handleChangeType}
                    selectedType="Withdrawals"
                    isLoading={isLoading}
                    numberOfRecords={
                      formattedTransactionTable?.tableRows.rowsData.length
                    }
                    paginate={handlePaginate}
                    numberOfRecordsPerPage={5}
                  />
                  <BetsTabHistory
                    tableHeaders={chunkedTransactionTable?.tableHeaders}
                    tableRows={chunkedTransactionTable?.tableRows}
                    hasActions={formattedTransactionTable?.hasActions}
                    selectedType="Bets"
                    isLoading={isLoading}
                    numberOfRecords={
                      formattedTransactionTable?.tableRows.rowsData.length
                    }
                    paginate={handlePaginate}
                    numberOfRecordsPerPage={5}
                    changeType={handleChangeType}
                  />
                  <AllTransactionsTabHistory
                    tableHeaders={chunkedTransactionTable?.tableHeaders}
                    tableRows={chunkedTransactionTable?.tableRows}
                    hasActions={formattedTransactionTable?.hasActions}
                    onActionTriggered={handleCancelWithdrawal}
                    actionName="Cancel"
                    onTypeChange={handleChangeType}
                    selectedType="All"
                    isLoading={isLoading}
                    numberOfRecords={
                      formattedTransactionTable?.tableRows.rowsData.length
                    }
                    paginate={handlePaginate}
                    numberOfRecordsPerPage={5}
                    changeType={handleChangeType}
                  />
                </div>
              </Tabs.Body>
            </div>
          </Tabs>
        </div>
      </div>
    </Modal>
  )
}

History.defaultProps = {
  activeService: "history",
  activeTab: tabIndexes.referral,
}

History.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
}

export default History
