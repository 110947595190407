import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Menu } from "@headlessui/react"
// import logo from "../assets/images/logo-new.svg"
import { formatter, getBrandFromUrl, imageDistributed } from "../helpers"
import iconLogOut from "../assets/icons/icon-logout-menu.svg"
import hamburgerMenu from "../assets/icons/hamburgerMenu.svg"
import themes from "../themes"

export const HamburgerMenuItem = ({
  onClick,
  children,
  isStripped,
  isLastItem,
}) => (
  <Menu.Item onClick={onClick}>
    {({ active }) => (
      <button
        type="button"
        className={`${active ? " text-white rounded-md" : " text-white"} ${
          isStripped ? "bg-lightPrimary" : ""
        } ${
          isLastItem ? "shadow-2xl" : ""
        }  flex  items-center w-full  text-md px-1 text-white py-4`}
      >
        {children}
      </button>
    )}
  </Menu.Item>
)

HamburgerMenuItem.defaultProps = {
  onClick: Function.prototype,
  isStripped: false,
  isLastItem: false,
}

HamburgerMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  isStripped: PropTypes.bool,
  isLastItem: PropTypes.bool,
}

const HamburgerMenu = ({ user, children, handleLogout, balance }) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <Menu as="div" className="relative inline-block text-center">
      {({ open }) => (
        <>
          <Menu.Button className="flex items-center pl-5">
            <img
              fetchpriority="high"
              src={hamburgerMenu}
              alt="hamburger-menu"
            />
          </Menu.Button>
          <div
            id="popup"
            className={`z-50 fixed w-full flex justify-center inset-0 ${
              open ? "" : "hidden"
            }`}
          >
            <div
              className="w-full  z-0 absolute inset-0 bg-overlayPurple  cursor-auto"
              role="button"
              tabIndex={0}
              onKeyDown={Function.prototype}
            >
              &nbsp;
            </div>

            <Menu.Items className=" divide-y divide-gray-100 rounded-none shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none fixed top-0 right-0 w-10/12 h-screen">
              <div
                className="h-full mobile-menu-items"
                style={{
                  backgroundColor: themeColors?.darkenBlue,
                  color: "white",
                }}
              >
                <div className="bg-white  w-full px-2 py-3 big:hidden">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center">
                      <Link to="/">
                        {" "}
                        <img
                          fetchpriority="high"
                          src={imageDistributed("logoNew")}
                          alt="newLogo"
                          className="w-[45px]"
                        />
                      </Link>
                      <div className="relative left-6 text-left">
                        <span className="text-black font-bold">{user}</span>
                        <br />
                        <span className="text-black">
                          {formatter.format(balance)}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={handleLogout}
                      role="button"
                      tabIndex={0}
                      onKeyDown={Function.prototype}
                    >
                      <img
                        fetchpriority="high"
                        src={iconLogOut}
                        alt="icon-log-out"
                        className="w-[25px]"
                      />
                    </div>
                  </div>
                </div>
                <div>{children}</div>
              </div>
            </Menu.Items>
          </div>
        </>
      )}
    </Menu>
  )
}

HamburgerMenu.propTypes = {
  children: PropTypes.element.isRequired,
  user: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired,
}

export default HamburgerMenu

HamburgerMenu.Item = HamburgerMenuItem
