import React from "react"
import PropTypes from "prop-types"
import { Switch } from "@headlessui/react"

const switchStyle = (enabled) =>
  enabled ? "bg-tertiary" : "bg-none border border-tertiary"

const spanSwitchStyle = (enabled) =>
  enabled ? "translate-x-6" : "translate-x-1 bg-tertiary"

const disabledStyle = (disabled) => (disabled ? "opacity-40" : "opacity-100")

const Toggle = ({ onChange, defaultStatus, disabled, onClick }) => (
  <Switch
    onClick={onClick}
    disabled={disabled}
    onChange={onChange}
    checked={defaultStatus}
    className={` ${switchStyle(defaultStatus)} ${disabledStyle(
      false
    )} relative inline-flex h-6  w-11 items-center rounded-full`}
  >
    <span
      className={`${spanSwitchStyle(
        defaultStatus
      )} inline-block h-4 w-4 transform rounded-full bg-white`}
    />
  </Switch>
)

Toggle.defaultProps = {
  onChange: Function.prototype,
  onClick: Function.prototype,
  defaultStatus: false,
  disabled: false,
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  defaultStatus: PropTypes.bool,
}

export default Toggle
