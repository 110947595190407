import React from "react"
import { Outlet } from "react-router-dom"

const PolicyContainer = () => (
  <div>
    <Outlet />
  </div>
)

export default PolicyContainer
