import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { Button as MTButton } from "@material-tailwind/react"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const style = (size) => {
  let buttonStyle = null
  if (size === "medium") {
    buttonStyle = css({
      padding: "11px 40px",
    })
  } else if (size === "small") {
    buttonStyle = css({
      padding: "5px 24px",
    })
  } else if (size === "extraSmall") {
    buttonStyle = css({
      padding: "5px 20px",
    })
  } else {
    buttonStyle = css({
      padding: "3px 0px",
    })
  }
  return buttonStyle
}

const fullWidthStyle = (fullWidth) => (fullWidth ? "w-full " : "")
// const disabledStyle=(disabled)=>disabled? ""

// const themeStyle = (theme) =>
//   theme === "dark" ? "text-white border-white" : "text-black border-black  "

const classes = (type) =>
  clsx(
    "mr-3 text-center text-[18px] ",
    type === "primary"
      ? "text-white text-[18px] font-normal normal-case hover:shadow-none rounded-full  hover:opacity-1 focus:ring-0"
      : "",
    type === "secondary"
      ? "text-white text-[18px] font-normal normal-case rounded-full shadow-none hover:shadow-none bg-transparent hover:opacity-1"
      : "",
    type === "tertiary"
      ? "text-black rounded-none text-[18px] font-normal bg-transparent  normal-case shadow-none hover:shadow-none hover:transform-none flex transition ease duration-300  px-0 border-b-[1px] border-t-0 border-r-0 border-l-0 border-b-transparent hover:border-b-[1px] hover:border-tertiary active:border-black focus:border-0 hover:box-border'"
      : "",
    type === "quaternary"
      ? "text-white text-[18px] font-normal normal-case hover:shadow-none rounded-full bg-gradient-to-r border-0 from-blued  to-greenBl hover:bg-gradient-to-r hover:from-bluedGradient hover:to-greenBlGradient hover:opacity-1 focus:ring-0 mr-0"
      : ""
  )

const subButton = {
  propTypes: {
    size: PropTypes.oneOf(["medium", "small"]),
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    theme: PropTypes.string,
  },
  defaultProps: {
    size: "medium",
    type: "",
    fullWidth: false,
    theme: "dark",
  },
}

export const ButtonComponent = ({
  children,
  type,
  size,
  onClick,
  onSubmit,
  fullWidth,
  disabled,
}) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  const buttonStyles = {
    primary: {
      background: `linear-gradient(to right, ${themeColors.mediumPurple}, ${themeColors.customIndigo})`,
      hoverBackground: `linear-gradient(to right, ${themeColors.heliotrope}, ${themeColors.purpleHeart})`,
      border: "0",
    },
    secondary: {
      borderColor: themeColors?.pickPurple,
      hoverBorderColor: themeColors?.lightPurple,
    },
    tertiary: {
      borderBottom: "1px solid",
      borderTop: "0",
      borderRight: "0",
      borderLeft: "0",
      borderBottomColor: "transparent",
      hoverBorderColor: themeColors?.tertiary,
    },
    quaternary: {
      background: `linear-gradient(to right, ${themeColors.blued}, ${themeColors.greenBl})`,
      hoverBackground: `linear-gradient(to right, ${themeColors.bluedGradient}, ${themeColors.greenBlGradient})`,
    },
  }

  const getTypeStyles = (types) =>
    css({
      background: buttonStyles[types]?.background,
      border: `${buttonStyles[types]?.border}`,
      borderColor: `${buttonStyles[types]?.borderColor}`,
      "&:hover": {
        background: buttonStyles[types]?.hoverBackground,
        borderColor: buttonStyles[types]?.hoverBorderColor,
        borderBottomWidth: "1px", // Add border-bottom with a width of 1px on hover
        borderBottomColor: buttonStyles[types]?.borderColor, // Use border color or fallback to currentColor
      },
      transition: "all 0.3s ease",
    })

  const dynamicStyles = style(size)
  const typeStyle = getTypeStyles(type)

  return (
    <MTButton
      disabled={disabled}
      variant="outlined"
      className={`${classes(type)} ${fullWidthStyle(fullWidth)} `}
      type={onSubmit ? "submit" : "button"}
      css={[dynamicStyles, typeStyle]}
      onClick={onClick}
      onSubmit={onSubmit}
    >
      {children}
    </MTButton>
  )
}

ButtonComponent.defaultProps = {
  ...subButton.defaultProps,
  size: "",
  type: "",
  onClick: Function.prototype,
  disabled: false,
}

ButtonComponent.propTypes = {
  ...subButton.propTypes,
  children: PropTypes.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

const ButtonPrimary = ({
  size,
  children,
  onClick,
  fullWidth,
  onSubmit,
  disabled,
}) => (
  <ButtonComponent
    type="primary"
    size={size}
    onClick={onClick}
    fullWidth={fullWidth}
    onSubmit={onSubmit}
    disabled={disabled}
  >
    {children}
  </ButtonComponent>
)

ButtonPrimary.defaultProps = subButton.defaultProps
ButtonPrimary.propTypes = subButton.propTypes

const ButtonSecondary = ({ size, children, onClick }) => (
  <ButtonComponent type="secondary" size={size} onClick={onClick}>
    {children}
  </ButtonComponent>
)

ButtonSecondary.defaultProps = subButton.defaultProps
ButtonSecondary.propTypes = subButton.propTypes

const ButtonTertiary = ({ children, onClick, theme, onSubmit, disabled }) => (
  <ButtonComponent
    type="tertiary"
    onClick={onClick}
    theme={theme}
    onSubmit={onSubmit}
    disabled={disabled}
  >
    {children}
  </ButtonComponent>
)

ButtonTertiary.defaultProps = subButton.defaultProps
ButtonTertiary.propTypes = subButton.propTypes

const ButtonQuaternary = ({
  size,
  children,
  onClick,
  fullWidth,
  onSubmit,
  disabled,
}) => (
  <ButtonComponent
    type="quaternary"
    size={size}
    onClick={onClick}
    fullWidth={fullWidth}
    onSubmit={onSubmit}
    disabled={disabled}
  >
    {children}
  </ButtonComponent>
)
ButtonQuaternary.defaultProps = subButton.defaultProps
ButtonQuaternary.propTypes = subButton.propTypes

const Button = {
  Primary: ButtonPrimary,
  Secondary: ButtonSecondary,
  Tertiary: ButtonTertiary,
  Quaternary: ButtonQuaternary,
}

Button.Primary = ButtonPrimary
Button.Secondary = ButtonSecondary
Button.Tertiary = ButtonTertiary
Button.Quaternary = ButtonQuaternary

export default Button
