import React from "react"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import Banner from "./Banner"
import Button from "./Button"

import "../features/PaymentGateways/style.css"

const buyWithCryptoArticles = {
  fr: "https://help.casinocarbon.com/fr/articles/6420298-depot-sur-casino-universe-en-utilisant-les-cryptomonnaies",
  en: "https://help.casinocarbon.com/en/articles/6420298-depositing-to-casino-universe-using-cryptocurrency",
  sp: "https://help.casinocarbon.com/es/articles/6420298-deposita-en-casino-universe-usando-criptomonedas",
  it: "https://help.casinocarbon.com/it/articles/6420298-deposita-su-casino-universe-utilizzando-le-criptovalute",
}

const CryptoPuchaseBanner = () => {
  const { t, i18n } = useTranslation()
  const languageSelected = i18n.language?.split("-")[0]

  const handleRedirectToHelpArticle = () => {
    window.open(`${buyWithCryptoArticles[languageSelected]}`, "_blank")
  }
  return (
    <div className="container-max-width-switchere py-6">
      <Banner height="full">
        <div className="  container-background-top-style rounded-3xl pl-10 pt-2  container-switchere-banner-style justify-center h-full w-full flex xl:flex-row items-center  gap-1">
          <div className=" h-full tablet:w-full desktop:w-full flex flex-col justify-center">
            <h1 className="container-tosp-banner-title-style text-3xl  md:text-xl xs:text-xl mb-2 mt-2 text-tertiary font-bold">
              {t("Get 20% crypto bonus")}
            </h1>
            <p className=" md:text-base xs:text-sm  pb-5">
              {t(
                "Don't forget that this bonus is in addition to the 100% welcome bonus, so take advantage of 120% bonus. Make your deposit in crypto-currency!"
              )}
            </p>
            <MediaQuery maxWidth={767}>
              <div className="mb-5">
                <Button.Secondary
                  size="small"
                  onClick={handleRedirectToHelpArticle}
                >
                  {t("Need Help ?")}
                </Button.Secondary>
              </div>
            </MediaQuery>
          </div>

          <div className="h-full container-top-banner-image-section ">
            <div className="flex justify-center items-center h-full w-full ">
              <MediaQuery minWidth={960}>
                <Button.Secondary onClick={handleRedirectToHelpArticle}>
                  {t("Need Help ?")}
                </Button.Secondary>
              </MediaQuery>
              <MediaQuery minWidth={768} maxWidth={959}>
                <Button.Secondary
                  size="small"
                  onClick={handleRedirectToHelpArticle}
                >
                  {t("Need Help ?")}
                </Button.Secondary>
              </MediaQuery>
            </div>
          </div>
        </div>
      </Banner>
    </div>
  )
}

export default CryptoPuchaseBanner
