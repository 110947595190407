import React from "react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import Header from "../../components/Header"
import { getBrandFromUrl, promptBrand } from "../../helpers"
import themes from "../../themes"

const listStyle = css`
  list-style: disc;
`

const AmlPolicy = () => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <>
      <Header title={`${promptBrand("name")} | Aml Policy`} />
      <section
        className=" text-white md:pb-32 xs:pb-20  w-full policies-custom-style"
        style={{ backgroundColor: themeColors?.primary }}
      >
        <div className="container-max-width">
          <div className="mx-auto  pt-40 sm:px-7 lg:px-0 xl-start:p-10 xs:px-6 pb-5">
            <h1 className="text-2xl font-bold mb-10">Aml policy</h1>
            <p>
              {promptBrand("site")}is operated by Carbon Games N.V, incorporated
              and registered in Curaçao with company number 161315 , whose
              registered office is Abraham de Veerstraat 9, Willemstad, P.O. Box
              3421, Curaçao, licensed to conduct online gaming operations by the
              Government of Curacao under license 365/JAZ 18+ to play
            </p>
            <h1 className="text-lg font-bold my-5">
              KYC-AML Policy of Carbon Games N.V.
            </h1>
            <p>Last updated: 02. June 2022</p>
            <p>
              The objective of the AML Policy: The company and its third-party
              partners seek to offer the highest security to all of tour users
              and customers on {promptBrand("site")}. For that, four-step
              account verification is carried out to ensure we establish the
              customer&#39;s identity and appropriately assess money laundering
              risks associated with that customer. The company imposes its
              measurements, checks, verifications and automatic systems on all
              users disregarding if they use cryptocurrency or cash deposit or
              withdrawal options. First, the company imposes general KYC (know
              your customer), afterwards advanced Anti Money Laundering checks
              follow as the second, third and fourth steps. In doubt, we may add
              a fifth step for clarification. This is to substantiate the
              person&#39;s details and ensure deposited values are not stolen or
              used by someone else. We also consider that extra safety
              measurements must be taken depending on the nationality, origin,
              way of payment and method of withdrawing.
            </p>
            <h1 className="text-lg font-bold my-5">Definitions</h1>
            <ul css={listStyle}>
              <li className="my-1">
                Website: refers {`http://${promptBrand("site")}`}
              </li>
              <li className="my-1">
                Company: refers to Carbon Games N.V., incorporated and
                registered in Curacao with company number 161315 whose
                registered office is at Abraham de Veerstraat 9, Willemstad,
                P.O. Box 3421, Curaçao.
              </li>
              <li className="my-1">WE or US or OUR: refers to the company</li>
              <li className="my-1">Country: Refers to Curacao</li>
              <li className="my-1">
                Governing law: refers to the laws of Curacao
              </li>
              <li className="my-1">
                Carbonidoo: refers to the currency used on carbonCasino.io . One
                Carbonido is equal to one dollar worth of Bitcoin or Ethereum or
                Litecoin or Cash at the current exchange rate.
              </li>
              <li className="my-1">
                Carbonido-Safe: refers to your on-side wallet in which your
                carbonido are stored.
              </li>
              <li className="my-1">
                Terms: refers to this Agreement (Terms of Service) in addition
                to the Privacy Policy, KYC-AML-Policy, and Responsible Gambling
                Policy.
              </li>
              <li className="my-1">
                Account refers to a player’s user account created on
                carboncasino.io under this agreement.
              </li>
              <li className="my-1">
                User: refers to any natural individual using services on
                carbonbase.com under the terms.
              </li>
              <li className="my-1">
                Player: refers to any user of our services
              </li>
              <li className="my-1">
                Affiliate: refers to any other user who joins via your invite
                link/code.
              </li>
              <li className="my-1">
                AML: refers to Anti-money laundering, further information in our
                KYC-AML policy.
              </li>
              <li className="my-1">
                AMLCO: stands for Anti Money Laundering Compliance Officer.
              </li>
              <li className="my-1">
                KYC: refers to Know your Customer, further information in our
                KYC-AML policy.
              </li>
              <li className="my-1">
                Support: refers to the CarbonCasino Support team and the
                CarbonCasino Compliance team.
              </li>
              <li className="my-1">
                Game: refers to any gambling services on the website, including
                games of skill, games of chance, and games of risk.
              </li>
              <li className="my-1">
                Third-party partner: refers to all of the companies business
                associates.
              </li>
            </ul>
            <h1 className="text-lg font-bold my-5">
              For Cash Deposits and Cash Withdraws.
            </h1>
            <p>
              AML Anti-Money-Laundering policy of http://www.
              {promptBrand("site")}
              by Carbon Games N.V.
            </p>
            <p>
              The objective of the AML Policy: The company and its third-party
              partners seek to offer the highest security to all of our users
              and customers on {promptBrand("site")} for that, four-step account
              verification is done to ensure the identity of our customers. The
              company implies its measurements, checks, verifications and
              automatic systems on all users disregarding if they use
              cryptocurrency or cash deposit or withdrawal options. The first
              step is general KYC, second, third and fourth steps are advanced
              Anti Money Laundering checks. In doubt, we may add a fifth step
              for clarification. This is to prove that the details of the person
              registered are correct and the deposited values are not stolen or
              being used by someone else, which is to create the general
              framework for the fight against money laundering. We also consider
              that extra safety measurements must be taken depending on the
              nationality, origin, way of payment and method of withdrawing.
            </p>
            <p>
              The company also puts reasonable measures in place to control,
              limit, and avoid ML risk, including dedicating the appropriate
              means for {`http://${promptBrand("site")}`}
            </p>
            <p>
              According to the EU guidelines, the company and its third-party
              partners are committed to high standards of anti-money laundering
              (AML) and compliance and require management and employees to
              enforce these standards to prevent the use of its services for
              money laundering purposes. Carbon Games N.V also takes additional
              safety measures to handle the higher risk of payments made with
              cryptocurrencies.
            </p>
            <p>
              The AML program of http://www.{promptBrand("site")}is designed to
              be compliant with :
            </p>
            <p>
              EU: “Directive 2015/849 of the European Parliament and of The
              Council of 20 May 2015 on the prevention of the use of the
              financial system for the purposes of money laundering.”
            </p>
            <p>
              EU: “Directive 2018/843 the fifth anti-money laundering directive
              which amended the 4 th anti-money laundering directive (Directive
              2015/849) to further improve the anti- money laundering program of
              the European Union and its partners.
            </p>
            <p>
              EU: “Regulation 2015/847 on information accompanying transfers of
              funds.” EU: Various regulations imposing sanctions or restrictive
              measures against persons and embargo on certain goods and
              technology, including all dual-use goods
            </p>
            <p>
              BE: “Law of 18 September 2017 on the prevention of money
              laundering limitation of the use of cash
            </p>
            <p>
              EU: first and second Risk assessment published by the European
              Commission 26 June 2017 and 24 July 2019. The commission assessed
              the vulnerability of financial products and services to risks of
              money laundering and terrorist financing. This risk analysis is
              conceived as a key tool to identify, analyses, and address money
              laundering and terrorist financing risks in the EU.
            </p>
            <p>
              EU: planned directive 6AMLD for 2021 to combat money laundering
              via cryptocurrencies and cyber criminality.
            </p>
            <h1 className="text-lg font-bold my-5">
              {" "}
              Definition of money laundering
            </h1>
            <p>Money Laundering is understood as:</p>
            <ul css={listStyle}>
              <li className="my-1">
                The conversion or transfer of property, especially money, but
                not exclusively money, knowing that such property is derived
                from criminal activity or from taking part in such activity, to
                conceal or disguise the illegal origin of the property or of
                helping any person who is involved in the commission of such an
                activity to evade the legal consequences of that person or
                companies action.
              </li>
              <li className="my-1">
                The concealment or disguise of the true nature, source,
                location, disposition, movement, rights concerning, or ownership
                of, property, knowing that such property is derived from
                criminal activity or an act of participation in such activity.
              </li>
              <li className="my-1">
                The acquisition, possession or use of property, knowing, at the
                time of receipt, that such property was derived from criminal
                activity or from assisting in such activity.
              </li>
              <li>
                Participation in, association to commit, attempts to commit and
                aid, abet, facilitate, and counsel the commission of any of the
                actions referred to in points before.
              </li>
              <li className="my-1">
                Besides, untaxed income, such as black money, shall not be
                accepted and reported to the corresponding government agency to
                ensure fair taxation and legal prosecution.
              </li>
            </ul>
            <p>
              Money laundering shall be regarded as such even when the
              activities which generated the property to be laundered were
              carried out in the territory of another Member State or in that of
              a third country.
            </p>
            <h1 className="text-lg font-bold my-5">
              Organisation of the AML for the website
            </h1>
            <p>
              Following the AML legislation, the company has appointed on its
              and its third-party
            </p>
            <p>
              partner&#39;s behalf the “highest level” for the prevention of
              money laundering: the full management of the company is in charge
              and responsible.
            </p>
            <p>
              Furthermore, an AMLCO (Anti Money Laundering Compliance Officer)
              is in charge of enforcing the AML policy and procedures within the
              system. The AMLCO is also in charge of constantly adjusting the
              AML to deal with all upcoming difficulties when preventing Money
              Laundering. (temporally M.Sc. Naria Schmitt with support of the
              Legal Department).
            </p>
            <p>
              The AMLCO is placed under the direct responsibility of the general
              Management and part of the comprehensive Management.
            </p>
            <h1 className="text-lg font-bold my-5">
              AML and KYC policy changes and implementation requirements:
            </h1>
            <p>
              Each major change of AML policy and KYC policy is subject to be
              approved by the company&#39;s general management and the AMLC.
            </p>
            <p>
              The company reserves the right to adjust, modify, or replace these
              terms and guidelines at any time, at our sole discretion. The
              company may have to adjust, modify or replace the terms and
              guidelines to comply with further changes in laws and regulations,
              as well as further threats of money laundering and illicit or
              illegal activities.
            </p>
            <p>
              The company will annually adjust the AML and KYC risk assessment
              and adjust its terms according to it. The company aims to prevent
              money laundering and illegal or illicit activities on the website
              and all services connected to it.
            </p>
            <h1 className="text-lg font-bold my-5">Four-step Verification:</h1>
            <h1 className="text-lg font-semibold my-3">
              Step one verification: - KYC
            </h1>
            <p>
              Step one verification is a simple KYC (Know your customer), which
              every user and customer must do when signing up to the website
              before making the first withdrawal, as recommended in Directive
              2018/843. Additionally, every new user must submit basic
              information when registering an account on the website.
              Disregarding the payment choice, the amount of payment, the amount
              of withdrawing, the choice of withdrawing, and the nationality of
              the user or customer, step one verification must be done before
              any withdrawal or transaction to another user can be made.
            </p>
            <p>
              Step one verification is an online document that must be filled
              out by the user/customer himself faithfully. The following
              information must be filled in: first name, second name, date of
              birth, country of usual residence, and telephone number.
            </p>
            <p>
              We do not allow any withdrawals of funds until the KYC is filled
              in. In case of misinformation or unfaithful filling in, the
              company may hold the funds until the KYC is approved. We might ask
              you to do steps two and three verification (AML 1 and AML 2)
              before withdrawing any funds if you filled in intentionally wrong
              information in the KYC online document.
            </p>
            <p>
              The Carbon Casino technical support will randomly perform
              crosschecks to ensure the entered address matches the IP
              address&#39;s location. If the IP address location does not match
              the given address, the company may lock the account for further
              investigation, and request steps two and three verification (AML 1
              and AML 2). The company will perform additional checks based on
              the situation, including, but not limited to, crosschecking for
              open sanctions and connections to illegal or fraudulent actions.
            </p>
            <h1 className="text-lg font-semibold my-3">
              Step two verification: - AML 1
            </h1>
            <p>
              Step two verification, a social media account and ID check which
              must be performed by every user who deposits or withdraws over a
              certain threshold as mentioned in Directive (EU) 2015/2366 or
              withdraws or deposits a certain threshold as mentioned in
              Directive (EU) 2015/2366 or sends another user over 50% (fifty
              percent) of the threshold as mentioned in Directive (EU) 2015/2366
              over 12 (twelve) months adjusted according to the individual risk
              level.
            </p>
            <p>
              he withdrawal, tip, or deposit will be held until steps two, and
              three verification are completed. Step two verification will lead
              the user or customer to a subpage where he must submit his ID. The
              company uses Veriff and Seon for those tasks; further information
              is in this policy&#39;s Veriff and Seon section. The user/customer
              has to take a picture of his ID and appear in a video net to his
              video. The user&#39;s face must be clearly visible next to his
              ID.: Only an official ID may be used for ID verification;
              depending on the country, the variety of accepted IDs may differ.
              (Normally, these are: Driving license, Passport, and Identity
              card. Depending on your country of origin, this may vary) The
              company may require you to submit both pictures and both sides of
              your ID to confirm your identity in addition to the video
              verification. Further information can be found in this
              policy&#39;s Customer identification and verification section.
            </p>
            <p>
              The company may ask you to submit your social media, especially
              your Facebook account, to verify your account. Your social media
              account will be used to crosscheck the information provided in the
              AML 1, AML 2 and KYC verification.
            </p>
            <p>
              There will also be an electronic check if the filled-in personal
              data from step one verification, the KYC, match. The electronic
              check will check via two different data-banks to ensure the given
              information matches the filled document and the ID&#39;s name.
            </p>
            <h1 className="text-lg font-semibold my-3">
              Step two verification: - AML 2
            </h1>
            <p>
              tep three verification is made up of Proof of address verification
              and an electronic origin check which must be performed by every
              user who deposits or withdraws over a certain threshold as
              mentioned in Directive (EU) 2015/2366 or withdraws or deposits a
              certain threshold as mentioned in Directive (EU) 2015/2366 or
              sends another user over 50% (fifty percent) of the threshold as
              mentioned in Directive (EU) 2015/2366 over 12 (twelve) months
              adjusted according to the individual risk level.
            </p>
            <p>
              The withdrawal, tip, or deposit will be held until steps two, and
              three verification are completed. Step two verification will lead
              the user or customer to a sub-page where he must submit his POA
              (Proof of address).
            </p>
            <p>
              There will also be an electronic check if the filled-in personal
              data from step one verification, the KYC, match. The electronic
              check will check via two different data-banks to ensure the given
              information matches the filled document and the ID&#39;s name.
              This electronic check crosschecks the origin of the user or
              customer and his state of residence and address.
            </p>
            <p>
              In addition to the company&#39;s electronic check, the user or
              customer must confirm his current residence. A certificate of
              registration by the government or a similar document is required.
              In cases where no such certificate exists, the utility bills of
              the last 3 (three) months may be used. Further information can be
              found in this policy&#39;s Proof of Address section.
            </p>
            <p>
              Unless otherwise stated, AML 2 is requested and performed
              simultaneously with AML 1. An employee may conclude that AML 1
              should be requested at an earlier state to address a user&#39;s or
              customers higher risk profile.
            </p>
            <h1 className="text-lg font-semibold my-3">
              Step two verification: - AML 3
            </h1>
            <p>
              Step four verification, verification of the source of funds, must
              be done by every user who deposits over the second threshold
              mentioned in Directive (EU) 2015/2366 or withdraws over the second
              threshold mentioned in Directive (EU) 2015/2366 or sends another
              user over 60% (sixty percent) of the second threshold as mentioned
              in Directive (EU) 2015/2366), over 12 (twelve) months adjusted
              according to the individual risk level.
            </p>
            <p>
              The withdrawal, user tip, or deposit will be held until step four
              verification is completed. For step four, a user or customer will
              be asked for a source of wealth. The user or customer has to
              upload a document proving his source of wealth via a subpage.
              Further details on the accepted source of funds can be found in
              this policy&#39;s source of funds section.
            </p>
            <h1 className="text-lg font-bold my-5">Source of funds</h1>
            <p>
              If a player deposits over five thousand euros, there is a process
              of understandings the source of wealth (SOW).
            </p>
            <p>Examples of SOW are:</p>
            <ul css={listStyle}>
              <li className="my-1">Ownership of business</li>
              <li className="my-1">Employment</li>
              <li className="my-1">Inheritance</li>
              <li className="my-1">Investment</li>
              <li className="my-1">Family</li>
            </ul>
            <p>
              The origin and legitimacy of that wealth must be clearly
              understood. If this is not possible, an employee may ask for an
              additional document or Proof.
            </p>
            <h1 className="text-lg font-bold my-5">
              Customer identification and verification (KYC- AML 1)
            </h1>
            <p>
              The formal identification of customers on entry into commercial
              relations is a vital element, both for the regulations relating to
              money laundering and for the KYC policy.
            </p>
            <p>
              This identification relies on the following fundamental
              principles:
            </p>
            <p>
              A copy of your Passport, ID card, or driving license, each shown
              alongside a handwritten note mentioning a six digits randomly
              generated number, as stated above. Otherwise, the copy of your
              Passport, ID card, or driving license, each shown alongside your
              face clearly visible.
            </p>
            <p>
              Please note that all four corners of the ID must be visible in the
              same image, or video and all details have to be readable. Take a
              photo or upload a video of the front side of your document. Ensure
              that there is sufficient light and avoid glare on the document.
              Ensure the image or video is not blurred. Make sure your full
              document is within the image or video. All edges of the document
              must be within the frame. Make sure that the document number and
              expiry date are clearly visible.
            </p>
            <p>
              Don&#39;t edit the image or video in any way. Don&#39;t upload the
              screenshots of any document. Don&#39;t hide, fold or crop any part
              of the document. If the video or picture is altered in any form or
              way, we can not accept it.
            </p>
            <p>
              The maximum image size you can upload is 16MB, and the maximum
              video size you can upload is 20MB. We support the following
              formats for videos and images: JPG, JPEG, PNG, PDF
            </p>
            <p>
              An employee may do additional checks if necessary, based on the
              situation.
            </p>
            <p>
              Please follow the instructions given by the employee of Veriff,
              which cooperate on the Video ID Verification with the company. For
              further information view the Veriff Section of this Policy.
            </p>
            <h1 className="text-lg font-bold my-5">
              Proof of Address: (AML 2)
            </h1>
            <p>
              Proof of address will be done via electronic checks, which use two
              different databases.
            </p>
            <p>
              For our electronic checks, we use the services of UNISERV; you may
              check their Privacy Policy under
              https://www.uniserv.com/en/privacy-policy/
            </p>
            <p>
              In addition to the company&#39;s electronic check, the user or
              customer must confirm his current residence. A recent utility bill
              sent to your registered address, issued within the last 3 (three)
              months, or an official document made by the government that proves
              your state of residence can be used.
            </p>
            <p>
              To make the approval process as speedy as possible, please ensure
              the document is sent with a clear resolution where all four
              corners of the document are visible and all text is readable.
            </p>
            <p>
              An example is an electricity bill, water bill, bank statement, or
              any governmental post addressed to you.
            </p>
            <p>
              An employee may do additional checks if necessary, based on the
              situation.
            </p>
            <h1 className="text-lg font-bold my-5">Veriff</h1>
            <p>
              Veriff is a company that specialises in ID verification and in the
              verification and approval of POA and addresses in general.
            </p>
            <p>
              The verification system is fully implemented into the
              company&#39;s AML 1 verification system and automatically goes
              through all documents submitted by users or customers in order to
              comply with the AML 1 measurements and processes.
            </p>
            <p>
              Veriff imposes the highest standards of data protection and aims
              to fully cooperate with carbon casino on keeping our users data
              safe and secure- You may view their Privacy Policy at:
              https://www.veriff.com/privacy-policy
            </p>
            <p>
              Veriff uses a combination of pictures and video verification for
              IDs. A user will be asked to submit a picture of his ID, of his
              face, and a live video investigated by an employee during which
              the ID and the user&#39;s or customer&#39;s face are clearly
              visible
            </p>
            <h1 className="text-lg font-bold my-5">
              Basic online document for KYC
            </h1>
            <p>
              The basic document will be accessible via the setting page on
              http://www.carboncasino.io Every user has to fill out the
              following information&#39;s:
            </p>
            <ul css={listStyle}>
              <li className="my-1">First name</li>
              <li className="my-1">Second name</li>
              <li className="my-1">Nationality</li>
              <li className="my-1">Date of birth</li>
              <li className="my-1">Telephone number</li>
            </ul>
            <p>
              The document will be saved and created by an AI; an employee may
              do additional checks if necessary based on the situation.
            </p>
            <h1 className="text-lg font-bold my-5">Social media crosscheck</h1>
            <p>
              Our CarbonCasino Compliance team may ask you to connect your
              Facebook account or other social media account with your
              CarbonCasino user account to support our KYC and AML verifications
              and checks.
            </p>
            <p>
              Suppose you choose to connect your Facebook account with your
              carbon casino user account. In that case, the company gets access
              to all your information, posts, pictures, and location data
              connected to your Facebook or other social media accounts.
            </p>
            <p>
              If you do not wish to connect your Facebook or other social media
              account with your carbon casino user account, you are free to do
              so and instead complete the AML 1 Verification to verify your
              carbon casino user account.
            </p>
            <p>
              The system will use your Facebook or other social media account to
              crosscheck all information given by you in the KYC and AML 1 check
              for accuracy and completeness. The company and third-party
              partners will not post anything from your account or affect your
              Facebook account in any visible way.
            </p>
            <h1 className="text-lg font-bold my-5">
              Sanctions, Persons, and Entities of Special Interest as well as
              PEPS
            </h1>
            <h1 className="text-lg font-semibold my-5">Shufti Pro</h1>
            <p>
              Shufti Pro Limited, called Shufti Pro in this document, complies
              with the high companies standards of data protection and
              cybersecurity. No data will be handed out to unauthorised
              subjects.
            </p>
            <p>
              Their Privacy Policy can be viewed at
              https://shuftipro.com/privacy-policy/
            </p>
            <p>
              Shufti Pro&#39;s Database includes over 1000 international and
              national sanction list and watch lists, which is used to
              crosscheck all customers and users of the company
            </p>
            <p>
              The ShuftiPro will support the company in their checks for
              possible fraud, screening people from political or criminal
              interests during all daily operations, and the entire AML-KYC
              procedure. Additionally, Shufti Pro assists in the customer and
              user verification and the authentication of documents submitted to
              the company. Their advanced system fully cooperates with its
              internal system, and the internal system is used together with its
              third-party partners.
            </p>
            <p>
              Additionally, Shufi Pros features are used during the AML 2 checks
              to verify documents for accuracy.
            </p>
            <h1 className="text-lg font-semibold my-5">
              Sanctions, Watchlists, and PEPS.
            </h1>
            <p>
              The ShuftiPro databases are used for screenings of international
              and national sanctions and national as well as international
              watchlists. ShuftiPro has a global database of persons and
              companies of political, criminal, or economic interest. It
              combines the most important sanctions lists, databases of
              politically exposed persons, watchlists, and other public
              information into a single, easy-to-access dataset and automated
              checks and screenings. It crosschecks and screens all users and
              customers in public databases for possible conflicts of interest
              and signs of illicit or illegal activity, tracks political
              conflicts, compares worldwide sanctions lists, national sanctions
              lists, international watch lists, and national watch lists and
              checks potential customers, users, and partners in international
              dealings. The project includes sanctions lists, lists of
              politicians, ban lists used in government procurement, watchlists
              of known terrorists, and other data sources relevant to
              journalistic research and due diligence.
            </p>
            <p>
              To facilitate this database, the company created a script that
              incorporates the ShuftiPro search engine on the website.
              Additionally, the ShuftiPro services support the ID and Address
              verification on the website, further improving its checks.
              ShuftiPro scans every customer or user going through AML 1, AML 2
              and KYC verification. The ShuftiPro search engine will scan every
              user who signs up with their name. Additionally, every ID
              submitted to the website will be verified and checked by the
              ShuftiPro verification algorithm. All of the checks done on
              ShuftiPro will be done in the background creating no delays for
              the customer or user. Any user or customer on an international or
              national watchlist or international or national sanction list will
              not be allowed on the website. They can not use any services on
              carbonbase.com. An employee may do additional checks if necessary
              or detect suspicious behaviour. If the results come back negative,
              the customer will not be able to enter the site, and we will
              inform authorities.
            </p>
            <h1 className="text-lg font-bold my-5">Risk management</h1>
            <p>
              The company aims to address the risks coming from different
              behaviours, customers, and jurisdictions. Therefore the company
              creates a risk assessment regarding all services and its website
              once a year to address it better in day-to-day operations.
            </p>
            <p>
              Furthermore, the company will work with its partners on risk
              management; further information regarding the shared data in this
              cooperation can be found in our Privacy Policy.
            </p>
            <h1 className="text-lg font-bold my-5">
              Territorial risk Assessment:
            </h1>
            <p>
              In order to deal with the different risks and different states of
              wealth in different regions on the earth, the company will
              categorize every nation into six different regions of risk. The
              territorial risk assessment is done once a year and updated
              whenever the local situation in a region drastically change.
            </p>
            <h1 className="text-lg font-bold my-5">Region one: no risk</h1>
            <p>
              As described earlier, the four-step verification is done for every
              nation from region one according to the threshold established
              through Directive (EU) 2015/2366.
            </p>
            <p>
              AML 1 and AML 2 will be done after depositing or withdrawing 100%
              (hundred percent) of the threshold established through Directive
              (EU) 2015/2366 for users from a no-risk region.
            </p>
            <p>
              AML 3 will be done after depositing or withdrawing as much as 100%
              (hundred percent) of the second threshold established through
              Directive (EU) 2015/2366 for SOF checks.
            </p>
            <h1 className="text-lg font-bold my-5">
              Region two: Very low risk
            </h1>
            <p>
              AML verification will be done at lower deposit and withdrawal
              thresholds for every user from any nation on the very low-risk
              list.
            </p>
            <p>
              AML 1 and AML 2 will be done after depositing or withdrawing 80%
              (eighty percent) of the threshold established through Directive
              (EU) 2015/2366 for users from a very low-risk region.
            </p>
            <p>
              AML 3 will be done after depositing or withdrawing as much as 80%
              (eighty percent) of the second threshold established through
              Directive (EU) 2015/2366 for SOF checks.
            </p>
            <h1 className="my-5 text-lg font-bold">Region three: low risk</h1>
            <p>
              AML verification will be done at lower deposit and withdrawal
              thresholds for every user from any nation on the low-risk list.
            </p>
            <p>
              AML 1 and AML 2 will be done after depositing or withdrawing 60%
              (sixty percent) of the threshold established through Directive
              (EU) 2015/2366 for users from a low-risk region.
            </p>
            <p>
              AML 3 will be done after depositing or withdrawing as much as 60%
              (sixty percent) of the second threshold established through
              Directive (EU) 2015/2366 for SOF checks.
            </p>
            <h1 className="text-lg my-5 font-bold">Region four: medium risk</h1>
            <p>
              AML verification will be done at lower deposit and withdrawal
              thresholds for every user from any nation on the medium-risk list.
            </p>
            <p>
              AML 1 and AML 2 will be done after depositing or withdrawing 45%
              (forty-five percent) of the threshold established through
              Directive (EU) 2015/2366 for the users from a medium risk region.
            </p>
            <p>
              AML 3 will be done after depositing or withdrawing as much as 45%
              (forty-five percent) of the second threshold established through
              Directive (EU) 2015/2366 for SOF checks.
            </p>
            <h1 className="text-lg font-bold my-5">Region five: high risk</h1>
            <p>
              AML verification will be done at lower deposit and withdrawal
              thresholds for every user from any nation on the high-risk list.
            </p>
            <p>
              AML 1 and AML 2 will be done after depositing or withdrawing 30%
              (thirty percent) of the threshold established through Directive
              (EU) 2015/2366 for the users from a high-risk region.
            </p>
            <p>
              AML 3 will be done after depositing or withdrawing as much as 30%
              (thirty percent) of the second threshold established through
              Directive (EU) 2015/2366 for SOF checks.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Region six: very high risk
            </h1>
            <p>
              Regions from the very high-risk list will be banned from the
              website and excluded from all its services. Very high-risk regions
              will be regularly updated to keep up with the changing environment
              of a fast-changing world.
            </p>
            <p>Behavior Risk Assessment:</p>
            <p>
              The company uses a variety of internal analyzing of risks and
              behaviors. It cooperates with SEON to evaluate customer behavior,
              potential fraud, actions of risk and general improvements of its
              measurements.
            </p>
            <p>
              The company&#39;s risk behavior risk assessment is a joint effort
              between its internal system and the external system provided by
              SEON.
            </p>
            <h1 className="my-5 text-lg font-bold">SEON</h1>
            <p>
              SEON is a company that specialises in various anti-fraud solutions
              and provides an all in one solution to analyse user behaviour for
              potential fraudulent actions and connections to illicit
              activities.
            </p>
            <p>
              The services provided by SEON have been fully implemented into the
              company&#39;s anti-money laundering system and the anti-fraud
              system, as well as general prevention of illicit activities and
              breaches of our terms.
            </p>
            <p>
              SEON imposes the highest standards of data protection and aims to
              fully cooperate with carbon casino on keeping our users&#39; data
              safe and secure- You may view their Privacy Policy at:
              https://seon.io/privacy-policy/
            </p>
            <h1 className="my-5 text-lg font-bold">Risk Score</h1>
            <p>
              SEONs software provides a risk score for each user or customer
              registered on the website taking over 100 (one hundred) variables
              into account. The company#39;s compliance team uses the individual
              risk score to assess if users or customers will be offered
              services on the website, which additional measurements may be
              required to ensure services can be safely provided and which users
              or customers may not connect to the website as a connection to
              illicit activities is likely.
            </p>
            <p>Document and Data Verification</p>
            <p>
              SEON&#39;s tools allow the compliance team to verify documents and
              crosscheck data through multiple external and internal data-banks.
              Additionally, each user account on the website will be checked for
              linked public domains and social media to ensure the accuracy of
              the provided data as well as gathering further information on the
              account.
            </p>
            <p>
              All user data collected on the website and submitted through
              customers will be analysed through the specialised tools SEON
              provides and checked for accuracy, connection to illicit
              activities, and previous violations of our terms of service.
              Furthermore, the data will only be transferred encrypted through a
              secure API, ensuring the user can only be accessed by authorised
              personal.
            </p>
            <h1 className="my-5 text-lg font-bold">Machine learning</h1>
            <p>
              SEON&#39;s anti-fraud tools automatically adjust to behaviours
              observed by our users and compliance team to catch fraud and other
              illicit activities before they happen.
            </p>
            <p>
              The anti-fraud team overseen by the AMLCO will continue to look
              for unusual behaviour and report it without delay to the system
              for further improvement. Through the wide experience of the
              company&#39;s compliance team and SEON~&#39;s automated tools, a
              constantly, automatically improving system is established.
            </p>
            <p>
              According to a risk-based view and broad experience, the human
              employees will recheck all checks done previously by the AI of
              SEON&#39;s anti-fraud software. The company&#39;s compliance team
              may redo or do additional checks according to the situation.
            </p>
            <p>
              SEON&#39;s machine learning is constantly improved by their data
              scientist cooperating with the company&#39;s data scientists and
              compliance team. The AI will look for unusual behaviour including,
              but not limited to depositing and withdrawing without longer
              betting sessions, attempts to use a different cryptocurrency
              wallet, or different bank account for deposit and withdrawal,
              nationality changes, currency changes, behaviour and activity
              changes, as well as checks if its original owner uses the account,
              or someone else.
            </p>
            <h1 className="my-5 text-lg font-bold">GeoComply</h1>
            <p>
              GeoComply is a company that specialises in offering geo-block and
              geofence solutions. GeoComply&#39;s services are used to
              strengthen the company&#39;s measurements and hardens to prevent
              citizens of restricted or banned areas and jurisdiction from
              accessing the website and any services on it.
            </p>
            <p>
              GeoComply&#39;s multiple measurements provide a strong barrier for
              the citizen of banned jurisdiction while also assisting the
              company in combatting fraud and other unlawful actions by blocking
              knowing offenders. In addition, GeoComply is the company&#39;s
              primary system to prevent unauthorised access to its website.
            </p>
            <p>
              Additionally, the GeoGuard function of GeoComply is implemented to
              ban all unauthorised usage of VPNs on the website. Further,
              GeoGuards function also prevents proxies, Tor, and data centres
              from connecting to the website and its services without
              authorisation.
            </p>
            <p>
              GeoComply&#39;s imposes the highest standards of data protection
              and aims to fully cooperate with carbon casino on keeping our
              users data safe and secure- You may view their Privacy Policy at:
              https://www.geocomply.com/privacy-policy/
            </p>
            <h1 className="my-5 text-lg font-bold">Tipping Policy</h1>
            <p>
              To address the money laundering risk that comes with a tipping
              function which allows users to send funds between each other, the
              company added additional restrictions, checks and verification to
              effectively prevent all possible forms of money laundering through
              the tipping function on the website.
            </p>
            <h1 className="my-5 text-lg font-bold">Sending Tips</h1>
            <p>
              For a user or customer to send any tips to another user or
              customer on the website, they must first complete the KYC
              verification described in our AML-KYC policy. The company freezes
              any attempted tip from a user or customer before completing the
              KYC Verification until the verification is completed.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped users
              or customers Roobcarbonido ido-safe after the KYC Verification is
              approved.
            </p>
            <p>
              If a user or customer reaches the total amount of 50% (fifty per
              cent) of the threshold used for the AML 1 verification, according
              to Directive (EU) 2015/2366 within 12 (twelve) months in tips sent
              to other users or customers, all further and currently the
              threshold exceeding tips are frozen until the user or customer
              completes AML 1 verification. AML 1 verification includes ID
              verification and various background scans.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped
              users&#39; or customers&#39; Carbonidoo-safe after the AML 1
              verification is approved.
            </p>
            <p>
              If a user or customer reaches the total amount of 50% (fifty per
              cent) of the threshold used for the AML 3 verification, according
              to Directive (EU) 2015/2366 within 12 (twelve) months in tips sent
              to other users or customers, all further and currently the
              threshold exceeding tips are frozen until the user or customer
              completes AML 3 verification. AML 3 verification includes a
              complete source of the funds&#39; background check.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped
              users&#39; or customers&#39; Carbonido-safe after the AML 3
              verification is approved.
            </p>
            <p>
              Additionally, all Carbonidoos used in tipping another user or
              customer must be used in wagers on the website before they can be
              withdrawn or tipped again. The carbonidos must be wagered twice
              (200%) in bets with a 2 (two) or higher multiplicator. Carbonido
              which do not meet these wager criteria can not be used to tip
              another user or customer on the website. Even when fully verified,
              a user or customer may not send tips exceeding the threshold used
              for AML 3 verification, according to Directive (EU) 2015/2366, by
              180% (one hundred eighty per cent) to another user or customer.
            </p>
            <p>
              Additionally, a user or customer can not send tips amounting to
              more than 360%(three hundred sixty per cent) of the threshold used
              for AML 3 verification, according to Directive (EU) 2015/2366 per
              month to other users or customers, even when fully verified.
            </p>
            <h1 className="my-5 text-lg font-bold">Receiving Tips</h1>
            <p>
              For a user or customer to receive any tips from another user or
              customer on the website, they must first complete the KYC
              verification described in our AML-KYC policy. The company freezes
              any attempted tip from a user or customer before completing the
              KYC Verification until the verification is completed.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped users
              or customers &#39; Carbonido-safe after the KYC Verification is
              approved.
            </p>
            <p>
              If a user or customer reaches the total amount of 50% (fifty per
              cent) of the threshold used for the AML 1 verification, according
              to Directive (EU) 2015/2366 within 12 (twelve) months in tips
              received from other users or customers, all further and currently
              the threshold exceeding tips are frozen until the user or customer
              completes AML 1 verification. AML 1 verification includes ID
              verification and various background scans.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped users
              or customers&#39; Carbonido-safe after the AML 1 verification is
              approved.
            </p>
            <p>
              If a user or customer reaches 50% (fifty per cent) of the
              threshold used for the AML 3 verification, according to Directive
              (EU) 2015/2366 within 12 (twelve) months in tips received from
              other users or customers, all further and currently the threshold
              exceeding tips are frozen until the user or customer completes AML
              3 verification. AML 3 verification includes a complete source of
              the funds&#39; background check.
            </p>
            <p>
              A frozen tip may take up to 24 hours to arrive at the tipped users
              or customers&#39; Carbonido-safe after the AML 1 verification is
              approved.
            </p>
            <p>
              Even when fully verified, a user or customer may not receive tips
              exceeding the threshold used for AML 3 verification, according to
              Directive (EU) 2015/2366, by 180% (one hundred eighty per cent)
              from another user or customer. Additionally, a user or customer
              can not receive tips amounting to more than 360%(three hundred
              sixty per cent) of the threshold used for AML 33 verification,
              according to Directive (EU) 2015/2366 per month from other users
              or customers, even when fully verified.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Withdrawing Funds received through tipping
            </h1>
            <p>
              Before a user or customer can withdraw funds received through a
              tip from another user or customer, they first must be used in
              wagers on the website. The Carbonidos must be wagered twice (200%)
              in bets with a 2 (two) or higher multiplicator. carbonidos which
              do not meet these wager criteria can not be used to tip another
              user or customer on the website.
            </p>
            <p>
              Additionally, the tipped user or customer can only use the same
              currency to withdraw the received funds as the tipping user or
              customer used as the deposit method.
            </p>
            <p>
              If the sending user or customer used Litecoin for the deposit, the
              receiving user or customer must choose Litecoin for the
              Withdrawal.
            </p>
            <p>
              If the sending user or customer used Bitcoin for the deposit, the
              receiving user or customer must choose Bitcoin for the Withdrawal.
            </p>
            <p>
              If the sending user or customer used Ethereum for the deposit, the
              receiving user or customer must choose Ethereum for the
              Withdrawal.
            </p>
            <p>
              The company&#39;s compliance team may do additional checks and
              verifications while also freezing all tips or withdrawal of tips
              if they suspect illicit activities or money laundering until all
              suspicions are cleared.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Anti-Money Laundering Support Systems
            </h1>
            <p>
              CarbonCasino AML team is supported by specialised third parties to
              entirely prevent and uncover any form of money laundering on{" "}
              http://www.{promptBrand("site")}and carboncasino.io
            </p>
            <h1 className="my-5 text-lg font-bold">Chainalysis</h1>
            <p>
              Chainalysis is a company that specialises in offering
              comprehensive cryptocurrency investigations and transaction
              monitoring solutions. Chainalysis is fully integrated into the
              company and its third-party partners&#39; AML system and
              establishes next-generation compliance frameworks. By providing
              more details, the AMLCO of carbon casino gets full control over
              the money flow connected involving carbon casino.
            </p>
            <p>
              aims to fully cooperate with carbon casino on keeping our
              user&#39;s data safe and secure. You may view their Privacy Policy
              at https://www.chainalysis.com/privacy-policy/
            </p>
            <h1 className="my-5 text-lg font-bold">
              How Chainalysis determines the risk
            </h1>
            <p>
              Risk is determined according to four main factors in Chainalysis:
            </p>
            <ul css={listStyle}>
              <li className="my-1">
                Regulatory environment: determining if the transaction is made
                from a country with a high-end AML/CTF framework or not, the
                base risk factor is adjusted accordingly. Additionally,
                Chainalysis checks if an entity is registered correctly.
              </li>
              <li className="my-1">
                Compliance efforts: If the exchange is known to have a high-end
                anti-money- laundering program or not and if the transaction is
                transparent regarding its business location
              </li>
              <li className="my-1">
                KYC processes: whether the transaction is made via an account
                requiring adequate KYC checks for registration
              </li>
              <li className="my-1">
                Illicit activity exposure: If an Account received cryptocurrency
                from illegal activities or sends cryptocurrencies for illegal
                activities.
              </li>
            </ul>
            <h1 className="my-5 text-lg font-bold">
              Risk categories on Chainalysis
            </h1>
            <p>
              Chainalysis puts all crypto accounts and wallets in one of four
              categories according to their risk factors and past transactions
              from that account or wallet.
            </p>
            <p>These four categories are:</p>
            <ul css={listStyle}>
              <li className="my-5">No risk indicators:</li>
              <li className="my-5">Medium-risk indicators</li>
              <li className="my-5">High-risk indicators</li>
              <li className="my-5">Severe-risk indicators</li>
            </ul>
            <h1 className="my-5 text-lg font-bold">Severe-risk indicators:</h1>
            <p>
              Accounts with severe-risk indicators are excluded from all
              Services on carbonbase.com and http://www.carbonbase.com.
              According to the judgment of the AMLCO, carbon casino has the
              right to immediately suspend an Account from all Services upon
              seeing Severe-risk indicators and report the User or Entity to the
              Authorities.
            </p>
            <p>Severe-risk indicators are given for:</p>
            <ul css={listStyle}>
              <li className="my-1">Selling Child abuse material.</li>
              <li className="my-1">Supporting or financing Terrorists</li>
              <li className="my-1">On official Sanctions lists.</li>
              <li className="my-1">
                Custom list of addresses identified from internal or external
                sources of suspicion
              </li>
            </ul>
            <h1 className="my-5 text-lg font-bold">High-risk indicators</h1>
            <p>
              Accounts with high-risk indicators will receive additional AML
              checks according to the judgment of the AMLCO. If he determines
              the risk to be related to illegal activities or money laundering
              carbon casino has the right to suspend an Account from all
              Services and report the user to the authorities for further
              investigations.
            </p>
            <p>High-risk Indicators are given for:</p>
            <ul css={listStyle}>
              <li className="my-1">Involvement in Darknet markets</li>
              <li className="my-1">Receiving Payments for Ransomware</li>
              <li className="my-1">Stolen cryptocurrency</li>
              <li className="my-1">
                Custom list of addresses identified from internal or external
                sources deemed to be associated with suspicious activity.
              </li>
            </ul>
            <h1 className="my-5 text-lg font-bold">Medium-risk indicators</h1>
            <p>
              Accounts with medium indicators will receive additional attention
              from the carbon casino Compliance team. The AMLCO may decide on
              future checks to evaluate the risk involved with transactions from
              that account. The AMLCO will determine if future business
              relations are possible with that user or if Risks are too severe.
            </p>
            <p>Medium-risk Indicators are given for:</p>
            <ul css={listStyle}>
              <li className="my-1">
                Gambling sites in jurisdictions that prohibit any form of
                gambling or when gambling sites are not adequately
                licensed/registered
              </li>
              <li className="my-1">
                Individual transactions are known to have significant exposure
                to high-risk counterparties.
              </li>
              <li className="my-1">SMixing Services (from entities)</li>
              <li className="my-1">P2P exchanges</li>
              <li className="my-1">
                High-risk exchanges (exchanges with criminal ties, sub-standard
                KAC, exposure to illicit activities)
              </li>
              <li className="my-1">
                Custom list of addresses identified from internal or external
                sources deemed to be associated with suspicious activity.
              </li>
            </ul>
            <h1 className="my-5 text-lg font-bold">No risk indicators:</h1>
            <p>
              Accounts with no risk indicators will receive standard AML checks.
              According to the judgment of the AMLCO further, checks may accord
              in considering the situation.
            </p>
            <h1 className="my-5 text-lg font-bold">Clustering </h1>
            <p>
              hainalysis provides the company with cutting edge technology in
              clustering cryptocurrency addresses. What is a cluster? A cluster
              is all addresses linked to one cryptocurrency wallet or account.
              Chainalysis looks at each address’s entire history linked to one
              account or wallet to determine the risk associated with that user.
              As soon as one cluster is identified, Chainalysis aims to identify
              the legal entity or person behind that cluster to crosscheck them
              for sanctions lists and illegal activities. Furthermore,
              Chainalysis has a full list of all transactions recorded since
              2013, which compares old transaction behaviors with new ones and
              link known entities together. This provides a high effectivity in
              identifying Accounts and wallets related to terror financing, dark
              markets, etc. ...
            </p>
            <h1 className="my-5 text-lg font-bold">Cryptocurrency tracking </h1>
            <p>
              As a part of the companies and their third-party partner’s risk
              management, every crypto deposit will be automatically checked for
              abuse. These automated checks done by Chainalyse are supplemented
              by manual checks done by the company’s AML Compliance team under
              the supervision of the AMLCO. These will be done via two different
              platforms simultaneously. These checks are done under the
              directions of EU: “Directive 2018/843 article 42ff. First Database
              is Bitcoin Abuse Network; you may see their Privacy Policy on
              [https://www.bitcoinabuse.com/](https://www.bitcoinabuse.com/)**
              The second Database is Crystal Blockchain; you may see their
              Privacy Policy on:
              [https://crystalblockchain.com/](https://crystalblockchain.com/)**
              Whenever a crypto wallet is shown as fraudulent in one of the two
              databases, the transaction will be automatically frozen. An
              employee of the company will look further into the case and
              investigate. If any fraud is detected, the authorities will be
              informed immediately. All accounts that were fraudulent, illegal,
              illicit activities or money laundering is suspected will be locked
              until the suspicion is cleared. All carbonidos will be frozen in
              the carbonido-safe during the investigation.
            </p>
            <h1 className="my-5 text-lg font-bold">SEON </h1>
            <p>
              SEON is a company that specialises in various anti-fraud solutions
              and provides an all in one solution to analyse user behaviour for
              potential fraudulent actions and connections to illicit
              activities. The services provided by SEON have been fully
              implemented into the company&#39;s anti-money laundering system
              and the anti-fraud system, as well as general prevention of
              illicit activities and breaches of our terms. SEON imposes the
              highest standards of data protection and aims to fully cooperate
              with carbon casino on keeping our users data safe and secure- You
              may view their Privacy Policy at:https://seon.io/privacy-policy/
              https://seon.io/privacy-policy/
            </p>
            <h1 className="my-5 text-lg font-bold">Risk Score</h1>
            <p>
              SEONs software provides a risk score for each user or customer
              registered on the website taking over 100 (one hundred) variables
              into account. The company&#39;s compliance team uses the
              individual risk score to assess if users or customers will be
              offered services on the website, which additional measurements may
              be required to ensure services can be safely provided and which
              users or customers may not connect to the website as a connection
              to illicit activities is likely.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Document and Data Verification
            </h1>
            <p>
              SEON&#39;s tools allow the compliance team to verify documents and
              crosscheck data through multiple external and internal databanks.
              Additionally, each user account on the website will be checked for
              linked public domains and social media to ensure the accuracy of
              the provided data as well as gathering further information on the
              account.
            </p>
            <p>
              All user data collected on the website and submitted through
              customers will be analysed through the specialised tools SEON
              provides and checked for accuracy, connection to illicit
              activities, and previous violations of our terms of service.
              Furthermore, the data will only be transferred encrypted through a
              secure API, ensuring the user can only be accessed by authorised
              personal.
            </p>
            <h1 className="my-5 text-lg font-bold">Machine Learning</h1>
            <p>
              SEON’s anti-fraud tools automatically adjust to behaviours
              observed by our users and compliance team to catch fraud and other
              illicit activities before they happen. The anti-fraud team
              overseen by the AMLCO will continue to look for unusual behaviour
              and report it without delay to the system for further improvement.
              Through the wide experience of the company’s compliance team and
              SEON’s automated tools, a constantly, automatically improving
              system is established. According to a risk-based view and broad
              experience, the human employees will recheck all checks done
              previously by the AI of SEON’s anti-fraud software. The company’s
              compliance team may redo or do additional checks according to the
              situation. SEON’s machine learning is constantly improved by their
              data scientist cooperating with the company’s data scientists and
              compliance team. The AI will look for unusual behaviour including,
              but not limited to depositing and withdrawing without longer
              betting sessions, attempts to use a different cryptocurrency
              wallet, or different bank account for deposit and withdrawal,
              nationality changes, currency changes, behaviour and activity
              changes, as well as checks if its original owner uses the account,
              or someone else.
            </p>
            <h1 className="my-5 text-lg font-bold">Additional Measurements</h1>
            <p>
              To prevent money laundering and other illegal activities, a user
              or customer must use cryptocurrency withdrawal methods for
              cryptocurrency deposit methods and cash withdrawal options for
              cash deposit methods. Further information on withdrawal from the
              website can be found in the Withdrawal section of the companies
              terms of service. The company’s compliance team may do additional
              checks and verifications or redo previously completed checks and
              verifications at any time at their sole discretion if deemed
              necessary. Enterprise-wide risk assessment As part of its
              risk-based approach, the company, in support of its third-party
              partners, has conducted an AML “Enterprise-wide risk assessment”
              (EWRA) to identify and understand risks specific to the company,
              its website, its services offered on it, and its business lines.
              The AML risk policy is determined after identifying and
              documenting the risks inherent to its business lines, such as the
              services the website offers. The users to whom services are
              offered, transactions performed by these users, delivery channels
              used by the bank and crypto wallet, the financial institution’s
              geographic locations, customers and transactions, and other
              qualitative and emerging risks. The identification of AML risk
              categories is based on the company’s understanding of regulatory
              requirements, regulatory expectations, and industry guidance.
              Additional safety measures are taken to take care of the
              additional risks the world wide web brings with it and the online
              gambling industry. The EWRA is yearly reassessed. The EWRA takes
              into account the additional risks of cryptocurrencies and online
              transactions.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Ongoing transaction monitoring
            </h1>
            <p>
              AML-Compliance ensures that an “ongoing transaction monitoring” is
              conducted to detect unusual or suspicious transactions compared to
              the customer profile. This transaction monitoring is conducted on
              three levels : 1. The First Line of Control: The company and its
              third-party partners work solely with trusted Payment Service
              Providers who all have effective KYC and AML policies in place to
              prevent the large majority of suspicious deposits onto the website
              from taking place without proper execution of KYC procedures onto
              the potential customer. 1. The Second Line of Control: The company
              and its third-party partners make their network-aware so that any
              contact with the customer or player or authorised representative
              must give rise to the exercise of due diligence on transactions on
              the account concerned. In particular,these include :
            </p>
            <p>
              - Requests for the execution of financial transactions on the
              account
              <br /> - Requests concerning means of payment or services on the
              account;
            </p>
            <p>
              The multi-step verification with adjusted risk management should
              also provide all necessary information about all customers of the
              company and visitors of the website at all times. All transactions
              must be overseen by employees overwatched by the AMLCO, who is
              overwatched by the general manager. The specific transactions
              submitted to the customer support manager, possibly through their
              Compliance Manager, must also be subject to due diligence.
              Determination of the unusual nature of one or more transactions
              essentially depends on a subjective assessment concerning the
              knowledge of the customer (KYC), their financial behavior, and the
              transaction counterparty. These checks will be done by an
              automated system, while an employee crosschecks them for
              additional security. The transactions observed on customer and
              user accounts for which it is difficult to understand the lawful
              activities and origin of funds must therefore rapidly be
              considered atypical (as they are not directly justifiable). Any
              company staff member and third-party partner staff member must
              inform the AML division of any atypical transactions they observe
              and cannot attribute to a lawful activity or source of income
              known to the customer. 1. The third Line of Control: As the last
              line of defense against AML on http://www.carbonbase.com
              http://www.carbonbase.com/ will do manual checks on all suspicious
              and higher risk users to fully prevent money laundering. If fraud,
              illegal or illicit activities, or money laundering is found, the
              authorities will be informed. The account will be immediately
              locked, and all carbonidos in the carbonido- safe indefinitely
              frozen.
            </p>
            <h1 className="my-5 text-lg font-bold">
              Reporting of Suspicious transactions on the website
            </h1>
            <p>
              In its internal procedures, the company describes in precise
              terms, for its staff members’ attention, when it is necessary to
              report and how to proceed with such reporting. Reports of atypical
              transactions are analysed within the AML Compliance team following
              the precise methodology described in the internal procedures.
              Depending on the result of this examination and based on the
              information gathered, the AML team :
            </p>
            <p>
              - will decide whether it is necessary or not to send a report to
              the FIU, following the legal obligations provided in the Law of 18
              September 2017. <br />- will decide whether or not it is necessary
              to terminate the business relations with the customer.
            </p>
            <h1 className="my-5 text-lg font-bold">SAR- Report</h1>
            <p>
              The AMLCO will fill any Sar-report (suspicious activity report) to
              the government of curacao and any local authorities involved in a
              potential case of money laundering or other illicit activities.
              The AMLCO will follow the guidelines of Curacao and the guidelines
              of the European Union when deciding if a Sar-Report is necessary.
              Criminal investigations Suppose the carbon casino compliance team,
              support team, or VIP Manager detect any illicit activities besides
              money laundering. In that case, the AMLCO will be informed
              immediately. The AMLCO will get in touch with the local
              authorities responsible for the prosecution of any detected
              crimes. The company and the website staff members aim to fully
              comply and cooperate with the local executive forces and
              authorities in the matter of all criminal investigations under the
              lead of their AMLCO.
            </p>
            <h1 className="my-5 text-lg font-bold">Procedures</h1>
            <p>
              The AML rules, including minimum KYC standards, will be translated
              into operational guidance or procedures that are available on the
              Intranet site of https://www.carboncasino.com . In case of
              conflict, the English guidelines will be exclusively used.
            </p>
            <h1 className="my-5 text-lg font-bold">Record keeping</h1>
            <p>
              Records of data obtained for identification must be kept a minimum
              of ten years after the business relationship has ended, as
              required by law. Records of all transaction data must be kept for
              at least ten years following the transactions’ carrying-out or the
              end of the business relationship. These data will be safe,
              encrypted, stored offline and online. Further information
              regarding the company’s storage of data and the protection of your
              personal data can be found in the company’s privacy policy.
            </p>
            <h1 className="my-5 text-lg font-bold">Training</h1>
            <p>
              he company and its trusted third-party partners use advanced AI
              and machine learning to further improve its AML defense. Also,
              trained developers constantly improve AI to prevent any security
              flaws. A specialised Developer is in charge of constant
              improvements. While at the same time, human employees will check
              and overlook the AI at any time and make manual controls on a
              risk-based approval for which they get special training. Its usage
              reflects the training and awareness program:
            </p>
            <ul css={listStyle}>
              <li className="my-5">
                A mandatory AML training program following the latest regulatory
                evolutions, for all in touch with finances.
              </li>
              <li className="my-5">
                Academic AML learning sessions for all new employees This
                training program’s content has to be established under the kind
                of business the trainees are working for and the posts they
                hold. These sessions are given by an AML-specialist working in
                the Carbon Games N.V. AML Compliance team.
              </li>
            </ul>
            <h1 className="my-5 text-lg font-bold">Auditing</h1>
            <p>Internal audit regularly establishes missions AML activities.</p>
            <h1 className="my-5 text-lg font-bold">Data Security</h1>
            <p>
              All data given by any user or customer will be kept secure, will
              not be sold or given to anyone else. Only if forced by law or the
              exceptions mentioned in the companies Privacy Policy to prevent
              money laundering data may be shared with the AML- authority of the
              affected state. Further information you can find in the companies
              Privacy Policy The company and http://www.{promptBrand("site")}
              will follow all guidelines and rules of the data protection
              directive (officially Directive 95/46/EC)
            </p>
            <h1 className="my-5 text-lg font-bold">
              Case Reporting and Punishment:
            </h1>
            <p>
              When dealing with a case of money laundering or any suspicion of
              money laundering or fraud, illicit or illegal activity, the
              company may freeze any user account and lock all carbonidos in the
              carbonido-safe on it until the suspicion is cleared. The AMLCO may
              ask any user for additional information before clearing the
              suspicion. The AMLCO will forward any case of money laundering to
              the authorities. The company and its third- party partners are not
              responsible for any legal consequences a user may face for illegal
              or illicit activities. The company has the right to ban any user
              immediately and suspend any account from its websites
              carbonbase.com, and {promptBrand("site")} and all services offered
              on it, when detecting money laundering, or a user fails to comply
              with the companies AML- KYC Policy or fails to comply with the AML
              Compliance team or AMLCO, or any other illegal or illicit activity
              is found. The company and its partners aim to fully cooperate with
              the authorities in the shared fight against illicit and illegal
              activities and money laundering.
            </p>
            <h1 className="my-5 text-lg font-bold">Contact us</h1>
            <p>
              If you have any questions about our AML and KYC policy, please
              contact us:{" "}
              <a
                href="https://help.casinocarbon.com/en/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Contact support team
              </a>
            </p>
            <p>
              If you have any complaints about our AML and KYC policy or the
              checks and verifications done on your account and your person,
              please contact us:
            </p>
            <p>
              By E-Mail:{" "}
              <a href="mailto:complaints@carbonteam.io" className="mx-5">
                complaints@carbonteam.io
              </a>
            </p>
            <p>
              Or by clicking here:{" "}
              <a
                href="https://help.casinocarbon.com/en/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Contact support team
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default AmlPolicy
