import React, { useState, useEffect } from "react"
import { useSearchParams, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"
import { GamesCatalog } from "../GamesCatalog"
import GamesView from "../GameView"
import gamesCategories from "./GamesCategories"
import PageLink from "../PageLink"
// import arrowRightTertiary from "../../assets/icons/arrow-right-tertiary.svg"
import themes from "../../themes"
import { getBrandFromUrl, imageDistributed } from "../../helpers"

const LinkToCategoryPage = ({ url }) => {
  const { t } = useTranslation()

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <PageLink
      classNames="flex flex-col justify-center items-center rounded-3xl cursor-pointer shadow-2xl h-[200px] games-catalog-card"
      to={url}
      style={{ backgroundColor: themeColors?.lightPrimaryTint, color: "white" }}
    >
      <span className="text-xl font-bold">{t("Load More")}</span>
      <img
        fetchpriority="low"
        src={imageDistributed("loadMoreArrow")}
        alt="array-right-tertiary"
        className="w-auto h-[70px] mt-4"
      />
    </PageLink>
  )
}
LinkToCategoryPage.propTypes = {
  url: PropTypes.string.isRequired,
}

const GamesCatalogDesktop = ({ countryCode }) => {
  const [searchParams] = useSearchParams()

  const [defaultCategorySelected, setDefaultCategorySelected] = useState("")
  const scrolledRef = React.useRef(false)
  const { hash } = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [hash])

  useEffect(() => {
    const category = searchParams.get("category")
    if (category) {
      const gamesCategorieSelected = gamesCategories.find(
        (element) => element.category === category
      )
      if (gamesCategorieSelected) {
        setDefaultCategorySelected(gamesCategorieSelected.category)
      } else {
        setDefaultCategorySelected("popular-games")
      }
    }
  }, [searchParams])

  return (
    <MediaQuery minWidth={960}>
      <div
        className="mt-10 scrollbar scrollbar-thumb-tertiary scrollbar-track-auto scrollbar-thumb-[2px]"
        id="games-catalog"
      >
        <GamesCatalog
          defaultCategory="popular-games"
          activatedCategory={defaultCategorySelected}
        >
          <GamesCatalog.Categories>
            {gamesCategories.map((category) => (
              <GamesCatalog.Categories.Item
                id={category.category}
                imageSource={category.imageSource}
              >
                {t(category.title)}
              </GamesCatalog.Categories.Item>
            ))}
          </GamesCatalog.Categories>
          <GamesCatalog.Content>
            <GamesCatalog.Content.Item id="popular-games" title="Popular Games">
              <GamesView tag="popular" display={11} countryCode={countryCode} />
              <LinkToCategoryPage url="/category/popular/" />
            </GamesCatalog.Content.Item>
            <GamesCatalog.Content.Item id="slot-games" title="Slot Games">
              <GamesView tag="slot" display={11} countryCode={countryCode} />
              <LinkToCategoryPage url="/category/slot/" />
            </GamesCatalog.Content.Item>

            <GamesCatalog.Content.Item id="table-games" title="Table Games">
              <GamesView tag="table" display={11} countryCode={countryCode} />
              <LinkToCategoryPage url="/category/table/" />
            </GamesCatalog.Content.Item>
            <GamesCatalog.Content.Item id="live-games" title="Live Games">
              <GamesView tag="live" display={11} countryCode={countryCode} />
              <LinkToCategoryPage url="/category/live/" />
            </GamesCatalog.Content.Item>
            <GamesCatalog.Content.Item id="crash-games" title="Crash Games">
              <GamesView tag="crash" display={11} countryCode={countryCode} />
              <LinkToCategoryPage url="/category/crash/" />
            </GamesCatalog.Content.Item>
          </GamesCatalog.Content>
        </GamesCatalog>
      </div>
    </MediaQuery>
  )
}

GamesCatalogDesktop.propTypes = {
  countryCode: PropTypes.string.isRequired,
}

export default GamesCatalogDesktop
