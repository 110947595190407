import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import carbonGamingIcon from "../assets/icons/logo-tab-carbon-gaming.png"
import redemptionBitsIcon from "../assets/icons/logo-tab-redemption-bits.png"
import { getBrandFromUrl } from "../helpers"

const tabImages = {
  "www.carbongaming.net": carbonGamingIcon,
  "www.redemptionbits.com": redemptionBitsIcon,
}

const Header = ({ title }) => {
  const brand = getBrandFromUrl()

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={tabImages[brand]} />
    </Helmet>
  )
}
Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Header
