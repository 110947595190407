import React from "react"
import PropTypes from "prop-types"
import Countdown, { zeroPad } from "react-countdown"

const CountDown = ({ onFinishCountDown, isMounted, duration }) => (
  <Countdown
    key={1}
    zeroPadTime={2}
    date={Date.now() + duration * 1000}
    precision={0}
    autoStart={false}
    onComplete={onFinishCountDown}
    renderer={({ minutes, seconds, api }) => {
      if (!isMounted) {
        api.start()
      } else {
        api.stop()
      }
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )
    }}
  />
)

CountDown.defaultProps = {
  onFinishCountDown: Function.prototype,
  isMounted: false,
  duration: 60,
}
CountDown.propTypes = {
  onFinishCountDown: PropTypes.func,
  isMounted: PropTypes.bool,
  duration: PropTypes.number,
}

export default CountDown
