import React from "react"
import PropTypes from "prop-types"

const heightTable = {
  full: "full",
  32: "32",
}
const Banner = ({ children, height }) => (
  <div className={`w-full rounded-md banner h-${heightTable[height]}`}>
    {children}
  </div>
)
Banner.defaultProps = {
  height: 32,
}
Banner.propTypes = {
  children: PropTypes.element.isRequired,
  height: PropTypes.string,
}
export default Banner
