import React from "react"
import { useTranslation } from "react-i18next"
// import dayjs from "dayjs"
import { css } from "@emotion/react"
import themes from "../themes"
/** @jsxImportSource @emotion/react */
import { Tabs } from "./Tabs"
import roundedLogo from "../assets/images/rounded-logo.svg"
import { getBrandFromUrl, pickRandomUsers } from "../helpers"
import { gamesStats } from "../constants/games"

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`
// const lengthOfUsers = [1, 2, 3, 4, 5]

// TODO :the data in the leader board is static , when it becomes dynamic , it will be props

const LeaderBoard = () => {
  const { t } = useTranslation()
  const gamesStatsPickedSevenDays = pickRandomUsers(gamesStats)
  const gamesStatsPickedAllTime = pickRandomUsers(gamesStats)

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <section
      className="py-12 about-section  text-white"
      // style={{
      //   backgroundColor: themeColors?.secondary,
      //   color: "white",
      // }}
    >
      <div className="container-max-width">
        <div className="mt-7 xl:px-0 xs:px-6">
          <h1 className="mb-7 xl:text-3xl xs:text-xl">{t("Leaderboard")}</h1>
          <div
            className="pt-8 pb-1"
            style={{
              backgroundColor: themeColors?.secondary,
              color: "white",
            }}
          >
            <Tabs defaultIndex={0}>
              <Tabs.Header>
                <div>
                  <Tabs.Header.Item className="w-full">
                    {t("Last 7 days")}
                  </Tabs.Header.Item>
                  <Tabs.Header.Item>{t("All Time")}</Tabs.Header.Item>
                </div>
              </Tabs.Header>
              <Tabs.Body>
                <Tabs.Body.Item>
                  <div
                    className="w-full flex-col xl:h-[500px] xl:overflow-auto xs:h-[550px] xs:overflow-auto scrollbar scrollbar-thumb-tertiary scrollbar-track-auto scrollbar-thumb-[2px]"
                    css={scrollbarStyle}
                  >
                    {gamesStatsPickedSevenDays.map((stat) => (
                      <div
                        key={stat.user}
                        className="mt-3 w-full xl:flex xl:flex-row xl:justify-between xl:items-center xs:flex xs:flex-col bg-primary py-4 xl:px-7 xs:px-0  border-2 border-tertiary "
                      >
                        <div className="flex items-center xl:w-1/4 xl:border-r-[1px] xl:border-white  xs:w-full xs:p-3 xl:p-0 ">
                          <img
                            fetchpriority="low"
                            src={roundedLogo}
                            alt="rounded-logo"
                            className="pr-4"
                          />
                          <p className="flex flex-col">
                            <span className="text-xs">{stat.user}</span>{" "}
                            <span className="">{stat.time}</span>
                          </p>
                        </div>
                        <div className=" xl:w-1/4 xs:w-full xl:border-t-0 xs:border-t-[1px] xs:border-white xs:py-6 xl:py-0 px-6 h-full  flex items-center">
                          <p className="">
                            {t("Payout")}
                            <span className="text-validGreen px-4">
                              {stat.payout}
                            </span>
                          </p>
                        </div>

                        <div className="xs:flex xs:flex-row xl:flex xl:w-1/2 xs:w-full">
                          <div className=" xs:w-1/2 px-6 xl:border-x-[1px] xs:border-r-[1px] border-white xl:w-1/2">
                            <p className="flex flex-col">
                              {t("Wager")} <span>{stat.wager}</span>
                            </p>
                          </div>
                          <div className="xl:w-1/2 xs:w-1/2 px-6">
                            <p className="flex flex-col">
                              {t("Mult")}
                              <span className="text-validGreen">
                                {stat.mult}x
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tabs.Body.Item>
                <Tabs.Body.Item>
                  <div
                    className="w-full flex-col xl:h-[500px] xl:overflow-auto xs:h-[550px] xs:overflow-auto scrollbar scrollbar-thumb-tertiary scrollbar-track-auto scrollbar-thumb-[2px]"
                    css={scrollbarStyle}
                  >
                    {gamesStatsPickedAllTime.map((stat) => (
                      <div
                        key={stat.user}
                        className="mt-3 w-full xl:flex xl:flex-row xl:justify-between xl:items-center xs:flex xs:flex-col bg-primary py-4 xl:px-7 xs:px-0  border-2 border-tertiary "
                      >
                        <div className="flex items-center xl:w-1/4 xl:border-r-[1px] xl:border-white  xs:w-full xs:p-3 xl:p-0 ">
                          <img
                            fetchpriority="low"
                            src={roundedLogo}
                            alt="rounded-logo"
                            className="pr-4"
                          />
                          <p className="flex flex-col">
                            <span className="text-xs">{stat.user}</span>{" "}
                            <span className="">{stat.time}</span>
                          </p>
                        </div>
                        <div className=" xl:w-1/4 xs:w-full xl:border-t-0 xs:border-t-[1px] xs:border-white xs:py-6 xl:py-0 px-6 h-full  flex items-center">
                          <p className="">
                            {t("Payout")}
                            <span className="text-tertiary px-4">
                              {stat.payout}
                            </span>
                          </p>
                        </div>

                        <div className="xs:flex xs:flex-row xl:flex xl:w-1/2 xs:w-full">
                          <div className=" xs:w-1/2 px-6 xl:border-x-[1px] xs:border-r-[1px] border-white xl:w-1/2">
                            <p className="flex flex-col">
                              {t("Wager")} <span>{stat.wager}</span>
                            </p>
                          </div>
                          <div className="xl:w-1/2 xs:w-1/2 px-6">
                            <p className="flex flex-col">
                              {t("Mult")}
                              <span className="text-validGreen">
                                {stat.mult}x
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tabs.Body.Item>
              </Tabs.Body>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LeaderBoard
