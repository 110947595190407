import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import { useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faTrophy,
  faDice,
  faGifts,
} from "@fortawesome/free-solid-svg-icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { useQuery } from "react-query"
import {
  Card as MTCard,
  CardHeader,
  Typography,
  Avatar,
} from "@material-tailwind/react"
import countries from "../../utils/countries"
import {
  getUser,
  getTwoFactorAuthenticatorQrCodeAndSecret,
  postTwoFactorAuthenticatorEnable,
  postTwoFactorAuthenticatorDisable,
} from "../../actions/user"
import formSchema from "../../model/changePasswordSchema"
import verificationSchema from "../../model/verificationLevel1Schema"
import {
  changePassword,
  saveKycLevel1,
  emailVerification,
  postLogOut,
} from "../../actions/auth"
import { Modal } from "../../components/Modal"
import { SideNav } from "../../components/VerticalNav"
import { Accordion } from "../../components/Accordion"
import Input from "../../components/Input"
import { toast } from "../../components/Toast"
import { logger, imageDistributed, getBrandFromUrl } from "../../helpers"
import errorCodes from "../../constants/error-codes"
import SelectField from "../../components/SelectField"
import Prompt2FaPINCard from "../../components/Prompt2FAPINCard"
import Button from "../../components/Button"
import Card from "../../components/Card"
import Toggle from "../../components/Toggle"
import Spinner from "../../components/Spinner"
import Tooltip from "../../components/Tooltip"
import IconsButton from "../../components/IconsButton"
import twoFactorAuthCodeSchema from "../../model/twoFactorCodeSchema"
import BonusBanner from "../../components/BonusBanner"
// import bonusActivationAsset from "../../assets/images/bonusCode/activate-bonus-asset.png"
import InfoCard from "../../components/InfoCard"
import MetaEventService from "../../services/MetaEventService"
import Checkbox from "../../components/Checkbox"
import themes from "../../themes"

// TODO : put this component below in separate file for the feature

const CheckEmailVerification = ({
  isVerified,
  isSent,
  onSendEmailClick,
  isLoading,
}) => {
  const { t } = useTranslation()
  if (isVerified) {
    return (
      <span className="text-base text-validGreen">
        <FontAwesomeIcon icon={faCheckCircle} /> {t("Email has been Verified")}
      </span>
    )
  }
  if (!isVerified && isSent) {
    return (
      <span className="text-base text-validGreen">
        <FontAwesomeIcon icon={faCheckCircle} /> {t("Email has been sent")}
      </span>
    )
  }
  return isLoading ? (
    <Spinner size="small" />
  ) : (
    <Button.Tertiary theme="light" size="medium" onClick={onSendEmailClick}>
      <span className="text-sm">{t("Send E-mail")}</span>
    </Button.Tertiary>
  )
}

CheckEmailVerification.propTypes = {
  isVerified: PropTypes.bool.isRequired,
  isSent: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSendEmailClick: PropTypes.func.isRequired,
}

const serviceName = "account"
const tabNames = {
  SECURITY: "security",
  VERIFICATION: "verification",
  Information: "information",
}
const Disable2FACard = ({ setIsDisableCardShown, setSwitched }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose2FADisable = () => {
    setIsDisableCardShown(false)
  }

  const handleDisableTwoFactor = async (pin) => {
    setIsDisableCardShown(false)
    const result = await postTwoFactorAuthenticatorDisable(pin)
    if (result?.status === 401 || result?.status === 400) {
      toast.error(
        t("Error of validation 2FA code."),
        t(
          "The 2FA authentication code you are using is invalid. Try with a valid code."
        )
      )
    }
    if (result === 204) {
      setSwitched(false)
      dispatch(getUser())
    }
  }

  return (
    <Prompt2FaPINCard
      title="Disable Two-Factor Authentication"
      onCancel={handleClose2FADisable}
      onSubmit={handleDisableTwoFactor}
    />
  )
}

Disable2FACard.propTypes = {
  setIsDisableCardShown: PropTypes.func.isRequired,
  setSwitched: PropTypes.func.isRequired,
}
const QRCode = ({ url }) => (
  <a href="https://www.authenticatorapi.com">
    <img
      fetchpriority="low"
      loading="lazy"
      decoding="async"
      src={url}
      border={0}
      alt=""
    />
  </a>
)
QRCode.propTypes = {
  url: PropTypes.string.isRequired,
}
const Enable2FACard = ({ setIsEnableCardShown, setSwitched }) => {
  const formOptions = { resolver: yupResolver(twoFactorAuthCodeSchema) }
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [qrCodeTwoFactorAuthenticator, setQrCodeTwoFactorAuthenticator] =
    useState("")

  const [twoFactorAuthenticatorSecret, setTwoFactorAuthenticatorSecret] =
    useState("")

  // const [isSuccess, setIsSuccess] = useState(false)

  const handleClose2FAEnable = () => {
    setIsEnableCardShown(false)
  }

  const handleEnableTwoFactor = async (authCode) => {
    const result = await postTwoFactorAuthenticatorEnable(
      twoFactorAuthenticatorSecret,
      authCode
    )
    if (result.status === 401 || result.status === 400) {
      toast.error(
        t("Error of validation 2FA code."),
        t(
          "The 2FA authentication code you are using is invalid. Try with a valid code."
        )
      )
    }
    if (result === 200) {
      dispatch(getUser())
      setIsEnableCardShown(false)
      setSwitched(true)
    }
  }

  const handleEnable2faCode = (e) => {
    e.preventDefault()
    handleEnableTwoFactor()
  }

  const handleEnable2FAauth = (data) => {
    handleEnableTwoFactor(data.authCode)
  }

  // using useQuery
  const { isLoading } = useQuery({
    queryKey: ["getTwoFactorAuthenticatorQrCodeAndSecret"],
    queryFn: getTwoFactorAuthenticatorQrCodeAndSecret(),
    onSuccess: ({ data }) => {
      const { qrcode_image_source: qrCodeImageSource, secret } = data
      setQrCodeTwoFactorAuthenticator(qrCodeImageSource)
      setTwoFactorAuthenticatorSecret(secret)
    },
    onError: (error) => {
      logger(error)
    },
  })

  return (
    <div className="fixed  inset-0 bg-overlayPurple bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="w-full h-full md:flex md:justify-center md:items-center xs:py-16">
        <Card>
          <div className="xs:h-screen xs:overflow-auto xl:h-auto">
            <h1 className="font-bold">{t("Two-Factor Authentications")}</h1>
            <p className="text-sm my-2">
              {t(
                "To enable two-factor authentication on login, scan this QR code with an app like Authy or Google Authenticator. On each login, you will be required to use the code created from your app to login."
              )}
            </p>
            <div className="w-full flex justify-center my-3 ">
              <div className="w-40 h-40 flex justify-center items-center">
                {isLoading ? (
                  <Spinner theme="dark" />
                ) : (
                  <QRCode url={qrCodeTwoFactorAuthenticator} />
                )}
              </div>
            </div>
            <p className="text-sm my-2">
              {t(
                "Enter the 6-digit code from your authenticator application to continue"
              )}
            </p>
            <div className="w-full">
              <span className="text-sm text-gray-500">Two-Factor Secret</span>
              <Button.Tertiary>
                <span className="text-black text-sm">
                  {twoFactorAuthenticatorSecret}
                </span>
              </Button.Tertiary>
            </div>
            <p className="text-gray-500 text-xs my-2">
              {t(
                "We highly recommend saving your secret somewhere safe (paper is best). In case you ever lose your phone, you can always enter in your secret manually."
              )}
            </p>
            <form className="my-2">
              <Controller
                name="authCode"
                control={control}
                render={({ field, formState }) => (
                  <Input
                    label="Enter Two-Factor Code"
                    id="2fa-pin"
                    forCopy
                    {...field}
                    error={formState.errors?.authCode}
                  />
                )}
              />
              <span className="text-red-700 text-xs">
                {errors?.authCode && t(errors.authCode.message)}
              </span>
              <div className="flex justify-end mt-2">
                <Button.Secondary size="small" onClick={handleClose2FAEnable}>
                  <span className="text-black">{t("Cancel")}</span>
                </Button.Secondary>
                <Button.Primary
                  size="small"
                  onClick={handleSubmit(handleEnable2FAauth)}
                  onSubmit={handleEnable2faCode}
                >
                  {t("Continue")}
                </Button.Primary>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </div>
  )
}

Enable2FACard.propTypes = {
  setIsEnableCardShown: PropTypes.func.isRequired,
  setSwitched: PropTypes.func.isRequired,
}

const twoFaGuideHelpArticles = {
  fr: "https://help.casinocarbon.com/fr/articles/6420269-guide-2fa",
  en: "https://help.casinocarbon.com/en/articles/6420269-2fa-guide",
  sp: "https://help.casinocarbon.com/es/articles/6420269-guia-2fa",
  it: " https://help.casinocarbon.com/it/articles/6420269-guida-2fa",
}

const SecurityTab = ({ activeTab }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false)

  const { is2FAActive } = useSelector((state) => state.user)
  const { isVerified } = useSelector((state) => state.auth)

  const { email } = useSelector((state) => state.auth)

  const [successfully, setSuccessfully] = useState(false)

  const [switched, setSwitched] = useState(false)

  const [isEnableCardShown, setIsEnableCardShown] = useState(false)

  const [isDisableCardShown, setIsDisableCardShown] = useState(false)

  const [showPasswordIn, setShowPasswordIn] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [showConfirmPasswordIn, setShowConfirmPasswordIn] = useState(false)

  const handleShowPasswordInModal = () => {
    setShowPasswordIn(!showPasswordIn)
  }

  const handleShowConfirmPasswordInModal = () => {
    setShowConfirmPasswordIn(!showConfirmPasswordIn)
  }

  const handleSendEmail = async () => {
    setIsLoading(true)
    const sendEmailVerification = await emailVerification(email)
    if (sendEmailVerification === 200) {
      setIsVerificationEmailSent(true)
      setIsLoading(false)
      toast.success(
        t("Check your email inbox !"),
        t("The email has been sent successfully.")
      )
    } else {
      toast.error(
        t("E-mail sending error"),
        t("Your recovery e-mail couldn’t be sent, please try again later.")
      )
      setIsLoading(true)
    }
  }

  const formOptions = { resolver: yupResolver(formSchema) }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = async (data) => {
    const result = await changePassword(data.password)
    if (result === "Password updated successfully") {
      reset()
      /* send notification */
      setSuccessfully(true)
      toast.success(
        t("Password changed"),
        "Your password has been changed successfully!"
      )
      const response = dispatch(await postLogOut())
      if (response) {
        setSuccessfully(false)
        navigate("/")
      }
    } else if (
      result.status === 400 &&
      result.data.code === errorCodes.CHANGE_PASSWORD_SAME_AS_CURRENT
    ) {
      reset()
      toast.error(
        "Changing password",
        "Your new password cannot be the same as your current password."
      )
    }
  }

  /* 2fa */
  const handleOpen2FAEnable = async (value) => {
    if (value) {
      /* disable */
      if (is2FAActive === false) {
        setIsEnableCardShown(true)
      }
    } else {
      setIsDisableCardShown(true)
    }
  }

  useEffect(() => {
    setSwitched(is2FAActive)
  }, [is2FAActive])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  const languageSelected = i18n.language?.split("-")[0]

  return (
    activeTab === tabNames.SECURITY && (
      <div className="px-5 w-full pb-10 xl:h-auto">
        <div className=" text-md  mb-10">
          <h1
            className="text-md leading-5 font-medium my-2"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Security")}
          </h1>
          <form className="w-full">
            <Input type="text" id="recovery-email" label={email} disabled />
            <div className=" w-full flex justify-end py-2">
              <CheckEmailVerification
                isVerified={isVerified}
                isSent={isVerificationEmailSent}
                isLoading={isLoading}
                onSendEmailClick={handleSendEmail}
              />
            </div>
          </form>
        </div>
        <div className="mt-5 ">
          <h1
            className="text-md leading-5 font-medium my-2"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Change Password")}
          </h1>
          <div>
            <form>
              <div className="py-5 my-3">
                <div className="relative">
                  <Controller
                    control={control}
                    name="password"
                    render={({ field, formState }) => (
                      <Input
                        type={showPasswordIn ? "text" : "password"}
                        label="New Password"
                        id="password-change"
                        {...field}
                        error={formState.errors?.password}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <div className="absolute bottom-[-8px] right-0 ">
                    {showPasswordIn ? (
                      <Tooltip content="Hide password">
                        <span>
                          <IconsButton onClick={handleShowPasswordInModal}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEye}
                                className=" text-black w-5"
                              />
                            </span>
                          </IconsButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip content="Show password">
                        <span>
                          <IconsButton onClick={handleShowPasswordInModal}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className=" text-black w-5"
                              />
                            </span>
                          </IconsButton>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <p className="text-xs absolute text-red-500">
                    {errors?.password && errors.password.message}
                  </p>
                </div>
              </div>
              <div className="relative">
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  render={({ field, formState }) => (
                    <Input
                      type={showConfirmPasswordIn ? "text" : "password"}
                      autoCapitalize="none"
                      id="confirm-new-password"
                      label="Confirm New Password"
                      {...field}
                      error={formState.errors?.passwordConfirmation}
                    />
                  )}
                />
                <div className="absolute bottom-[-8px] right-0 ">
                  {showConfirmPasswordIn ? (
                    <Tooltip content="Hide password">
                      <span>
                        <IconsButton onClick={handleShowConfirmPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEye}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Show password">
                      <span>
                        <IconsButton onClick={handleShowConfirmPasswordInModal}>
                          <span>
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              className=" text-black w-5"
                            />
                          </span>
                        </IconsButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <p className="text-xs absolute text-red-500">
                  {errors?.passwordConfirmation &&
                    errors.passwordConfirmation.message}
                </p>
              </div>
              {successfully ? (
                <span className="text-base text-validGreen">
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  {t("Password updated successfully")}
                </span>
              ) : (
                <div className="flex justify-end w-full mb-7 py-2">
                  <Button.Tertiary
                    onSubmit={handleSubmit(onSubmit)}
                    theme="light"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="text-sm">{t("Change Password")}</span>
                  </Button.Tertiary>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="mt-5 ">
          <h1
            className="text-md leading-5 font-medium my-2"
            style={{ color: themeColors?.tertiary }}
          >
            {t("Two-Factor Authentications")}
          </h1>
          <div>
            <p className="flex justify-start Items-center">
              <Toggle onChange={handleOpen2FAEnable} defaultStatus={switched} />
              <span className="ml-3">
                {t("Enable Two-Factor Authentication")}
              </span>
            </p>
            <p className="text-gray-400 text-sm py-2">
              {t(
                "Enabling two-factor authentication will require a code when updating or viewing sensitive information."
              )}{" "}
              <a
                href={`${twoFaGuideHelpArticles[languageSelected]}`}
                className="underline"
                target="_blank"
                rel="noreferrer"
                style={{ color: themeColors?.tertiary }}
              >
                {" "}
                {t("Need Help? Read our 2FA Guide")}
              </a>
            </p>
          </div>
          {isDisableCardShown && (
            <Disable2FACard
              setIsDisableCardShown={setIsDisableCardShown}
              setSwitched={setSwitched}
            />
          )}
          {isEnableCardShown && (
            <Enable2FACard
              setIsEnableCardShown={setIsEnableCardShown}
              setSwitched={setSwitched}
            />
          )}
        </div>
      </div>
    )
  )
}

SecurityTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
}

const VerificationTab = ({ activeTab }) => {
  const { t } = useTranslation()
  const { show: showIntercom } = useIntercom()
  const dispatch = useDispatch()
  let country = ""
  const { kyc } = useSelector((state) => state.user)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(verificationSchema),
  })

  const onSubmit = async (data) => {
    console.log("the data is :", data)
    const date = new Date(data.dob)
    const formatDateFormat = dayjs(date).format("YYYY-MM-DD")
    const kyc1 = {
      first_name: data.firstName,
      last_name: data.lastName,
      DOB: formatDateFormat,
      country: data.country.value,
      address_line1: data.addressLine1,
      address_line2: data.addressLine2,
      state_or_province: data.state,
      city: data.city,
      postal_code: data.postalCode,
      gender: data.gender,
    }
    const saveKycResponse = await saveKycLevel1(kyc1)
    const { status, data: kycData } = saveKycResponse
    if (status === 200) {
      MetaEventService.sendKYCUpdateEvent(kycData?.meta_event_id)
    }

    toast.success(
      t("KYC level 1 complete!"),
      t("You can always update your information and submit it again.")
    )

    dispatch(getUser())
  }

  if (kyc?.kycLevel1Complete) {
    country = countries.find((obj) => obj.value === kyc.country)
  }
  const kycLevel = [
    kyc?.kycLevel1Complete,
    kyc?.kycLevel2Complete,
    kyc?.kycLevel3Complete,
  ].findIndex((item) => item === false)
  const defaultActiveTab = kycLevel !== -1 ? `level${kycLevel + 1}` : "level1"

  const handleStartVErificationLevel2 = () => {
    if (!kyc?.kycLevel1Complete) {
      toast.error(
        t("Verification Level 1 is not completed."),
        t(
          "You can’t proceed to verify the level 2 or 3 until you complete the first level of verification account."
        )
      )
    } else {
      showIntercom()
    }
  }

  const handleVerificationLevel3 = () => {
    if (!kyc?.kycLevel1Complete || !kyc?.kycLevel2Complete) {
      toast.error(
        t("Verification Level 2 or level 1 is not completed."),
        t(
          "You can’t proceed to verify the level 2 or 3 until you complete precedent levels of verification."
        )
      )
    } else {
      showIntercom()
    }
  }

  // Revised handleCheckBoxOnchange function to toggle gender selection
  const handleCheckBoxOnChange = (fieldOnChange, value) => (e) => {
    if (e.target.checked) {
      // When the checkbox is checked, update the field's value to either "M" or "F"
      fieldOnChange(value)
    } else {
      // If the checkbox is unchecked, you might want to clear the value or handle it differently
      // This part depends on your application logic. If unchecking should clear the value, uncomment the next line.
      // fieldOnChange('');
    }
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    activeTab === tabNames.VERIFICATION && (
      <div className="w-full mb-5">
        <Accordion defaultActiveItem={defaultActiveTab}>
          <Accordion.Item key="level1">
            <Accordion.Item.Header id="level1">
              <p className="flex justify-between w-full">
                <span>{t("Level 1")}</span>{" "}
                {kyc?.kycLevel1Complete ? (
                  <span className="text-gray-400">{t("Completed")}</span>
                ) : (
                  ""
                )}
              </p>
            </Accordion.Item.Header>
            <Accordion.Item.Content id="level1">
              <div className="xl:h-[450px] xl:overflow-auto scrollbar scrollbar-thumb-gray-100 scrollbar-track-auto ">
                <p className="text-md mb-5">
                  {t(
                    "Please fill out all fields below to qualify for level 1 verification"
                  )}
                </p>
                <form className=" grid grid-cols-1 gap-y-4 gap-4 ">
                  <div className="flex xs:flex-col lg:flex-row  mb-2  ">
                    <div className="w-full  xs:mb-2 desktop:pr-2 ">
                      <Controller
                        control={control}
                        name="firstName"
                        defaultValue={
                          kyc?.kycLevel1Complete ? kyc.firstName : ""
                        }
                        render={({ field, formState }) => (
                          <Input
                            type="text"
                            label={t("First Name")}
                            id="first-name"
                            {...field}
                            error={formState.errors?.firstName}
                          />
                        )}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.firstName && errors.firstName.message}
                      </span>
                    </div>
                    <div className="w-full lg:px-5">
                      <Controller
                        control={control}
                        defaultValue={
                          kyc?.kycLevel1Complete ? kyc.lastName : ""
                        }
                        name="lastName"
                        render={({ field, formState }) => (
                          <Input
                            type="text"
                            label={t("Last Name")}
                            {...field}
                            error={formState.errors?.lastName}
                          />
                        )}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.lastName && errors.lastName.message}
                      </span>
                    </div>
                  </div>
                  <div className="flex xs:flex-col lg:flex-row items-center mb-2">
                    <div className="w-full  xs:mb-2  desktop:pr-2 ">
                      <Controller
                        control={control}
                        name="dob"
                        defaultValue={kyc?.kycLevel1Complete ? kyc.dob : ""}
                        render={({ field, formState }) => (
                          <Input
                            type="date"
                            label={t("Date")}
                            id="dob"
                            {...field}
                            error={formState.errors?.dob}
                          />
                        )}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.dob && errors.dob.message}
                      </span>
                    </div>
                    <div className="flex flex-col w-full lg:px-5">
                      <Controller
                        control={control}
                        name="country"
                        defaultValue={kyc?.kycLevel1Complete ? country : ""}
                        render={({ field, formState }) => (
                          <SelectField
                            placeholder={
                              kyc?.kycLevel1Complete
                                ? country.label
                                : t("Select Country")
                            }
                            options={countries}
                            theme="light"
                            {...field}
                            error={formState.errors?.country}
                          />
                        )}
                        onChange={([selected]) => ({ value: selected })}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.country && errors.country.message}
                      </span>
                    </div>
                  </div>
                  <div className="mb-2 lg:pr-5">
                    <Controller
                      control={control}
                      name="addressLine1"
                      defaultValue={
                        kyc?.kycLevel1Complete ? kyc.addressLine1 : ""
                      }
                      render={({ field, formState }) => (
                        <Input
                          type="text"
                          label={t("Address Line 1")}
                          id="address-line1"
                          {...field}
                          error={formState.errors?.addressLine1}
                        />
                      )}
                    />
                    <span className="text-red-700 text-xs">
                      {errors?.addressLine1 && errors.addressLine1.message}
                    </span>
                    <div className="my-3">
                      <Controller
                        control={control}
                        name="addressLine2"
                        defaultValue={
                          kyc?.kycLevel1Complete ? kyc.addressLine2 : ""
                        }
                        render={({ field }) => (
                          <Input
                            type="text"
                            label={t("Address Line 2")}
                            id="address-line2"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex xs:flex-col desktop:flex-row w-full ">
                    <div className="w-full  xs:mb-2 desktop:pr-1">
                      <Controller
                        control={control}
                        name="city"
                        defaultValue={kyc?.kycLevel1Complete ? kyc.city : ""}
                        render={({ field, formState }) => (
                          <Input
                            type="text"
                            label={t("City")}
                            id="city"
                            {...field}
                            error={formState.errors?.city}
                          />
                        )}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.city && errors.city.message}
                      </span>
                    </div>
                    <div className="w-full lg:pr-5 xs:mb-2">
                      <Controller
                        control={control}
                        name="postalCode"
                        defaultValue={
                          kyc?.kycLevel1Complete ? kyc.postalCode : ""
                        }
                        render={({ field, formState }) => (
                          <Input
                            type="text"
                            label={t("Postal code")}
                            id="postalCode"
                            {...field}
                            error={formState.errors?.postalCode}
                          />
                        )}
                      />
                      <span className="text-red-700 text-xs">
                        {errors?.postalCode && errors.postalCode.message}
                      </span>
                    </div>
                  </div>
                  <div className="w-full  xs:mb-2 lg:pr-5  ">
                    <Controller
                      control={control}
                      name="state"
                      defaultValue={
                        kyc?.kycLevel1Complete ? kyc.stateOrProvince : ""
                      }
                      render={({ field, formState }) => (
                        <Input
                          type="text"
                          label={t("State/Province")}
                          id="state"
                          {...field}
                          error={formState.errors?.state}
                        />
                      )}
                    />
                    <span className="text-red-700 text-xs">
                      {errors?.state && errors.state.message}
                    </span>
                  </div>
                  <div className="w-full  xs:mb-2 lg:pr-5  ">
                    <h1
                      className="font-semibold"
                      style={{ color: themeColors?.tertiary }}
                    >
                      {t("Gender")}:
                    </h1>
                    <Controller
                      control={control}
                      name="gender"
                      render={({ field }) => (
                        <>
                          <Checkbox
                            id="male"
                            value="M" // Specify the value for this checkbox
                            checked={field.value === "M"}
                            onChange={handleCheckBoxOnChange(
                              field.onChange,
                              "M"
                            )}
                            label={
                              <div
                                className="px-3 desktop-md-screen:text-sm xs:text-[12px] cursor-pointer"
                                style={{
                                  color: errors?.gender ? "red" : "black",
                                }}
                              >
                                {t("Male")}
                              </div>
                            }
                          />
                          <Checkbox
                            id="female"
                            value="F" // Specify the value for this checkbox
                            checked={field.value === "F"}
                            onChange={handleCheckBoxOnChange(
                              field.onChange,
                              "F"
                            )}
                            label={
                              <div
                                className="px-3 desktop-md-screen:text-sm xs:text-[12px] cursor-pointer"
                                style={{
                                  color: errors?.gender ? "red" : "black",
                                }}
                              >
                                {t("Female")}
                              </div>
                            }
                          />
                        </>
                      )}
                    />

                    <span className="text-red-700 text-xs block">
                      {errors?.gender && errors.gender.message}
                    </span>
                  </div>
                  <div className=" xs:flex xs:justify-center lg:flex lg:justify-end">
                    <Button.Primary
                      size="small"
                      onClick={handleSubmit(onSubmit)}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {t("Save")}
                    </Button.Primary>
                  </div>
                </form>
              </div>
            </Accordion.Item.Content>
          </Accordion.Item>
          <Accordion.Item key="level2">
            <Accordion.Item.Header id="level2">
              <p className="flex justify-between w-full">
                <span>{t("Level 2")}</span>{" "}
                {kyc?.kycLevel2Complete ? (
                  <span className="text-gray-400">{t("Completed")}</span>
                ) : (
                  ""
                )}
              </p>
            </Accordion.Item.Header>
            <Accordion.Item.Content id="level2">
              <div className="py-5 mb-5">
                <p className="text-xl font-semibold mb-2">
                  {t("Identity Verification")}
                </p>
                <p className="text-md mb-5">
                  {t(
                    "Please use the button below to complete your identity verification verification."
                  )}
                </p>
                <div>
                  <Button.Secondary
                    theme="dark"
                    onClick={handleStartVErificationLevel2}
                  >
                    <span className="text-black">{t("Verify")}</span>
                  </Button.Secondary>
                </div>
              </div>
            </Accordion.Item.Content>
          </Accordion.Item>
          <Accordion.Item key="level3">
            <Accordion.Item.Header id="level3">
              <p className="flex justify-between w-full">
                <span>{t("Level 3")}</span>{" "}
                {kyc?.kycLevel3Complete ? (
                  <span className="text-gray-400">{t("Completed")}</span>
                ) : (
                  ""
                )}
              </p>
            </Accordion.Item.Header>
            <Accordion.Item.Content id="level3">
              <div className="py-5 mb-5">
                <p className="text-xl font-semibold mb-2">
                  {t("Proof of Address")}
                </p>
                <p className="text-md mb-5">
                  {t(
                    "Please use the button below to upload your proof of address."
                  )}
                </p>
                <div>
                  <Button.Secondary
                    theme="dark"
                    onClick={handleVerificationLevel3}
                  >
                    <span className="text-black">{t("Verify")}</span>
                  </Button.Secondary>
                </div>
              </div>
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      </div>
    )
  )
}

VerificationTab.defaultProps = {
  activeTab: "verification",
}
VerificationTab.propTypes = {
  activeTab: PropTypes.string,
}

const OverViewTab = ({ activeTab }) => {
  const { t } = useTranslation()
  const { userLevelDetails, bonusActive } = useSelector((state) => state.user)
  const { name } = userLevelDetails
  const currentLevel = name || "Carbon Squad"
  const { username } = useSelector((state) => state.auth)
  const currentRewards = userLevelDetails.totalRewards ?? 0
  const currentWager = userLevelDetails.totalWager ?? 0

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    activeTab === tabNames.Information && (
      <div className="px-2 w-full pb-10 xl:h-auto">
        <div className=" text-md  mb-10">
          <h1
            className="text-md font-semibold leading-5 mt-2 flex items-center gap-5 mb-3"
            style={{ color: themeColors?.tertiary }}
          >
            <span>{t("Overview")}</span>
          </h1>
          <div className="w-full mt-3">
            {/* <div className="flex items-center mb-5"></div> */}
            <div className="flex justify-start items-center mobile:flex mobile:items-start gap-5">
              <InfoCard
                content={username}
                description={`${t("Level")} ${currentLevel}`}
                image={faTrophy}
              />
              <InfoCard
                content={`$ ${currentWager}`}
                description={t("Total Wagered")}
                image={faDice}
              />
              <InfoCard
                content={`$ ${currentRewards}`}
                description={t("Total Reward")}
                image={faGifts}
              />
            </div>
            <div className="my-5">
              <h1
                className=" font-semibold flex items-center gap-5"
                style={{ color: themeColors?.tertiary }}
              >
                <span> {t("Wager Bonus")} </span>
              </h1>

              <div>
                <MTCard
                  color="transparent"
                  shadow={false}
                  className="w-full shadow-lg px-5 banner"
                >
                  {bonusActive ? (
                    <BonusBanner isIntegrated />
                  ) : (
                    <CardHeader
                      color="transparent"
                      floated={false}
                      shadow={false}
                      className="mx-0 flex items-center gap-4 pt-0 pb-8"
                    >
                      <Avatar
                        size="lg"
                        variant="circular"
                        src={imageDistributed("bonusCodeAsset")}
                        alt="tania andrew"
                      />
                      <div className="flex w-full flex-col gap-0.5">
                        <div className="flex items-center justify-between">
                          <Typography
                            variant="h5"
                            color="blue-gray"
                            className=""
                            style={{ color: themeColors?.tertiary }}
                          >
                            <p>{t("You have no wager made yet !")}</p>
                          </Typography>
                        </div>
                      </div>
                    </CardHeader>
                  )}
                </MTCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

OverViewTab.defaultProps = {
  activeTab: "verification",
}
OverViewTab.propTypes = {
  activeTab: PropTypes.string,
}

const AccountModal = ({ activeService, activeTab, handleCloseModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (activeService === serviceName) dispatch(getUser())
  }, [activeService, dispatch])

  const handleSideNavItemClick = (tabName) => () => {
    searchParams.set("tab", tabName)
    setSearchParams(searchParams)
  }

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="px-3 xs:h-[680px] xs:overflow-auto modal-account-view">
        <MediaQuery maxWidth={959}>
          <div className="flex justify-between items-center xl:h-auto xs:h-16 xs:bg-white  xs:w-full">
            <h1 className="xs:text-tertiary xl:text-tertiary text-xl leading-5 font-medium px-7">
              {t("Account")}
            </h1>
            <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={960}>
          <div className="flex justify-end items-center">
            <Modal.Header handleCloseModal={handleCloseModal} />
          </div>
        </MediaQuery>
        <div className=" px-2 flex xs:flex-col xl:flex-row justify-start ">
          <SideNav>
            <div className="xs:flex xs:flex-row">
              <SideNav.Item
                id="information"
                activeItem={activeTab || tabNames.Information}
                onClick={handleSideNavItemClick("information")}
              >
                {t("Information")}
              </SideNav.Item>
              <SideNav.Item
                id="security"
                activeItem={activeTab || tabNames.Information}
                onClick={handleSideNavItemClick("security")}
              >
                {t("Security")}
              </SideNav.Item>
              <SideNav.Item
                id="verification"
                activeItem={activeTab || tabNames.Information}
                onClick={handleSideNavItemClick("verification")}
              >
                {t("Verification")}
              </SideNav.Item>
            </div>
          </SideNav>
          <OverViewTab activeTab={activeTab || tabNames.Information} />
          <SecurityTab activeTab={activeTab} />
          <VerificationTab activeTab={activeTab} />
        </div>
      </div>
    </Modal>
  )
}

AccountModal.defaultProps = {
  activeTab: tabNames.Information,
  activeService: false,
}

AccountModal.propTypes = {
  activeService: PropTypes.string,
  activeTab: PropTypes.oneOf(Object.values(tabNames)),
  handleCloseModal: PropTypes.func.isRequired,
}
export default AccountModal
