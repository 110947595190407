import React from "react"
import { Outlet } from "react-router-dom"

// Components
import CryptoPuchaseBanner from "../components/CryptoPuchaseBanner"
import BetsWageredBanner from "../components/BetsWageredBanner"
import "../features/PaymentGateways/style.css"

// TODO only accessible for logged in users -> ProtectedRoute
const CashierContainer = () => (
  <section className="container-max-height-banner   bg-primary w-full h-full text-white ">
    <div className=" h-full container-background-style  ">
      <CryptoPuchaseBanner />
      <div className="  flex  justify-center relative  py-5 z-150">
        <Outlet />
      </div>
    </div>
    <BetsWageredBanner />
  </section>
)

export default CashierContainer
