import React from "react"
import { useQuery } from "react-query"
import PropTypes from "prop-types"

import StatTable from "../../../components/StatTable"

import { getGameStatistics } from "../../../actions/game"
import Spinner from "../../../components/Spinner"
import { getBrandFromUrl, logger } from "../../../helpers"
import statsBackground from "../../../assets/images/home/stats-bg.png"
import themes from "../../../themes"

const Stats = ({ home }) => {
  const { isLoading, data: statistics } = useQuery(
    ["GameStatistics"],
    () => getGameStatistics(),
    {
      onError: () => {
        /* TODO:replace with a toast */
        logger("error occurred with statistics table !")
      },
    }
  )
  /* border-y-2 border-tertiary */

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return (
    <section
      className="pt-20  md:pb-44 xs:pb-24 text-white stats-section "
      style={{
        background: `url(${statsBackground}) top/contain no-repeat, ${themeColors.primary}`,
        color: "white",
        borderStyle: home ? "solid" : "",
        borderTopWidth: home ? "2px" : "",
        borderBottomWidth: home ? "2px" : "",
        borderColor: home ? themeColors?.tertiary : "",
      }}
    >
      <div className="container-max-width sm:px-7 lg:px-0 xs:px-6 xs:-mb-3  ">
        {isLoading ? <Spinner /> : <StatTable data={statistics.statistics} />}
      </div>
    </section>
  )
}

Stats.defaultProps = {
  home: false,
}

Stats.propTypes = {
  home: PropTypes.bool,
}
export default Stats
