import React from "react"
import { Outlet } from "react-router-dom"

// Components
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

const PageContainer = () => (
  <div>
    <Navbar className="bg-customPurple" variant="filled" color="customPurple" />
    <Outlet />
    <Footer />
  </div>
)

export default PageContainer
