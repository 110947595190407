import React from "react"
import { useSearchParams } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
// import { Alert } from "@material-tailwind/react"
import MediaQuery from "react-responsive"
import { useQuery } from "react-query"
import GameCard from "../../../components/GameCard"
import Button from "../../../components/Button"
import VipBanner from "../../../components/VipBanner"
import { Carousel } from "../../../components/Carousel"
import GamesCatalogWrapper from "../../../components/GamesCatalogWrapper"
import Banner from "../../../components/Banner"
import lightningRoulette from "../../../assets/images/games/Evolution2-Lightning_Roulette-main.png"
import jetX from "../../../assets/images/games/SmartSoft-JetX-main.png"
import royalCoinsHoldAndWin2 from "../../../assets/images/games/Playson-Royal_Coins_2__Hold_and_Win-main.png"

// import carouselItemOneDesktopEn from "../../../assets/images/carouselAssets/carousel-item-one-desktop-en-carbon-gaming.png"
import carouselItemOneDesktopEnRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-one-desktop-en-redemption-bits.jpg"
// import carouselItemOneDesktopFr from "../../../assets/images/carouselAssets/carousel-item-one-desktop-fr-carbon-gaming.png"
import carouselItemOneDesktopFrRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-one-desktop-fr-redemption-bits.jpg"
import carouselItemOneMobileEn from "../../../assets/images/carouselAssets/carousel-item-one-mobile-en.png"
// import carouselItemOneMobileFr from "../../../assets/images/carouselAssets/carousel-item-one-mobile-fr.png"
import carouselItemOneMobileFr from "../../../assets/images/carouselAssets/carousel-item-one-mobile-fr.png"

// import carouselItemTwoDesktopEn from "../../../assets/images/carouselAssets/carousel-item-two-desktop-en-carbongaming.png"
// import carouselItemTwoDesktopFr from "../../../assets/images/carouselAssets/carousel-item-two-desktop-fr-carbon-gaming.png"
import carouselItemTwoDesktopEnRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-desktop-en-redemption-bits.jpg"
import carouselItemTwoDesktopFrRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-desktop-fr-redemption-bits.jpg"
// import carouselItemTwoDesktopSpCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-desktop-sp-carbon-gaming.png"
import carouselItemTwoDesktopSpRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-desktop-sp-redemption-bits.jpg"
// import carouselItemTwoMobileEnCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-mobile-en-carbon-gaming.png"
import carouselItemTwoMobileEnRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-mobile-en-redemption-bits.jpg"
// import carouselItemTwoMobileFrCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-mobile-fr-test1.png"
import carouselItemTwoMobileFrRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-mobile-fr-redemption-bits.jpg"
// import carouselItemThreeDesktop from "../../../assets/images/carouselAssets/carousel-item-three-desktop-carbon-gaming.png"
import carouselItemThreeDesktopRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-three-desktop-redemption-bits.png"
import carouselItemTwoMobileSpCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-mobile-sp-carbon-gaming.png"
// import carouselItemThreeMobileCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-three-mobile-carbon-gaming.png"
import carouselItemThreeMobileRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-three-mobile-redemption-bits.jpg"
// import carouselItemFourDesktop from "../../../assets/images/carouselAssets/carousel-item-four-desktop.png"
// import carouselItemFourMobile from "../../../assets/images/carouselAssets/carousel-item-four-mobile.png"
// import carouselItemOneDesktopSp from "../../../assets/images/carouselAssets/carousel-item-one-desktop-sp-carbon-gaming.png"
import carouselItemOneDesktopSpRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-one-desktop-sp-redemption-bits.jpg"
import carouselItemOneMobileSp from "../../../assets/images/carouselAssets/carousel-item-one-mobile-sp.png"
// import carouselItemOneDesktopItCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-one-desktop-it-carbon-gaming.png"
import carouselItemOneMobileItCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-one-mobile-it-carbon-gaming.png"
// import carouselItemTwoDesktopItCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-desktop-it-carbongaming.png"
// import carouselItemTwoMobileItCarbonGaming from "../../../assets/images/carouselAssets/carousel-item-two-mobile-it-carbon-gaming.png"
import carouselItemOneDesktopItRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-one-desktop-it-redemption-bits.png"
// import carouselItemOneMobileItRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-one-mobile-it-carbon-gaming.png"
import carouselItemTwoDesktopItRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-desktop-it-redemption-bits.jpg"
import carouselItemTwoMobileItRedemptionBits from "../../../assets/images/carouselAssets/carousel-item-two-mobile-it-redemption-bits.png"

import visaAsset from "../../../assets/images/home/visa-asset.png"
import visaVectorAsset from "../../../assets/images/home/visa-vector-asset.png"
import thumbAsset from "../../../assets/images/home/thumb-asset.png"
import applePayAsset from "../../../assets/images/home/apple-pay-asset.png"
import "../style.css"
import { getGamesCatalog } from "../../../actions/game"
import { GET_GAMES_FULFILLED } from "../../../actions/types"
import { getBrandFromUrl, logger } from "../../../helpers"
import themes from "../../../themes"
import heroBackgroundCarbonGaming from "../../../assets/images/home/hero-bg-carbon-gaming.png"
import heroBackgroundCarbonGames from "../../../assets/images/home/hero-bg-carbon-games.png"
import PageLink from "../../../components/PageLink"
// Jinene Bets Carousel Assets

// Item One
import carouselItemOneDesktopEnOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-en-jinene-bets.png"
import carouselItemOneDesktopFrOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-fr-jinene-bets.jpg"
import carouselItemOneDesktopSpOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-sp-jinene-bets.png"
import carouselItemOneDesktopItOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-it-jinene-bets.png"
import carouselItemOneDesktopNlOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-nl-jinene-bets.png"
import carouselItemOneMobileNlOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-mobile-nl.png"
import carouselItemOneDesktopDeOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-desktop-de-jinene-bets.png"
import carouselItemOneMobileDeOjoriBets from "../../../assets/images/carouselAssets/jinen-bets/carousel-item-one-mobile-de.png"

// Item Two
import carouselItemTwoDesktopEnOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-en-ojori-bets.jpg"
import carouselItemTwoDesktopFrOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-fr-ojori-bets.jpg"
import carouselItemTwoDesktopSpOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-sp-ojori_bets.png"
import carouselItemTwoDesktopItOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-it-ojori-bets.png"
import carouselItemTwoMobileEnOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-en-ojori-bets.jpg"
import carouselItemTwoMobileFrOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-fr-ojori-bets.jpg"
import carouselItemTwoMobileSpOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-sp-ojori-bets.png"
import carouselItemTwoMobileItOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-it-ojori-bets.png"
import carouselItemTwoDesktopNlOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-nl-ojori-bets.png"
import carouselItemTwoMobileNlOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-nl-ojori-bets.png"
import carouselItemTwoDesktopDeOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-desktop-de-ojori-bets.png"
import carouselItemTwoMobileDeOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-two-mobile-de-ojori-bets.png"

// Item Three
import carouselItemThreeMobileOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-three-mobile-ojori-bets.png"
import carouselItemThreeDesktopOjoriBets from "../../../assets/images/carouselAssets/ojori-bets/carousel-item-three-desktop-ojori-bets.png"

// TODO : check the style de la version tablet in redesign home page

// TODO : check the style of mobile version in redesign home page
// TODO : change the architecture of the games catalog mobile and desktop and try to separate them

const imageLanguageSelectedItemOne = {
  "www.carbongaming.net": {
    desktop: {
      en: carouselItemOneDesktopEnOjoriBets,
      fr: carouselItemOneDesktopFrOjoriBets,
      sp: carouselItemOneDesktopSpOjoriBets,
      it: carouselItemOneDesktopItOjoriBets,
      nl: carouselItemOneDesktopNlOjoriBets,
      de: carouselItemOneDesktopDeOjoriBets,
    },
    mobile: {
      en: carouselItemOneMobileEn,
      fr: carouselItemOneMobileFr,
      sp: carouselItemOneMobileSp,
      it: carouselItemOneMobileItCarbonGaming,
      nl: carouselItemOneMobileNlOjoriBets,
      de: carouselItemOneMobileDeOjoriBets,
    },
  },
  "www.redemptionbits.com": {
    desktop: {
      en: carouselItemOneDesktopEnRedemptionBits,
      fr: carouselItemOneDesktopFrRedemptionBits,
      sp: carouselItemOneDesktopSpRedemptionBits,
      it: carouselItemOneDesktopItRedemptionBits,
    },
    mobile: {
      en: carouselItemOneMobileEn,
      fr: carouselItemOneMobileFr,
      sp: carouselItemOneMobileSp,
      it: carouselItemOneMobileItCarbonGaming,
    },
  },
}

const secondCarouselImage = {
  "www.carbongaming.net": {
    desktop: {
      en: carouselItemTwoDesktopEnOjoriBets,
      fr: carouselItemTwoDesktopFrOjoriBets,
      sp: carouselItemTwoDesktopSpOjoriBets,
      it: carouselItemTwoDesktopItOjoriBets,
      nl: carouselItemTwoDesktopNlOjoriBets,
      de: carouselItemTwoDesktopDeOjoriBets,
    },
    mobile: {
      en: carouselItemTwoMobileEnOjoriBets,
      fr: carouselItemTwoMobileFrOjoriBets,
      sp: carouselItemTwoMobileSpOjoriBets,
      it: carouselItemTwoMobileItOjoriBets,
      nl: carouselItemTwoMobileNlOjoriBets,
      de: carouselItemTwoMobileDeOjoriBets,
    },
  },
  "www.redemptionbits.com": {
    desktop: {
      en: carouselItemTwoDesktopEnRedemptionBits,
      fr: carouselItemTwoDesktopFrRedemptionBits,
      sp: carouselItemTwoDesktopSpRedemptionBits,
      it: carouselItemTwoDesktopItRedemptionBits,
    },
    mobile: {
      en: carouselItemTwoMobileEnRedemptionBits,
      fr: carouselItemTwoMobileFrRedemptionBits,
      sp: carouselItemTwoMobileSpCarbonGaming,
      it: carouselItemTwoMobileItRedemptionBits,
    },
  },
}

const thirdCarouselImage = {
  "www.carbongaming.net": {
    desktop: carouselItemThreeDesktopOjoriBets,
    mobile: carouselItemThreeMobileOjoriBets,
  },
  "www.redemptionbits.com": {
    desktop: carouselItemThreeDesktopRedemptionBits,
    mobile: carouselItemThreeMobileRedemptionBits,
  },
}

const Hero = () => {
  const dispatch = useDispatch()
  const { isLoading } = useQuery({
    queryKey: ["getGamesCatalog"],
    queryFn: () => getGamesCatalog(),
    refetchOnMount: true,
    onSuccess: ({ data: gamesCatalogData }) => {
      dispatch({
        type: GET_GAMES_FULFILLED,
        payload: gamesCatalogData,
      })
    },
    onError: (error) => {
      logger(error)
    },
  })

  const { loggedIn } = useSelector((state) => state.auth)
  const { bonusActive } = useSelector((state) => state.wallet)

  const { t, i18n } = useTranslation()
  const languageSelected = i18n.language?.split("-")[0]
  const [searchParams, setSearchParams] = useSearchParams()

  const handleRedirectToReferralModal = () => {
    if (loggedIn) {
      searchParams.set("service", "affiliate")
      searchParams.set("tab", "referral")
      setSearchParams(searchParams)
    } else {
      searchParams.set("service", "login")
      setSearchParams(searchParams)
    }
  }
  const redirectToDepositModal = () => {
    if (!loggedIn) {
      searchParams.set("service", "login")
      setSearchParams(searchParams)
    } else {
      searchParams.set("service", "cashier")
      searchParams.set("tab", "deposit")
      setSearchParams(searchParams)
    }
  }
  const navigateToGamesCatalog = () => {
    // scroll to view and offset it
    const gamesCatalog = document.getElementById("games-catalog-id")
    if (gamesCatalog) {
      gamesCatalog.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start",
      })
      if (window.screen.width > 700) {
        window.scrollBy(0, 250)
      }
    }
  }

  const handleRedirectToBuyCryptoModal = () => {
    if (loggedIn) {
      searchParams.set("service", "cashier")
      searchParams.set("tab", "buy-crypto")
      setSearchParams(searchParams)
    } else {
      searchParams.set("service", "login")
      setSearchParams(searchParams)
    }
  }
  const { currentLevelSlug, groups } = useSelector((state) => state.user)

  // const getMobileImageSource = (brand) => {
  //   if (languageSelected === "en") {
  //     return brand === "www.carbongaming.net"
  //       ? carouselItemTwoMobileEnCarbonGaming
  //       : carouselItemTwoMobileEnRedemptionBits
  //   }
  //   return brand === "www.carbongaming.net"
  //     ? carouselItemTwoMobileFrCarbonGaming
  //     : carouselItemTwoMobileFrRedemptionBits // Replace with the appropriate variables
  // }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return (
    <section
      className={`md:pb-8 xs:pb-3  text-white w-full h-auto ${
        currentLevelSlug ? "pt-24" : "pt-16"
      }`}
      style={{
        background:
          brand === "www.carbongaming.net"
            ? `url(${heroBackgroundCarbonGaming}) top/contain no-repeat, ${themeColors.primary}`
            : `url(${heroBackgroundCarbonGames}) top/contain no-repeat, ${themeColors.primary}`,
        color: "white",
      }}
    >
      {loggedIn && currentLevelSlug && !groups?.includes("marketers") && (
        <VipBanner />
      )}
      <div
        className={`container-max-width sm:px-7 desktop:px-0 xl-start:p-10 xs:px-6 about-section h-[100%] ${
          bonusActive
            ? "pt-14 mobile:pt-11"
            : "desktop:pt-[58px] mobile:pt-12 mobile-big-screen:pt-28 tablet:pt-28"
        }`}
      >
        {" "}
        <Carousel id="carousel-homepage">
          <Carousel.Iem
            imageSource={
              imageLanguageSelectedItemOne[brand]?.desktop[languageSelected]
            }
            mobileImageSource={
              imageLanguageSelectedItemOne[brand]?.mobile[languageSelected]
            }
            isActive
          >
            <div className="mobile:flex  mobile:justify-end mobile:pr-12 mobile-big-screen:flex  mobile-big-screen:justify-end mobile-big-screen:pr-12 relative top-2 ">
              <Button.Primary size="small" onClick={redirectToDepositModal}>
                <span className="xs:text-xs tablet:text-base">
                  {" "}
                  {t("Deposit")}
                </span>
              </Button.Primary>
              <Button.Secondary size="small" onClick={navigateToGamesCatalog}>
                <span className="w-full xs:text-xs tablet:text-base">
                  {t("Play Games")}
                </span>
              </Button.Secondary>
            </div>
          </Carousel.Iem>
          <Carousel.Iem
            imageSource={
              secondCarouselImage[brand] &&
              secondCarouselImage[brand].desktop[languageSelected]
            }
            mobileImageSource={
              secondCarouselImage[brand] &&
              secondCarouselImage[brand].mobile[languageSelected]
            }
          >
            <div className="mobile:flex mobile:justify-end mobile:pr-12 mobile-big-screen:flex mobile-big-screen:justify-end mobile-big-screen:pr-12 ">
              <Button.Primary
                size="small"
                onClick={handleRedirectToReferralModal}
              >
                <span className="xs:text-xs tablet:text-base">
                  {" "}
                  {t("Invite a friend")}
                </span>
              </Button.Primary>
              <Button.Secondary size="small" onClick={redirectToDepositModal}>
                <span className="xs:text-xs tablet:text-base">
                  {" "}
                  {t("Deposit")}
                </span>
              </Button.Secondary>
            </div>
          </Carousel.Iem>
          <Carousel.Iem
            imageSource={thirdCarouselImage[brand].desktop}
            mobileImageSource={thirdCarouselImage[brand].mobile}
          >
            <div className="mobile:flex mobile:justify-end mobile:pr-12  mobile-big-screen:flex mobile-big-screen:justify-end mobile-big-screen:pr-12 ">
              <MediaQuery maxWidth={399}>
                <Button.Primary
                  size="extraSmall"
                  onClick={handleRedirectToReferralModal}
                >
                  <span className="xs:text-xs tablet:text-base">
                    {" "}
                    {t("Refer A Friend")}
                  </span>
                </Button.Primary>
              </MediaQuery>
              <MediaQuery minWidth={400}>
                <Button.Primary
                  size="small"
                  onClick={handleRedirectToReferralModal}
                >
                  <span className="xs:text-xs tablet:text-base ">
                    {" "}
                    {t("Refer A Friend")}
                  </span>
                </Button.Primary>
              </MediaQuery>
              {languageSelected === "sp" ? (
                <MediaQuery minWidth={500}>
                  <Button.Secondary size="small">
                    <PageLink to="/category/popular">
                      <span className=" w-full xs:text-xs  tablet:text-base ">
                        {t("Latest Games")}
                      </span>
                    </PageLink>
                  </Button.Secondary>
                </MediaQuery>
              ) : (
                <Button.Secondary size="small">
                  <PageLink to="/category/popular">
                    <span className="w-full xs:text-xs tablet:text-base">
                      {t("Latest Games")}
                    </span>
                  </PageLink>
                </Button.Secondary>
              )}
            </div>
          </Carousel.Iem>
          {/* <Carousel.Iem
            imageSource={carouselItemFourDesktop}
            mobileImageSource={carouselItemFourMobile}
          >
            <div className="mobile:flex mobile:justify-end mobile:pr-12 mobile-big-screen:flex mobile-big-screen:justify-end mobile-big-screen:pr-12  ">
              <Button.Primary size="small">
                <a
                  href="https://www.carbonbasenft.com"
                  target="_blank"
                  rel="noreferrer"
                  className="xs:text-sm tablet:text-base"
                >
                  {t("Discover")}
                </a>
              </Button.Primary>
              <Button.Secondary size="small">
                <Link
                  to="#games-catalog"
                  className="xs:text-sm tablet:text-base"
                >
                  {t("Play Games")}
                </Link>
              </Button.Secondary>
            </div>
          </Carousel.Iem> */}
        </Carousel>
        {isLoading ? (
          <div />
        ) : (
          <div>
            <div id="games-catalog-id">
              <div className="grid desktop:grid-cols-3 mobile:grid-cols-3 mobile-big-screen:grid-cols-3 justify-content-center sm:grid-cols-3 gap-5 xs:gap-3 desktop:my-12 tablet:mt-12 tablet:mb-6 xs:mt-8 xs:mb-0">
                <GameCard
                  slug="SmartSoft-JetX"
                  className=" xl:my-0 xl:ml-2"
                  imageSource={jetX}
                />
                <GameCard
                  slug="Evolution2-Lightning_Roulette"
                  className="xl:mt-0 p-22  rounded-3xl  md:text-center xs:text-left"
                  imageSource={lightningRoulette}
                />
                <GameCard
                  slug="Playson-Royal_Coins_2__Hold_and_Win"
                  className="mr-0 lg:mr-2   lg:my-5 xl:my-0 lg:mb-0"
                  imageSource={royalCoinsHoldAndWin2}
                />
              </div>
            </div>
            <div className="my-16 xs:mt-6">
              <Banner>
                <div
                  className="rounded-3xl py-4  xs:px-4 desktop:flex desktop:justify-between desktop:items-center xs:flex xs:flex-col xs:justify-between xs:items-center cursor-pointer"
                  style={{
                    backgroundColor: themeColors?.secondary,
                    color: "white",
                  }}
                  onClick={handleRedirectToBuyCryptoModal}
                  onKeyDown={Function.prototype}
                  role="button"
                  tabIndex={0}
                >
                  <h1 className="text-[27px] desktop-md-screen:text-[20px] pl-5 mobile:text-center ">
                    {t("Get Crypto in seconds")}
                  </h1>
                  <div className="flex justify-between items-center xs:flex  xs:my-5 desktop:my-auto">
                    <img
                      fetchpriority="low"
                      src={visaAsset}
                      alt="master-card-asset"
                      className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-5"
                    />
                    <img
                      fetchpriority="low"
                      src={visaVectorAsset}
                      alt="visa-card-asset"
                      className="desktop:w-full desktop:h-6 mx-3 xs:w-auto xs:h-5 "
                    />
                    <img
                      fetchpriority="low"
                      src={thumbAsset}
                      alt="google-pay-asset"
                      className="desktop:w-full desktop:h-9 mx-3 xs:w-auto xs:h-5 "
                    />
                    <img
                      fetchpriority="low"
                      src={applePayAsset}
                      alt="apple-pay-asset"
                      className="desktop:w-full desktop:h-8 mx-3 xs:w-auto xs:h-5 "
                    />
                  </div>
                  <div className="flex justify-center xs:my-6 desktop:my-auto desktop:pr-4">
                    <MediaQuery minWidth={320} maxWidth={1199}>
                      <Button.Secondary size="small">
                        <span className="text-xs">{t("Buy Crypto")}</span>
                      </Button.Secondary>
                    </MediaQuery>
                    <MediaQuery minWidth={1200}>
                      <Button.Secondary>{t("Buy Crypto")}</Button.Secondary>
                    </MediaQuery>
                  </div>
                </div>
              </Banner>
            </div>
            <div>
              <GamesCatalogWrapper />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
