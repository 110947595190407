import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "@material-tailwind/react"

const IconsButton = ({ children, onClick }) => (
  <IconButton
    onClick={onClick}
    size="md"
    variant="text"
    className="text-center hover:bg-transparent focus:bg-transparent active:bg-transparent"
  >
    {children}
  </IconButton>
)

IconsButton.defaultProps = {
  onClick: Function.prototype,
}

IconsButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
}

export default IconsButton
