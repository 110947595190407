import * as Yup from "yup"

export default Yup.object().shape({
  authCode: Yup.string()
    .required("Authentication code is required.")
    .matches(
      /^\d{6}$/,
      "Authentication code must be exactly 6 digits with no special characters."
    ),
})
