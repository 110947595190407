import * as Yup from "yup"

export default Yup.object().shape({
  address: Yup.string().required("Ethereum Address  is required."),
  amount: Yup.string()
    .required("Amount is required.")
    .matches(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/, "Please enter an amount.")
    .typeError("Please enter an amount.")
    .test(
      "len",
      "The amount specified is less than the minimum amount.",
      (val) => parseInt(val, 10) >= 10
    ),
})
