import React, { useEffect, useState } from "react"
import MediaQuery from "react-responsive"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import Header from "../../components/Header"
import Button from "../../components/Button"
import GamesView from "../../components/GameView"
import { getCountryCode } from "../../actions/user"
import { getGamesCatalog } from "../../actions/game"
import { GET_GAMES_FULFILLED } from "../../actions/types"
import { getBrandFromUrl, logger, promptBrand } from "../../helpers"
import Spinner from "../../components/Spinner"
import { DropdownMenu } from "../../components/DropdownMenu"
import Input from "../../components/Input"
import { categories as gamesCategoriesDisplay } from "../../constants/games"
import popularGamesIcon from "../../assets/icons/gamesCatalog/popular-games-icon.png"
import allGamesIcon from "../../assets/icons/gamesCatalog/carbon-picks-icon.png"
import slotGamesIcon from "../../assets/icons/gamesCatalog/slot-games-icon.png"
import crashGamesIcon from "../../assets/icons/gamesCatalog/crash-games-icon.png"
import onlineCasinoIcon from "../../assets/icons/gamesCatalog/online-casino-icon.png"
import liveGamesIcon from "../../assets/icons/gamesCatalog/latest-games-icon.png"
import clearFilterAsset from "../../assets/images/clear-filter-asset.svg"
import themes from "../../themes"
import PageLink from "../../components/PageLink"

const gamesCategories = [
  {
    category: "all",
    imageSource: allGamesIcon,
    tags: "all",
    title: "All Games",
    mobile: true,
  },
  {
    category: "popular-games",
    imageSource: popularGamesIcon,
    tags: "popular",
    title: "Popular Games",
    mobile: true,
  },
  {
    category: "slot-games",
    imageSource: slotGamesIcon,
    title: "Slot Games",
    tags: "slot",
    mobile: true,
  },

  {
    category: "table-games",
    imageSource: onlineCasinoIcon,
    title: "Table Games",
    tags: "table",
    mobile: true,
  },
  {
    category: "live-games",
    imageSource: liveGamesIcon,
    title: "Live Games",
    tags: "live",
    mobile: true,
  },
  {
    category: "crash-games",
    imageSource: crashGamesIcon,
    title: "Crash Games",
    tags: "crash",
    mobile: true,
  },
]

const Hero = () => {
  const dispatch = useDispatch()
  useQuery({
    queryKey: ["getGamesCatalog"],
    queryFn: () => getGamesCatalog(),
    refetchOnMount: true,
    onSuccess: ({ data: gamesCatalogData }) => {
      dispatch({
        type: GET_GAMES_FULFILLED,
        payload: gamesCatalogData,
      })
    },
    onError: (error) => {
      logger(error)
    },
  })
  const navigate = useNavigate()
  const { games } = useSelector((state) => state.games)
  const { t } = useTranslation()
  const [selectedProvider, setSelectedProvider] = useState({
    label: "All Providers",
  })
  const [searchParam, setSearchParam] = useSearchParams()

  let { category } = useParams()

  // check if category has a corresponding value in gamesCategories
  const categoryInGamesCategories = gamesCategories.find(
    (item) => item.tags === category
  )
  // if not, set category to "all"
  if (!categoryInGamesCategories) {
    category = "all"
  }

  let categorySelectedViaUrl = category
  if (games && !games.find((item) => item.categories === category)) {
    const categories = "popular"
    categorySelectedViaUrl = categories
  }

  const [numberGamesToDisplay, setNumberGamesToDisplay] = useState(20)

  const [selectedCategory, setSelectedCategory] = useState(
    gamesCategories.find(
      (categorySelected) => categorySelected.tags === categorySelectedViaUrl
    ) || gamesCategories[0]
  )

  useEffect(() => {
    const searchInputRef = document.getElementById("search-input")
    searchInputRef.focus()
  }, [])
  const handleSelectCategory = (item) => {
    setSelectedCategory(item)
    setSearchParam(searchParam)
    navigate({
      pathname: `/category/${item.tags}/`,
      search: searchParam.toString(),
    })
  }
  const permittedProviders = ["All Providers"]
  games.map(
    (item) =>
      !permittedProviders.includes(item.provider) &&
      permittedProviders.push(item.provider)
  )
  useEffect(() => {
    if (permittedProviders.includes(searchParam.get("provider"))) {
      if (searchParam.get("provider") !== selectedProvider.label) {
        setSelectedProvider({ label: searchParam.get("provider") })
      }
    }
  }, [permittedProviders])

  useEffect(() => {
    setSelectedCategory(
      gamesCategories.find(
        (categorySelected) => categorySelected.tags === category
      )
    )
  }, [category])

  let lengthCategoryGames = games.length
  if (category.toLowerCase() !== "all") {
    lengthCategoryGames = games.filter((game) =>
      game.categories?.includes(category)
    ).length
  }

  const handleNumberOfGamesToDisplay = () =>
    setNumberGamesToDisplay((previous) => previous + 20)

  const { isLoading, data: countryCode } = useQuery(
    ["userLocation"],
    () => getCountryCode(),
    {
      onError: () => {
        logger("error occurred with getting user Location!")
      },
    }
  )

  const [searchInputGame, setSearchInputGame] = useState("")
  const handleSearchInputGame = (e) => {
    searchParam.set("search", e.target.value)
    setSearchParam(searchParam)
    setSearchInputGame(e.target.value)
  }

  const handleSelectGamesProvider = (item) => {
    searchParam.set("provider", item)
    setSearchParam(searchParam)
    setSelectedProvider({ label: item })
  }

  // const categoryNameToDisplay = gamesCategoriesDisplay.find(
  //   (item) => item.tag === category
  // ).displayName

  // const lengthGamesOfOneCategoryProvider = games
  //   .filter((game) => game.tags.includes(selectedCategory.tags))
  //   .filter((game) => game.slug.includes(selectedProvider.label)).length

  useEffect(() => {
    setSearchInputGame(searchParam.get("search"))
    if (permittedProviders.includes(searchParam.get("provider"))) {
      setSelectedProvider({ label: searchParam.get("provider") })
    } else {
      setSelectedProvider({ label: "All Providers" })
    }
  }, [searchParam.get("provider"), searchParam.get("search")])

  const handleClearFilter = () => {
    setSearchInputGame("")
    searchParam.delete("search")
    setSearchParam(searchParam)
    setSelectedProvider({ label: "All Providers" })
  }
  const categoryGameNameDisplay = gamesCategoriesDisplay.find(
    (item) => item.tag === category
  ).displayName

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <section
      className="hero-section  md:pb-12 xs:pb-9  text-white capitalize w-full about-section games-category-page-hero-section "
      style={{
        backgroundColor: themeColors?.primary,
        color: "white",
      }}
    >
      <Header
        title={`${promptBrand("name")} | ${selectedCategory.tags} Games`}
      />
      <div className="container-max-width pt-40 sm:px-7 lg:px-0 xl-start:p-10 xs:px-6 h-[100%]">
        <MediaQuery minWidth={320} maxWidth={767}>
          <div className="mb-5">
            <div className=" my-5">
              <Input
                type="text"
                id="search-input"
                label={t("Search games")}
                value={searchInputGame}
                onChange={handleSearchInputGame}
                isCustom
              />
            </div>
            <div className="flex justify-between items-center category-page-container-mobile">
              <DropdownMenu
                items={gamesCategories}
                type="small"
                selectedLanguage={selectedCategory}
              >
                {gamesCategories.map((item) => (
                  <DropdownMenu.Item onClick={() => handleSelectCategory(item)}>
                    <img
                      fetchpriority="high"
                      src={item.imageSource}
                      className="w-6 ml-2"
                      alt="flag"
                    />
                    <span className="font-medium text-wl mx-1 text-black">
                      {item.title.slice(0, item.title.indexOf(" "))}
                    </span>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu>
              <div className="flex justify-center">
                <DropdownMenu
                  items={permittedProviders}
                  type="custom"
                  selectedLanguage={selectedProvider}
                >
                  <div className="category-page-providers-containers">
                    {permittedProviders.map((item) => (
                      <DropdownMenu.Item
                        onClick={() => handleSelectGamesProvider(item)}
                      >
                        <span className="font-medium text-xs mx-2 text-black">
                          {item}
                        </span>
                      </DropdownMenu.Item>
                    ))}
                  </div>
                </DropdownMenu>
              </div>
              <div
                className="flex items-center justify-center"
                onClick={handleClearFilter}
                onKeyDown={Function.prototype}
                tabIndex={0}
                role="button"
              >
                <img
                  src={clearFilterAsset}
                  alt="clear-filter-asset"
                  className="w-auto h-10 relative top-2"
                />
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1126}>
          <div className="my-5 z-50 w-full">
            <div className="my-5">
              <Input
                type="text"
                id="search-input"
                label={t("Search games")}
                value={searchInputGame}
                onChange={handleSearchInputGame}
                isCustom
              />
            </div>
            <div className="flex justify-between">
              <PageLink to="/games/Evolution2-Lightning_Roulette">
                <Button.Quaternary size="small">
                  {t("I'm Feeling Lucky")}
                </Button.Quaternary>
              </PageLink>
              <DropdownMenu
                items={gamesCategories}
                type="small"
                selectedLanguage={selectedCategory}
              >
                {gamesCategories.map((item) => (
                  <DropdownMenu.Item onClick={() => handleSelectCategory(item)}>
                    <img
                      fetchpriority="high"
                      src={item.imageSource}
                      className="w-6 ml-2"
                      alt="flag"
                    />
                    <span className="font-medium text-wl mx-2 text-black">
                      {t(item.title.slice(0, item.title.indexOf(" ")))}
                    </span>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu>
              <div className="category-page-providers-dropdown">
                <DropdownMenu
                  items={permittedProviders}
                  type="custom"
                  selectedLanguage={selectedProvider}
                >
                  <div className="category-page-providers-containers">
                    {permittedProviders.map((item) => (
                      <DropdownMenu.Item
                        onClick={() => handleSelectGamesProvider(item)}
                      >
                        <span className="font-medium text-xs mx-2 text-black">
                          {item}
                        </span>
                      </DropdownMenu.Item>
                    ))}
                  </div>
                </DropdownMenu>
              </div>
              <Button.Quaternary onClick={handleClearFilter} size="small">
                {t("Clear Filter")}
              </Button.Quaternary>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1127}>
          <div className="my-5 z-50 flex items-center justify-between w-full">
            <div className="flex">
              <div className="mr-2">
                <PageLink to="/games/Evolution2-Lightning_Roulette">
                  <Button.Quaternary size="small">
                    {t("I'm Feeling Lucky")}
                  </Button.Quaternary>
                </PageLink>
              </div>
              <DropdownMenu
                items={gamesCategories}
                type="small"
                selectedLanguage={selectedCategory}
              >
                {gamesCategories.map((item) => (
                  <DropdownMenu.Item onClick={() => handleSelectCategory(item)}>
                    <img
                      fetchpriority="high"
                      src={item.imageSource}
                      className="w-6 ml-2"
                      alt="flag"
                    />
                    <span className="font-medium text-wl mx-2 text-black">
                      {item.title.slice(0, item.title.indexOf(" "))}
                    </span>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu>
            </div>
            <div className="category-page-input-container">
              <Input
                type="text"
                id="search-input"
                label={t("Search games")}
                value={searchInputGame}
                onChange={handleSearchInputGame}
                isCustom
              />
            </div>
            <div className="flex justify-between ml-2 gap-2">
              <div className="category-page-providers-dropdown">
                <DropdownMenu
                  items={permittedProviders}
                  type="custom"
                  selectedLanguage={selectedProvider}
                >
                  <div className="category-page-providers-containers">
                    {permittedProviders.map((item) => (
                      <DropdownMenu.Item
                        onClick={() => handleSelectGamesProvider(item)}
                      >
                        <span className="font-medium text-xs mx-2 text-black">
                          {item}
                        </span>
                      </DropdownMenu.Item>
                    ))}
                  </div>
                </DropdownMenu>
              </div>
              <Button.Quaternary onClick={handleClearFilter} size="small">
                {t("Clear Filter")}
              </Button.Quaternary>
            </div>
          </div>
        </MediaQuery>
        <h1 className="text-3xl font-bold capitalize">
          <span className="capitalize">{t(categoryGameNameDisplay)}</span>{" "}
          {t("Games")}
        </h1>
        {isLoading ? (
          <div className="w-[100%] h-full  flex justify-center items-center pt-10 pb-10">
            <Spinner theme="light" />
          </div>
        ) : (
          <>
            <div className="my-10 catalog-page-games desktop-md-screen:grid-cols-4 z-0">
              <GamesView
                tag={category}
                display={numberGamesToDisplay}
                countryCode={countryCode}
                search={searchInputGame}
                selectedProvider={selectedProvider}
              />
            </div>
            <div className="w-[50%] mx-auto flex justify-center items-center">
              <Button.Primary
                size="small"
                onClick={handleNumberOfGamesToDisplay}
                disabled={
                  lengthCategoryGames === numberGamesToDisplay ||
                  lengthCategoryGames < numberGamesToDisplay
                }
              >
                {t("Load More")}
              </Button.Primary>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Hero
