// this file for later use

import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import { useNavigate } from "react-router-dom"

/* import { useQuery } from "react-query"
import { useTranslation } from "react-i18next" */

// components
/* import Spinner from "../../components/Spinner"
 */
// Utilities
/* import { getPaysageCheckoutUrl } from "../../actions/wallet"
 */

// assets
import arrowLeft from "../assets/icons/arrow-left.svg"
import cardIcon from "../assets/icons/card-icon.svg"
import masterCard from "../assets/icons/master-card.svg"
import visaCard from "../assets/icons/visa-card.svg"
import checkMarkAsset from "../assets/icons/check-mark-carbon-gaming.svg"

// style
import "../features/PaymentGateways/style.css"
import getAmount from "../features/PaymentGateways/helpers"
import { logger } from "../helpers"

const PaymentMethodContext = React.createContext(null)

const paymentMethods = [
  {
    method: "paysage",
    label: "Pay with paysage credit cards",
    imageSource: masterCard,
  },
  {
    method: "noda/cc",
    label: "Pay with noda credit cards",
    imageSource: visaCard,
  },
]

const PaymentCard = ({ children, id }) => {
  const { activeMethod, setActiveMethod } = useContext(PaymentMethodContext)
  const handleSelectMethod = () => {
    setActiveMethod(id)
  }
  return (
    <div
      onClick={handleSelectMethod}
      role="button"
      onKeyDown={Function.prototype}
      tabIndex={0}
      className={`relative bg-white h-full w-full  flex justify-center items-center rounded-xl  cursor-pointer z-10 ${
        activeMethod === id ? "border-4 border-tertiary z-50" : "border-none"
      }`}
      id={id}
    >
      {children}
      <img
        fetchpriority="low"
        loading="lazy"
        decoding="async"
        src={checkMarkAsset}
        alt="check-mark-asset"
        className={`${
          activeMethod === id
            ? "inline-block absolute -top-2 -right-2 z-50 rounded-full bg-white"
            : "hidden z-50"
        }`}
      />
    </div>
  )
}

PaymentCard.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
}

const CreditCardswitch = () => {
  const navigate = useNavigate()
  const amount = getAmount()
  logger(amount)
  const [activeMethod, setActiveMethod] = useState(null)
  const context = useMemo(
    () => ({ activeMethod, setActiveMethod }),
    [activeMethod]
  )

  useEffect(() => {
    if (activeMethod) {
      if (activeMethod === "paysage") {
        navigate(`/cashier/${activeMethod}?amount=${amount}`)
      } else {
        navigate(`/cashier/billingaddress?amount=${amount}`)
      }
    }
  }, [activeMethod])

  const handleOnclick = () => {
    navigate(-1)
  }
  return (
    <PaymentMethodContext.Provider value={context}>
      <div className="container-max-width-switchere card-pick-height w-full  bg-white rounded-lg	 py-5 flex flex-col justify-start						 ">
        <div className="h-auto			w-auto  z-0 flex justify-between py-5 px-8	pb-5 ">
          <div
            onClick={handleOnclick}
            role="button"
            onKeyDown={Function.prototype}
            tabIndex={0}
            className="	W-1/2 z-0 flex  items-start "
          >
            <img
              fetchpriority="low"
              loading="lazy"
              decoding="async"
              src={arrowLeft}
              className="h-7 "
              alt="arrowLeft"
            />

            <MediaQuery minWidth={600}>
              <div className="	w-1/2 z-0 flex px-5 text-tertiary card-text-back-style ">
                Back
              </div>
            </MediaQuery>
          </div>
          <div className="	w-1/2  z-0 flex  items-center justify-end">
            <MediaQuery minWidth={600}>
              <img
                fetchpriority="low"
                loading="lazy"
                decoding="async"
                src={cardIcon}
                className="h-8"
                alt="cardIcon"
              />
            </MediaQuery>
            <div className="z-0 text-tertiary card-text-pay-with-credit-style desktop:text-base	 xs:text-xs	 ">
              Pay with Credit Card
            </div>
          </div>
        </div>
        <div className="h-full	w-full  z-0 flex flex-col py-5 px-5  ">
          <div className=" z-0 flex text-black   pb-10 desktop:text-xl	 xs:text-base mobile:text-xs	 card-text-style">
            Click to pick your credit card
          </div>
          <div className=" w-full h-full flex items-center justify-center gap-2 pb-10">
            {paymentMethods.map((method) => (
              <div key={method.label} className="w-64  h-1/2 ">
                <PaymentCard id={method.method}>
                  <div
                    className={`	h-full w-full  p-4 flex items-center justify-center  ${
                      method.method === "paysage"
                        ? "card-master-pick-color"
                        : "card-visa-pick-color"
                    }`}
                  >
                    <div className=" w-3/6 flex items-end justify-center 	">
                      <img
                        fetchpriority="low"
                        loading="lazy"
                        decoding="async"
                        src={method.imageSource}
                        className="h-8"
                        alt="masterCard"
                      />
                    </div>
                  </div>
                </PaymentCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PaymentMethodContext.Provider>
  )
}

CreditCardswitch.propTypes = {}

export default CreditCardswitch
