import { GET_USER_DEPOSIT_BONUS_FULFILLED } from "./types"
import api from "../api"

export const postWithdraw = async (amount, address, pin) => {
  try {
    const response = await api.post("/payments/withdrawal", {
      external_eth_address: address,
      amount: parseFloat(amount),
      pin,
    })

    return response
  } catch (err) {
    return err.response
  }
}

export const putWithdraw = async (pk) => {
  try {
    const response = await api.put(`/payments/withdrawal/cancel/${pk}`)

    return response
  } catch (err) {
    return err.response
  }
}

export const checkUserHasBonusCode = async () => {
  try {
    const response = await api.get("/user/coupon")
    return response
  } catch (err) {
    throw err.response
  }
}

export const postBonusCode = async (code) => {
  try {
    const response = await api.post("/payments/coupon-code", { code })
    return response
  } catch (err) {
    return err.response
  }
}

export const getNodaUrlWidget = async (currency, amount) => {
  try {
    const responseRedirect = await api.post("payments/nodapay/create-payment", {
      currency,
      amount,
    })
    return responseRedirect
  } catch (err) {
    return err.response
  }
}
export const getDepositBonus = () => async (dispatch) => {
  try {
    const response = await api.get("/user/wager")
    const payload = {
      deposit_wagered: response.data.deposit_wagered,
      amount_wagered: response.data.amount_wagered,
    }

    dispatch({
      type: GET_USER_DEPOSIT_BONUS_FULFILLED,
      payload,
    })

    return response
  } catch (err) {
    return err.response
  }
}

export const getPaysageCheckoutUrl = async (amount, currency, language) => {
  try {
    /* paysage  payments  Backend */
    const responseBackend = await api.get("payments/paysage/payment/", {
      params: { amount, currency, language },
    })
    return responseBackend
  } catch (err) {
    return err.response
  }
}

export const getWalletWithCurrency = async (currency) => {
  try {
    const response = await api.get(`/user/wallet/${currency}`)
    return response
  } catch (err) {
    return err.response
  }
}

export const postTip = async (username, amount) => {
  try {
    const response = await api.post("/user/tip", {
      username,
      amount,
    })

    return response
  } catch (err) {
    return err.response
  }
}

export const getPSPCheckoutURL = async () => {
  try {
    const response = await api.get("/payments/credit-card/checkout")
    return response
  } catch (err) {
    return err.response
  }
}

export default {}
