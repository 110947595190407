import {
  GET_USER_FULFILLED,
  GET_USER_ERROR,
  GET_USER_AFFILIATE_FULFILLED,
  POST_USER_METADATA_WALLET_FULFILLED,
  GET_WALLET_FULFILLED,
  POST_AUTH_FULFILLED,
} from "./types"

import api from "../api"
import { toast } from "../components/Toast"
import { logger } from "../helpers"
import MetaEventService from "../services/MetaEventService"

export const getUser = () => async (dispatch) => {
  try {
    const user = await api.get("/user/")
    const kyc = {
      dob: user.data.kyc?.DOB,
      addressLine1: user.data.kyc?.address_line1,
      addressLine2: user.data.kyc?.address_line2,
      city: user.data.kyc?.city,
      country: user.data.kyc?.country,
      firstName: user.data.kyc?.first_name,
      lastName: user.data.kyc?.last_name,
      kycLevel1Complete: user.data.kyc?.level1_complete,
      kycLevel2Complete: user.data.kyc?.level2_complete,
      kycLevel3Complete: user.data.kyc?.level3_complete,
      stateOrProvince: user.data.kyc?.state_or_province,
      postalCode: user.data.kyc?.postal_code,
      gender: user.data.kyc?.gender,
    }

    const userLevelDetails = {
      name: user.data.user_level_details?.name,
      nextLevelWagerToReach:
        user.data.user_level_details?.next_level_wager_to_reach,
      percentageCompletion: user.data.user_level_details?.percentage_completion,
      slut: user.data.user_level_details?.slug,
      totalRewards: user.data.user_level_details?.total_rewards,
      totalWager: user.data.user_level_details?.total_wager,
      wagerToReach: user.data.user_level_details?.wager_to_reach,
    }

    const payload = {
      kyc,
      is2FAActive: user.data.is_2fa_active,
      showDepositBonusPopup: user.data.show_deposit_bonus_popup,
      intercomHash: user.data.intercom_hash,
      currentLevelSlug: user.data.current_level_slug,
      nextLevelSlug: user.data.next_level_slug,
      showLevelRewardPopup: user.data.show_level_reward_popup,
      userLevelDetails,
      bonusActive: user.data.is_bonus_active,
      groups: user.data.groups,
    }

    const walletPayload = {
      balance: user.data.balance,
      ethWalletAddress: user.data.eth_wallet_address,
      bonusActive: user.data.is_bonus_active,
    }

    const authPayload = {
      email: user.data.email,
      username: user.data.username,
      isVerified: user.data.is_verified,
      phoneNumber: user.data.phone_number,
      canBeReferred: user.data.can_be_referred,
    }

    logger(walletPayload)
    logger(user)

    dispatch({
      type: GET_USER_FULFILLED,
      payload,
    })

    dispatch({
      type: POST_AUTH_FULFILLED,
      payload: authPayload,
    })

    dispatch({
      type: GET_WALLET_FULFILLED,
      payload: walletPayload,
    })

    return payload
  } catch (err) {
    dispatch({ type: GET_USER_ERROR, payload: err })

    return err
  }
}

export const getNotifications = () => async (dispatch) => {
  try {
    const notifications =
      JSON.parse(localStorage.getItem("notifications")) || []

    const response = await api.get("/user/notifications")
    const newNotifications = response.data

    newNotifications.forEach((element) => {
      logger("looping through notifications: ")
      logger(element)
      notifications.push(element)

      const {
        meta_event_id: eventID,
        meta_event_type: metaEventType,
        balance,
        amount,
        currency,
      } = element.metadata || {}

      logger("Notification Metadata: ")
      logger(element.metadata)

      if (eventID && metaEventType) {
        const eventType = MetaEventService.eventTypes[metaEventType]

        if (eventType) {
          switch (eventType) {
            case MetaEventService.eventTypes.Purchase:
              MetaEventService.sendPurchaseEvent(amount, currency, eventID)
              logger({ eventID, metaEventType, amount, currency })
              break
            default:
              // Default config
              break
          }
        } else {
          console.error(`Unknown event type: ${metaEventType}`)
        }
      }

      if (balance) {
        dispatch({
          type: POST_USER_METADATA_WALLET_FULFILLED,
          payload: { balance },
        })
      }

      logger("showing async notification toast...")
      toast[element.type](element.title, element.body)
    })

    localStorage.setItem("notifications", JSON.stringify(notifications))

    return response
  } catch (err) {
    return err.response
  }
}

/* getEnable2FA   */
export const getTwoFactorAuthenticatorQrCodeAndSecret = () => async () => {
  try {
    const response = await api.get("user/2fa/enable/")

    return response
  } catch (err) {
    return err.response
  }
}
/* postEnable2FA   */
export const postTwoFactorAuthenticatorEnable = async (secret, pin) => {
  try {
    const response = await api.post("user/2fa/enable/", {
      secret,
      pin,
    })
    return response.status
  } catch (err) {
    return err.response
  }
}
/* post 2FA disable */
export const postTwoFactorAuthenticatorDisable = async (pin) => {
  try {
    const response = await api.post("user/2fa/disable/", {
      pin,
    })
    return response.status
  } catch (err) {
    return err.response
  }
}
/* post 2FA check */
export const postTwoFactorAuthenticatorCheck = async (pin) => {
  try {
    const response = await api.post("user/2fa/check/", {
      pin,
    })
    return response.status
  } catch (err) {
    return err.response
  }
}

export const postUserAffiliation = async (ref, leadId) => {
  try {
    const response = await api.post("/user/affiliation", {
      ref,
      lead_id: leadId,
    })
    return response.status
  } catch (err) {
    return err.response
  }
}

export const getAffiliateInformation = () => async (dispatch) => {
  try {
    const response = await api.get("/user/affiliation")

    const payload = {
      referrer: response?.data?.referrer,
      affiliateInformation: {
        referrals: response?.data?.referral_count,
        numberBet: response?.data?.bet_count,
        deposits: response?.data?.sum_deposit,
      },
    }

    dispatch({
      type: GET_USER_AFFILIATE_FULFILLED,
      payload,
    })

    return response
  } catch (err) {
    throw err.response
  }
}

export const getHistory = async () => {
  try {
    const response = await api.get("/user/history/")
    return response
  } catch (err) {
    throw err.response
  }
}
export const getDepositBonusInfo = async () => {
  try {
    const response = await api.get("user/bonus/")
    return response
  } catch (err) {
    throw err.response
  }
}

export const getDepositBonus = async () => {
  try {
    const response = await api.get("user/deposit_bonus/activate")
    return response
  } catch (err) {
    throw err.response
  }
}

export const getDepositBonusData = async (depositCoupon) => {
  try {
    const response = await api.get(
      `user/deposit_bonus/activate?coupon-deposit-bonus=${depositCoupon}`
    )
    return response
  } catch (err) {
    throw err.response
  }
}

export const activateDepositBonus = async () => {
  try {
    const response = await api.post("user/deposit_bonus/activate")
    return response
  } catch (err) {
    return err.response
  }
}

export const activateDepositBonusCode = async (depositCoupon) => {
  try {
    const response = await api.post(
      `user/deposit_bonus/activate?coupon-deposit-bonus=${depositCoupon}`
    )
    return response
  } catch (err) {
    return err.response
  }
}

export const dismissDepositBonus = async () => {
  try {
    const response = await api.post("user/deposit_bonus/dismiss")
    return response
  } catch (err) {
    throw err.response
  }
}

export const getLevelReward = async () => {
  try {
    const response = await api.get("/user/reward/accept")
    return response
  } catch (err) {
    throw err.response
  }
}

export const activateLevelReward = async () => {
  try {
    const response = await api.post("/user/reward/accept")
    return response
  } catch (err) {
    throw err.response
  }
}

export const dismissLevelRewardPopup = async () => {
  try {
    const response = await api.post("/user/reward/dismiss")
    return response
  } catch (err) {
    throw err.response
  }
}

// action to get the user location
export const getCountryCode = async () => {
  try {
    let countryCode = sessionStorage.getItem("country-code")
    if (countryCode) {
      return countryCode
    }

    const response = await api.get("/user/locate/")
    countryCode = response.data.country_code
    sessionStorage.setItem("country-code", countryCode)
    return countryCode
  } catch (err) {
    throw err.response
  }
}

export default {}
