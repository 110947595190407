import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import MediaQuery from "react-responsive"
// import NftBanner from "../../../components/NftBanner"
import masterCardAsset from "../../../assets/images/home/visa-asset.png"
import visaCardAsset from "../../../assets/images/home/visa-vector-asset.png"
import googlePayAsset from "../../../assets/images/home/thumb-asset.png"
import applePayAsset from "../../../assets/images/home/apple-pay-asset.png"
import Banner from "../../../components/Banner"
import Button from "../../../components/Button"
import "../style.css"
import themes from "../../../themes"
import PageLink from "../../../components/PageLink"
import { getBrandFromUrl } from "../../../helpers"

const BannerSection = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { loggedIn } = useSelector((state) => state.auth)
  const query = new URLSearchParams(location.search)
  const refParam = query.get("ref") ? `ref=${query.get("ref")}` : ""

  const url = loggedIn
    ? `?service=cashier${refParam ? `&${refParam}` : ""}`
    : `?service=login${refParam ? `&${refParam}` : ""}`

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <section
      className="banner-section text-white"
      style={{ backgroundColor: themeColors?.primary, color: "white" }}
    >
      {/* <NftBanner /> */}
      <div className="container-max-width  xs:px-6  desktop:py-5 xs:py-3 desktop-md-screen:px-5">
        <Banner>
          <PageLink to={url}>
            <div
              className="rounded-3xl py-4  xs:px-4 desktop:flex desktop:justify-between desktop:items-center xs:flex xs:flex-col xs:justify-between xs:items-center cursor-pointer"
              style={{
                backgroundColor: themeColors?.secondary,
                color: "white",
              }}
              onKeyDown={Function.prototype}
              role="button"
              tabIndex={0}
            >
              <h1 className="text-[27px] pl-5">{t("Instant Top up")}</h1>
              <div className="flex justify-between items-center xs:flex  xs:my-5 desktop:my-auto">
                <img
                  fetchpriority="low"
                  src={masterCardAsset}
                  alt="master-card-asset"
                  className="desktop:w-full desktop:h-7 mx-3 xs:w-auto xs:h-5"
                />
                <img
                  fetchpriority="low"
                  src={visaCardAsset}
                  alt="visa-card-asset"
                  className="desktop:w-full desktop:h-6 mx-3 xs:w-auto xs:h-5"
                />
                <img
                  fetchpriority="low"
                  src={googlePayAsset}
                  alt="google-pay-asset"
                  className="desktop:w-full desktop:h-9 mx-3 xs:w-auto xs:h-5"
                />
                <img
                  fetchpriority="low"
                  src={applePayAsset}
                  alt="apple-pay-asset"
                  className="desktop:w-full desktop:h-8 mx-3 xs:w-auto xs:h-5"
                />
              </div>
              <div className="flex justify-center xs:my-6 desktop:my-auto desktop:pr-4">
                <MediaQuery minWidth={320} maxWidth={1199}>
                  <Button.Secondary size="small">
                    <span className="text-xs">{t("Let’s go")}</span>
                  </Button.Secondary>
                </MediaQuery>
                <MediaQuery minWidth={1200}>
                  <Button.Secondary>{t("Let’s go")}</Button.Secondary>
                </MediaQuery>
              </div>
            </div>
          </PageLink>
        </Banner>
      </div>
    </section>
  )
}

export default BannerSection
