import React from "react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import BillingAddressSchema from "../model/BillingAddressSchema"
import Input from "./Input"
import countries from "../utils/countries"

import SelectField from "./SelectField"
import Button from "./Button"
import { POST_USER_BILLING_ADDRESS_FULFILLED } from "../actions/types"
import getAmount from "../features/PaymentGateways/helpers"
import { logger } from "../helpers"

const BillingAddressForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const amount = getAmount()
  logger(amount)
  const formOptions = { resolver: yupResolver(BillingAddressSchema) }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = (data) => {
    dispatch({
      type: POST_USER_BILLING_ADDRESS_FULFILLED,
      payload: data,
    })
    navigate(`/cashier/noda/cc?amount=${amount}`)
  }

  return (
    <form>
      <div className="h-full w-full z-50">
        <form className="py-2 px-10 relative z-50">
          <div className="flex xs:flex-col lg:flex-row w-full z-50 ">
            <div className="w-full mb-2 lg:pr-5 z-50">
              <Controller
                control={control}
                name="addressLine1"
                render={({ field, formState }) => (
                  <Input
                    type="text"
                    label={t("Address Line 1")}
                    id="address-line1"
                    {...field}
                    error={formState.errors?.addressLine1}
                  />
                )}
              />
              <span className="text-red-700 text-xs">
                {errors?.addressLine1 && errors.addressLine1.message}
              </span>
            </div>
            <div className="div-above lg:pr-5 xs:mb-2 ">
              <Controller
                control={control}
                name="city"
                render={({ field, formState }) => (
                  <Input
                    type="text"
                    label={`${t("City")}*`}
                    id="city"
                    {...field}
                    error={formState.errors?.city}
                  />
                )}
              />
              <span className="text-red-700 text-xs">
                {errors?.city && errors.city.message}
              </span>
            </div>
          </div>

          <div className="flex xs:flex-col lg:flex-row  ">
            <div className="w-full lg:pr-5 xs:mb-2">
              <Controller
                control={control}
                name="postalCode"
                render={({ field, formState }) => (
                  <Input
                    type="text"
                    label={`${t("Postal Code")}*`}
                    id="postalCode"
                    {...field}
                    error={formState.errors?.postalCode}
                  />
                )}
              />
              <span className="text-red-700 text-xs">
                {errors?.postalCode && errors.postalCode.message}
              </span>
            </div>
            <div className="flex flex-col w-full relative z-50 lg:px-5 xs:mb-2 ">
              <Controller
                control={control}
                name="country"
                render={({ field, formState }) => (
                  <SelectField
                    placeholder={`${t("Country")}*`}
                    options={countries}
                    theme="light"
                    {...field}
                    error={formState.errors?.country}
                  />
                )}
                onChange={([selected]) => ({ value: selected })}
              />
              <span className="text-red-700 text-xs">
                {errors?.country && errors.country.message}
              </span>
            </div>
            <div className="lg:px-5 xs:mb-2">
              <Controller
                control={control}
                defaultValue=""
                name="state"
                render={({ field, formState }) => (
                  <Input
                    type="text"
                    label={t("State/Province")}
                    id="state"
                    {...field}
                    error={formState.errors?.state}
                  />
                )}
              />
              <span className="text-red-700 text-xs">
                {errors?.state && errors.state.message}
              </span>
            </div>
          </div>
          <div className="mt-5 xs:flex xs:justify-center lg:flex lg:justify-end">
            <Button.Primary
              size="small"
              onClick={handleSubmit(onSubmit)}
              onSubmit={handleSubmit(onSubmit)}
            >
              {t("Save")}
            </Button.Primary>
          </div>
        </form>
      </div>
    </form>
  )
}

export default BillingAddressForm
