import { setAuthTokens, clearAuthTokens, getRefreshToken } from "axios-jwt"

import api from "../api"

import {
  POST_LOGIN_FULFILLED,
  POST_LOGIN_ERROR,
  POST_LOGOUT_FULFILLED,
  POST_REGISTER_CHECK_FULFILLED,
  POST_SEND_VERIFICATION_CODE_FULFILLED,
  POST_REGISTER_FULFILLED,
  GO_BACK_REGISTER_FORM_VIEW,
  SET_LOGGED_IN,
  DELETE_USER,
  SITE_LOAD_COMPLETE,
} from "./types"

// eslint-disable-next-line import/no-cycle
import { getBrandFromUrl } from "../helpers"

export const setLoggedIn = () => async (dispatch) => {
  dispatch({ type: SET_LOGGED_IN })
  return true
}

/* login */
export const postLogin =
  (usernameOrEmail, password, pin, userToImpersonate) => async (dispatch) => {
    try {
      const brand = getBrandFromUrl()
      const response = await api.post(
        "/user/auth/login/",
        {
          username: usernameOrEmail,
          password,
          user_to_impersonate: userToImpersonate,
          pin,
        },
        {
          headers: {
            Brand: brand,
          },
        }
      )

      setAuthTokens({
        accessToken: response.data.tokens.access,
        refreshToken: response.data.tokens.refresh,
      })

      dispatch({
        type: POST_LOGIN_FULFILLED,
        payload: {
          username: response.data.username,
          email: response.data.email,
          loggedIn: true,
        },
      })
      return response
    } catch (err) {
      dispatch({
        type: POST_LOGIN_ERROR,
        payload: err,
      })

      return err.response
    }
  }

/* Register */
export const postRegisterCheck =
  (username, email, phoneNumber, password, bonusCode) => async (dispatch) => {
    const referrerCode = localStorage.getItem("referrer")
    const leadId = localStorage.getItem("lead_id")
    try {
      const response = await api.post("/user/auth/register/check/", {
        username,
        email,
        phone_number: phoneNumber,
        password,
        code: bonusCode,
        referrer: referrerCode,
        lead_id: leadId,
      })

      dispatch({
        type: POST_REGISTER_CHECK_FULFILLED,
        payload: {
          username,
          email,
          phoneNumber,
          password,
          bonusCode,
          referrer: referrerCode,
          requestID: response.data.request_id,
        },
      })

      return response
    } catch (err) {
      return err.response
    }
  }

export const goBackToRegisterFormView = () => async (dispatch) => {
  dispatch({
    type: GO_BACK_REGISTER_FORM_VIEW,
  })
}

export const sendPhoneVerificationCode =
  (phoneNumber, channel) => async (dispatch) => {
    try {
      const response = await api.post(
        "/user/auth/register/send-phone-verification",
        {
          phone_number: phoneNumber,
          channel,
        }
      )

      dispatch({
        type: POST_SEND_VERIFICATION_CODE_FULFILLED,
        payload: {
          requestID: response.data.request_id,
        },
      })

      return response
    } catch (err) {
      return err.response
    }
  }
/* Register */
export const postRegister =
  (
    username,
    email,
    phoneNumber,
    password,
    bonusCode,
    otpValue,
    requestID
    // brand
  ) =>
  async (dispatch) => {
    const referrerCode = localStorage.getItem("referrer")
    const leadId = localStorage.getItem("lead_id")
    const brand = getBrandFromUrl()

    if (!brand) {
      throw new Error("Brand is not set in localStorage.")
    }

    try {
      const response = await api.post("/user/auth/register/", {
        username,
        email,
        phone_number: phoneNumber,
        password,
        code: bonusCode,
        referrer: referrerCode,
        lead_id: leadId,
        verification_code: otpValue,
        request_id: requestID,
        brand,
      })
      dispatch({
        type: POST_REGISTER_FULFILLED,
      })

      return response
    } catch (err) {
      return err.response
    }
  }

/* changePassword */
// TODO take it out of auth
export const changePassword = async (password) => {
  try {
    const validationChangePassword = await api.post(
      "/user/auth/request-change-password/",
      { password }
    )
    return validationChangePassword.data.message
  } catch (error) {
    return error.response
  }
}

/* save kyc */
// TODO take it out of auth
export const saveKycLevel1 = async (kyc1) => {
  try {
    const result = await api.post("/user/kyc/level1", kyc1)
    return result
  } catch (err) {
    return err
  }
}

/* logout */
export const postLogOut = async () => async (dispatch) => {
  try {
    const refreshToken = getRefreshToken()
    const logoutResponse = await api.post("/user/auth/logout", {
      refresh: refreshToken,
    })
    dispatch({
      type: POST_LOGOUT_FULFILLED,
      payload: {},
    })
    dispatch({
      type: DELETE_USER,
      payload: {},
    })

    return logoutResponse
  } catch (err) {
    return err.response
  } finally {
    clearAuthTokens()
    Object.keys(localStorage)
      .filter((key) => key !== "brand")
      .forEach((key) => localStorage.removeItem(key))
    sessionStorage.clear()
  }
}

/* send-recovery-email */
export const sendRecoveryEmail = async (email) => {
  try {
    const brand = getBrandFromUrl()
    const sendRecoveryEmailResponse = await api.post(
      "/user/auth/send-recovery-email/",
      { email },
      {
        headers: {
          Brand: brand,
        },
      }
    )
    return sendRecoveryEmailResponse
  } catch (error) {
    return error
  }
}

/* Forget password */
export const forgetPassword = async (password, tokens) => {
  try {
    const brand = getBrandFromUrl()
    const forgetPasswordResponse = await api.post(
      "/user/auth/request-change-password/",
      { password, tokens },
      {
        headers: {
          Brand: brand,
        },
      }
    )
    return forgetPasswordResponse.status
  } catch (error) {
    return error
  }
}

/* email verification */

export const emailVerification = async (email) => {
  try {
    const emailVerificationResponse = await api.post(
      "/user/auth/email-verification/",
      { email }
    )
    return emailVerificationResponse.status
  } catch (error) {
    return error
  }
}

export const siteLoadComplete = () => (dispatch) => {
  dispatch({
    type: SITE_LOAD_COMPLETE,
  })
}
