import React, { useEffect, useState } from "react"
import "./style.css"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useQuery } from "react-query"
import Header from "../../components/Header"
import Hero from "./sections/Hero"
import About from "./sections/About"
import CatalogSection from "./sections/CatalogSection"
import { GET_GAMES_FULFILLED } from "../../actions/types"
import { getGamesCatalog } from "../../actions/game"
import logger, { getBrandFromUrl, promptBrand } from "../../helpers"
import themes from "../../themes"

const GamePage = () => {
  const dispatch = useDispatch()
  useQuery({
    queryKey: ["getGamesCatalog"],
    queryFn: () => getGamesCatalog(),
    refetchOnMount: true,
    onSuccess: ({ data: gamesCatalogData }) => {
      if (gamesCatalogData) {
        dispatch({
          type: GET_GAMES_FULFILLED,
          payload: gamesCatalogData,
        })
      }
    },
    onError: (error) => {
      logger(error)
    },
  })
  const { games } = useSelector((state) => state.games)
  const { slug } = useParams()
  let gameSelected = games.find((game) => game.slug === slug)
  // const navigate = useNavigate()

  useEffect(() => {
    if (!gameSelected) {
      gameSelected = []
    }
  }, [gameSelected])

  const brand = getBrandFromUrl()
  const [themeColors, setThemeColors] = useState(
    themes["www.carbongaming.net"].colors
  )

  useEffect(() => {
    setThemeColors(themes[brand]?.colors)
  }, [brand])

  return (
    <>
      <Header title={`${promptBrand("name")} | Game`} />

      {gameSelected ? <Hero /> : <div />}
      <About game={gameSelected} themeColors={themeColors} />
      <CatalogSection />
    </>
  )
}

export default GamePage
