import moment from "moment/moment"

// TODO: Implement higher level of abstraction
// Consider Builder patter + Reducer pattern

export const formatDepositsTable = (data, isAll) => {
  const tableHeaders = isAll
    ? ["Date", "Amount", "Status"]
    : ["Date", "Type", "Amount", "Status"]
  const tableRows = {
    rowsData: [],
    rowsMetaData: [],
  }
  if (!isAll) {
    data.forEach((item) => {
      const { created, type, amount, status, pk } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        type: type.charAt(0).toUpperCase() + type.slice(1),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: pk,
      })
    })
  } else {
    data.forEach((item) => {
      const { created, type, amount, status, pk } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
        type: type.charAt(0).toUpperCase() + type.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: pk,
      })
    })
  }

  return {
    tableHeaders,
    tableRows,
  }
}

export const formatWithdrawalsTable = (data, isAll) => {
  const tableHeaders = isAll
    ? ["Date", "Amount", "Status"]
    : ["Date", "Type", "Amount", "Status"]
  const tableRows = {
    rowsData: [],
    rowsMetaData: [],
  }
  if (!isAll) {
    data.forEach((item) => {
      const { created, type, amount, status, pk } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        type: type.charAt(0).toUpperCase() + type.slice(1),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: pk,
      })
    })
  } else {
    data.forEach((item) => {
      const { created, type, amount, status, pk } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
        type: type.charAt(0).toUpperCase() + type.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: pk,
      })
    })
  }

  return {
    tableHeaders,
    tableRows,
    hasActions: true,
  }
}

export const formatBetsTable = (data, isAll) => {
  const tableHeaders = isAll
    ? ["Date", "Amount", "Status"]
    : ["Date", "Type", "Amount", "Status"]
  const tableRows = {
    rowsData: [],
    rowsMetaData: [],
  }
  if (!isAll) {
    data.forEach((item, index) => {
      const { created, type, amount, status } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        type: type.charAt(0).toUpperCase() + type.slice(1),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: index,
      })
    })
  } else {
    data.forEach((item, index) => {
      const { created, type, amount, status } = item
      tableRows.rowsData.push({
        date: moment
          .utc(created, "DD-MM-YYYY HH:mm")
          .local()
          .format("D MMM. YYYY HH:mm"),
        amount: amount.toFixed(2),
        status: status.charAt(0).toUpperCase() + status.slice(1),
        type: type.charAt(0).toUpperCase() + type.slice(1),
      })
      tableRows.rowsMetaData.push({
        id: index,
      })
    })
  }
  return {
    tableHeaders,
    tableRows,
  }
}

export const formatWinsTable = (data) => {
  const tableHeaders = ["Date", "Amount", "Status"]
  const tableRows = {
    rowsData: [],
    rowsMetaData: [],
  }
  data.forEach((item, index) => {
    const { created, amount, type, status } = item
    tableRows.rowsData.push({
      date: moment
        .utc(created, "DD-MM-YYYY HH:mm")
        .local()
        .format("D MMM. YYYY HH:mm"),
      amount: amount.toFixed(2),
      status: status.charAt(0).toUpperCase() + status.slice(1),
      type: type.charAt(0).toUpperCase() + type.slice(1),
    })
    tableRows.rowsMetaData.push({
      id: index,
    })
  })

  return {
    tableHeaders,
    tableRows,
  }
}

export const formatBonusesTable = (data) => {
  const tableHeaders = ["Date", "Amount", "Status"]
  const tableRows = {
    rowsData: [],
    rowsMetaData: [],
  }
  data.forEach((item, index) => {
    const { created, amount, type, status } = item
    tableRows.rowsData.push({
      date: moment
        .utc(created, "DD-MM-YYYY HH:mm")
        .local()
        .format("D MMM. YYYY HH:mm"),
      amount: amount.toFixed(2),
      status: status.charAt(0).toUpperCase() + status.slice(1),
      type: type.charAt(0).toUpperCase() + type.slice(1),
    })
    tableRows.rowsMetaData.push({
      id: index,
    })
  })

  return {
    tableHeaders,
    tableRows,
  }
}

export const formatTipsTable = (data, username) => {
  const tableHeaders = ["Date", "Amount", "Receiver", "Sender"]
  const { received, sent } = data
  const receivedTipsRows = {
    rowsData: [],
    rowsMetaData: [],
  }

  received.forEach((item, index) => {
    const { created, amount } = item
    receivedTipsRows.rowsData.push({
      date: moment
        .utc(created, "DD-MM-YYYY HH:mm")
        .local()
        .format("D MMM. YYYY HH:mm"),
      amount: amount.toFixed(2),
      receiver: username,
      sender: item.sender_username,
      type: "Tip",
    })
    receivedTipsRows.rowsMetaData.push({
      id: index,
    })
  })

  const sentTipsRows = {
    rowsData: [],
    rowsMetaData: [],
  }

  sent.forEach((item, index) => {
    const { created, amount } = item
    sentTipsRows.rowsData.push({
      date: moment
        .utc(created, "DD-MM-YYYY HH:mm")
        .local()
        .format("D MMM. YYYY HH:mm"),
      amount: amount.toFixed(2),
      sender: username,
      receiver: item.receiver_username,
      type: "Tip",
    })
    sentTipsRows.rowsMetaData.push({
      id: index,
    })
  })

  return {
    tableHeaders,
    tableRows: {
      rowsData: [...receivedTipsRows.rowsData, ...sentTipsRows.rowsData],
      rowsMetaData: [
        ...receivedTipsRows.rowsMetaData,
        ...sentTipsRows.rowsMetaData,
      ],
    },
  }
}

export const formatSportsBookTable = (data) => {
  const tableHeaders = ["Date", "Status", "Match", "Bet Amount", "Win Amount"]
  const tableRows = data.map((item) => {
    const { created, type, status } = item
    return {
      date: moment
        .utc(created, "DD-MM-YYYY HH:mm")
        .format("DD MMM. YYYY HH:mm"),
      status: status.charAt(0).toUpperCase() + status.slice(1),
      match: "",
      betAmount: item.bet_amount,
      winAmount: item.win_amount,
      type: type.charAt(0).toUpperCase() + type.slice(1),
    }
  })

  return {
    tableHeaders,
    tableRows,
  }
}
