import React, { useState } from "react"
import MediaQuery from "react-responsive"

import { Controller, useForm } from "react-hook-form"

import { useTranslation } from "react-i18next"

import { useDispatch } from "react-redux"

import { useQuery } from "react-query"

import { yupResolver } from "@hookform/resolvers/yup"
import depositBonusCodeSchema from "../model/depositBonusCodeSchema"

import Input from "./Input"
import Dialog from "./Dialog"
import Button from "./Button"
// import moneyBagAsset from "../assets/images/home/money-bag.png"

import {
  brandLogo,
  logger,
  imageDistributed,
  getBrandFromUrl,
} from "../helpers"
import {
  dismissDepositBonus,
  getDepositBonus,
  getDepositBonusData,
  activateDepositBonus,
  activateDepositBonusCode,
  getUser,
} from "../actions/user"
import Spinner from "./Spinner"
import { toast } from "./Toast"
import themes from "../themes"
import { DISMISS_DEPOSIT_POPUP_FULFILLED } from "../actions/types"

const DepositPopup = () => {
  const { t } = useTranslation()
  const [depositBonusWager, setDepositBonusWager] = useState(0)
  const [bonusAmount, setBonusAmount] = useState(0)
  const [depositBonusCoupon, setDepositBonusCoupon] = useState(null)
  const dispatch = useDispatch()

  const { isLoading } = useQuery({
    queryKey: ["getDepositBonus"],
    queryFn: () => getDepositBonus(),
    refetchOnMount: true,
    onSuccess: ({ data: depositBonusData }) => {
      logger(depositBonusData)
      setBonusAmount(
        Math.min(
          depositBonusData.last_deposit_amount *
            (depositBonusData.deposit_bonus_multiplier +
              depositBonusData.deposit_bonus_coupon_amount),
          depositBonusData.deposit_bonus_max_amount
        )
      )
      setDepositBonusWager(depositBonusData.deposit_bonus_wager)
    },

    onError: (error) => {
      logger(error)
    },
  })

  const formOptions = { resolver: yupResolver(depositBonusCodeSchema) }
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const applyDepositCoupon = async (data) => {
    try {
      const depositCouponResult = await getDepositBonusData(data.depositCoupon)

      if (depositCouponResult.status === 200) {
        setBonusAmount(depositCouponResult.data.deposit_bonus_total_amount)
        setDepositBonusCoupon(data.depositCoupon)
        toast.success(
          t("Confirmed Deposit Bonus Code."),
          t(
            "Congratulations, your attempt to increase your deposit bonus is successful! Have fun!"
          )
        )
        reset()
      }
    } catch (e) {
      if (
        e.status === 403 ||
        e.data?.code === "deposit-bonus-incorrect-coupon-code"
      ) {
        toast.error(
          t("Deposit bonus code  denied."),
          t(
            "The code you attempt to use to increase your deposit bonus is invalid! Please try again!"
          )
        )
        reset()
      }
    }
  }

  const onClickActivateDepositBonus = async () => {
    let bonusActivationResult
    if (depositBonusCoupon === null || depositBonusCoupon.trim().length === 0) {
      bonusActivationResult = await activateDepositBonus()
    } else {
      bonusActivationResult = await activateDepositBonusCode(depositBonusCoupon)
    }
    logger(bonusActivationResult)
    if (bonusActivationResult.status === 201) {
      toast.success(
        t("Bonus Code Activated !"),
        t("Congratulations, you have successfully activated your Bonus!")
      )
      dispatch(getUser())
    } else if (
      bonusActivationResult.data?.code === "deposit-bonus-cant-be-activated"
    ) {
      toast.warning(
        t("Deposit Bonus"),
        t(" You can't activate your deposit bonus. Contact support.")
      )
    } else if (bonusActivationResult.data?.code === "no-deposit") {
      // TODO: nameing
      toast.warning(t("No Deposit"), t("You need to make a first deposit."))
    }
    dispatch({
      type: DISMISS_DEPOSIT_POPUP_FULFILLED,
    })
  }

  const onClickdismissDepositBonus = async () => {
    const bonusDismissResult = await dismissDepositBonus()
    if (bonusDismissResult.status === 202) {
      dispatch({
        type: DISMISS_DEPOSIT_POPUP_FULFILLED,
      })
    }
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Dialog>
      <div className=" h-full w-full py-2 ">
        {isLoading ? (
          <div className=" h-[200px] w-full flex justify-center items-center ">
            <Spinner />
          </div>
        ) : (
          <div className="flex-col   justify-center items-center  xl:pt-5">
            <MediaQuery maxWidth={750}>
              <div className="flex justify-start items-center mb-20  pl-5">
                <img
                  fetchpriority="high"
                  loading="lazy"
                  decoding="async"
                  src={brandLogo()}
                  alt="logo-mobile-version"
                  className="w-36 h-auto"
                />
              </div>
            </MediaQuery>

            <h1 className="text-center text-2xl font-bold mb-2">
              {t("Congratulations !")}
            </h1>
            <div className="text-center text-base mobile:px-8">
              <span className="">
                {t("We offer you the chance to get a deposit bonus gift of")}
              </span>
              <span
                className="  "
                style={{ color: themeColors?.tertiary }}
              >{` ${bonusAmount}$`}</span>
            </div>
            <div className="xs:mb-5 xl:mb-auto text-center text-xs flex justify-center items-center">
              {t("This deposit bonus has a wager of ")}
              {`${depositBonusWager}x`}
            </div>
            <div className="flex flex-col gap-4 justify-center items-center mt-4 mb-5 py-5">
              <img
                fetchpriority="high"
                loading="lazy"
                decoding="async"
                src={imageDistributed("depositBonusAsset")}
                alt="logo-mobile-version"
                className="w-24 h-auto"
              />
              <form className="">
                <div className=" flex items-stretch gap-1">
                  <div className="relative">
                    <Controller
                      name="depositCoupon"
                      control={control}
                      render={({ field, formState }) => (
                        <Input
                          type="text"
                          placeholder={t("Promo code")}
                          label="Bonus Code"
                          error={formState.errors?.depositCoupon}
                          {...field}
                        />
                      )}
                    />
                    <span className="absolute b-0 text-sm text-red-200">
                      {errors.depositCoupon && errors.depositCoupon.message}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="px-2 py-1 bg-customIndigo rounded-md"
                    style={{ backgroundColor: themeColors?.customIndigo }}
                    onSubmit={handleSubmit(applyDepositCoupon)}
                    onClick={handleSubmit(applyDepositCoupon)}
                  >
                    {t("Apply")}
                  </button>
                </div>
              </form>
            </div>
            <div className=" ml-2 w-full text-center py-2 mb-2 flex justify-center items-center px-2 ">
              <Button.Primary
                size="small"
                onClick={onClickActivateDepositBonus}
              >
                <span className="text-sm "> {t("Continue")}</span>
              </Button.Primary>
              <Button.Secondary
                size="small"
                onClick={onClickdismissDepositBonus}
              >
                <span className="text-sm ">{t("Decline Gift")}</span>
              </Button.Secondary>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default DepositPopup
