import * as Yup from "yup"

export default Yup.object().shape({
  password: Yup.string()
    .required("Password is mandatory")
    .min(8, "Password must be at least 8 characters long.")

    .test(
      "specialCharacter",
      "The password should include at least one special character.",
      (value) => [/[^\w\s]/].every((pattern) => pattern.test(value))
    )
    .test(
      "specialCharacter",
      "The password should include at least one special character.",
      (value) => [/[^\w\s]/].every((pattern) => pattern.test(value))
    )
    .test(
      "oneNumber",
      "The password should include at least one number.",
      (value) => [/[0-9]/].every((pattern) => pattern.test(value))
    ),

  passwordConfirmation: Yup.string()
    .required("Password is mandatory")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
})
