import * as Yup from "yup"
import regexZipCode from "../utils/postalCodeData.json"

export default Yup.object().shape({
  addressLine1: Yup.string()
    .required("The address is required.")
    .max(255, "The address is too long.")
    .matches(
      /^[0-9]+[a-zA-Z]?(?:\s[a-zA-ZÀ-ÿ'-]+)+$/,
      "The address is not valid."
    ),
  city: Yup.string()
    .required("The city is required.")
    .max(25, "The city's name is too long.")
    .matches(
      /^[a-zA-ZÀ-ÿ]+(?:[-\s][a-zA-ZÀ-ÿ]+)*$/,
      "The city's name need to be only letter."
    ),
  state: Yup.string()
    .max(20, "The state's name is too long.")
    .matches(
      /^[a-zA-ZÀ-ÿ]+(?:[-\s][a-zA-ZÀ-ÿ]+)*$/,
      " state need to be only letter."
    ),
  country: Yup.object().required("The country is required."),
  postalCode: Yup.string()
    .required("Postal code is required.")
    .when(["country"], (country, schema) => {
      if (country) {
        const regExp = regexZipCode[country?.value]
        return schema.matches(regExp, {
          message: "Invalid postal code.",
          excludeEmptyString: true,
        })
      }
      return schema
    }),
})
