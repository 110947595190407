import React from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import Button from "./Button"
import PageLink from "./PageLink"
// import playButton from "../assets/images/cards/play-button.png"
import { imageDistributed } from "../helpers"

const GameCard = ({
  categoryName,
  className,
  slug,
  imageSource,
  title,
  description,
}) => (
  <PageLink
    to={`/games/${slug}`}
    classNames={`${className} game-card rounded-3xl relative   mb-2 md:mb-0 md:flex-1 xs:mb-8 block`}
  >
    <div className="card-items rounded-3xl w-full h-full py-5 hidden">
      <p className="game-card-title text-tertiary text-center font-bold text-md">
        {title}
      </p>
      <p className="game-card-description text-center px-3 mb-10">
        {`${description?.substr(0, 50)} ...`}
      </p>
      <p className="font-light xl:text-xl md:text-2xl xs:text-base">
        {categoryName && <span>Category : {categoryName}</span>}
      </p>
      <img
        fetchpriority="high"
        src={imageDistributed("gamePlayButton")}
        alt="play-asset"
        className="game-card-play-button"
      />
      <div className=" absolute bottom-4 mb-4 z-10 flex justify-center w-full hidden">
        <Button.Primary size="small">Play Now</Button.Primary>
      </div>
    </div>
    <img
      fetchpriority="high"
      src={imageSource}
      alt="card-game"
      className="card-game-image rounded-3xl"
    />
    <div className=" absolute hidden bottom-4 mt-8 mb-7 z-10 flex justify-center w-full">
      <MediaQuery minWidth={959}>
        <Button.Primary size="small">Play Now</Button.Primary>
      </MediaQuery>
    </div>
  </PageLink>
)

GameCard.defaultProps = {
  categoryName: "",
  className: "",
  description: "No description",
  title: "No title",
}

GameCard.propTypes = {
  categoryName: PropTypes.string,
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default GameCard
