export const gamesAssets = {
  "No_Limit_City-Legion_X": new URL(
    "../assets/images/games/No_Limit_City-Legion_X.png?w=200",
    import.meta.url
  ),
  "No_Limit_City-Evil_Goblins_xBomb": new URL(
    "../assets/images/games/No_Limit_City-Evil_Goblins_xBomb.png?w=200",
    import.meta.url
  ),
  "No_Limit_City-Das_xBoot": new URL(
    "../assets/images/games/No_Limit_City-Das_xBoot.png?w=200",
    import.meta.url
  ),
  "No_Limit_City-Mental": new URL(
    "../assets/images/games/No_Limit_City-Mental.png?w=200",
    import.meta.url
  ),
  "No_Limit_City-Infectious_5_xWays": new URL(
    "../assets/images/games/No_Limit_City-Infectious_5_xWays.png?w=200",
    import.meta.url
  ),
  "PragmaticPlay-Congo_Cash": new URL(
    "../assets/images/games/PragmaticPlay-Congo_Cash.png?w=200",
    import.meta.url
  ),
  "PragmaticPlay-Madame_Destiny_Megaways": new URL(
    "../assets/images/games/PragmaticPlay-Madame_Destiny_Megaways.png?w=200",
    import.meta.url
  ),
  "PragmaticPlay-Dragon_Kingdom___Eyes_of_Fire": new URL(
    "../assets/images/games/PragmaticPlay-Dragon_Kingdom___Eyes_of_Fire.png?w=200",
    import.meta.url
  ),
  "PragmaticPlay-Eye_of_the_Storm": new URL(
    "../assets/images/games/PragmaticPlay-Eye_of_the_Storm.png?w=200",
    import.meta.url
  ),
  "PragmaticPlay-Joker_King": new URL(
    "../assets/images/games/PragmaticPlay-Joker_King.png?w=200",
    import.meta.url
  ),
  "SmartSoft-JetX": new URL(
    "../assets/images/games/SmartSoft-JetX.png?w=200",
    import.meta.url
  ),
  "Evolution2-Lightning_Roulette": new URL(
    "../assets/images/games/Evolution2-Lightning_Roulette.png?w=200",
    import.meta.url
  ),
  "Jetgames-_Plinko": new URL(
    "../assets/images/games/Jetgames-_Plinko.png?w=200",
    import.meta.url
  ),
  "Platipus-Fruit_Boost": new URL(
    `../assets/images/games/Platipus-Fruit_Boost.png?w=200`,
    import.meta.url
  ),
  "Playson-Book_of_Gold__Multichance": new URL(
    `../assets/images/games/Playson-Book_of_Gold__Multichance.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fire_Archer": new URL(
    `../assets/images/games/PragmaticPlay-Fire_Archer.png?w=200`,
    import.meta.url
  ),
  "Playson-Royal_Coins_2__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Royal_Coins_2__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "BGaming-Heads_and_Tails_XY": new URL(
    `../assets/images/games/BGaming-Heads_and_Tails_XY.png?w=200`,
    import.meta.url
  ),
  "Platipus-Joker_Chase": new URL(
    `../assets/images/games/Platipus-Joker_Chase.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Texas_Hold_em_Bonus_Poker": new URL(
    `../assets/images/games/Evolution2-Texas_Hold_em_Bonus_Poker.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Gold_Hunter": new URL(
    `../assets/images/games/BoomingGames-Gold_Hunter.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wizarding_Wins": new URL(
    `../assets/images/games/BoomingGames-Wizarding_Wins.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Diamond_Riches": new URL(
    `../assets/images/games/BoomingGames-Diamond_Riches.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Buffalo_Hold_and_Win": new URL(
    `../assets/images/games/BoomingGames-Buffalo_Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Mustang_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Mustang_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Golden_Beauty": new URL(
    `../assets/images/games/PragmaticPlay-Golden_Beauty.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Buffalo_King": new URL(
    `../assets/images/games/PragmaticPlay-Buffalo_King.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Great_Rhino_Deluxe": new URL(
    `../assets/images/games/PragmaticPlay-Great_Rhino_Deluxe.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Panda_s_Fortune": new URL(
    `../assets/images/games/PragmaticPlay-Panda_s_Fortune.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Tree_of_Riches": new URL(
    `../assets/images/games/PragmaticPlay-Tree_of_Riches.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dance_Party": new URL(
    `../assets/images/games/PragmaticPlay-Dance_Party.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wild_Wild_Riches": new URL(
    `../assets/images/games/PragmaticPlay-Wild_Wild_Riches.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Lady_Godiva": new URL(
    `../assets/images/games/PragmaticPlay-Lady_Godiva.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Baccarat": new URL(
    `../assets/images/games/Vivogaming-Baccarat.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Roulette": new URL(
    `../assets/images/games/Vivogaming-Roulette.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Blackjack": new URL(
    `../assets/images/games/Vivogaming-Blackjack.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Casino_Hold_em": new URL(
    `../assets/images/games/Vivogaming-Casino_Hold_em.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Dragon_Tiger": new URL(
    `../assets/images/games/Vivogaming-Dragon_Tiger.png?w=200`,
    import.meta.url
  ),
  "Vivogaming-Teen_Patti": new URL(
    `../assets/images/games/Vivogaming-Teen_Patti.png?w=200`,
    import.meta.url
  ),
  "BGaming-Sic_Bo": new URL(
    `../assets/images/games/BGaming-Sic_Bo.png?w=200`,
    import.meta.url
  ),
  "BGaming-Sic_Bo_Macau": new URL(
    `../assets/images/games/BGaming-Sic_Bo_Macau.png?w=200`,
    import.meta.url
  ),
  "BGaming-Texas_Hold_em": new URL(
    `../assets/images/games/BGaming-Texas_Hold_em.png?w=200`,
    import.meta.url
  ),
  "BGaming-Trey_Poker": new URL(
    `../assets/images/games/BGaming-Trey_Poker.png?w=200`,
    import.meta.url
  ),
  "BGaming-Wild_Texas": new URL(
    `../assets/images/games/BGaming-Wild_Texas.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Crazy_Coin_Flip": new URL(
    `../assets/images/games/Evolution2-Crazy_Coin_Flip.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Lightning_Blackjack": new URL(
    `../assets/images/games/Evolution2-First_Person_Lightning_Blackjack.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Dream_Catcher": new URL(
    `../assets/images/games/Evolution2-First_Person_Dream_Catcher.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Football_studio": new URL(
    `../assets/images/games/Evolution2-Football_studio.png?w=200`,
    import.meta.url
  ),
  "BGaming-Rocket_Dice_XY": new URL(
    `../assets/images/games/BGaming-Rocket_Dice_XY.png?w=200`,
    import.meta.url
  ),
  "BGaming-Minesweeper_XY": new URL(
    `../assets/images/games/BGaming-Minesweeper_XY.png?w=200`,
    import.meta.url
  ),
  "BGaming-Plinko_XY": new URL(
    `../assets/images/games/BGaming-Plinko_XY.png?w=200`,
    import.meta.url
  ),
  "BGaming-Space_XY": new URL(
    `../assets/images/games/BGaming-Space_XY.png?w=200`,
    import.meta.url
  ),
  "BGaming-Rocket_Dice": new URL(
    `../assets/images/games/BGaming-Rocket_Dice.png?w=200`,
    import.meta.url
  ),
  "BGaming-Minesweeper": new URL(
    `../assets/images/games/BGaming-Minesweeper.png?w=200`,
    import.meta.url
  ),
  "BGaming-Heads_and_Tails": new URL(
    `../assets/images/games/BGaming-Heads_and_Tails.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Spaceman": new URL(
    `../assets/images/games/PragmaticPlay-Spaceman.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-JetX3": new URL(
    `../assets/images/games/SmartSoft-JetX3.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Cappadocia": new URL(
    `../assets/images/games/SmartSoft-Cappadocia.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Balloon": new URL(
    `../assets/images/games/SmartSoft-Balloon.png?w=200`,
    import.meta.url
  ),
  "BGaming-Wild_Cash_X9990": new URL(
    `../assets/images/games/BGaming-Wild_Cash_X9990.png?w=200`,
    import.meta.url
  ),
  "BGaming-Forty_Fruity_Million": new URL(
    `../assets/images/games/BGaming-Forty_Fruity_Million.png?w=200`,
    import.meta.url
  ),
  "BGaming-Beer_Bonanza": new URL(
    `../assets/images/games/BGaming-Beer_Bonanza.png?w=200`,
    import.meta.url
  ),
  "BGaming-Halloween_Bonanza": new URL(
    `../assets/images/games/BGaming-Halloween_Bonanza.png?w=200`,
    import.meta.url
  ),
  "BGaming-Book_of_Cats_Megaways": new URL(
    `../assets/images/games/BGaming-Book_of_Cats_Megaways.png?w=200`,
    import.meta.url
  ),
  "BGaming-Soccermania": new URL(
    `../assets/images/games/BGaming-Soccermania.png?w=200`,
    import.meta.url
  ),
  "BGaming-Burning_Chilli_X": new URL(
    `../assets/images/games/BGaming-Burning_Chilli_X.png?w=200`,
    import.meta.url
  ),
  "BGaming-Gangsterz": new URL(
    `../assets/images/games/BGaming-Gangsterz.png?w=200`,
    import.meta.url
  ),
  "BGaming-Gift_Rush": new URL(
    `../assets/images/games/BGaming-Gift_Rush.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Monkey_Madness": new URL(
    `../assets/images/games/PragmaticPlay-Monkey_Madness.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Pyramid_King": new URL(
    `../assets/images/games/PragmaticPlay-Pyramid_King.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wolf_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Wolf_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Caishen_s_Cash": new URL(
    `../assets/images/games/PragmaticPlay-Caishen_s_Cash.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wild_Spells": new URL(
    `../assets/images/games/PragmaticPlay-Wild_Spells.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hot_to_Burn": new URL(
    `../assets/images/games/PragmaticPlay-Hot_to_Burn.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Extra_Juicy": new URL(
    `../assets/images/games/PragmaticPlay-Extra_Juicy.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Bronco_Spirit": new URL(
    `../assets/images/games/PragmaticPlay-Bronco_Spirit.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Star_Bounty": new URL(
    `../assets/images/games/PragmaticPlay-Star_Bounty.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Greek_Gods": new URL(
    `../assets/images/games/PragmaticPlay-Greek_Gods.png?w=200`,
    import.meta.url
  ),
  "Jetgames-mines": new URL(
    `../assets/images/games/Jetgames-mines.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-John_Hunter_and_the_Book_of_Tut": new URL(
    `../assets/images/games/PragmaticPlay-John_Hunter_and_the_Book_of_Tut.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Triple_Tigers": new URL(
    `../assets/images/games/PragmaticPlay-Triple_Tigers.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Super_Joker": new URL(
    `../assets/images/games/PragmaticPlay-Super_Joker.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Lucky_New_Year": new URL(
    `../assets/images/games/PragmaticPlay-Lucky_New_Year.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Ancient_Egypt_Classic": new URL(
    `../assets/images/games/PragmaticPlay-Ancient_Egypt_Classic.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Super_7s": new URL(
    `../assets/images/games/PragmaticPlay-Super_7s.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Pirate_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Pirate_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Monkey_Warrior": new URL(
    `../assets/images/games/PragmaticPlay-Monkey_Warrior.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dragon_Kingdom": new URL(
    `../assets/images/games/PragmaticPlay-Dragon_Kingdom.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Gold_Rush": new URL(
    `../assets/images/games/PragmaticPlay-Gold_Rush.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wild_Walker": new URL(
    `../assets/images/games/PragmaticPlay-Wild_Walker.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Asgard": new URL(
    `../assets/images/games/PragmaticPlay-Asgard.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Treasure_Horse": new URL(
    `../assets/images/games/PragmaticPlay-Treasure_Horse.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Release_the_Kraken": new URL(
    `../assets/images/games/PragmaticPlay-Release_the_Kraken.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fruit_Rainbow": new URL(
    `../assets/images/games/PragmaticPlay-Fruit_Rainbow.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Wild_Machine": new URL(
    `../assets/images/games/PragmaticPlay-The_Wild_Machine.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Drago___Jewels_of_Fortune": new URL(
    `../assets/images/games/PragmaticPlay-Drago___Jewels_of_Fortune.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dwarven_Gold_Deluxe": new URL(
    `../assets/images/games/PragmaticPlay-Dwarven_Gold_Deluxe.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Curse_of_the_Werewolf_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-Curse_of_the_Werewolf_Megaways.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wild_Pixies": new URL(
    `../assets/images/games/PragmaticPlay-Wild_Pixies.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hercules_and_Pegasus": new URL(
    `../assets/images/games/PragmaticPlay-Hercules_and_Pegasus.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Peking_Luck": new URL(
    `../assets/images/games/PragmaticPlay-Peking_Luck.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Mighty_Kong": new URL(
    `../assets/images/games/PragmaticPlay-Mighty_Kong.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Pixie_Wings": new URL(
    `../assets/images/games/PragmaticPlay-Pixie_Wings.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Egyptian_Fortunes": new URL(
    `../assets/images/games/PragmaticPlay-Egyptian_Fortunes.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Ultra_Burn": new URL(
    `../assets/images/games/PragmaticPlay-Ultra_Burn.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-John_Hunter_and_the_Aztec_Treasure": new URL(
    `../assets/images/games/PragmaticPlay-John_Hunter_and_the_Aztec_Treasure.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hot_Safari": new URL(
    `../assets/images/games/PragmaticPlay-Hot_Safari.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fairytale_Fortune": new URL(
    `../assets/images/games/PragmaticPlay-Fairytale_Fortune.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Book_Of_Kingdoms": new URL(
    `../assets/images/games/PragmaticPlay-Book_Of_Kingdoms.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Gold_Train": new URL(
    `../assets/images/games/PragmaticPlay-Gold_Train.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-7_Monkeys": new URL(
    `../assets/images/games/PragmaticPlay-7_Monkeys.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Triple_Dragons": new URL(
    `../assets/images/games/PragmaticPlay-Triple_Dragons.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Mysterious": new URL(
    `../assets/images/games/PragmaticPlay-Mysterious.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Magic_Journey": new URL(
    `../assets/images/games/PragmaticPlay-Magic_Journey.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Safari_King": new URL(
    `../assets/images/games/PragmaticPlay-Safari_King.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Jungle_Gorilla": new URL(
    `../assets/images/games/PragmaticPlay-Jungle_Gorilla.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Santa": new URL(
    `../assets/images/games/PragmaticPlay-Santa.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-3_Genie_Wishes": new URL(
    `../assets/images/games/PragmaticPlay-3_Genie_Wishes.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Queen_of_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Queen_of_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Christmas_Carol_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-Christmas_Carol_Megaways.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Cowboys_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Cowboys_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Emerald_King": new URL(
    `../assets/images/games/PragmaticPlay-Emerald_King.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Pirate_Gold_Deluxe": new URL(
    `../assets/images/games/PragmaticPlay-Pirate_Gold_Deluxe.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Dragon_Tiger": new URL(
    `../assets/images/games/PragmaticPlay-The_Dragon_Tiger.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Great_Chicken_Escape": new URL(
    `../assets/images/games/PragmaticPlay-The_Great_Chicken_Escape.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Master_Chen_s_Fortune": new URL(
    `../assets/images/games/PragmaticPlay-Master_Chen_s_Fortune.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Triple_Jokers": new URL(
    `../assets/images/games/PragmaticPlay-Triple_Jokers.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Madame_Destiny": new URL(
    `../assets/images/games/PragmaticPlay-Madame_Destiny.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Honey_Honey_Honey": new URL(
    `../assets/images/games/PragmaticPlay-Honey_Honey_Honey.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Vegas_Nights": new URL(
    `../assets/images/games/PragmaticPlay-Vegas_Nights.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hercules_Son_of_Zeus": new URL(
    `../assets/images/games/PragmaticPlay-Hercules_Son_of_Zeus.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Vampires_vs_Wolves": new URL(
    `../assets/images/games/PragmaticPlay-Vampires_vs_Wolves.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-7_Piggies": new URL(
    `../assets/images/games/PragmaticPlay-7_Piggies.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Jurassic_Giants": new URL(
    `../assets/images/games/PragmaticPlay-Jurassic_Giants.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Leprechaun_Song": new URL(
    `../assets/images/games/PragmaticPlay-Leprechaun_Song.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Street_Racer": new URL(
    `../assets/images/games/PragmaticPlay-Street_Racer.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Leprechaun_Carol": new URL(
    `../assets/images/games/PragmaticPlay-Leprechaun_Carol.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Ancient_Egypt": new URL(
    `../assets/images/games/PragmaticPlay-Ancient_Egypt.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Jade_Butterfly": new URL(
    `../assets/images/games/PragmaticPlay-Jade_Butterfly.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Panther_Queen": new URL(
    `../assets/images/games/PragmaticPlay-Panther_Queen.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Aladdin_and_the_Sorcerer": new URL(
    `../assets/images/games/PragmaticPlay-Aladdin_and_the_Sorcerer.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Great_Reef": new URL(
    `../assets/images/games/PragmaticPlay-Great_Reef.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-El_Paso_Gunfight_xNudge": new URL(
    `../assets/images/games/No_Limit_City-El_Paso_Gunfight_xNudge.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Bushido_Ways_xNudge": new URL(
    `../assets/images/games/No_Limit_City-Bushido_Ways_xNudge.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Fire_In_The_Hole_xBomb": new URL(
    `../assets/images/games/No_Limit_City-Fire_In_The_Hole_xBomb.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-East_Coast_Vs_West_Coast": new URL(
    `../assets/images/games/No_Limit_City-East_Coast_Vs_West_Coast.png?w=200`,
    import.meta.url
  ),
  "Evoplay-Rock_Paper_Scissors": new URL(
    `../assets/images/games/Evoplay-Rock_Paper_Scissors.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Aladdin_s_Treasure": new URL(
    `../assets/images/games/PragmaticPlay-Aladdin_s_Treasure.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Glorious_Rome": new URL(
    `../assets/images/games/PragmaticPlay-Glorious_Rome.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Magic_Crystals": new URL(
    `../assets/images/games/PragmaticPlay-Magic_Crystals.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dwarven_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Dwarven_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Romeo_and_Juliet": new URL(
    `../assets/images/games/PragmaticPlay-Romeo_and_Juliet.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-San_Quentin_xWays": new URL(
    `../assets/images/games/No_Limit_City-San_Quentin_xWays.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Tomb_of_Akenhaten": new URL(
    `../assets/images/games/No_Limit_City-Tomb_of_Akenhaten.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Warrior_Graveyard_xNudge": new URL(
    `../assets/images/games/No_Limit_City-Warrior_Graveyard_xNudge.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Monkey_s_Gold_xPays": new URL(
    `../assets/images/games/No_Limit_City-Monkey_s_Gold_xPays.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Buffalo_Hunter": new URL(
    `../assets/images/games/No_Limit_City-Buffalo_Hunter.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Queen_of_Atlantis": new URL(
    `../assets/images/games/PragmaticPlay-Queen_of_Atlantis.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hockey_League": new URL(
    `../assets/images/games/PragmaticPlay-Hockey_League.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hockey_League_Wild_Match": new URL(
    `../assets/images/games/PragmaticPlay-Hockey_League_Wild_Match.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Catfather": new URL(
    `../assets/images/games/PragmaticPlay-The_Catfather.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Catfather_Part_II": new URL(
    `../assets/images/games/PragmaticPlay-The_Catfather_Part_II.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Book_Of_Shadows": new URL(
    `../assets/images/games/No_Limit_City-Book_Of_Shadows.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Immortal_Fruits": new URL(
    `../assets/images/games/No_Limit_City-Immortal_Fruits.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Golden_Genie___the_Walking_Wilds": new URL(
    `../assets/images/games/No_Limit_City-Golden_Genie___the_Walking_Wilds.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Milky_Ways": new URL(
    `../assets/images/games/No_Limit_City-Milky_Ways.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Bonus_Bunnies": new URL(
    `../assets/images/games/No_Limit_City-Bonus_Bunnies.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Champions": new URL(
    `../assets/images/games/PragmaticPlay-The_Champions.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Queen_of_Gold_100_000": new URL(
    `../assets/images/games/PragmaticPlay-Queen_of_Gold_100_000.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Panda_Gold_10_000": new URL(
    `../assets/images/games/PragmaticPlay-Panda_Gold_10_000.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Hot_Safari_50_000": new URL(
    `../assets/images/games/PragmaticPlay-Hot_Safari_50_000.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Gold_Rush_250_000": new URL(
    `../assets/images/games/PragmaticPlay-Gold_Rush_250_000.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Deadwood_xNudge": new URL(
    `../assets/images/games/No_Limit_City-Deadwood_xNudge.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Harlequin_Carnival": new URL(
    `../assets/images/games/No_Limit_City-Harlequin_Carnival.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Gaelic_Gold": new URL(
    `../assets/images/games/No_Limit_City-Gaelic_Gold.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Barbarian_Fury": new URL(
    `../assets/images/games/No_Limit_City-Barbarian_Fury.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Punk_Rocker": new URL(
    `../assets/images/games/No_Limit_City-Punk_Rocker.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Diamond_Strike_100_000": new URL(
    `../assets/images/games/PragmaticPlay-Diamond_Strike_100_000.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-7_Piggies_5_000": new URL(
    `../assets/images/games/PragmaticPlay-7_Piggies_5_000.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-3_Kingdoms___Battle_of_Red_Cliffs": new URL(
    `../assets/images/games/PragmaticPlay-3_Kingdoms___Battle_of_Red_Cliffs.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Big_Bass_Bonanza": new URL(
    `../assets/images/games/PragmaticPlay-Big_Bass_Bonanza.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-John_Hunter_And_The_Mayan_Gods": new URL(
    `../assets/images/games/PragmaticPlay-John_Hunter_And_The_Mayan_Gods.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Poison_Eve": new URL(
    `../assets/images/games/No_Limit_City-Poison_Eve.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Dragon_Tribe": new URL(
    `../assets/images/games/No_Limit_City-Dragon_Tribe.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Tomb_Of_Nefertiti": new URL(
    `../assets/images/games/No_Limit_City-Tomb_Of_Nefertiti.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Manhattan_Goes_Wild": new URL(
    `../assets/images/games/No_Limit_City-Manhattan_Goes_Wild.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Pixies_vs_Pirates": new URL(
    `../assets/images/games/No_Limit_City-Pixies_vs_Pirates.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Mysterious_Egypt": new URL(
    `../assets/images/games/PragmaticPlay-Mysterious_Egypt.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Spartan_King": new URL(
    `../assets/images/games/PragmaticPlay-Spartan_King.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Voodoo_Magic": new URL(
    `../assets/images/games/PragmaticPlay-Voodoo_Magic.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Lucky_Lightning": new URL(
    `../assets/images/games/PragmaticPlay-Lucky_Lightning.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dragon_Hot_Hold_and_Spin": new URL(
    `../assets/images/games/PragmaticPlay-Dragon_Hot_Hold_and_Spin.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Hot_4_Cash": new URL(
    `../assets/images/games/No_Limit_City-Hot_4_Cash.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Thor_Hammer_Time": new URL(
    `../assets/images/games/No_Limit_City-Thor_Hammer_Time.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Tractor_Beam": new URL(
    `../assets/images/games/No_Limit_City-Tractor_Beam.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Mayan_Magic_Wildfire": new URL(
    `../assets/images/games/No_Limit_City-Mayan_Magic_Wildfire.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Tombstone": new URL(
    `../assets/images/games/No_Limit_City-Tombstone.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Heart_of_Rio": new URL(
    `../assets/images/games/PragmaticPlay-Heart_of_Rio.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Panda_s_Fortune_2": new URL(
    `../assets/images/games/PragmaticPlay-Panda_s_Fortune_2.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Magic_Cauldron___Enchanted_Brew": new URL(
    `../assets/images/games/PragmaticPlay-The_Magic_Cauldron___Enchanted_Brew.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Cash_Elevator": new URL(
    `../assets/images/games/PragmaticPlay-Cash_Elevator.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Phoenix_Forge": new URL(
    `../assets/images/games/PragmaticPlay-Phoenix_Forge.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Starstruck": new URL(
    `../assets/images/games/No_Limit_City-Starstruck.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Owls": new URL(
    `../assets/images/games/No_Limit_City-Owls.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Fruits": new URL(
    `../assets/images/games/No_Limit_City-Fruits.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Ice_Ice_Yeti": new URL(
    `../assets/images/games/No_Limit_City-Ice_Ice_Yeti.png?w=200`,
    import.meta.url
  ),
  "No_Limit_City-Dungeon_Quest": new URL(
    `../assets/images/games/No_Limit_City-Dungeon_Quest.png?w=200`,
    import.meta.url
  ),
  "BGaming-Plinko": new URL(
    `../assets/images/games/BGaming-Plinko.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Mega_Ball": new URL(
    `../assets/images/games/Evolution2-Mega_Ball.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Monopoly_Big_Baller": new URL(
    `../assets/images/games/Evolution2-Monopoly_Big_Baller.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Crazy_Time": new URL(
    `../assets/images/games/Evolution2-Crazy_Time.png?w=200`,
    import.meta.url
  ),
  "Evolution2-XXXTreme_Lightning_Roulette": new URL(
    `../assets/images/games/Evolution2-XXXTreme_Lightning_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Gonzo_s_Treasure_Hunt": new URL(
    `../assets/images/games/Evolution2-Gonzo_s_Treasure_Hunt.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Fan_Tan": new URL(
    `../assets/images/games/Evolution2-Fan_Tan.png?w=200`,
    import.meta.url
  ),
  "Jetgames-Comet_crash": new URL(
    `../assets/images/games/Jetgames-Comet_crash.png?w=200`,
    import.meta.url
  ),
  "TripleCherry-Goal_Crash": new URL(
    `../assets/images/games/TripleCherry-Goal_Crash.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Caishen_s_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Caishen_s_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Ultra_Hold_and_Spin": new URL(
    `../assets/images/games/PragmaticPlay-Ultra_Hold_and_Spin.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fire_88": new URL(
    `../assets/images/games/PragmaticPlay-Fire_88.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Great_Rhino_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-Great_Rhino_Megaways.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-John_Hunter_and_the_Tomb_of_the_Scarab_Queen": new URL(
    `../assets/images/games/PragmaticPlay-John_Hunter_and_the_Tomb_of_the_Scarab_Queen.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-5_Lions_Gold": new URL(
    `../assets/images/games/PragmaticPlay-5_Lions_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-5_Lions": new URL(
    `../assets/images/games/PragmaticPlay-5_Lions.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Sweet_Bonanza_Xmas": new URL(
    `../assets/images/games/PragmaticPlay-Sweet_Bonanza_Xmas.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Return_of_the_Dead": new URL(
    `../assets/images/games/PragmaticPlay-Return_of_the_Dead.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Master_Joker": new URL(
    `../assets/images/games/PragmaticPlay-Master_Joker.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Chilli_Heat": new URL(
    `../assets/images/games/PragmaticPlay-Chilli_Heat.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Aztec_Gems": new URL(
    `../assets/images/games/PragmaticPlay-Aztec_Gems.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Joker_s_Jewels": new URL(
    `../assets/images/games/PragmaticPlay-Joker_s_Jewels.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Vegas_Magic": new URL(
    `../assets/images/games/PragmaticPlay-Vegas_Magic.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fire_Strike": new URL(
    `../assets/images/games/PragmaticPlay-Fire_Strike.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Aztec_Gems_Deluxe": new URL(
    `../assets/images/games/PragmaticPlay-Aztec_Gems_Deluxe.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Great_Rhino": new URL(
    `../assets/images/games/PragmaticPlay-Great_Rhino.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-888_Dragons": new URL(
    `../assets/images/games/PragmaticPlay-888_Dragons.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-8_Dragons": new URL(
    `../assets/images/games/PragmaticPlay-8_Dragons.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-5_Lions_Dance": new URL(
    `../assets/images/games/PragmaticPlay-5_Lions_Dance.png?w=200`,
    import.meta.url
  ),
  "BGaming-Oasis_Poker": new URL(
    `../assets/images/games/BGaming-Oasis_Poker.png?w=200`,
    import.meta.url
  ),
  "BGaming-Pontoon": new URL(
    `../assets/images/games/BGaming-Pontoon.png?w=200`,
    import.meta.url
  ),
  "BGaming-Scratch_Dice": new URL(
    `../assets/images/games/BGaming-Scratch_Dice.png?w=200`,
    import.meta.url
  ),
  "BGaming-Hi_Lo_Switch": new URL(
    `../assets/images/games/BGaming-Hi_Lo_Switch.png?w=200`,
    import.meta.url
  ),
  /* "BGaming-Jacks_or_Better": new URL(
    `../assets/images/games/BGaming-Jacks_or_Better.png?w=200`,
    import.meta.url
  ), */
  "BGaming-Multihand_Blackjack": new URL(
    `../assets/images/games/BGaming-Multihand_Blackjack.png?w=200`,
    import.meta.url
  ),
  "BGaming-French_Roulette": new URL(
    `../assets/images/games/BGaming-French_Roulette.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-European_Roulette___Announced_Bets": new URL(
    `../assets/images/games/Tomhorn-European_Roulette___Announced_Bets.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-La_Partage": new URL(
    `../assets/images/games/Tomhorn-La_Partage.png?w=200`,
    import.meta.url
  ),
  "Platipus-Aces_and_Faces": new URL(
    `../assets/images/games/Platipus-Aces_and_Faces.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-American_Blackjack": new URL(
    `../assets/images/games/PragmaticPlay-American_Blackjack.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Roulette": new URL(
    `../assets/images/games/PragmaticPlay-Roulette.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Baccarat": new URL(
    `../assets/images/games/PragmaticPlay-Baccarat.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Multihand_Blackjack": new URL(
    `../assets/images/games/PragmaticPlay-Multihand_Blackjack.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Bonus_Roulette": new URL(
    `../assets/images/games/Evolution2-Crazy_Coin_Flip.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Russian_Keno": new URL(
    `../assets/images/games/SmartSoft-Russian_Keno.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-VIP_Keno": new URL(
    `../assets/images/games/SmartSoft-VIP_Keno.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Virtual_Classic_Roulette": new URL(
    `../assets/images/games/SmartSoft-Virtual_Classic_Roulette.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Virtual_Burning_Roulette": new URL(
    `../assets/images/games/SmartSoft-Virtual_Burning_Roulette.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Virtual_Roulette": new URL(
    `../assets/images/games/SmartSoft-Virtual_Roulette.png?w=200`,
    import.meta.url
  ),
  "BGaming-Double_Exposure": new URL(
    `../assets/images/games/BGaming-Double_Exposure.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Blackjack": new URL(
    `../assets/images/games/Evolution2-First_Person_Blackjack.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Mega_Ball": new URL(
    `../assets/images/games/Evolution2-First_Person_Mega_Ball.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Lightning_Baccarat": new URL(
    `../assets/images/games/Evolution2-First_Person_Lightning_Baccarat.png?w=200`,
    import.meta.url
  ),
  "Platipus-Black_Jack_VIP": new URL(
    `../assets/images/games/Platipus-Black_Jack_VIP.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Craps": new URL(
    `../assets/images/games/Evolution2-First_Person_Craps.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Dragon_Tiger": new URL(
    `../assets/images/games/Evolution2-First_Person_Dragon_Tiger.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Golden_Wealth_Baccarat": new URL(
    `../assets/images/games/Evolution2-First_Person_Golden_Wealth_Baccarat.png?w=200`,
    import.meta.url
  ),
  "BGaming-Baccarat": new URL(
    `../assets/images/games/BGaming-Baccarat.png?w=200`,
    import.meta.url
  ),
  "BGaming-Caribbean_Poker": new URL(
    `../assets/images/games/BGaming-Caribbean_Poker.png?w=200`,
    import.meta.url
  ),
  "BGaming-Casino_Hold_em": new URL(
    `../assets/images/games/BGaming-Casino_Hold_em.png?w=200`,
    import.meta.url
  ),
  "BGaming-American_Roulette": new URL(
    `../assets/images/games/BGaming-American_Roulette.png?w=200`,
    import.meta.url
  ),
  "BGaming-Multihand_Blackjack_Pro": new URL(
    `../assets/images/games/BGaming-Multihand_Blackjack_Pro.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Lightning_Dice": new URL(
    `../assets/images/games/Evolution2-Lightning_Dice.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-European_Roulette": new URL(
    `../assets/images/games/Tomhorn-European_Roulette.png?w=200`,
    import.meta.url
  ),
  "Platipus-European_Roulette": new URL(
    `../assets/images/games/Platipus-European_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Blackjack": new URL(
    `../assets/images/games/Evolution2-Blackjack.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Baccarat": new URL(
    `../assets/images/games/Evolution2-Baccarat.png?w=200`,
    import.meta.url
  ),
  "Platipus-Baccarat_Mini": new URL(
    `../assets/images/games/Platipus-Baccarat_Mini.png?w=200`,
    import.meta.url
  ),
  "Platipus-Baccarat_VIP": new URL(
    `../assets/images/games/Platipus-Baccarat_VIP.png?w=200`,
    import.meta.url
  ),
  "Platipus-Baccarat_Pro": new URL(
    `../assets/images/games/Platipus-Baccarat_Pro.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Triple_Card_Poker": new URL(
    `../assets/images/games/Evolution2-Triple_Card_Poker.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Deal_or_No_Deal": new URL(
    `../assets/images/games/Evolution2-First_Person_Deal_or_No_Deal.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Teen_Patti": new URL(
    `../assets/images/games/Evolution2-Teen_Patti.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Peek_Baccarat": new URL(
    `../assets/images/games/Evolution2-Peek_Baccarat.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Double_Ball_Roulette": new URL(
    `../assets/images/games/Evolution2-Double_Ball_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Super_Andar_Bahar": new URL(
    `../assets/images/games/Evolution2-Super_Andar_Bahar.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Salon_Priv__Roulette": new URL(
    `../assets/images/games/Evolution2-Salon_Priv__Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-T_rk_e_Lightning_Rulet": new URL(
    `../assets/images/games/Evolution2-T_rk_e_Lightning_Rulet.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Roulette": new URL(
    `../assets/images/games/Evolution2-Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Speed_Roulette": new URL(
    `../assets/images/games/Evolution2-Speed_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Auto_Roulette_La_Partage": new URL(
    `../assets/images/games/Evolution2-Auto_Roulette_La_Partage.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Auto_Roulette_VIP": new URL(
    `../assets/images/games/Evolution2-Auto_Roulette_VIP.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Power_Blackjack": new URL(
    `../assets/images/games/Evolution2-Power_Blackjack.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Disco_Bar_7s": new URL(
    `../assets/images/games/BoomingGames-Disco_Bar_7s.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_person_Top_Card": new URL(
    `../assets/images/games/Evolution2-First_person_Top_Card.png?w=200`,
    import.meta.url
  ),
  "Evolution2-First_Person_Baccarat": new URL(
    `../assets/images/games/Evolution2-First_Person_Baccarat.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Craps": new URL(
    `../assets/images/games/Evolution2-Craps.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Infinite_Blackjack": new URL(
    `../assets/images/games/Evolution2-Infinite_Blackjack.png?w=200`,
    import.meta.url
  ),
  "Evolution2-American_Roulette": new URL(
    `../assets/images/games/Evolution2-American_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Super_Sic_Bo": new URL(
    `../assets/images/games/Evolution2-Super_Sic_Bo.png?w=200`,
    import.meta.url
  ),
  "Evolution2-VIP_Roulette": new URL(
    `../assets/images/games/Evolution2-VIP_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Immersive_Roulette": new URL(
    `../assets/images/games/Evolution2-Immersive_Roulette.png?w=200`,
    import.meta.url
  ),
  "Evolution2-MONOPOLY_Live": new URL(
    `../assets/images/games/Evolution2-MONOPOLY_Live.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Casino_Hold_em": new URL(
    `../assets/images/games/Evolution2-Casino_Hold_em.png?w=200`,
    import.meta.url
  ),
  "Evolution2-2_Hand_Casino_Hold_em": new URL(
    `../assets/images/games/Evolution2-2_Hand_Casino_Hold_em.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Dragon_Tiger": new URL(
    `../assets/images/games/Evolution2-Dragon_Tiger.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Extreme_Texas_Hold_em": new URL(
    `../assets/images/games/Evolution2-Extreme_Texas_Hold_em.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Side_Bet_City": new URL(
    `../assets/images/games/Evolution2-Side_Bet_City.png?w=200`,
    import.meta.url
  ),
  "Evolution2-Auto_Roulette": new URL(
    `../assets/images/games/Evolution2-Auto_Roulette.png?w=200`,
    import.meta.url
  ),
  "Platipus-Black_Jack": new URL(
    `../assets/images/games/Platipus-Black_Jack.png?w=200`,
    import.meta.url
  ),
  "Platipus-Jacks_or_Better": new URL(
    `../assets/images/games/Platipus-Jacks_or_Better.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Fluxberry": new URL(
    `../assets/images/games/Tomhorn-Fluxberry.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Hawaiian_Fever": new URL(
    `../assets/images/games/Tomhorn-Hawaiian_Fever.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-3_Mermaids": new URL(
    `../assets/images/games/Tomhorn-3_Mermaids.png?w=200`,
    import.meta.url
  ),

  "Tomhorn-Book_of_Vampires": new URL(
    `../assets/images/games/Tomhorn-Book_of_Vampires.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-243_Christmas_Fruits": new URL(
    `../assets/images/games/Tomhorn-243_Christmas_Fruits.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Lucky_Scarabs": new URL(
    `../assets/images/games/BoomingGames-Lucky_Scarabs.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Freezing_Classics": new URL(
    `../assets/images/games/BoomingGames-Freezing_Classics.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Book_of_Tombs": new URL(
    `../assets/images/games/BoomingGames-Book_of_Tombs.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Cash_Pig": new URL(
    `../assets/images/games/BoomingGames-Cash_Pig.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Sphinx_Fortune": new URL(
    `../assets/images/games/BoomingGames-Sphinx_Fortune.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Mine_Mine_Quest": new URL(
    `../assets/images/games/Tomhorn-Mine_Mine_Quest.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Cricket_Mania": new URL(
    `../assets/images/games/Tomhorn-Cricket_Mania.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Frutopia": new URL(
    `../assets/images/games/Tomhorn-Frutopia.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-PengWins": new URL(
    `../assets/images/games/Tomhorn-PengWins.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Dragon_vs_Phoenix": new URL(
    `../assets/images/games/Tomhorn-Dragon_vs_Phoenix.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Dolphin_s_Luck_2": new URL(
    `../assets/images/games/BoomingGames-Dolphin_s_Luck_2.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Baby_Bloomers": new URL(
    `../assets/images/games/BoomingGames-Baby_Bloomers.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Burning_Classics": new URL(
    `../assets/images/games/BoomingGames-Burning_Classics.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Horror_House": new URL(
    `../assets/images/games/BoomingGames-Horror_House.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wombaroo": new URL(
    `../assets/images/games/BoomingGames-Wombaroo.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-81_Frutas_Grandes": new URL(
    `../assets/images/games/Tomhorn-81_Frutas_Grandes.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-La_Tomatina": new URL(
    `../assets/images/games/Tomhorn-La_Tomatina.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Beastie_Bux": new URL(
    `../assets/images/games/Tomhorn-Beastie_Bux.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Fruits_Go_Pop": new URL(
    `../assets/images/games/Tomhorn-Fruits_Go_Pop.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Space_Jammers": new URL(
    `../assets/images/games/Tomhorn-Space_Jammers.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Astro_Pandas": new URL(
    `../assets/images/games/BoomingGames-Astro_Pandas.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Snail_Race": new URL(
    `../assets/images/games/BoomingGames-Snail_Race.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Dragon_s_Chest": new URL(
    `../assets/images/games/BoomingGames-Dragon_s_Chest.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Rudolph_s_Ride": new URL(
    `../assets/images/games/BoomingGames-Rudolph_s_Ride.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Wolf_Sierra": new URL(
    `../assets/images/games/Tomhorn-Wolf_Sierra.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Gold_X": new URL(
    `../assets/images/games/Tomhorn-Gold_X.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Sweet_Crush": new URL(
    `../assets/images/games/Tomhorn-Sweet_Crush.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Black_Mummy_Dice": new URL(
    `../assets/images/games/Tomhorn-Black_Mummy_Dice.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Wheel_of_Luck": new URL(
    `../assets/images/games/Tomhorn-Wheel_of_Luck.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wunderfest": new URL(
    `../assets/images/games/BoomingGames-Wunderfest.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Golden_Lucky_Pigs": new URL(
    `../assets/images/games/BoomingGames-Golden_Lucky_Pigs.png?w=200`,
    import.meta.url
  ),

  "BoomingGames-Wild_Ocean": new URL(
    `../assets/images/games/BoomingGames-Wild_Ocean.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Santa_s_Kiss": new URL(
    `../assets/images/games/BoomingGames-Santa_s_Kiss.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Stellar_Spins": new URL(
    `../assets/images/games/BoomingGames-Stellar_Spins.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-The_Secret_of_Ba": new URL(
    `../assets/images/games/Tomhorn-The_Secret_of_Ba.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Joker_Reelz": new URL(
    `../assets/images/games/Tomhorn-Joker_Reelz.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-243_Crystal_Fruits_Reversed": new URL(
    `../assets/images/games/Tomhorn-243_Crystal_Fruits_Reversed.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Asia_Wins": new URL(
    `../assets/images/games/BoomingGames-Asia_Wins.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Gladiator_Arena": new URL(
    `../assets/images/games/BoomingGames-Gladiator_Arena.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Crazy_Bananas": new URL(
    `../assets/images/games/BoomingGames-Crazy_Bananas.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Vegas_VIP_Gold_": new URL(
    `../assets/images/games/BoomingGames-Vegas_VIP_Gold_.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Hot_n_Fruity": new URL(
    `../assets/images/games/Tomhorn-Hot_n_Fruity.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Diamond_Hill": new URL(
    `../assets/images/games/Tomhorn-Diamond_Hill.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Spinball": new URL(
    `../assets/images/games/Tomhorn-Spinball.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Golden_Girls": new URL(
    `../assets/images/games/BoomingGames-Golden_Girls.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Leprechaun_s_Lucky_Barrel": new URL(
    `../assets/images/games/BoomingGames-Leprechaun_s_Lucky_Barrel.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Royal_Wins": new URL(
    `../assets/images/games/BoomingGames-Royal_Wins.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-SHERLOCK._A_SCANDAL_IN_BOHEMIA.": new URL(
    `../assets/images/games/Tomhorn-SHERLOCK._A_SCANDAL_IN_BOHEMIA..png?w=200`,
    import.meta.url
  ),
  "Tomhorn-INCA_S_TREASURE": new URL(
    `../assets/images/games/Tomhorn-INCA_S_TREASURE.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-KONGO_BONGO": new URL(
    `../assets/images/games/Tomhorn-KONGO_BONGO.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Cuba_Caliente": new URL(
    `../assets/images/games/BoomingGames-Cuba_Caliente.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Lava_Loca": new URL(
    `../assets/images/games/BoomingGames-Lava_Loca.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Cheeky_Monkeys": new URL(
    `../assets/images/games/BoomingGames-Cheeky_Monkeys.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Golden_Profits": new URL(
    `../assets/images/games/BoomingGames-Golden_Profits.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Super_Boom": new URL(
    `../assets/images/games/BoomingGames-Super_Boom.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Surfin__Reels": new URL(
    `../assets/images/games/BoomingGames-Surfin__Reels.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Frozen_Queen": new URL(
    `../assets/images/games/Tomhorn-Frozen_Queen.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-BLACKBEARD_S_QUEST_MINI_GAME": new URL(
    `../assets/images/games/Tomhorn-BLACKBEARD_S_QUEST_MINI_GAME.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Wild_Weather": new URL(
    `../assets/images/games/Tomhorn-Wild_Weather.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Dragon_Riches": new URL(
    `../assets/images/games/Tomhorn-Dragon_Riches.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Fire__n__Hot": new URL(
    `../assets/images/games/Tomhorn-Fire__n__Hot.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-243_Crystal_Fruits": new URL(
    `../assets/images/games/Tomhorn-243_Crystal_Fruits.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Red_Lights": new URL(
    `../assets/images/games/Tomhorn-Red_Lights.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Show_me_the_Mummy": new URL(
    `../assets/images/games/BoomingGames-Show_me_the_Mummy.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Valley_of_Pharaohs": new URL(
    `../assets/images/games/BoomingGames-Valley_of_Pharaohs.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Dragon_Egg": new URL(
    `../assets/images/games/Tomhorn-Dragon_Egg.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Monster_Madness": new URL(
    `../assets/images/games/Tomhorn-Monster_Madness.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Panda_s_Run": new URL(
    `../assets/images/games/Tomhorn-Panda_s_Run.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Spinosaurus": new URL(
    `../assets/images/games/BoomingGames-Spinosaurus.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wild_Energy": new URL(
    `../assets/images/games/BoomingGames-Wild_Energy.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Classico": new URL(
    `../assets/images/games/BoomingGames-Classico.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-The_Cup": new URL(
    `../assets/images/games/Tomhorn-The_Cup.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Sky_Barons": new URL(
    `../assets/images/games/Tomhorn-Sky_Barons.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Feng_Fu": new URL(
    `../assets/images/games/Tomhorn-Feng_Fu.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wild_Pride": new URL(
    `../assets/images/games/BoomingGames-Wild_Pride.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Sugar_Skulls": new URL(
    `../assets/images/games/BoomingGames-Sugar_Skulls.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Colossal_Vikings": new URL(
    `../assets/images/games/BoomingGames-Colossal_Vikings.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Savannah_King": new URL(
    `../assets/images/games/Tomhorn-Savannah_King.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Hot_Blizzard": new URL(
    `../assets/images/games/Tomhorn-Hot_Blizzard.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Sizable_Win": new URL(
    `../assets/images/games/Tomhorn-Sizable_Win.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Black_Mummy": new URL(
    `../assets/images/games/Tomhorn-Black_Mummy.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Triple_Joker": new URL(
    `../assets/images/games/Tomhorn-Triple_Joker.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Flaming_Fruit": new URL(
    `../assets/images/games/Tomhorn-Flaming_Fruit.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Vegas_Wins": new URL(
    `../assets/images/games/BoomingGames-Vegas_Wins.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Booming_Bananas": new URL(
    `../assets/images/games/BoomingGames-Booming_Bananas.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Big_Apple_Wins": new URL(
    `../assets/images/games/BoomingGames-Big_Apple_Wins.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wild_Diamond_7x": new URL(
    `../assets/images/games/BoomingGames-Wild_Diamond_7x.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Witches_Wild_Brew": new URL(
    `../assets/images/games/BoomingGames-Witches_Wild_Brew.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Paris_Nights": new URL(
    `../assets/images/games/BoomingGames-Paris_Nights.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Blackbeard_s_Quest": new URL(
    `../assets/images/games/Tomhorn-Blackbeard_s_Quest.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Book_Of_Spells": new URL(
    `../assets/images/games/Tomhorn-Book_Of_Spells.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Hooked": new URL(
    `../assets/images/games/BoomingGames-Hooked.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Boomshakalaka": new URL(
    `../assets/images/games/BoomingGames-Boomshakalaka.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Aztec_Palace": new URL(
    `../assets/images/games/BoomingGames-Aztec_Palace.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-VIP_Filthy_Riches": new URL(
    `../assets/images/games/BoomingGames-VIP_Filthy_Riches.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Monkey_27": new URL(
    `../assets/images/games/Tomhorn-Monkey_27.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Don_Juan_s_Peppers": new URL(
    `../assets/images/games/Tomhorn-Don_Juan_s_Peppers.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Danger_Zone": new URL(
    `../assets/images/games/BoomingGames-Danger_Zone.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Gunspinner": new URL(
    `../assets/images/games/BoomingGames-Gunspinner.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Arabian_Spins": new URL(
    `../assets/images/games/BoomingGames-Arabian_Spins.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Gold_Vein": new URL(
    `../assets/images/games/BoomingGames-Gold_Vein.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Booming_Seven_Deluxe": new URL(
    `../assets/images/games/BoomingGames-Booming_Seven_Deluxe.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Show_Master": new URL(
    `../assets/images/games/BoomingGames-Show_Master.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Thrones_Of_Persia": new URL(
    `../assets/images/games/Tomhorn-Thrones_Of_Persia.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Geisha_s_Fan": new URL(
    `../assets/images/games/Tomhorn-Geisha_s_Fan.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Shaolin_Tiger": new URL(
    `../assets/images/games/Tomhorn-Shaolin_Tiger.png?w=200`,
    import.meta.url
  ),
  "Tomhorn-Rot_Stormo_40": new URL(
    `../assets/images/games/Tomhorn-Rot_Stormo_40.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Mr_Chu_Tycoon": new URL(
    `../assets/images/games/Spadegaming-Mr_Chu_Tycoon.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Gold_Panther": new URL(
    `../assets/images/games/Spadegaming-Gold_Panther.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Triple_Panda": new URL(
    `../assets/images/games/Spadegaming-Triple_Panda.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Magical_lamp": new URL(
    `../assets/images/games/Spadegaming-Magical_lamp.png?w=200`,
    import.meta.url
  ),
  "Mascot-The_Tomb": new URL(
    `../assets/images/games/Mascot-The_Tomb.png?w=200`,
    import.meta.url
  ),
  "Mascot-The_Rite": new URL(
    `../assets/images/games/Mascot-The_Rite.png?w=200`,
    import.meta.url
  ),
  "Mascot-Lion_s_Pride": new URL(
    `../assets/images/games/Mascot-Lion_s_Pride.png?w=200`,
    import.meta.url
  ),
  "Mascot-CanCan_Saloon": new URL(
    `../assets/images/games/Mascot-CanCan_Saloon.png?w=200`,
    import.meta.url
  ),
  "Mascot-Northern_Heat": new URL(
    `../assets/images/games/Mascot-Northern_Heat.png?w=200`,
    import.meta.url
  ),
  "Mascot-Re_Kill": new URL(
    `../assets/images/games/Mascot-Re_Kill.png?w=200`,
    import.meta.url
  ),
  "Mascot-Anksunamun__the_queen_of_Egypt": new URL(
    `../assets/images/games/Mascot-Anksunamun__the_queen_of_Egypt.png?w=200`,
    import.meta.url
  ),
  "Jetgames-Keno": new URL(
    `../assets/images/games/Jetgames-Keno.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Dancing_Fever": new URL(
    `../assets/images/games/Spadegaming-Dancing_Fever.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Sweet_Bakery": new URL(
    `../assets/images/games/Spadegaming-Sweet_Bakery.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Three_Lucky_Stars": new URL(
    `../assets/images/games/Spadegaming-Three_Lucky_Stars.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Money_Mouse": new URL(
    `../assets/images/games/Spadegaming-Money_Mouse.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Book_of_Myth": new URL(
    `../assets/images/games/Spadegaming-Book_of_Myth.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Double_Flame": new URL(
    `../assets/images/games/Spadegaming-Double_Flame.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Joker_s_Treasure": new URL(
    `../assets/images/games/Spadegaming-Joker_s_Treasure.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Fishing_War": new URL(
    `../assets/images/games/Spadegaming-Fishing_War.png?w=200`,
    import.meta.url
  ),
  "Spadegaming-Fishing_God": new URL(
    `../assets/images/games/Spadegaming-Fishing_God.png?w=200`,
    import.meta.url
  ),
  "Playson-Divine_Dragon__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Divine_Dragon__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-5_Super_Sevens___Fruits__6_reels": new URL(
    `../assets/images/games/Playson-5_Super_Sevens___Fruits__6_reels.png?w=200`,
    import.meta.url
  ),
  "Playson-5_Super_Sevens___Fruits": new URL(
    `../assets/images/games/Playson-5_Super_Sevens___Fruits.png?w=200`,
    import.meta.url
  ),
  "Playson-Super_Sunny_Fruits": new URL(
    `../assets/images/games/Playson-Super_Sunny_Fruits.png?w=200`,
    import.meta.url
  ),
  "Playson-Solar_King": new URL(
    `../assets/images/games/Playson-Solar_King.png?w=200`,
    import.meta.url
  ),
  "Playson-Diamond_Wins__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Diamond_Wins__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Buffalo_Power__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Buffalo_Power__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Sunny_Fruits__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Sunny_Fruits__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Rome__Caesar_s_Glory": new URL(
    `../assets/images/games/Playson-Rome__Caesar_s_Glory.png?w=200`,
    import.meta.url
  ),
  "Playson-Sevens_Fruits": new URL(
    `../assets/images/games/Playson-Sevens_Fruits.png?w=200`,
    import.meta.url
  ),
  "Playson-Solar_Queen": new URL(
    `../assets/images/games/Playson-Solar_Queen.png?w=200`,
    import.meta.url
  ),
  "Playson-Fruits_and_Jokers__100_Lines": new URL(
    `../assets/images/games/Playson-Fruits_and_Jokers__100_Lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Blackjack_Mid": new URL(
    `../assets/images/games/Playson-Blackjack_Mid.png?w=200`,
    import.meta.url
  ),
  "Playson-Mighty_Africa": new URL(
    `../assets/images/games/Playson-Mighty_Africa.png?w=200`,
    import.meta.url
  ),
  "Playson-Burning_Wins__classic_5_lines": new URL(
    `../assets/images/games/Playson-Burning_Wins__classic_5_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Ruby_Hit__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Ruby_Hit__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Juice_Inc.": new URL(
    `../assets/images/games/Playson-Juice_Inc..png?w=200`,
    import.meta.url
  ),
  "Playson-Lion_Gems__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Lion_Gems__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Joker_s_Coins__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Joker_s_Coins__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Diamond_Fortunator__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Diamond_Fortunator__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Burning_Wins_x2": new URL(
    `../assets/images/games/Playson-Burning_Wins_x2.png?w=200`,
    import.meta.url
  ),
  "Playson-Royal_Coins__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Royal_Coins__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Spirit_of_Egypt__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Spirit_of_Egypt__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Eagle_Power__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Eagle_Power__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-5_Fortunator": new URL(
    `../assets/images/games/Playson-5_Fortunator.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Zombie_Queen": new URL(
    `../assets/images/games/Kalamba-Zombie_Queen.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Griffin_s_Quest": new URL(
    `../assets/images/games/Kalamba-Griffin_s_Quest.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Blazing_Bull_2": new URL(
    `../assets/images/games/Kalamba-Blazing_Bull_2.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Caribbean_Anne_2": new URL(
    `../assets/images/games/Kalamba-Caribbean_Anne_2.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Times_Xmas": new URL(
    `../assets/images/games/Kalamba-Joker_Times_Xmas.png?w=200`,
    import.meta.url
  ),
  "Playson-Hot_Burning_Wins": new URL(
    `../assets/images/games/Playson-Hot_Burning_Wins.png?w=200`,
    import.meta.url
  ),
  "Playson-9_Happy_Pharaohs": new URL(
    `../assets/images/games/Playson-9_Happy_Pharaohs.png?w=200`,
    import.meta.url
  ),
  "Playson-Rich_Diamonds__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Rich_Diamonds__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Platipus-Pearls_of_the_Ocean": new URL(
    `../assets/images/games/Platipus-Pearls_of_the_Ocean.png?w=200`,
    import.meta.url
  ),
  "Platipus-Diamond_Hunt": new URL(
    `../assets/images/games/Platipus-Diamond_Hunt.png?w=200`,
    import.meta.url
  ),
  "Platipus-Leprechaun_s_Coins": new URL(
    `../assets/images/games/Platipus-Leprechaun_s_Coins.png?w=200`,
    import.meta.url
  ),
  "Playson-Hot_Coins__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Hot_Coins__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Wolf_Power__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Wolf_Power__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Imperial_Fruits__40_lines": new URL(
    `../assets/images/games/Playson-Imperial_Fruits__40_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Wild_Burning_Wins__5_lines": new URL(
    `../assets/images/games/Playson-Wild_Burning_Wins__5_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Joker_Expand__40_Lines": new URL(
    `../assets/images/games/Playson-Joker_Expand__40_Lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Book_of_Gold__Classic": new URL(
    `../assets/images/games/Playson-Book_of_Gold__Classic.png?w=200`,
    import.meta.url
  ),
  "Platipus-Bamboo_Grove": new URL(
    `../assets/images/games/Platipus-Bamboo_Grove.png?w=200`,
    import.meta.url
  ),
  "Platipus-Guises_of_Dracula": new URL(
    `../assets/images/games/Platipus-Guises_of_Dracula.png?w=200`,
    import.meta.url
  ),
  "Platipus-Might_of_Zeus": new URL(
    `../assets/images/games/Platipus-Might_of_Zeus.png?w=200`,
    import.meta.url
  ),
  "Platipus-1001_Spins": new URL(
    `../assets/images/games/Platipus-1001_Spins.png?w=200`,
    import.meta.url
  ),
  "Platipus-Lord_of_the_Sun": new URL(
    `../assets/images/games/Platipus-Lord_of_the_Sun.png?w=200`,
    import.meta.url
  ),
  "Platipus-7___Hot_Fruits": new URL(
    `../assets/images/games/Platipus-7___Hot_Fruits.png?w=200`,
    import.meta.url
  ),
  "Platipus-Wealth_of_Wisdom": new URL(
    `../assets/images/games/Platipus-Wealth_of_Wisdom.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Bangkok_Dreams": new URL(
    `../assets/images/games/Kalamba-Bangkok_Dreams.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Atlantis_Thunder_St_Patrick_s_Edition": new URL(
    `../assets/images/games/Kalamba-Atlantis_Thunder_St_Patrick_s_Edition.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Age_of_Dragons": new URL(
    `../assets/images/games/Kalamba-Age_of_Dragons.png?w=200`,
    import.meta.url
  ),
  "Platipus-Hawaiian_Night": new URL(
    `../assets/images/games/Platipus-Hawaiian_Night.png?w=200`,
    import.meta.url
  ),
  "Platipus-Santa_s_Bag": new URL(
    `../assets/images/games/Platipus-Santa_s_Bag.png?w=200`,
    import.meta.url
  ),
  "Platipus-Royal_Lotus": new URL(
    `../assets/images/games/Platipus-Royal_Lotus.png?w=200`,
    import.meta.url
  ),
  "Platipus-Chilli_Fiesta": new URL(
    `../assets/images/games/Platipus-Chilli_Fiesta.png?w=200`,
    import.meta.url
  ),
  "Platipus-Jackpot_Lab": new URL(
    `../assets/images/games/Platipus-Jackpot_Lab.png?w=200`,
    import.meta.url
  ),
  "Playson-3_Fruits_Win__10_lines": new URL(
    `../assets/images/games/Playson-3_Fruits_Win__10_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Book_of_Gold__Double_Chance": new URL(
    `../assets/images/games/Playson-Book_of_Gold__Double_Chance.png?w=200`,
    import.meta.url
  ),
  "Playson-Rise_of_Egypt": new URL(
    `../assets/images/games/Playson-Rise_of_Egypt.png?w=200`,
    import.meta.url
  ),
  "Playson-Fruits___Jokers__20_Lines": new URL(
    `../assets/images/games/Playson-Fruits___Jokers__20_Lines.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Sea_Secret": new URL(
    `../assets/images/games/Gamebeat-Sea_Secret.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Odin_s_Tree": new URL(
    `../assets/images/games/Gamebeat-Odin_s_Tree.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Max_Miner": new URL(
    `../assets/images/games/Gamebeat-Max_Miner.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Fortune_Five_Double": new URL(
    `../assets/images/games/Gamebeat-Fortune_Five_Double.png?w=200`,
    import.meta.url
  ),
  "Playson-Fruits___Clovers__20_lines": new URL(
    `../assets/images/games/Playson-Fruits___Clovers__20_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-100_Joker_Staxx": new URL(
    `../assets/images/games/Playson-100_Joker_Staxx.png?w=200`,
    import.meta.url
  ),
  "Playson-Wild_Warriors": new URL(
    `../assets/images/games/Playson-Wild_Warriors.png?w=200`,
    import.meta.url
  ),
  "Platipus-Aztec_Coins": new URL(
    `../assets/images/games/Platipus-Aztec_Coins.png?w=200`,
    import.meta.url
  ),
  "Platipus-Book_of_Egypt": new URL(
    `../assets/images/games/Platipus-Book_of_Egypt.png?w=200`,
    import.meta.url
  ),
  "Platipus-Juicy_Spins": new URL(
    `../assets/images/games/Platipus-Juicy_Spins.png?w=200`,
    import.meta.url
  ),
  "Platipus-Coinfest": new URL(
    `../assets/images/games/Platipus-Coinfest.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Book_Of_Ancients": new URL(
    `../assets/images/games/Gamebeat-Book_Of_Ancients.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Witch_Treasures": new URL(
    `../assets/images/games/Gamebeat-Witch_Treasures.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Lord_Of_The_Seas": new URL(
    `../assets/images/games/Gamebeat-Lord_Of_The_Seas.png?w=200`,
    import.meta.url
  ),
  "Playson-Treasures_of_Fire__Scatter_Pays": new URL(
    `../assets/images/games/Playson-Treasures_of_Fire__Scatter_Pays.png?w=200`,
    import.meta.url
  ),
  "Platipus-Book_of_Light": new URL(
    `../assets/images/games/Platipus-Book_of_Light.png?w=200`,
    import.meta.url
  ),
  "Platipus-Wild_Crowns": new URL(
    `../assets/images/games/Platipus-Wild_Crowns.png?w=200`,
    import.meta.url
  ),
  "Platipus-Piedra_del_Sol": new URL(
    `../assets/images/games/Platipus-Piedra_del_Sol.png?w=200`,
    import.meta.url
  ),
  "Platipus-9_Gems": new URL(
    `../assets/images/games/Platipus-9_Gems.png?w=200`,
    import.meta.url
  ),
  "Platipus-Posh_Cats": new URL(
    `../assets/images/games/Platipus-Posh_Cats.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Fortune_Three_Xmas": new URL(
    `../assets/images/games/Gamebeat-Fortune_Three_Xmas.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Buffalo_trail": new URL(
    `../assets/images/games/Gamebeat-Buffalo_trail.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-ConquestEra": new URL(
    `../assets/images/games/Gamebeat-ConquestEra.png?w=200`,
    import.meta.url
  ),
  "Playson-Luxor_Gold__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Luxor_Gold__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Platipus-Frozen_Mirror": new URL(
    `../assets/images/games/Platipus-Frozen_Mirror.png?w=200`,
    import.meta.url
  ),
  "Platipus-Little_Witchy": new URL(
    `../assets/images/games/Platipus-Little_Witchy.png?w=200`,
    import.meta.url
  ),
  "Platipus-Un_Dia_De_Muertos": new URL(
    `../assets/images/games/Platipus-Un_Dia_De_Muertos.png?w=200`,
    import.meta.url
  ),
  "Platipus-Wild_Justice": new URL(
    `../assets/images/games/Platipus-Wild_Justice.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Fortune_Five": new URL(
    `../assets/images/games/Gamebeat-Fortune_Five.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Fortune_Three": new URL(
    `../assets/images/games/Gamebeat-Fortune_Three.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Book_of_Hor": new URL(
    `../assets/images/games/Gamebeat-Book_of_Hor.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Book_of_Elixir": new URL(
    `../assets/images/games/Gamebeat-Book_of_Elixir.png?w=200`,
    import.meta.url
  ),
  "Platipus-Dynasty_Warriors": new URL(
    `../assets/images/games/Platipus-Dynasty_Warriors.png?w=200`,
    import.meta.url
  ),
  "Platipus-Wild_Spin": new URL(
    `../assets/images/games/Platipus-Wild_Spin.png?w=200`,
    import.meta.url
  ),
  "Platipus-Da_Ji_Da_Li": new URL(
    `../assets/images/games/Platipus-Da_Ji_Da_Li.png?w=200`,
    import.meta.url
  ),
  "Platipus-The_Ancient_Four": new URL(
    `../assets/images/games/Platipus-The_Ancient_Four.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Raging_Lion": new URL(
    `../assets/images/games/Gamebeat-Raging_Lion.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Throne_of_Camelot": new URL(
    `../assets/images/games/Gamebeat-Throne_of_Camelot.png?w=200`,
    import.meta.url
  ),
  "Gamebeat-Dangerous_Monster": new URL(
    `../assets/images/games/Gamebeat-Dangerous_Monster.png?w=200`,
    import.meta.url
  ),
  "Playson-Pearl_Beauty__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Pearl_Beauty__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Platipus-Dragon_s_Element": new URL(
    `../assets/images/games/Platipus-Dragon_s_Element.png?w=200`,
    import.meta.url
  ),
  "Platipus-Caishen_s_Gifts": new URL(
    `../assets/images/games/Platipus-Caishen_s_Gifts.png?w=200`,
    import.meta.url
  ),
  "Playson-Solar_Temple": new URL(
    `../assets/images/games/Playson-Solar_Temple.png?w=200`,
    import.meta.url
  ),
  "Platipus-Pirates_Map": new URL(
    `../assets/images/games/Platipus-Pirates_Map.png?w=200`,
    import.meta.url
  ),
  "Playson-Imperial_Fruits__100_lines": new URL(
    `../assets/images/games/Playson-Imperial_Fruits__100_lines.png?w=200`,
    import.meta.url
  ),
  "Platipus-Chinese_Tigers": new URL(
    `../assets/images/games/Platipus-Chinese_Tigers.png?w=200`,
    import.meta.url
  ),
  "Platipus-Rhino_Mania": new URL(
    `../assets/images/games/Platipus-Rhino_Mania.png?w=200`,
    import.meta.url
  ),
  "Platipus-Webby_Heroes": new URL(
    `../assets/images/games/Platipus-Webby_Heroes.png?w=200`,
    import.meta.url
  ),
  "Platipus-Lucky_Cat": new URL(
    `../assets/images/games/Platipus-Lucky_Cat.png?w=200`,
    import.meta.url
  ),
  "Platipus-Bison_Trail": new URL(
    `../assets/images/games/Platipus-Bison_Trail.png?w=200`,
    import.meta.url
  ),
  "Platipus-Jade_Valley": new URL(
    `../assets/images/games/Platipus-Jade_Valley.png?w=200`,
    import.meta.url
  ),
  "Platipus-Neon_Classic": new URL(
    `../assets/images/games/Platipus-Neon_Classic.png?w=200`,
    import.meta.url
  ),
  "Platipus-Power_of_Poseidon": new URL(
    `../assets/images/games/Platipus-Power_of_Poseidon.png?w=200`,
    import.meta.url
  ),
  "Platipus-Great_Ocean": new URL(
    `../assets/images/games/Platipus-Great_Ocean.png?w=200`,
    import.meta.url
  ),
  "Platipus-Mega_Drago": new URL(
    `../assets/images/games/Platipus-Mega_Drago.png?w=200`,
    import.meta.url
  ),
  "Platipus-Legend_of_Atlantis": new URL(
    `../assets/images/games/Platipus-Legend_of_Atlantis.png?w=200`,
    import.meta.url
  ),
  "Platipus-Triple_Dragon": new URL(
    `../assets/images/games/Platipus-Triple_Dragon.png?w=200`,
    import.meta.url
  ),
  "Platipus-Aztec_Temple": new URL(
    `../assets/images/games/Platipus-Aztec_Temple.png?w=200`,
    import.meta.url
  ),
  "Platipus-Monkey_s_Journey": new URL(
    `../assets/images/games/Platipus-Monkey_s_Journey.png?w=200`,
    import.meta.url
  ),
  "Platipus-Cinderella": new URL(
    `../assets/images/games/Platipus-Cinderella.png?w=200`,
    import.meta.url
  ),
  "Platipus-Sakura_Wind": new URL(
    `../assets/images/games/Platipus-Sakura_Wind.png?w=200`,
    import.meta.url
  ),
  "Platipus-Power_of_Gods": new URL(
    `../assets/images/games/Platipus-Power_of_Gods.png?w=200`,
    import.meta.url
  ),
  "Platipus-Crazy_Jelly": new URL(
    `../assets/images/games/Platipus-Crazy_Jelly.png?w=200`,
    import.meta.url
  ),
  "Platipus-Lucky_Dolphin": new URL(
    `../assets/images/games/Platipus-Lucky_Dolphin.png?w=200`,
    import.meta.url
  ),
  "Platipus-Mistress_of_Amazon": new URL(
    `../assets/images/games/Platipus-Mistress_of_Amazon.png?w=200`,
    import.meta.url
  ),
  "Platipus-Arabian_Tales": new URL(
    `../assets/images/games/Platipus-Arabian_Tales.png?w=200`,
    import.meta.url
  ),
  "Platipus-Jungle_Spin": new URL(
    `../assets/images/games/Platipus-Jungle_Spin.png?w=200`,
    import.meta.url
  ),
  "Platipus-Fruity_Sevens": new URL(
    `../assets/images/games/Platipus-Fruity_Sevens.png?w=200`,
    import.meta.url
  ),
  "Platipus-Crocoman": new URL(
    `../assets/images/games/Platipus-Crocoman.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Blazing_Bull_Cash_Quest": new URL(
    `../assets/images/games/Kalamba-Blazing_Bull_Cash_Quest.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Max__Hit__n__Roll": new URL(
    `../assets/images/games/Kalamba-Joker_Max__Hit__n__Roll.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Machina_Megaways_Mini_Max": new URL(
    `../assets/images/games/Kalamba-Machina_Megaways_Mini_Max.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Safari_Chase__Hit__n__Roll": new URL(
    `../assets/images/games/Kalamba-Safari_Chase__Hit__n__Roll.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Monkey_God": new URL(
    `../assets/images/games/Kalamba-Monkey_God.png?w=200`,
    import.meta.url
  ),
  "Platipus-Ways_of_the_Gauls": new URL(
    `../assets/images/games/Platipus-Ways_of_the_Gauls.png?w=200`,
    import.meta.url
  ),
  "Platipus-9_Dragon_Kings": new URL(
    `../assets/images/games/Platipus-9_Dragon_Kings.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Griffin_s_Quest_Xmas": new URL(
    `../assets/images/games/Kalamba-Griffin_s_Quest_Xmas.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Lanterns": new URL(
    `../assets/images/games/Kalamba-Joker_Lanterns.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Sky_Hunters": new URL(
    `../assets/images/games/Kalamba-Sky_Hunters.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Operation_Diamond_Hunt": new URL(
    `../assets/images/games/Kalamba-Operation_Diamond_Hunt.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Caribbean_Anne": new URL(
    `../assets/images/games/Kalamba-Caribbean_Anne.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Burning_Diamonds": new URL(
    `../assets/images/games/Kalamba-Burning_Diamonds.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Ruby_Hunter": new URL(
    `../assets/images/games/Kalamba-Ruby_Hunter.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Supreme": new URL(
    `../assets/images/games/Kalamba-Joker_Supreme.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Desert_Gem": new URL(
    `../assets/images/games/Kalamba-Desert_Gem.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Wildmine": new URL(
    `../assets/images/games/Kalamba-Wildmine.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Atlantis_Thunder": new URL(
    `../assets/images/games/Kalamba-Atlantis_Thunder.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Gates_of_Babylon": new URL(
    `../assets/images/games/Kalamba-Gates_of_Babylon.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Tree_of_Gold": new URL(
    `../assets/images/games/Kalamba-Tree_of_Gold.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Dino_Odyssey": new URL(
    `../assets/images/games/Kalamba-Dino_Odyssey.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Goldfire7s": new URL(
    `../assets/images/games/Kalamba-Goldfire7s.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Machina_Megaways": new URL(
    `../assets/images/games/Kalamba-Machina_Megaways.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Goblins___Gemstones__Hit__n__Roll": new URL(
    `../assets/images/games/Kalamba-Goblins___Gemstones__Hit__n__Roll.png?w=200`,
    import.meta.url
  ),
  "Kalamba-FruitMax__Cashlinez": new URL(
    `../assets/images/games/Kalamba-FruitMax__Cashlinez.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Tiger_Claws": new URL(
    `../assets/images/games/Kalamba-Tiger_Claws.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Double_Joker": new URL(
    `../assets/images/games/Kalamba-Double_Joker.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Fire_Eagle": new URL(
    `../assets/images/games/Kalamba-Fire_Eagle.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Holmes__Reel_Detective": new URL(
    `../assets/images/games/Kalamba-Holmes__Reel_Detective.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Fortune_Blitz": new URL(
    `../assets/images/games/Kalamba-Joker_Fortune_Blitz.png?w=200`,
    import.meta.url
  ),
  "Platipus-Extra_Gems": new URL(
    `../assets/images/games/Platipus-Extra_Gems.png?w=200`,
    import.meta.url
  ),
  "Platipus-Hallowin": new URL(
    `../assets/images/games/Platipus-Hallowin.png?w=200`,
    import.meta.url
  ),
  "Platipus-Safari_Adventures": new URL(
    `../assets/images/games/Platipus-Safari_Adventures.png?w=200`,
    import.meta.url
  ),
  "Platipus-Richy_Witchy": new URL(
    `../assets/images/games/Platipus-Richy_Witchy.png?w=200`,
    import.meta.url
  ),
  "Platipus-Jewel_Bang": new URL(
    `../assets/images/games/Platipus-Jewel_Bang.png?w=200`,
    import.meta.url
  ),
  "Playson-Buffalo_Power__Christmas": new URL(
    `../assets/images/games/Playson-Buffalo_Power__Christmas.png?w=200`,
    import.meta.url
  ),
  "Playson-Book_del_Sol__Multiplier": new URL(
    `../assets/images/games/Playson-Book_del_Sol__Multiplier.png?w=200`,
    import.meta.url
  ),
  "Playson-Ultra_Fortunator__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Ultra_Fortunator__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "Playson-Pirate_Sharky": new URL(
    `../assets/images/games/Playson-Pirate_Sharky.png?w=200`,
    import.meta.url
  ),
  "Playson-Sevens___Fruits__20_lines": new URL(
    `../assets/images/games/Playson-Sevens___Fruits__20_lines.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Blazing_Bull": new URL(
    `../assets/images/games/Kalamba-Blazing_Bull.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Pearls_of_Aphrodite_Mini_Max": new URL(
    `../assets/images/games/Kalamba-Pearls_of_Aphrodite_Mini_Max.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Machina_Reloaded___Megaways": new URL(
    `../assets/images/games/Kalamba-Machina_Reloaded___Megaways.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Finnegan_s_Banditos": new URL(
    `../assets/images/games/Kalamba-Finnegan_s_Banditos.png?w=200`,
    import.meta.url
  ),
  "Kalamba-9_Blazing_Cashpots": new URL(
    `../assets/images/games/Kalamba-9_Blazing_Cashpots.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Mammoth_Chase_Easter_Edition": new URL(
    `../assets/images/games/Kalamba-Mammoth_Chase_Easter_Edition.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Max": new URL(
    `../assets/images/games/Kalamba-Joker_Max.png?w=200`,
    import.meta.url
  ),
  "Playson-Burning_Fortunator": new URL(
    `../assets/images/games/Playson-Burning_Fortunator.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Hong_Bao": new URL(
    `../assets/images/games/Kalamba-Hong_Bao.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Age_of_Ice_Dragons": new URL(
    `../assets/images/games/Kalamba-Age_of_Ice_Dragons.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Mermaids_Galore": new URL(
    `../assets/images/games/Kalamba-Mermaids_Galore.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Doctor_Electro": new URL(
    `../assets/images/games/Kalamba-Doctor_Electro.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Midas_Treasure": new URL(
    `../assets/images/games/Kalamba-Midas_Treasure.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Big_Bounty_Bill": new URL(
    `../assets/images/games/Kalamba-Big_Bounty_Bill.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Wildcraft": new URL(
    `../assets/images/games/Kalamba-Wildcraft.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Blood_Moon_Express": new URL(
    `../assets/images/games/Kalamba-Blood_Moon_Express.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Times": new URL(
    `../assets/images/games/Kalamba-Joker_Times.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Temple_of_Heroes": new URL(
    `../assets/images/games/Kalamba-Temple_of_Heroes.png?w=200`,
    import.meta.url
  ),
  "Playson-Fruits_and_Stars": new URL(
    `../assets/images/games/Playson-Fruits_and_Stars.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Pyro_Pixie": new URL(
    `../assets/images/games/Kalamba-Pyro_Pixie.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Crystal_Cavern": new URL(
    `../assets/images/games/Kalamba-Crystal_Cavern.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Miami_Bonus_Wheel": new URL(
    `../assets/images/games/Kalamba-Miami_Bonus_Wheel.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Maui_Millions": new URL(
    `../assets/images/games/Kalamba-Maui_Millions.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Beers_on_Reels": new URL(
    `../assets/images/games/Kalamba-Beers_on_Reels.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Pawprints_of_Purrsia": new URL(
    `../assets/images/games/Kalamba-Pawprints_of_Purrsia.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Sadie_Swift__Guns_n_Glyphs": new URL(
    `../assets/images/games/Kalamba-Sadie_Swift__Guns_n_Glyphs.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Operation_Diamond_Hunt_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Operation_Diamond_Hunt_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Playson-Super_Burning_Wins": new URL(
    `../assets/images/games/Playson-Super_Burning_Wins.png?w=200`,
    import.meta.url
  ),
  "Playson-Imperial_Fruits__5_lines": new URL(
    `../assets/images/games/Playson-Imperial_Fruits__5_lines.png?w=200`,
    import.meta.url
  ),
  "Playson-Legend_of_Cleopatra": new URL(
    `../assets/images/games/Playson-Legend_of_Cleopatra.png?w=200`,
    import.meta.url
  ),
  "Playson-Joker_Expand": new URL(
    `../assets/images/games/Playson-Joker_Expand.png?w=200`,
    import.meta.url
  ),
  "Playson-Crystal_Land": new URL(
    `../assets/images/games/Playson-Crystal_Land.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Lanterns_Hit__n__Roll": new URL(
    `../assets/images/games/Kalamba-Joker_Lanterns_Hit__n__Roll.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Legend_of_Senteng": new URL(
    `../assets/images/games/Kalamba-Legend_of_Senteng.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Rumble_Ratz_Megaways": new URL(
    `../assets/images/games/Kalamba-Rumble_Ratz_Megaways.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Super_Size_Atlas": new URL(
    `../assets/images/games/Kalamba-Super_Size_Atlas.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Football_Blast": new URL(
    `../assets/images/games/Kalamba-Football_Blast.png?w=200`,
    import.meta.url
  ),
  "Platipus-Love_is": new URL(
    `../assets/images/games/Platipus-Love_is.png?w=200`,
    import.meta.url
  ),
  "Platipus-Lucky_money": new URL(
    `../assets/images/games/Platipus-Lucky_money.png?w=200`,
    import.meta.url
  ),
  "Platipus-Fiery_Planet": new URL(
    `../assets/images/games/Platipus-Fiery_Planet.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Goblins___Gemstones": new URL(
    `../assets/images/games/Kalamba-Goblins___Gemstones.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Ducks_Till_Dawn": new URL(
    `../assets/images/games/Kalamba-Ducks_Till_Dawn.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Agent_51": new URL(
    `../assets/images/games/Kalamba-Agent_51.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Speakeasy_Boost": new URL(
    `../assets/images/games/Kalamba-Speakeasy_Boost.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Monkey_God_Buy_Feature": new URL(
    `../assets/images/games/Kalamba-Monkey_God_Buy_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Finnegan_s_Formula": new URL(
    `../assets/images/games/Kalamba-Finnegan_s_Formula.png?w=200`,
    import.meta.url
  ),
  "Platipus-Magical_Mirror": new URL(
    `../assets/images/games/Platipus-Magical_Mirror.png?w=200`,
    import.meta.url
  ),
  "Platipus-Magical_Wolf": new URL(
    `../assets/images/games/Platipus-Magical_Wolf.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Burning_Diamonds_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Burning_Diamonds_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Bangkok_Dreams_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Bangkok_Dreams_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Firedrake_s_Fortune_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Firedrake_s_Fortune_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Platipus-Fairy_Forest": new URL(
    `../assets/images/games/Platipus-Fairy_Forest.png?w=200`,
    import.meta.url
  ),
  "Platipus-Princess_of_Birds": new URL(
    `../assets/images/games/Platipus-Princess_of_Birds.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Age_of_Huracan": new URL(
    `../assets/images/games/Kalamba-Age_of_Huracan.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Cosmic_Charms": new URL(
    `../assets/images/games/Kalamba-Cosmic_Charms.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Pearls_of_Aphrodite": new URL(
    `../assets/images/games/Kalamba-Pearls_of_Aphrodite.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Safari_Chase": new URL(
    `../assets/images/games/Kalamba-Safari_Chase.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Jewels_of_Jupiter": new URL(
    `../assets/images/games/Kalamba-Jewels_of_Jupiter.png?w=200`,
    import.meta.url
  ),
  "Platipus-Cleo_s_Gold": new URL(
    `../assets/images/games/Platipus-Cleo_s_Gold.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Lady_Lava": new URL(
    `../assets/images/games/Kalamba-Lady_Lava.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Blazing_Bull_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Blazing_Bull_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-El_Vigilante": new URL(
    `../assets/images/games/Kalamba-El_Vigilante.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Sky_Hunters_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Sky_Hunters_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Caribbean_Anne_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Caribbean_Anne_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Cashpot_Kegs": new URL(
    `../assets/images/games/Kalamba-Cashpot_Kegs.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Pharaoh_s_Reign": new URL(
    `../assets/images/games/Kalamba-Pharaoh_s_Reign.png?w=200`,
    import.meta.url
  ),
  "Platipus-Pharaoh_s_Empire": new URL(
    `../assets/images/games/Platipus-Pharaoh_s_Empire.png?w=200`,
    import.meta.url
  ),
  "Playson-Fruits___Jokers__40_lines": new URL(
    `../assets/images/games/Playson-Fruits___Jokers__40_lines.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Tales_of_Egypt": new URL(
    `../assets/images/games/PragmaticPlay-Tales_of_Egypt.png?w=200`,
    import.meta.url
  ),
  "BGaming-Domnitor_s_Treasure": new URL(
    `../assets/images/games/BGaming-Domnitor_s_Treasure.png?w=200`,
    import.meta.url
  ),
  "BGaming-Potion_Spells": new URL(
    `../assets/images/games/BGaming-Potion_Spells.png?w=200`,
    import.meta.url
  ),
  "BGaming-Wild_Cash": new URL(
    `../assets/images/games/BGaming-Wild_Cash.png?w=200`,
    import.meta.url
  ),
  "BGaming-Penny_Pelican": new URL(
    `../assets/images/games/BGaming-Penny_Pelican.png?w=200`,
    import.meta.url
  ),
  "BGaming-Big_Atlantis_Frenzy": new URL(
    `../assets/images/games/BGaming-Big_Atlantis_Frenzy.png?w=200`,
    import.meta.url
  ),
  "BGaming-Gold_Rush_with_Johnny_Cash": new URL(
    `../assets/images/games/BGaming-Gold_Rush_with_Johnny_Cash.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lady_Wolf_Moon_Megaways": new URL(
    `../assets/images/games/BGaming-Lady_Wolf_Moon_Megaways.png?w=200`,
    import.meta.url
  ),
  "BGaming-Miss_Cherry_Fruits_Jackpot_Party": new URL(
    `../assets/images/games/BGaming-Miss_Cherry_Fruits_Jackpot_Party.png?w=200`,
    import.meta.url
  ),
  "BGaming-Maneki_88_Gold": new URL(
    `../assets/images/games/BGaming-Maneki_88_Gold.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lady_Wolf_Moon": new URL(
    `../assets/images/games/BGaming-Lady_Wolf_Moon.png?w=200`,
    import.meta.url
  ),
  "BGaming-Road_2_Riches": new URL(
    `../assets/images/games/BGaming-Road_2_Riches.png?w=200`,
    import.meta.url
  ),
  "BGaming-Deep_Sea": new URL(
    `../assets/images/games/BGaming-Deep_Sea.png?w=200`,
    import.meta.url
  ),
  "BGaming-Bonanza_Billion": new URL(
    `../assets/images/games/BGaming-Bonanza_Billion.png?w=200`,
    import.meta.url
  ),
  "BGaming-WBC_Ring_of_Riches": new URL(
    `../assets/images/games/BGaming-WBC_Ring_of_Riches.png?w=200`,
    import.meta.url
  ),
  "BGaming-Dig_Dig_Digger": new URL(
    `../assets/images/games/BGaming-Dig_Dig_Digger.png?w=200`,
    import.meta.url
  ),
  "BGaming-Aztec_Magic_Megaways": new URL(
    `../assets/images/games/BGaming-Aztec_Magic_Megaways.png?w=200`,
    import.meta.url
  ),
  "BGaming-Aloha_King_Elvis": new URL(
    `../assets/images/games/BGaming-Aloha_King_Elvis.png?w=200`,
    import.meta.url
  ),
  "BGaming-Aztec_Magic_Bonanza": new URL(
    `../assets/images/games/BGaming-Aztec_Magic_Bonanza.png?w=200`,
    import.meta.url
  ),
  "BGaming-Four_Lucky_Diamonds": new URL(
    `../assets/images/games/BGaming-Four_Lucky_Diamonds.png?w=200`,
    import.meta.url
  ),
  "BGaming-Clover_Bonanza": new URL(
    `../assets/images/games/BGaming-Clover_Bonanza.png?w=200`,
    import.meta.url
  ),
  "BGaming-Dragon_s_Gold_100": new URL(
    `../assets/images/games/BGaming-Dragon_s_Gold_100.png?w=200`,
    import.meta.url
  ),
  "BGaming-Joker_Queen": new URL(
    `../assets/images/games/BGaming-Joker_Queen.png?w=200`,
    import.meta.url
  ),
  "BGaming-Miss_Cherry_Fruits": new URL(
    `../assets/images/games/BGaming-Miss_Cherry_Fruits.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lucky_Farm_Bonanza": new URL(
    `../assets/images/games/BGaming-Lucky_Farm_Bonanza.png?w=200`,
    import.meta.url
  ),
  "BGaming-Candy_Monsta": new URL(
    `../assets/images/games/BGaming-Candy_Monsta.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lucky_Dama_Muerta": new URL(
    `../assets/images/games/BGaming-Lucky_Dama_Muerta.png?w=200`,
    import.meta.url
  ),
  "BGaming-Slotomon_Go": new URL(
    `../assets/images/games/BGaming-Slotomon_Go.png?w=200`,
    import.meta.url
  ),
  "BGaming-West_Town": new URL(
    `../assets/images/games/BGaming-West_Town.png?w=200`,
    import.meta.url
  ),
  "BGaming-Four_Lucky_Clover": new URL(
    `../assets/images/games/BGaming-Four_Lucky_Clover.png?w=200`,
    import.meta.url
  ),
  "BGaming-Spin_and_Spell": new URL(
    `../assets/images/games/BGaming-Spin_and_Spell.png?w=200`,
    import.meta.url
  ),
  "BGaming-All_Lucky_Clovers": new URL(
    `../assets/images/games/BGaming-All_Lucky_Clovers.png?w=200`,
    import.meta.url
  ),
  "BGaming-All_Lucky_Clovers_100": new URL(
    `../assets/images/games/BGaming-All_Lucky_Clovers_100.png?w=200`,
    import.meta.url
  ),
  "BGaming-All_Lucky_Clovers_20": new URL(
    `../assets/images/games/BGaming-All_Lucky_Clovers_20.png?w=200`,
    import.meta.url
  ),
  "BGaming-All_Lucky_Clovers_40": new URL(
    `../assets/images/games/BGaming-All_Lucky_Clovers_40.png?w=200`,
    import.meta.url
  ),
  "BGaming-All_Lucky_Clovers_5": new URL(
    `../assets/images/games/BGaming-All_Lucky_Clovers_5.png?w=200`,
    import.meta.url
  ),
  "BGaming-Johnny_Cash": new URL(
    `../assets/images/games/BGaming-Johnny_Cash.png?w=200`,
    import.meta.url
  ),
  "BGaming-Hit_The_Route": new URL(
    `../assets/images/games/BGaming-Hit_The_Route.png?w=200`,
    import.meta.url
  ),
  "BGaming-Fruit_Million": new URL(
    `../assets/images/games/BGaming-Fruit_Million.png?w=200`,
    import.meta.url
  ),
  "Playson-Bozo_Cats": new URL(
    `../assets/images/games/Playson-Bozo_Cats.png?w=200`,
    import.meta.url
  ),
  "Playson-Pirate_Chest__Hold_and_Win": new URL(
    `../assets/images/games/Playson-Pirate_Chest__Hold_and_Win.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Gates_of_Olympus": new URL(
    `../assets/images/games/PragmaticPlay-Gates_of_Olympus.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Sweet_Bonanza": new URL(
    `../assets/images/games/PragmaticPlay-Sweet_Bonanza.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Sugar_Rush": new URL(
    `../assets/images/games/PragmaticPlay-Sugar_Rush.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Big_Bass_Splash": new URL(
    `../assets/images/games/PragmaticPlay-Big_Bass_Splash.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Starlight_Princess": new URL(
    `../assets/images/games/PragmaticPlay-Starlight_Princess.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Dog_House": new URL(
    `../assets/images/games/PragmaticPlay-The_Dog_House.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Dog_House_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-The_Dog_House_Megaways.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Hand_of_Midas": new URL(
    `../assets/images/games/PragmaticPlay-The_Hand_of_Midas.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wild_West_Gold": new URL(
    `../assets/images/games/PragmaticPlay-Wild_West_Gold.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-The_Dog_House_Dice": new URL(
    `../assets/images/games/PragmaticPlay-The_Dog_House_Dice.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fruit_Party": new URL(
    `../assets/images/games/PragmaticPlay-Fruit_Party.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Fruit_Party_2": new URL(
    `../assets/images/games/PragmaticPlay-Fruit_Party_2.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Cleocatra": new URL(
    `../assets/images/games/PragmaticPlay-Cleocatra.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Gems_Bonanza": new URL(
    `../assets/images/games/PragmaticPlay-Gems_Bonanza.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Dragon_Hero": new URL(
    `../assets/images/games/PragmaticPlay-Dragon_Hero.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-5_Lions_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-5_Lions_Megaways.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Bigger_Bass_Blizzard___Christmas_Catch": new URL(
    `../assets/images/games/PragmaticPlay-Bigger_Bass_Blizzard___Christmas_Catch.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Magician_s_Secrets": new URL(
    `../assets/images/games/PragmaticPlay-Magician_s_Secrets.png?w=200`,
    import.meta.url
  ),
  "BGaming-Elvis_Frog_in_Vegas": new URL(
    `../assets/images/games/BGaming-Elvis_Frog_in_Vegas.png?w=200`,
    import.meta.url
  ),
  "BGaming-Avalon__The_Lost_Kingdom": new URL(
    `../assets/images/games/BGaming-Avalon__The_Lost_Kingdom.png?w=200`,
    import.meta.url
  ),
  "BGaming-Book_Of_Cats": new URL(
    `../assets/images/games/BGaming-Book_Of_Cats.png?w=200`,
    import.meta.url
  ),
  "BGaming-Scroll_of_Adventure": new URL(
    `../assets/images/games/BGaming-Scroll_of_Adventure.png?w=200`,
    import.meta.url
  ),
  "BGaming-Princess_Royal": new URL(
    `../assets/images/games/BGaming-Princess_Royal.png?w=200`,
    import.meta.url
  ),
  "BGaming-Princess_of_Sky": new URL(
    `../assets/images/games/BGaming-Princess_of_Sky.png?w=200`,
    import.meta.url
  ),
  "BGaming-Platinum_Lightning_Deluxe": new URL(
    `../assets/images/games/BGaming-Platinum_Lightning_Deluxe.png?w=200`,
    import.meta.url
  ),
  "BGaming-Platinum_Lightning": new URL(
    `../assets/images/games/BGaming-Platinum_Lightning.png?w=200`,
    import.meta.url
  ),
  "BGaming-Jogo_Do_Bicho": new URL(
    `../assets/images/games/BGaming-Jogo_Do_Bicho.png?w=200`,
    import.meta.url
  ),
  "BGaming-Journey_Flirt": new URL(
    `../assets/images/games/BGaming-Journey_Flirt.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lucky_Blue": new URL(
    `../assets/images/games/BGaming-Lucky_Blue.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lucky_Lady_s_Clover": new URL(
    `../assets/images/games/BGaming-Lucky_Lady_s_Clover.png?w=200`,
    import.meta.url
  ),
  "BGaming-Lucky_Sweets": new URL(
    `../assets/images/games/BGaming-Lucky_Sweets.png?w=200`,
    import.meta.url
  ),
  "BGaming-Mechanical_Orange": new URL(
    `../assets/images/games/BGaming-Mechanical_Orange.png?w=200`,
    import.meta.url
  ),
  "BGaming-Crazy_Starter": new URL(
    `../assets/images/games/BGaming-Crazy_Starter.png?w=200`,
    import.meta.url
  ),
  "BGaming-Domnitors": new URL(
    `../assets/images/games/BGaming-Domnitors.png?w=200`,
    import.meta.url
  ),
  "BGaming-Domnitors_Deluxe": new URL(
    `../assets/images/games/BGaming-Domnitors_Deluxe.png?w=200`,
    import.meta.url
  ),
  "BGaming-Fantasy_Park": new URL(
    `../assets/images/games/BGaming-Fantasy_Park.png?w=200`,
    import.meta.url
  ),
  "BGaming-Fire_Lightning": new URL(
    `../assets/images/games/BGaming-Fire_Lightning.png?w=200`,
    import.meta.url
  ),
  "BGaming-Hawaii_Cocktails": new URL(
    `../assets/images/games/BGaming-Hawaii_Cocktails.png?w=200`,
    import.meta.url
  ),
  "BGaming-Hello_Easter": new URL(
    `../assets/images/games/BGaming-Hello_Easter.png?w=200`,
    import.meta.url
  ),
  "Platipus-Crystal_Sevens": new URL(
    `../assets/images/games/Platipus-Crystal_Sevens.png?w=200`,
    import.meta.url
  ),
  "Platipus-Xmas_Avalanche": new URL(
    `../assets/images/games/Platipus-Xmas_Avalanche.png?w=200`,
    import.meta.url
  ),
  "Platipus-The_Big_Score": new URL(
    `../assets/images/games/Platipus-The_Big_Score.png?w=200`,
    import.meta.url
  ),
  "Platipus-Pirate_s_Legacy": new URL(
    `../assets/images/games/Platipus-Pirate_s_Legacy.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Wolf_Gold_1_Million": new URL(
    `../assets/images/games/PragmaticPlay-Wolf_Gold_1_Million.png?w=200`,
    import.meta.url
  ),
  "PragmaticPlay-Mammoth_Gold_Megaways": new URL(
    `../assets/images/games/PragmaticPlay-Mammoth_Gold_Megaways.png?w=200`,
    import.meta.url
  ),
  "BGaming-Brave_Viking": new URL(
    `../assets/images/games/BGaming-Brave_Viking.png?w=200`,
    import.meta.url
  ),
  "BGaming-Desert_Treasure": new URL(
    `../assets/images/games/BGaming-Desert_Treasure.png?w=200`,
    import.meta.url
  ),
  "BGaming-Bob_s_Coffee_Shop": new URL(
    `../assets/images/games/BGaming-Bob_s_Coffee_Shop.png?w=200`,
    import.meta.url
  ),
  "BGaming-Book_of_Pyramids": new URL(
    `../assets/images/games/BGaming-Book_of_Pyramids.png?w=200`,
    import.meta.url
  ),
  "BGaming-Cherry_Fiesta": new URL(
    `../assets/images/games/BGaming-Cherry_Fiesta.png?w=200`,
    import.meta.url
  ),
  "BGaming-Aztec_Magic": new URL(
    `../assets/images/games/BGaming-Aztec_Magic.png?w=200`,
    import.meta.url
  ),
  "BGaming-Aztec_Magic_Deluxe": new URL(
    `../assets/images/games/BGaming-Aztec_Magic_Deluxe.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Apollo": new URL(
    `../assets/images/games/SmartSoft-Apollo.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Argo": new URL(
    `../assets/images/games/SmartSoft-Argo.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Aztec": new URL(
    `../assets/images/games/SmartSoft-Aztec.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-BookOfWin": new URL(
    `../assets/images/games/SmartSoft-BookOfWin.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Christmas": new URL(
    `../assets/images/games/SmartSoft-Christmas.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-CitySlot": new URL(
    `../assets/images/games/SmartSoft-CitySlot.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Cowboy": new URL(
    `../assets/images/games/SmartSoft-Cowboy.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-FunFruit": new URL(
    `../assets/images/games/SmartSoft-FunFruit.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Samurai": new URL(
    `../assets/images/games/SmartSoft-Samurai.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-SpinX": new URL(
    `../assets/images/games/SmartSoft-SpinX.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-MoonStone": new URL(
    `../assets/images/games/SmartSoft-MoonStone.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Evolution": new URL(
    `../assets/images/games/SmartSoft-Evolution.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Dark": new URL(
    `../assets/images/games/SmartSoft-Dark.png?w=200`,
    import.meta.url
  ),
  /*   "SmartSoft-Vampires": new URL(
    `../assets/images/games/SmartSoft-Vampires.png?w=200`,
    import.meta.url
  ), */
  "SmartSoft-Dark_100": new URL(
    `../assets/images/games/SmartSoft-Dark_100.png?w=200`,
    import.meta.url
  ),
  "SmartSoft-Summer_Cocktail": new URL(
    `../assets/images/games/SmartSoft-Summer_Cocktail.png?w=200`,
    import.meta.url
  ),
  "Jetgames-dice": new URL(
    `../assets/images/games/Jetgames-dice.png?w=200`,
    import.meta.url
  ),
  "Jetgames-double": new URL(
    `../assets/images/games/Jetgames-double.png?w=200`,
    import.meta.url
  ),
  "Jetgames-Passage": new URL(
    `../assets/images/games/Jetgames-Passage.png?w=200`,
    import.meta.url
  ),
  "Jetgames-roulette": new URL(
    `../assets/images/games/Jetgames-roulette.png?w=200`,
    import.meta.url
  ),
  "Jetgames-hilo": new URL(
    `../assets/images/games/Jetgames-hilo.png?w=200`,
    import.meta.url
  ),
  "Jetgames-limbo": new URL(
    `../assets/images/games/Jetgames-limbo.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Wild_Wild_Vegas": new URL(
    `../assets/images/games/BoomingGames-Wild_Wild_Vegas.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Greek_Legends": new URL(
    `../assets/images/games/BoomingGames-Greek_Legends.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Majestic_Safari": new URL(
    `../assets/images/games/BoomingGames-Majestic_Safari.png?w=200`,
    import.meta.url
  ),
  "BoomingGames-Bang_Bang": new URL(
    `../assets/images/games/BoomingGames-Bang_Bang.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Zombie_Queen_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Zombie_Queen_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Griffin_s_Quest_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Griffin_s_Quest_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Vegas_Blast": new URL(
    `../assets/images/games/Kalamba-Vegas_Blast.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Max_Gamble_Feature": new URL(
    `../assets/images/games/Kalamba-Joker_Max_Gamble_Feature.png?w=200`,
    import.meta.url
  ),
  "Kalamba-Joker_Leprechauns": new URL(
    `../assets/images/games/Kalamba-Joker_Leprechauns.png?w=200`,
    import.meta.url
  ),
  "CarbonGames-Rock_Paper_Scissors": new URL(
    `../assets/images/games/CarbonGames-Rock_Paper_Scissors.png?w=200`,
    import.meta.url
  ),
  "CarbonGames-Hi_Lo": new URL(
    `../assets/images/games/CarbonGames-Hi_Lo.png?w=200`,
    import.meta.url
  ),
  "CarbonGames-Mines_Sweeper": new URL(
    `../assets/images/games/CarbonGames-Mines_Sweeper.png?w=200`,
    import.meta.url
  ),
  "CarbonGames-Limbo": new URL(
    `../assets/images/games/CarbonGames-Limbo.png?w=200`,
    import.meta.url
  ),
  "CarbonGames-Dice": new URL(
    `../assets/images/games/CarbonGames-Dice.png?w=200`,
    import.meta.url
  ),
}

export const categories = [
  {
    tag: "popular",
    displayName: "Popular",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "all",
    displayName: "All",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "carbon-pick",
    displayName: "Carbon Picks",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "slot",
    displayName: "Slot",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "crash",
    displayName: "Crash",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "table",
    displayName: "Table",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
  {
    tag: "live",
    displayName: "Live",
    "coeff-session-played": 1,
    "coeff-score": 1,
  },
]
export default {
  sevenDays: [
    {
      user: "Sauceavalanchejudo ",
      time: "1 day ago",
      payout: "$4,157.00",
      wager: "$20.00",
      mult: "207.85",
    },
    {
      user: "TBeautifulbeautiful ",
      time: "2 days ago",
      payout: "$12,000.00",
      wager: "120$",
      mult: "100",
    },
    {
      user: "Chickenwingchicken",
      time: "3 days ago",
      payout: "$18,234.00",
      wager: "$111.00",
      mult: "164.27",
    },
    {
      user: "Beerstarshipostrich",
      time: "5 days ago",
      payout: "$41,760.00",
      wager: "$200.00",
      mult: "208.8",
    },
    {
      user: "Wrestlingsweetpotato",
      time: "5 days ago",
      payout: "$37,840.00",
      wager: "$55.00",
      mult: "688",
    },
  ],
  allTime: [
    {
      user: "flutegrantorinoapple",
      time: "1 day ago",
      payout: "$21,500.00",
      wager: "$500.00",
      mult: "43",
    },
    {
      user: "Animaltracksbassoon",
      time: "1 day ago",
      payout: "$33,452.00",
      wager: "$400.00",
      mult: "83.63",
    },
    {
      user: "Wrestlingsweetpotato",
      time: "5 days ago",
      payout: "$37,840.00",
      wager: "$55.00",
      mult: "688",
    },
    {
      user: "Beerstarshipostrich",
      time: "5 days ago",
      payout: "$41,760.00",
      wager: "$200.00",
      mult: "208.8",
    },
    {
      user: "Antlifeofbrianmilk",
      time: "13 days ago",
      payout: "$42,000.00",
      wager: "$42.000",
      mult: "1000",
    },
  ],
}

export const gamesStats = [
  {
    user: "Jhonnydump",
    time: "4 days ago",
    payout: "$2500",
    wager: "$10",
    mult: "250",
  },
  {
    user: "Emil17",
    time: "2 days ago",
    payout: "$5200",
    wager: "$20",
    mult: "260",
  },
  {
    user: "Kilan55",
    time: "6 days ago",
    payout: "$7500",
    wager: "$30",
    mult: "250",
  },
  {
    user: "Siri88",
    time: "3 days ago",
    payout: "$9000",
    wager: "$30",
    mult: "300",
  },
  {
    user: "DaniPili",
    time: "5 days ago",
    payout: "$8250",
    wager: "$30",
    mult: "275",
  },
  {
    user: "Oliva",
    time: "2 days ago",
    payout: "$4500",
    wager: "15",
    mult: "300",
  },
  {
    user: "vincentObrigado",
    time: "3 days ago",
    payout: "$9300",
    wager: "$30",
    mult: "310",
  },
  {
    user: "EminiaLan",
    time: "6 days ago",
    payout: "$9450",
    wager: "$30",
    mult: "315",
  },
  {
    user: "Jacou99",
    time: "4 days ago",
    payout: "$4050",
    wager: "$15",
    mult: "270",
  },
  {
    user: "Bizal22",
    time: "2 days ago",
    payout: "$5700",
    wager: "$18",
    mult: "320",
  },
  {
    user: "Zerib69",
    time: "5 days ago",
    payout: "$12500",
    wager: "50",
    mult: "250",
  },
  {
    user: "RazakBacar",
    time: "3 days ago",
    payout: "$26250",
    wager: "$70",
    mult: "375",
  },
  {
    user: "EthianFranz",
    time: "4 days ago",
    payout: "$8200",
    wager: "$20",
    mult: "410",
  },
  {
    user: "Milan05",
    time: "5 days ago",
    payout: "$13600",
    wager: "$34",
    mult: "400",
  },
  {
    user: "Kalimou33",
    time: "2 days ago",
    payout: "$350",
    wager: "$20000",
    mult: "400",
  },
  {
    user: "Martinez22",
    time: "3 days ago",
    payout: "$50000",
    wager: "$100",
    mult: "500",
  },
  {
    user: "LucasMauri1",
    time: "6 days ago",
    payout: "$19500",
    wager: "$75",
    mult: "260",
  },
  {
    user: "KacilloMax",
    time: "4 days ago",
    payout: "$24600",
    wager: "$60",
    mult: "410",
  },
  {
    user: "Silan543",
    time: "3 days ago",
    payout: "$6400",
    wager: "$20",
    mult: "320",
  },
  {
    user: "LijavMikilan",
    time: "5 days ago",
    payout: "$38400",
    wager: "$64",
    mult: "600",
  },
  {
    user: "coliherto43",
    time: "2 days ago",
    payout: "$5000",
    wager: "$25",
    mult: "200",
  },
  {
    user: "TomiJoki",
    time: "4 days ago",
    payout: "$12760",
    wager: "$44",
    mult: "290",
  },
  {
    user: "Soukir23",
    time: "6 days ago",
    payout: "$5600",
    wager: "$20",
    mult: "280",
  },
  {
    user: "JokiMoki00",
    time: "3 days ago",
    payout: "$576",
    wager: "$48",
    mult: "14300",
  },
  {
    user: "Kalis33",
    time: "2 days ago",
    payout: "$13000",
    wager: "$52",
    mult: "250",
  },
  {
    user: "Marcizevdo11",
    time: "5 days ago",
    payout: "$5880",
    wager: "$28",
    mult: "210",
  },
  {
    user: "KingJani1",
    time: "4 days ago",
    payout: "$7840",
    wager: "$28",
    mult: "280",
  },
  {
    user: "SoulanKilari",
    time: "6 days ago",
    payout: "$20350",
    wager: "$55",
    mult: "370",
  },
  {
    user: "Karlit97",
    time: "3 days ago",
    payout: "$2750",
    wager: "$11",
    mult: "250",
  },
  {
    user: "KhristoDan",
    time: "5 days ago",
    payout: "$11357",
    wager: "$41",
    mult: "277",
  },
  {
    user: "RomeJoke",
    time: "2 days ago",
    payout: "$11970",
    wager: "$45",
    mult: "266",
  },
  {
    user: "Mask3",
    time: "4 days ago",
    payout: "$8925",
    wager: "$35",
    mult: "255",
  },
  {
    user: "Lihand22",
    time: "6 days ago",
    payout: "$17808",
    wager: "$48",
    mult: "371",
  },
  {
    user: "JoshuaLii",
    time: "3 days ago",
    payout: "$9827",
    wager: "$31",
    mult: "317",
  },
  {
    user: "PikaiBasket6",
    time: "2 days ago",
    payout: "$17080",
    wager: "$56",
    mult: "305",
  },
  {
    user: "YanourMosc",
    time: "5 days ago",
    payout: "$29565",
    wager: "$73",
    mult: "405",
  },
  {
    user: "Dalin2",
    time: "4 days ago",
    payout: "$22750",
    wager: "$65",
    mult: "350",
  },
  {
    user: "Kalimbo1",
    time: "6 days ago",
    payout: "$8671",
    wager: "$29",
    mult: "299",
  },
]
