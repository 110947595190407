import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSearchParams } from "react-router-dom"
import {
  toast as nonCustomToast,
  ToastContainer as NonCustomToastContainer,
} from "react-toastify"

import cross from "../assets/icons/cross-close.svg"

const notifications = {
  "email-verified": {
    type: "success",
    title: "Email verified!",
    body: "Your email has been successfully verified.",
  },
  "email-not-verified": {
    type: "error",
    title: "Email verification failed!",
    body: "Sorry, there was an issue verifying your email. Please try again !",
  },
  "success-buy-crypto": {
    type: "warning",
    title: "Transaction processing time !",
    body: "It may take up to 15 minutes for your deposit to be confirmed.",
  },
}

const CloseButton = ({ closeToast }) => (
  <div
    onClick={closeToast}
    onKeyDown={Function.prototype}
    role="button"
    tabIndex={0}
  >
    <img
      fetchpriority="high"
      loading="lazy"
      decoding="async"
      src={cross}
      alt="cross-close"
      className="w-4 absolute top-3 right-2"
    />
  </div>
)

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
}

export const ToastMessage = ({ title, body }) => (
  <div className="ml-3 py-2">
    <h1 className="text-md text-black font-bold">{title}</h1>
    <p className="text-black text-sm">{body}</p>
  </div>
)

ToastMessage.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export const toast = {
  success: (title, body, options = {}) =>
    nonCustomToast.success(<ToastMessage title={title} body={body} />, options),
  warning: (title, body, options = {}) =>
    nonCustomToast.warning(<ToastMessage title={title} body={body} />, options),
  error: (title, body, options = {}) =>
    nonCustomToast.error(<ToastMessage title={title} body={body} />, options),
}

export const ToastContainer = () => {
  let searchParams = null
  if (typeof useSearchParams === "function") {
    ;[searchParams] = useSearchParams()
  } else {
    searchParams = new URLSearchParams(window.location.search)
  }

  useEffect(() => {
    if (searchParams) {
      const notification = notifications[searchParams.get("notification")]
      if (notification) {
        toast[notification.type](notification.title, notification.body)
      }
    }
  }, [searchParams])

  return (
    <NonCustomToastContainer
      hideProgressBar
      className="xl:w-[425px] xs:w-full fixed top-20"
      toastClassName="border-l-[7px] rounded-sm"
      closeButton={CloseButton}
      icon={false}
      autoClose={5000}
    />
  )
}

export default ToastContainer
