import React from "react"
import MediaQuery from "react-responsive"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import arrowLeft from "../assets/icons/arrow-left.svg"
import cardIcon from "../assets/icons/card-icon.svg"

// style
import "../features/PaymentGateways/style.css"
import BillingAddressForm from "./BillingAddressForm"

const AddressFormCard = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleOnClickBackButton = () => {
    navigate(-1)
  }

  return (
    <div className="w-full  container-background-style  ">
      <div className="container-max-width-switchere h-full  flex  justify-center  py-5">
        <div className="container-max-width-switchere  w-full  bg-white rounded-lg	 py-5 flex flex-col justify-start			div-above			 ">
          <div className="h-auto			w-auto  z-50 relative flex justify-between py-5 px-8	pb-5 ">
            <div
              onClick={handleOnClickBackButton}
              role="button"
              onKeyDown={Function.prototype}
              tabIndex={0}
              className="	W-1/2 z-0 flex  items-start "
            >
              <img src={arrowLeft} className="h-7 " alt="arrowLeft" />

              <MediaQuery minWidth={600}>
                <div className="	w-1/2 z-0 flex px-5 text-tertiary card-text-back-style ">
                  Back
                </div>
              </MediaQuery>
            </div>
            <div className="	w-1/2  z-0 flex  items-center justify-end">
              <MediaQuery minWidth={600}>
                <img src={cardIcon} className="h-8" alt="cardIcon" />
              </MediaQuery>
              <div className="z-0 text-tertiary card-text-pay-with-credit-style desktop:text-base	 xs:text-xs	 ">
                {t("Pay with Credit Card")}
              </div>
            </div>
          </div>
          <div className="h-full	w-full  z-0 flex flex-col py-2 px-5  ">
            <div className=" z-0 flex text-black   pb-10 desktop:text-xl	 xs:text-base mobile:text-xs	 card-text-style">
              {t("Fill in your billing address")}
            </div>
          </div>
          <div className="h-full	w-full  z-0 flex flex-col py-2 px-5  z-60 ">
            <BillingAddressForm />
          </div>
        </div>
      </div>
    </div>
  )
}

AddressFormCard.propTypes = {}

export default AddressFormCard
