import React from "react"
import MediaQuery from "react-responsive"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Banner from "./Banner"
import Button from "./Button"
import ProgressBar from "./ProgressBar"
import { getBrandFromUrl, imageDistributed } from "../helpers"
import themes from "../themes"

// TODO I comment the deposit bonus banner logic and remove the onClick function, check the pr

const VipBanner = () => {
  const { t } = useTranslation()
  const { userLevelDetails } = useSelector((state) => state.user)
  const { name, wagerToReach, percentageCompletion, nextLevelWagerToReach } =
    userLevelDetails
  const levelMessage =
    wagerToReach === 0
      ? "Make your first bet to gain or Top up the account !"
      : `${name}`

  const [searchParams, setSearchParams] = useSearchParams()

  const onclickReload = () => {
    searchParams.set("service", "cashier")
    searchParams.set("tab", "deposit")
    setSearchParams(searchParams)
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return (
    <div className="container-max-width sm:px-7 desktop:px-0  xs:px-6 about-section h-[100%]  desktop:pt-8 mobile:pt-8">
      <Banner>
        <div
          className="rounded-3xl pt-3 "
          style={{
            background: `url(${imageDistributed("vipBannerAsset")}), ${
              themeColors?.personalPurple
            }`,
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <h1 className="text-white font-bold text-2xl mobile:mb-3 mobile:text-md text-center">
            {t("VIP Progression")}
          </h1>
          <div className="desktop:w-[80%] tablet:w-full mx-auto flex justify-between items-center relative">
            <div className="basis-1/3 mobile:hidden">
              <img
                fetchpriority="low"
                src={imageDistributed("giftBox")}
                alt="gift-box-asset"
                className="absolute -top-14 xs:-top-10 mobile:left-5 mobile-big-screen:left-5 tablet:left-7  w-auto h-36 xs:h-32"
              />
            </div>
            <div className="mobile:basis-3/3 desktop:basis-2/3  tablet:basis-2/3 flex flex-col mobile:pl-3 mobile:w-full  mobile:pr-3">
              <h2 className="mb-3 mobile:mb-1 flex   justify-between  items-center">
                <span className="text-md font-bold mobile:text-sm">
                  {t(levelMessage)}
                </span>
                <span className="pl-5 font-bold mobile:text-sm">
                  {nextLevelWagerToReach}$
                </span>
              </h2>
              <div className="mb-10 mobile:mb-3">
                <ProgressBar value={percentageCompletion} />
              </div>
              <div className="flex justify-end mb-3">
                <MediaQuery maxWidth={499}>
                  <Button.Tertiary size="small" onClick={onclickReload}>
                    <span className="text-white flex items-center gap-2">
                      {t("Top Up")}
                    </span>{" "}
                  </Button.Tertiary>
                </MediaQuery>
              </div>
            </div>
            <MediaQuery minWidth={500}>
              <div className="basis-1/3 flex desktop:justify-end xs:justify-center mb-5">
                <Button.Primary size="small" onClick={onclickReload}>
                  {t("Top Up")}
                </Button.Primary>
              </div>
            </MediaQuery>
          </div>
        </div>
      </Banner>
    </div>
  )
}

export default VipBanner
