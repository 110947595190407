import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import uuid from "react-uuid"

/* components */
import Spinner from "../../components/Spinner"

// Utilities
import { loadScript, removeScript, logger } from "../../helpers"
import getAmount from "./helpers"

/* style */
import "./style.css"

const Switchere = () => {
  const { username } = useSelector((state) => state.auth)
  const amount = getAmount()

  logger(amount)

  const [isLoading, setIsLoading] = useState(true)

  const initSwitchere = () => {
    window.switchereSdk.debug = process.env.REACT_APP_CONTEXT !== "production"

    const magicString = uuid()

    window.switchereSdk.init({
      el: "#switchere",
      partner_key: process.env.REACT_APP_SWITCHERE_API_KEY,

      partnerOrderId: `${username}-${magicString}`,
      httpReturnSuccess:
        "https://www.casinocarbon.com/#/?notification=buy-crypto-successful",
      httpReturnFailed:
        "https://www.casinocarbon.com/#/?notification=buy-crypto-failed",
      payinGroupDefault: "card",
      payinAmountDefault: amount,
      payinCurrencyDefault: "EUR",
      payoutCurrency: "USDTSW",
      payoutGroup: "balance",
    })
    setIsLoading(false)
  }

  useEffect(() => {
    if (process.env.REACT_APP_CONTEXT === "production") {
      loadScript(
        "https://switchere.com/js/sdk-builder.js",
        "switchere-sdk",
        initSwitchere
      )
    } else {
      loadScript(
        "https://sandbox.switchere.com/js/sdk-builder.js",
        "switchere-sdk",
        initSwitchere
      )
    }

    return () => {
      removeScript("switchere-sdk")
    }
  }, [])
  return isLoading ? (
    <div className="container-max-width-switchere  iframe-max-height h-full  w-full   py-5">
      <Spinner />
    </div>
  ) : (
    <div className="container-max-width-switchere  h-full  w-full   py-5">
      <div id="switchere" className="   rounded-3xl " />
    </div>
  )
}

export default Switchere
