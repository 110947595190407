import React from "react"
import PropTypes from "prop-types"
import { Tooltip as ToolTip } from "@material-tailwind/react"

const toolTipTypes = {
  default: "z-50 w-36",
  check: "bg-tertiary w-44",
}

const Tooltip = ({ children, content, type }) => (
  <ToolTip
    content={content}
    placement="top"
    animate={{
      mount: { scale: 1, y: 0 },
      unmount: { scale: 0, y: 25 },
    }}
    className={`${toolTipTypes[type]}`}
  >
    {children}
  </ToolTip>
)

Tooltip.defaultProps = {
  type: "default",
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default Tooltip
