export const DEPOSITS = "Deposits"

export const WITHDRAWALS = "Withdrawals"

export const BETS = "Bets"

export const WINS = "Wins"

export const BONUSES = "Bonuses"

export const TIPS = "Tips"

export const SPORTSBOOK = "Sportsbook"

export const ALL = "All"
