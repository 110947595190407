import React, { useState } from "react"
import MediaQuery from "react-responsive"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"

// import { useQuery } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { QRCodeSVG } from "qrcode.react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClone, faCheck } from "@fortawesome/free-solid-svg-icons"
import Input from "../../components/Input"
import Tooltip from "../../components/Tooltip"
import IconsButton from "../../components/IconsButton"
import { copyTextToClipboard, logger } from "../../helpers"
// import payWithCryptoAsset from "../../assets/images/paymentMethods/pay-with-crypto.png"
import arrowBackDirection from "../../assets/icons/arrow-back-direction.png"
import eth from "../../assets/images/depositAssets/eth.png"
import btc from "../../assets/images/depositAssets/btc.png"
import usdt from "../../assets/images/depositAssets/usdt.png"
import ltc from "../../assets/images/depositAssets/ltc.png"
import bch from "../../assets/images/depositAssets/bch.png"
import doge from "../../assets/images/depositAssets/doge.png"
import eos from "../../assets/images/depositAssets/eos.png"
import trx from "../../assets/images/depositAssets/trx.png"
import xrp from "../../assets/images/depositAssets/xrp.png"
import "./style.css"
import { getWalletWithCurrency } from "../../actions/wallet"
import Spinner from "../../components/Spinner"
import { toast } from "../../components/Toast"
import Button from "../../components/Button"
// import Spinner from "./Spinner"

const paymentMethods = [
  {
    method: "eth",
    label: "ETH",
    subLabel: "",
    imageSource: eth,
    network: "Ethereum",
    name: "Ethereum",
    access: "eth",
  },
  {
    method: "bitcoin",
    label: "BTC",
    subLabel: "",
    imageSource: btc,
    network: "Bitcoin",
    name: "Bitcoin",
    access: "btc",
  },
  {
    method: "tether",
    label: "USDT",
    subLabel: "",
    imageSource: usdt,
    network: "ERC20",
    name: "Ethereum",
    access: "usdt",
  },
  {
    method: "litecoin",
    label: "LTC",
    subLabel: "",
    imageSource: ltc,
    network: "Litecoin",
    name: "Litecoin",
    access: "ltc",
  },
  {
    method: "bitcoin-cash",
    label: "BCH",
    subLabel: "",
    imageSource: bch,
    network: "",
    name: "Bitcoin-cash",
    access: "bch",
  },
  {
    method: "doge",
    label: "DOGE",
    subLabel: "",
    imageSource: doge,
    network: "Dogecoin",
    name: "Dogecoin",
    access: "doge",
  },
  {
    method: "eos",
    label: "EOS",
    subLabel: "",
    imageSource: eos,
    network: "",
    name: "Eos",
    access: "eos",
  },
  {
    method: "trx",
    label: "TRX",
    subLabel: "",
    imageSource: trx,
    network: "TRON",
    name: "TRON",
    access: "trx",
  },
  {
    method: "xrp",
    label: "XRP",
    subLabel: "",
    imageSource: xrp,
    network: "",
    name: "XRP",
    access: "trx",
  },
]

const Oxprocessing = () => {
  const navigate = useNavigate()
  const [onClickOnCopyIcon, setOnclickOnCopyIcon] = useState(false)
  const [ethWalletAddress, setEthWalletAddress] = useState("")

  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const paymentMethodUrl = searchParams.get("method")
  const paymentMethodSelected = paymentMethods.find(
    (method) => method.method === paymentMethodUrl
  )

  // redirect if paymentMethodSelected is undefined
  if (paymentMethodSelected === undefined) {
    toast.error(
      t("Unknown cryptocurrency!"),
      t("Please select one of the cryptocurrencies listed below."),
      {
        toastId: 1,
      }
    )
    navigate("/?service=cashier")
  }

  const handleClickOnIcon = () => {
    setOnclickOnCopyIcon(true)
    copyTextToClipboard(ethWalletAddress)
    setTimeout(() => {
      setOnclickOnCopyIcon(false)
    }, 1000)
  }

  const { isFetching } = useQuery({
    queryKey: ["getWalletWithCurrency", paymentMethodSelected.label],
    queryFn: () => getWalletWithCurrency(paymentMethodSelected.label),
    refetchOnMount: true,
    onSuccess: ({ data }) => {
      logger(data)
      setEthWalletAddress(data.wallet_address)
    },
    onError: (error) => {
      logger(error)
      if (
        error.status === 400 &&
        error.data.code === "cryptocurrency-not-exist"
      ) {
        toast.error("Invalid cryptocurrency.", "Invalid cryptocurrency.")
      }
    },
  })

  const handleBackButton = () => navigate(-1)
  const languageSelected = i18n.language?.split("-")[0]

  const handleRedirectToBuyCryptoTab = () => {
    navigate("/?service=cashier&tab=buy-crypto")
  }
  return (
    <section className=" bg-primary w-full text-white pb-12">
      <div className=" w-full container-background-style  mt-5">
        <div className="container-max-width-switchere bg-white rounded-3xl">
          <div className="mb-3 flex justify-between items-center py-5 px-5 border-b border-gray-700 oxprocssing-header">
            <div
              className="flex items-center"
              onClick={handleBackButton}
              role="button"
              onKeyDown={Function.prototype}
              tabIndex={0}
            >
              <img
                fetchpriority="low"
                src={arrowBackDirection}
                alt="arrow-back-direction"
                className="w-7 mr-2 h-auto"
              />
            </div>
            <div className="flex items-center">
              <img
                fetchpriority="low"
                src={paymentMethodSelected.imageSource}
                alt="pay-with-crypto"
                className="w-8 mr-2"
              />
              <span className="text-gray-700 font-semibold">
                {t("Pay with")} {paymentMethodSelected.label}
              </span>
            </div>
          </div>
          <div className="text-black py-5 pl-5 bg-gray-100">
            <div className="flex justify-between items-center">
              <p className="text-specialPrimary  text-xl font-bold mb-2 pl-4">
                {`${paymentMethodSelected.label} ${t("wallet address")} `}
              </p>
            </div>
            <div className=" relative w-full xs:my-5 lg:my-auto tablet:px-4 desktop:px-4 mobile:pr-2 px-5">
              <MediaQuery maxWidth={767}>
                {isFetching ? (
                  <div className="w-full relative">
                    <Spinner />
                  </div>
                ) : (
                  <div className="">
                    <Input
                      label={`  ${paymentMethodSelected.label} ${t(
                        "wallet address"
                      )}`}
                      value={ethWalletAddress}
                      disabledInputBackground
                      smallFont
                    />
                    <div className="absolute right-5 copy-cta-container">
                      {onClickOnCopyIcon ? (
                        <Tooltip content="Address copied !">
                          <span>
                            <IconsButton onClick={handleClickOnIcon}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-specialFontBlue bg-gray-300 text-md w-3 h-3 p-2 rounded-full border border-specialFontBlue"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Copy address !">
                          <span>
                            <IconsButton onClick={handleClickOnIcon}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </MediaQuery>
              <MediaQuery minWidth={768} maxWidth={959}>
                {isFetching ? (
                  <div className="w-full relative">
                    <Spinner />
                  </div>
                ) : (
                  <div className="w-full relative">
                    <Input
                      label={`  ${paymentMethodSelected.label} ${t(
                        "wallet address"
                      )}`}
                      value={ethWalletAddress}
                      disabledInputBackground
                    />
                    <div className="flex flex-row justify-between items-center absolute right-2 copy-cta-container">
                      {onClickOnCopyIcon ? (
                        <Tooltip content="Address copied !">
                          <span>
                            <IconsButton>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-specialFontBlue bg-gray-300 text-md w-3 h-3 p-2 rounded-full border border-specialFontBlue"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Copy address !">
                          <span>
                            <IconsButton onClick={handleClickOnIcon}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </MediaQuery>
              <MediaQuery minWidth={960} maxWidth={1199}>
                {isFetching ? (
                  <div className="w-full relative">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Input
                      label={`  ${paymentMethodSelected.label} ${t(
                        "wallet address"
                      )}`}
                      value={ethWalletAddress}
                      disabledInputBackground
                    />
                    <div className="absolute right-5 copy-cta-container">
                      {onClickOnCopyIcon ? (
                        <Tooltip content="Address copied !">
                          <span>
                            <IconsButton>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-specialFontBlue bg-gray-300 text-md w-3 h-3 p-2 rounded-full border border-specialFontBlue"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Copy address !">
                          <span>
                            <IconsButton onClick={handleClickOnIcon}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </>
                )}
              </MediaQuery>
              <MediaQuery minWidth={1200}>
                {isFetching ? (
                  <div className="w-full relative">
                    <Spinner />
                  </div>
                ) : (
                  <div className="w-full relative pr-5">
                    <Input
                      label={`  ${paymentMethodSelected.label} ${t(
                        "wallet address"
                      )}`}
                      value={ethWalletAddress}
                      disabledInputBackground
                    />
                    <div className="flex flex-row justify-between items-center absolute right-5 copy-cta-container">
                      {onClickOnCopyIcon ? (
                        <Tooltip content="Address copied !">
                          <span>
                            <IconsButton>
                              <span>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Copy address !">
                          <span>
                            <IconsButton onClick={handleClickOnIcon}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faClone}
                                  className="text-white bg-specialFontBlue text-md w-3 h-3 p-2 rounded-full"
                                />
                              </span>
                            </IconsButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </MediaQuery>
            </div>
          </div>
          <div className="text-gray-700 px-7 tablet:px-4  xs:px-3 xs:text-sm">
            <p>
              {t("This address accepts only")} {paymentMethodSelected.label}{" "}
              {t("on the ")} &nbsp;
              {paymentMethodSelected.network}{" "}
              {languageSelected === "en" && "network"},{" "}
              {t(
                "transferring here any other coin will result in fund loss. Make sure you use the copy button above to get the correct wallet address."
              )}
            </p>
            <p>
              {t(
                "The minimum deposit amount is 30 USD, lower amount won't be credited or refunded."
              )}
            </p>
          </div>
          <div className="container-max-width-switchere desktop:flex tablet:flex justify-center py-5">
            <div className="xs:w-full flex flex-col justify-center items-center bg-white xs:mb-2 desktop:mb-0 px-3 py-4">
              {isFetching ? (
                <div className="w-full relative">
                  <Spinner />
                </div>
              ) : (
                <>
                  <MediaQuery minWidth={320} maxWidth={959}>
                    <QRCodeSVG value={ethWalletAddress} size={150} />
                  </MediaQuery>
                  <MediaQuery minWidth={960} maxWidth={1199}>
                    <QRCodeSVG value={ethWalletAddress} size={165} />
                  </MediaQuery>
                  <MediaQuery minWidth={1200}>
                    <QRCodeSVG value={ethWalletAddress} size={210} />
                  </MediaQuery>
                </>
              )}
              <p className="text-gray-700 desktop:text-base xs:text-sm mt-2 text-center pl-3">
                {t("Scan for quick access")}
              </p>
            </div>
          </div>
          <div className="text-black text-left pt-10 pb-7 px-8 flex flex-col mobile:flex-col mobile:gap-5 items-center justify-between xs:text-center">
            <span className="font-semibold mb-4 xs:text-sm ">
              {t(
                "Don't have any crypto-currency? You can get one with your bank card in less than 5 minutes!"
              )}
            </span>
            <Button.Tertiary
              size="small"
              onClick={handleRedirectToBuyCryptoTab}
            >
              <span className="xs:text-sm text-tertiary">
                {t("Buy crypto !")}
              </span>
            </Button.Tertiary>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Oxprocessing
