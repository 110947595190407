import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import dayjs from "dayjs"
import MediaQuery from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/free-solid-svg-icons"
import IconsButton from "./IconsButton"
import Button from "./Button"
// import toastSuccessIcon from "../assets/icons/toast-success-icon.svg"
// import toastErrorIcon from "../assets/icons/toast-error-icon.svg"
// import toastWarningIcon from "../assets/icons/toast-warning-icon.svg"
import noNotificationIcon from "../assets/images/notificationSpace/no-notification-icon.svg"
import logo from "../assets/images/notificationSpace/logo-new.svg"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

// TODO Making the notification system slide to left

const styleForShow = (isShown) => (isShown ? "block" : "hidden")
const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 5px;
    padding: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`
// const styleOnNotificationArray = (length) =>
//   length ? "h-full" : "h-full overflow-hidden"

// const iconType = {
//   success: toastSuccessIcon,
//   error: toastErrorIcon,
//   warning: toastWarningIcon,
// }

const customBorderStyle = {
  success: "border-green-500",
  error: "border-red-500",
  warning: "border-yellow-500",
}

const Notification = ({ title, body, type, timestamp, indexed }) => (
  <div
    className={`flex px-5 py-2 justify-between items-start border-l-[5px] shadow-xl ${
      customBorderStyle[type]
    } ${indexed ? "bg-lightPrimary" : ""}`}
  >
    <div>
      <h1 className="xl:text-base xs:text-xs font-bold text-white">{title}</h1>
      <p className="xl:text-base xs:text-xs xl:py1- xs:py-2 text-white py-[2px]">
        {body}
      </p>
      <span className="text-[10px] text-white">
        {" "}
        {dayjs(timestamp).format("MMM D-YYYY h:mm A")}
      </span>
    </div>
  </div>
)

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  indexed: PropTypes.bool.isRequired,
}

const NotificationSpace = ({
  notificationsArray,
  onClose,
  isShown,
  userName,
  openDepositModal,
}) => {
  const { t } = useTranslation()
  const handleCloseAndOpenCashierModal = () => {
    onClose()
    openDepositModal()
  }
  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isShown])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return (
    <div
      id="popup"
      className={`z-50 fixed w-full flex justify-center inset-0 ${
        isShown ? "" : "hidden"
      }`}
    >
      <div
        onClick={onClose}
        className="w-full  z-0 absolute inset-0 bg-overlayPurple  cursor-auto"
        role="button"
        tabIndex={0}
        onKeyDown={Function.prototype}
      >
        &nbsp;
      </div>
      <div
        className={`text-black xl:fixed xl:top-20 xl:right-0 shadow-2xl xl:w-2/6 xs:fixed xs:top-0 xs:right-0 xs:w-9/12  xl:rounded-md z-50 xl:overflow-auto  h-full  ${styleForShow(
          isShown
        )}`}
        css={scrollbarStyle}
        style={{ backgroundColor: themeColors?.primary }}
      >
        <MediaQuery minWidth={960}>
          <div className="flex justify-end items-center w-full absolute top-2 right-2  cursor-pointer ">
            <IconsButton onClick={onClose}>
              <FontAwesomeIcon icon={faX} className=" text-white" />
            </IconsButton>
          </div>
        </MediaQuery>
        <div className="h-full">
          {!notificationsArray?.length ? (
            <div
              className="h-full mobile-notification-space"
              style={{ backgroundColor: themeColors?.primary }}
            >
              <div className="bg-white absolute top-0  w-full px-2 py-3 xl:hidden">
                <div className="flex">
                  <img
                    fetchpriority="high"
                    src={logo}
                    alt="newLogo"
                    className="w-[45px]"
                  />
                  <div className="ml-5">
                    <span className="text-black font-bold">{userName}</span>
                    <br />
                    <span>$0.00</span>
                  </div>
                </div>
              </div>
              <div className="text-xl text-center py-10  h-full flex flex-col items-center justify-center">
                <img
                  fetchpriority="high"
                  src={noNotificationIcon}
                  alt="noNotificationIcon"
                  className="w-[100px]"
                />{" "}
                <div className="text-white text-center text-[15px] px-8">
                  {t("You have no notifications yet")}.<br />{" "}
                  {t("Start by placing a bet !")}
                </div>
                <div className="my-3">
                  <Button.Primary
                    size="small"
                    onClick={handleCloseAndOpenCashierModal}
                  >
                    {t("Make a Deposit")}
                  </Button.Primary>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-full">
              <div className="bg-white w-full px-2 py-3 xl:hidden">
                <div className="flex">
                  <img
                    fetchpriority="high"
                    src={logo}
                    alt="newLogo"
                    className="w-[45px]"
                  />
                  <div className="ml-5">
                    <span className="text-black font-bold">{userName}</span>
                    <br />
                    <span>$0.00</span>
                  </div>
                </div>
              </div>
              <div className="h-screen overflow-visible">
                <div className="flex justify-end px-5">
                  <span type="button" className="text-white text-xs py-3">
                    Mark all as read
                  </span>
                </div>
                <div>
                  {notificationsArray
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((notification) => (
                      <Notification
                        key={notification.timestamp}
                        title={notification.title}
                        body={notification.body}
                        type={notification.type}
                        timestamp={notification.timestamp}
                        indexed={
                          notificationsArray.indexOf(notification) % 2 === 0
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

NotificationSpace.defaultProps = {
  onClose: Function.prototype,
  isShown: true,
  notificationsArray: [],
  userName: "",
}
NotificationSpace.propTypes = {
  notificationsArray: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  isShown: PropTypes.bool,
  userName: PropTypes.string,
  openDepositModal: PropTypes.func.isRequired,
}
export default NotificationSpace
