import React from "react"
import PropTypes from "prop-types"
import { Tab } from "@headlessui/react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

export const TabsHeader = ({ children }) => (
  <Tab.List className="border-b border-gray-300 box-border z-0 pl-5">
    {children}
  </Tab.List>
)
TabsHeader.propTypes = {
  children: PropTypes.element.isRequired,
}

const tabStyle = (selected, themeColors) => css`
  font-weight: bold; /* Equivalent to font-semibold */
  padding: 0 1rem; /* Equivalent to px-4 */
  box-sizing: border-box; /* Equivalent to box-border */
  ${selected
    ? `
    border-bottom: 3px solid ${themeColors.tertiary}; /* Dynamic tertiary color */
    color: ${themeColors.tertiary}; /* Dynamic tertiary color */
    z-index: 50;
  `
    : `
    color: #a0aec0; /* Equivalent to text-gray-400 */
  `}
`

export const TabsHeaderItem = ({ children, onClick }) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Tab onClick={onClick}>
      {({ selected }) => (
        <span
          // className={
          //   selected
          //     ? " border-b-[3px] border-tertiary box-border z-50 text-tertiary font-semibold px-4"
          //     : "text-gray-400 font-semibold box-border px-4"
          // }
          css={tabStyle(selected, themeColors)}
        >
          {children}
        </span>
      )}
    </Tab>
  )
}
TabsHeaderItem.defaultProps = {
  onClick: Function.prototype,
}
TabsHeaderItem.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export const TabsBody = ({ children }) => <Tab.Panels>{children}</Tab.Panels>
TabsBody.propTypes = {
  children: PropTypes.element.isRequired,
}
export const TabsBodyItem = ({ children }) => (
  <Tab.Panel className="pt-5 xl:px-10 xs:px-5 overflow-y-auto flex-1 justify-center custom-padding-bottom tablet:overflow-x-hidden mobile-big-screen:overflow-x-hidden">
    {children}
  </Tab.Panel>
)
TabsBodyItem.propTypes = {
  children: PropTypes.element.isRequired,
}

export const Tabs = ({ children, defaultIndex, selectedIndex }) => (
  <Tab.Group defaultIndex={defaultIndex} selectedIndex={selectedIndex}>
    {children}
  </Tab.Group>
)

Tabs.defaultProps = {
  defaultIndex: 0,
  selectedIndex: null,
}
Tabs.propTypes = {
  children: PropTypes.element.isRequired,
  defaultIndex: PropTypes.number,
  selectedIndex: PropTypes.number,
}

Tabs.Header = TabsHeader
Tabs.Header.Item = TabsHeaderItem
Tabs.Body = TabsBody
Tabs.Body.Item = TabsBodyItem
