import React from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"

const selectStyle = (theme) => ({
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "",
    color: "#fff",
    borderRadius: "none",
    boxShadow: "none",
    outline: "none",
    border: theme === "dark" ? "1px solid #fff" : "1px solid #gray !important",
    "&:hover": {
      border: `${theme === "dark" ? "#000" : "#fff"}${
        state.isFocused ? "1px solid #fff" : "1px solid #fff"
      }`,
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: theme === "dark" ? "#fff" : "#000",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme === "dark" ? "transparent" : "#fff",
    border: "1px solid #fff",
    margin: "0 0",
    borderRadius: "none",
  }),
  option: (styles) => ({
    ...styles,
    color: theme === "dark" ? "#fff" : "#000",
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: theme === "dark" ? "transparent" : "#cfaaff",
      color: theme === "dark" ? "" : "white",
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: theme === "dark" ? "#fff" : "#000",
  }),
})
const DropdownIndicator = (props) => {
  const { selectProps } = props
  let arrow
  if (selectProps.menuIsOpen) {
    arrow = (
      <ChevronUpIcon
        className=" h-5 w-5 text-violet-200 hover:text-violet-100"
        aria-hidden="true"
      />
    )
  } else {
    arrow = (
      <ChevronDownIcon
        className=" h-5 w-5 text-violet-200 hover:text-violet-100"
        aria-hidden="true"
      />
    )
  }
  return (
    <components.DropdownIndicator {...props}>
      {arrow}
    </components.DropdownIndicator>
  )
}
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape.isRequired,
}

const SelectField = ({ options, theme, onChange, disabled, placeholder }) => (
  <Select
    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
    options={options}
    placeholder={placeholder}
    styles={selectStyle(theme)}
    defaultValue={options[0].label}
    onChange={onChange}
    isSearchable={false}
    isDisabled={disabled}
  />
)
SelectField.defaultProps = {
  theme: "dark",
  onChange: Function.prototype,
  disabled: false,
  placeholder: "",
}
SelectField.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  theme: PropTypes.string,
}

export default SelectField
