import React from "react"
// import logo from "../assets/icons/spinner-logo.svg"
// import carbonLogo from "../assets/icons/carbon-gaming-spinner-logo.svg"
import { getBrandFromUrl, logoSpinner } from "../helpers"

const LogoSpinner = () => {
  const brand = getBrandFromUrl()
  return (
    <div className="flex justify-center items-center div-loader">
      <img
        fetchpriority="high"
        src={logoSpinner[brand]}
        width={200}
        height={200}
        alt="logo spinner"
        className="svg-loader aspect-auto rounded-full "
      />
    </div>
  )
}

LogoSpinner.defaultProps = {}

LogoSpinner.propTypes = {}

export default LogoSpinner
