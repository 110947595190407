import React, { useEffect } from "react"
import PropTypes from "prop-types"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const Dialog = ({ children }) => {
  useEffect(() => {
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [document.body.style.overflow])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <div
      className="fixed  inset-0 bg-opacity-50 overflow-y-auto h-screen w-full z-50"
      style={{
        backgroundColor: themeColors?.overlayPurple,
      }}
    >
      <div className="w-full h-full flex justify-center items-center">
        <div
          className="px-3  xl:w-[400px] xl:h-max md:w-3/5 md:h-max xs:w-full tablet:w-3/5 tablet:h-max xs:h-full shadow-lg rounded-2xl"
          style={{
            backgroundColor: themeColors?.primary,
          }}
        >
          <div className=" text-white w-full">{children}</div>
        </div>
      </div>
    </div>
  )
}

Dialog.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Dialog
