import React from "react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import Header from "../../components/Header"
import { promptBrand } from "../../helpers"

const listStyle = css`
  list-style: disc;
`

const FairBets = () => (
  <>
    <Header title={`${promptBrand("name")} | Fair Bets`} />
    <section className="bg-primary text-white md:pb-32 xs:pb-20  w-full policies-custom-style">
      <div className="container-max-width">
        <div className="mx-auto  pt-40 sm:px-7 lg:px-0 xl-start:p-10 xs:px-6">
          <h1 className="text-2xl font-bold mb-10">Fair Bets</h1>
          <h2 className="text-xl font-bold my-3">
            How CarbonTeam is Provably Fair
          </h2>
          <p className="my-2">Overview</p>
          <p>
            CarbonTeam prides itself on being an honest casino, so it seems
            fitting that we have taken some measures to ensure our games are
            provably fair.
            <br /> Our crypto is provably fair which means that you do not have
            to “trust” us to be fair, but you can prove our fairness.
            <br /> Each bet made on CarbonTeam can be verified so that you know
            with certainty the house could have in no way “chosen” the outcome.
            <br /> You will see several tabs above each named after a game we
            offer.
            <br /> By clicking them you will be able to see how we generate our
            bet results. Thank you for using CarbonTeam !
          </p>
          <ul className="py-5" css={listStyle}>
            <li className="my-1">UpGaming - Chicken MyStake</li>
            <li className="my-1">SmartSoft Gaming - Jet X</li>
            <li className="my-1">Evolution Gaming - Lighting Roulette</li>
            <li className="my-1">Stake - Plinko</li>
            <li className="my-1">
              Evolution gaming - Monopoly Live (a voir étant donné que ça
              représente un autre univers)
            </li>
            <li className="my-1"> Evolutiln gaming - Dream Catcher</li>
            <li className="my-1">Evolution gaming - crazy time</li>
            <li className="my-1">Evolution Gaming - Speed roulette</li>
            +information server seed & client seed
          </ul>
        </div>
      </div>
    </section>
  </>
)

export default FairBets
