import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useSearchParams, useParams, useNavigate } from "react-router-dom"
import MediaQuery from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMaximize, faX } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import Button from "../../../components/Button"
import Toggle from "../../../components/Toggle"
import getGameUrl from "../../../actions/game"
import { toast } from "../../../components/Toast"
import Spinner from "../../../components/Spinner"
import noBalanceAvatar from "../../../assets/icons/no_balance_avatar.png"
import loginAvatar from "../../../assets/icons/login-avatar.png"
import noLobbyAsset from "../../../assets/icons/no_lobby_asset.png"
import frameError from "../../../assets/icons/error-avatar.png"
import themes from "../../../themes"
import IconsButton from "../../../components/IconsButton"
import { getBrandFromUrl, logger } from "../../../helpers"
import PageLink from "../../../components/PageLink"

const FrameViews = {
  SIGN_IN_SIGN_UP: "sign_in_sign_up",
  DEPOSIT: "deposit",
  GAME: "game",
  GAME_NO_LOBBY: "GAME_NO_LOBBY",
  GAME_ERROR_OCCURED: "GAME_ERROR_OCCURED",
}
const GameView = ({
  hasDemo,
  isFullScreenActive,
  setIsFullScreenActive,
  setGameError,
  setDisplayedViewName,
}) => {
  const { i18n } = useTranslation()
  const iframeRef = useRef(null)
  const { slug } = useParams()
  const [errorGameUrl, setErrorGameUrl] = useState(false)
  const [url, setUrl] = useState("")
  const [isShown, setIsShown] = useState(false)
  const [mobileIsLoading, setMobileIsLoading] = useState(false)
  const countryCode = i18n.language.split("-")[0]
  const { t } = useTranslation()

  const { isLoading, refetch } = useQuery({
    queryKey: ["GetGameURL", slug, hasDemo, i18n],
    queryFn: () => getGameUrl(slug, hasDemo, countryCode),
    refetchOnMount: true,
    enabled: window.innerWidth >= 785,
    onSuccess: ({ data: gameData }) => {
      setUrl(gameData?.url)
      setGameError(false)
    },

    onError: (error) => {
      setErrorGameUrl(true)
      if (error.status === 403 && error.data.code === "game-has-no-lobby") {
        toast.error("Game has no lobby.", "Please try again later.")
        setGameError(true)
      } else if (
        error.status === 403 &&
        error.data.code === "game-access-forbidden"
      ) {
        toast.error("An error has occurred.", "Game access forbidden.")
        setDisplayedViewName(FrameViews.GAME_ERROR_OCCURED)
      } else {
        toast.error("An error has occurred.", "Please try again later.")
        setDisplayedViewName(FrameViews.GAME_ERROR_OCCURED)
      }
    },
  })

  const handleOpenGameOnMobileDevice = async () => {
    setMobileIsLoading(true)
    refetch()
      .then((data) => {
        logger(data)
        logger(data?.data?.data?.url)
        if (data?.data?.data?.url) {
          setTimeout(() => {
            window.open(data?.data?.data?.url, "_top")
            setMobileIsLoading(false)
          })
        }
      })
      .catch((error) => {
        logger(error)
        handleOpenGameOnMobileDevice()
      })
  }

  const handleFullScreenMode = () => {
    if (!errorGameUrl) {
      const myIframe = iframeRef.current
      if (isFullScreenActive && myIframe) {
        if (myIframe.requestFullscreen) {
          myIframe.requestFullscreen()
        } else if (myIframe.mozRequestFullScreen) {
          myIframe.mozRequestFullscreen()
        } else if (myIframe.webkitRequestFullScreen) {
          myIframe.webkitRequestFullscreen()
        }
      }
      if (!myIframe) {
        toast.error(
          t("Full Screen option is unavailable"),
          t(
            "Sorry, the full  screen option is not available now, please try again !"
          )
        )
      }
      setIsFullScreenActive(false)
    }
  }

  const iFrameLoadEvent = () => {
    setIsShown(true)
  }

  useEffect(() => {
    if (isFullScreenActive) {
      handleFullScreenMode()
    }
  }, [isFullScreenActive])

  return (
    <>
      <MediaQuery minWidth={275} maxWidth={767}>
        {mobileIsLoading ? (
          <Spinner theme="light" />
        ) : (
          <Button.Primary onClick={handleOpenGameOnMobileDevice}>
            {t("Play Now")}
          </Button.Primary>
        )}
      </MediaQuery>
      <MediaQuery minWidth={768}>
        {isLoading ? (
          <Spinner theme="light" />
        ) : (
          <div className="h-full w-full relative">
            <iframe
              fetchpriority="high"
              loading="lazy"
              decoding="async"
              ref={iframeRef}
              title="game"
              src={url}
              onLoad={iFrameLoadEvent}
              frameBorder="0"
              allow={isFullScreenActive ? "fullscreen" : ""}
              allowFullScreen={isFullScreenActive}
              className="w-full h-full z-0"
            />
            {isShown ? (
              <div />
            ) : (
              <div className="z-20 absolute h-full w-full inset-0 flex justify-center items-center">
                <Spinner theme="light" />
              </div>
            )}
          </div>
        )}
      </MediaQuery>
    </>
  )
}
GameView.defaultProps = {
  isFullScreenActive: false,
  setIsFullScreenActive: Function.prototype,
  setGameError: Function.prototype,
  setDisplayedViewName: Function.prototype,
}

GameView.propTypes = {
  hasDemo: PropTypes.bool.isRequired,
  isFullScreenActive: PropTypes.bool,
  setIsFullScreenActive: PropTypes.func,
  setGameError: Function.prototype,
  setDisplayedViewName: Function.prototype,
}

const FrameView = ({
  viewName,
  toggleStatus,
  isFullScreenActive,
  setIsFullScreenActive,
  setGameError,
  setDisplayedViewName,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleOpenLoginModal = () => {
    searchParams.set("service", "login")
    setSearchParams(searchParams)
  }

  const handleOpenRegisterModal = () => {
    searchParams.set("service", "register")
    setSearchParams(searchParams)
  }

  const handleMakeDeposit = () => {
    searchParams.set("service", "cashier")
    setSearchParams(searchParams)
  }

  const handleRetry = () => {
    window.location.reload(true)
  }
  const handleBrowseGames = () => {
    navigate("/")
  }

  const navigateToGamesCatalog = () => navigate("#games-catalog-game-page")

  if (viewName === FrameViews.GAME) {
    return (
      <GameView
        hasDemo={toggleStatus}
        isFullScreenActive={isFullScreenActive}
        setIsFullScreenActive={setIsFullScreenActive}
        setGameError={setGameError}
        setDisplayedViewName={setDisplayedViewName}
      />
    )
  }

  if (viewName === FrameViews.GAME_ERROR_OCCURED) {
    return (
      <div className="xs:h-[500px] md:h-[600px] xl:h-[600px] flex flex-col justify-center items-center">
        <img
          fetchpriority="high"
          loading="lazy"
          decoding="async"
          className="w-5/12 m-4 "
          src={frameError}
          alt=""
        />
        <p className="xl:text-xl xs:text-xs text-center font-bold m-4">
          {t("Oops ! An error has occured.")}
        </p>
        <div className="w-full flex justify-center items-center mt-2 text-center">
          <Button.Primary size="small" onClick={navigateToGamesCatalog}>
            <span className="xs:text-xs xl:text-md w-full">
              {" "}
              {t("Browse Games")}
            </span>
          </Button.Primary>
          <Button.Secondary size="small" onClick={handleRetry}>
            <span className="xs:text-xs w-full xl:text-md">{t("Retry")}</span>
          </Button.Secondary>
        </div>
      </div>
    )
  }

  if (viewName === FrameViews.DEPOSIT) {
    return (
      <div className="xs:h-[500px] md:h-[600px] xl:h-[600px] flex flex-col justify-center items-center">
        <img
          fetchpriority="high"
          loading="lazy"
          decoding="async"
          className="w-5/12 m-4 "
          src={noBalanceAvatar}
          alt=""
        />
        <p className="xl:text-xl xs:text-xs text-center font-bold m-4">
          {t("You have no balance, make a deposit to experience Real mode")}
        </p>
        <div className="w-full flex justify-center items-center mt-2 text-center">
          <Button.Primary size="small" onClick={handleMakeDeposit}>
            <span className="xs:text-xs xl:text-md w-full">
              {t("Make a Deposit")}
            </span>
          </Button.Primary>
          <Button.Secondary size="small" onClick={navigateToGamesCatalog}>
            <span className="xs:text-xs w-full xl:text-md">
              {t("Browse Games")}
            </span>
          </Button.Secondary>
        </div>
      </div>
    )
  }

  if (viewName === FrameViews.SIGN_IN_SIGN_UP) {
    return (
      <div className="xs:h-[500px] md:h-[600px] xl:h-[600px] flex flex-col justify-center items-center">
        <img
          fetchpriority="high"
          loading="lazy"
          decoding="async"
          className="w-5/12 m-4"
          src={loginAvatar}
          alt=""
        />
        <p className="xl:text-xl xs:text-xs text-center font-bold m-4">
          {t("You are playing for fun, login to play for real.")}
        </p>
        <div className="w-full flex justify-center items-center mt-2 text-center">
          <Button.Primary size="small" onClick={handleOpenLoginModal}>
            {t("Sign In")}
          </Button.Primary>
          <Button.Secondary size="small" onClick={handleOpenRegisterModal}>
            {t("Sign Up")}
          </Button.Secondary>
        </div>
      </div>
    )
  }
  if (viewName === FrameViews.GAME_NO_LOBBY) {
    return (
      <div className="xs:h-[500px] md:h-[600px] xl:h-[600px] flex flex-col justify-center items-center">
        <img
          fetchpriority="high"
          loading="lazy"
          decoding="async"
          className="w-5/12 m-4"
          src={noLobbyAsset}
          alt=""
        />
        <p className="xl:text-xl xs:text-xs text-center font-bold m-4">
          {t("This game is not available in your jurisdiction.")}
        </p>
        <div className="w-full flex justify-center items-center mt-2 text-center">
          <Button.Secondary size="small" onClick={handleBrowseGames}>
            <span className="xs:text-xs w-full xl:text-md">
              {t("Browse Games")}
            </span>
          </Button.Secondary>
        </div>
      </div>
    )
  }
  return <div />
}
FrameView.defaultProps = {
  isFullScreenActive: false,
  setIsFullScreenActive: Function.prototype,
  setGameError: Function.prototype,
  setDisplayedViewName: Function.prototype,
}
FrameView.propTypes = {
  viewName: PropTypes.string.isRequired,
  toggleStatus: PropTypes.bool.isRequired,
  isFullScreenActive: PropTypes.bool,
  setIsFullScreenActive: PropTypes.func,
  setGameError: PropTypes.func,
  setDisplayedViewName: PropTypes.func,
}
const ToggleHandlings = {
  DEFAULT: "default",
  GAME_TO_DEPOSIT: "game_to_deposit",
  GAME_TO_SIGN_IN_SIGN_UP: "game_to_sign_in_sign_up",
}

const Hero = () => {
  const { t } = useTranslation()
  const { slug } = useParams()
  const { games } = useSelector((state) => state.games)

  const { loggedIn } = useSelector((state) => state.auth)
  const { balance } = useSelector((state) => state.wallet)

  const [displayedViewName, setDisplayedViewName] = useState()

  const [isToggleShown, setIsToggleShown] = useState(false)
  const [isToggleEnabled, setIsToggleEnabled] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(true)
  const [gameError, setGameError] = useState(false)
  const [toggleHandling, setToggleHandling] = useState(ToggleHandlings.DEFAULT)

  const [isFullScreenActive, setIsFullScreenActive] = useState(false)
  const handleFullScreen = () => {
    setIsFullScreenActive(!isFullScreenActive)
  }

  const gameData = games.find((game) => game.slug === slug)
  const hasFunMode = gameData.has_fun_mode

  const handleToggle = (isRealMode) => {
    if (toggleHandling === ToggleHandlings.GAME_TO_DEPOSIT) {
      if (isRealMode) {
        setDisplayedViewName(FrameViews.DEPOSIT)
      } else {
        setDisplayedViewName(FrameViews.GAME)
      }
    }

    if (toggleHandling === ToggleHandlings.GAME_TO_SIGN_IN_SIGN_UP) {
      if (isRealMode) {
        setDisplayedViewName(FrameViews.SIGN_IN_SIGN_UP)
      } else {
        setDisplayedViewName(FrameViews.GAME)
      }
    }

    setToggleStatus(isRealMode)
  }

  useEffect(() => {
    if (loggedIn) {
      if (balance > 0) {
        if (hasFunMode) {
          setIsToggleShown(true)
          // default real mode
          setToggleStatus(true)
          // toggle enabled
          setIsToggleEnabled(true)
          setDisplayedViewName(FrameViews.GAME)
          // toggle switch fun real
          setToggleHandling(ToggleHandlings.DEFAULT)
        } else {
          setIsToggleShown(true)
          // default real mode
          setToggleStatus(true)
          // toggle disabled
          setIsToggleEnabled(false)
          setDisplayedViewName(FrameViews.GAME)
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (hasFunMode) {
          setIsToggleShown(true)
          setIsToggleEnabled(true)
          // default fun mode
          setToggleStatus(true)
          setDisplayedViewName(FrameViews.DEPOSIT)
          // toggle show deposit view
          setToggleHandling(ToggleHandlings.GAME_TO_DEPOSIT)
        } else {
          // hide toggle
          setIsToggleShown(false)
          // show deposit view
          setDisplayedViewName(FrameViews.DEPOSIT)
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (hasFunMode) {
        setIsToggleShown(true)
        setIsToggleEnabled(true)
        // default fun mode
        setToggleStatus(true)
        setDisplayedViewName(FrameViews.SIGN_IN_SIGN_UP)
        // toggle show game view
        setToggleHandling(ToggleHandlings.GAME_TO_SIGN_IN_SIGN_UP)
      } else {
        // hide toggle
        setIsToggleShown(false)
        // show sign view
        setDisplayedViewName(FrameViews.SIGN_IN_SIGN_UP)
      }
    }
  }, [loggedIn, balance > 0, hasFunMode])
  useEffect(() => {
    if (gameError) {
      setDisplayedViewName(FrameViews.GAME_NO_LOBBY)
    }
  }, [gameError])

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <section
      className=" about-section  md:pb-10 xs:pb-10  text-white w-full"
      style={{
        backgroundColor: themeColors?.primary,
        color: "white",
      }}
    >
      <div className="container-max-width  pt-32 sm:px-7 lg:px-0 xl-start:p-10 xs:px-6 ">
        <div
          className="w-full flex  flex-col	 justify-center items-center rounded-[25px]"
          style={{
            backgroundColor: themeColors?.secondary,
            color: "white",
          }}
        >
          <div
            className="border-b-black w-full flex  justify-center items-center game-view  border-2 border-secondary"
            style={{
              backgroundColor: themeColors?.secondary,
              color: "white",
            }}
          >
            <FrameView
              viewName={displayedViewName}
              toggleStatus={toggleStatus}
              isFullScreenActive={isFullScreenActive}
              setIsFullScreenActive={setIsFullScreenActive}
              setGameError={setGameError}
              setDisplayedViewName={setDisplayedViewName}
            />
          </div>
          <div className="w-full flex justify-between items-center p-2">
            <div className="w-auto flex justify-start items-center">
              <MediaQuery minWidth={960}>
                <IconsButton onClick={handleFullScreen}>
                  <FontAwesomeIcon
                    icon={faMaximize}
                    className="w-5 h-5 m-4 ml-4 cursor-pointer text-white"
                  />
                </IconsButton>
              </MediaQuery>
              <IconsButton>
                <PageLink to="/">
                  <FontAwesomeIcon
                    icon={faX}
                    className="w-5 h-5 m-4 ml-4 cursor-pointer text-white"
                  />
                </PageLink>
              </IconsButton>
            </div>
            {isToggleShown && (
              <div className="flex justify-end items-center mt-1 pr-3">
                {toggleStatus ? (
                  <div className="xl:text-xl xs:text-xs mr-2">
                    {t("Real Mode")}
                  </div>
                ) : (
                  <div className="xl:text-xl xs:text-xs mr-2">
                    {t("Fun Mode")}
                  </div>
                )}
                <Toggle
                  onChange={handleToggle}
                  disabled={!isToggleEnabled}
                  defaultStatus={toggleStatus}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
