import { Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

const PrivateRoute = () => {
  const { loggedIn } = useSelector((state) => state.auth)

  if (!loggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/?service=login" replace />
  }

  // authorized so return child components
  return <Outlet />
}

export default PrivateRoute
