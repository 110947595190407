import React, { useState } from "react"
import { useQuery } from "react-query"
import MediaQuery from "react-responsive"

import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Banner from "./Banner"
import Button from "./Button"
import ProgressBar from "./ProgressBar"
// import giftBoxAsset from "../assets/images/home/gift-box.png"
import { getDepositBonusInfo } from "../actions/user"
import Spinner from "./Spinner"
import { logger } from "../helpers"

// TODO I comment the deposit bonus banner logic and remove the onClick function, check the pr

const computePercentage = (amountWagered, amountToBeReached) => {
  if (amountWagered > 0) {
    const percentage =
      Math.round((amountWagered / amountToBeReached) * 10000) / 100
    return percentage
  }
  return 0
}

const BonusBanner = () => {
  const { t } = useTranslation()
  const [amountToBeReachedValue, setAmountToBeReachedValue] = useState(0)
  const [totalEarningsValue, setTotalEarningsValue] = useState(0)
  const [bonusAmountValue, setBonusAmountValue] = useState(0)
  const [messageBonus, setMessageBonus] = useState(
    "Make your first bet to gain or Top up the account !"
  )

  const [depositBonusProgressValue, setDepositBonusProgressValue] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const { isLoading } = useQuery({
    queryKey: ["getDepositBonusInfo"],
    queryFn: () => getDepositBonusInfo(),
    refetchOnMount: true,
    onSuccess: ({ data: depositBonusData }) => {
      const {
        amount_wagered: amountWagered,
        amount_to_be_reached: amountToBeReached,
        total_win_amount_since_activation: totalEarnings,
        bonus_amount: bonusAmount,
      } = depositBonusData

      const percentage = computePercentage(amountWagered, amountToBeReached)

      setAmountToBeReachedValue(Math.round(amountToBeReached * 100) / 100)
      setTotalEarningsValue(Math.round(totalEarnings * 100) / 100)
      logger(totalEarnings)
      setBonusAmountValue(Math.round(bonusAmount * 100) / 100)
      setDepositBonusProgressValue(Math.min(percentage, 100))

      // message
      if (percentage < 100 && percentage > 0) {
        setMessageBonus("Make more bets or Top up the account")
      } else if (percentage === 0) {
        setMessageBonus("Make your first bet to gain or Top up the account !")
      } else {
        setMessageBonus(
          "Congratulations ! You have reached your bonus challenge, top up your account !"
        )
      }
    },
    onError: (error) => {
      logger(error)
    },
  })

  const onclickReload = () => {
    searchParams.set("service", "cashier")
    searchParams.set("tab", "deposit")
    setSearchParams(searchParams)
  }

  return (
    <div className="desktop:px-0 about-section h-[100%] xs:pt-8 my-3">
      <Banner>
        <div className=" rounded-3xl pt-3 ">
          <div className="tablet:w-full mx-auto flex justify-start gap-5 items-center relative w-full">
            <div className="mobile:basis-3/3 desktop:basis-4/5 custom-banner-style  w-full tablet:basis-2/3 flex flex-col mobile:pl-3   mobile:pr-3">
              <h2 className="mb-3 flex justify-between items-center">
                <span className="text-xs font-bold text-black">
                  {t(messageBonus)}
                </span>
                <span className="pl-5 font-bold">
                  {amountToBeReachedValue}$
                </span>
              </h2>

              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <ProgressBar value={depositBonusProgressValue} isReused />
                  <div className="w-full flex   justify-between pt-2 mb-2 ">
                    <span className="text-xs mobile:pl-2 text-black">
                      {`${t("Bonus : ")}$${bonusAmountValue}`}
                    </span>
                    <span className="text-xs text-black">
                      {`${t("Total Earnings")}: $${totalEarningsValue}`}
                    </span>
                  </div>
                </>
              )}
            </div>
            <MediaQuery minWidth={500}>
              <div className=" flex desktop:justify-end xs:justify-center">
                <Button.Primary size="small" onClick={onclickReload}>
                  {t("Top Up")}
                </Button.Primary>
              </div>
            </MediaQuery>
          </div>
          <MediaQuery maxWidth={499}>
            <div className="flex justify-end">
              <Button.Tertiary size="small" onClick={onclickReload}>
                <span className="text-tertiary text-sm flex items-center gap-2">
                  {t("Top Up")}
                </span>{" "}
              </Button.Tertiary>
            </div>
          </MediaQuery>
        </div>
      </Banner>
    </div>
  )
}

export default BonusBanner
