import React from "react"
import { Link, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

// const useQuery = () => new URLSearchParams(useLocation().search)

const PageLink = ({ to, children, classNames, textStyle }) => {
  const { loggedIn } = useSelector((state) => state.auth)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const refParam = query.get("ref") ? `ref=${query.get("ref")}` : ""
  const leadIdParam = query.get("lead_id")
    ? `lead_id=${query.get("lead_id")}`
    : ""
  const urlParams = [refParam, leadIdParam].filter(Boolean).join("&")
  const urlParam = urlParams ? `?${urlParams}` : ""

  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors || {}

  return !loggedIn ? (
    <Link
      to={`${to}${urlParam}`}
      className={classNames}
      style={{
        color: textStyle ? themeColors[textStyle] : themeColors[classNames],
      }}
    >
      {children}
    </Link>
  ) : (
    <Link
      to={`${to}`}
      className={classNames}
      style={{
        color: textStyle ? themeColors[textStyle] : themeColors[classNames],
      }}
    >
      {children}
    </Link>
  )
}
PageLink.defaultProps = {
  textStyle: "",
}

PageLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  classNames: PropTypes.string.isRequired,
  textStyle: PropTypes.string,
}

export default PageLink
