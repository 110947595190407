import { useNavigate, useSearchParams, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import MediaQuery from "react-responsive"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types"
import Checkbox from "./Checkbox"
import SloganText from "./SloganText"
import { Modal } from "./Modal"
import Spinner from "./Spinner"
import Button from "./Button"
// import loginModalAsset from "../assets/images/modalAssets/sign-in-modal-asset.png"
import { brandLogo, getBrandFromUrl, imageDistributed } from "../helpers"
import themes from "../themes"

const serviceName = "welcome"

const borderStyle = (themeColors) => css`
  border: 1px solid ${themeColors.tertiary};
`

const textStyle = (themeColors) => css`
  @media (max-width: 959px) {
    color: black !important;
  }

  @media (min-width: 960px) {
    color: ${themeColors.tertiary} !important;
  }
`

const WelcomePopup = ({ activeService }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // const { username, password } = useSelector((state) => state.auth)

  const [searchParams, setSearchParams] = useSearchParams()

  const [subscribed, setSubscribed] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubscribeToNewsLetter = () => setSubscribed(!subscribed)

  const handleRedirectToHomePage = async () => {
    setIsLoading(true)
    searchParams.delete("service")
    setSearchParams(searchParams)
    navigate("/", { state: { legalNotice: true } })
    setIsLoading(false)
  }
  const handleCloseModal = () => {
    searchParams.delete("service")
    setSearchParams(searchParams)
  }

  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <Modal activeModal={activeService === serviceName}>
      <div className="flex relative w-full">
        <div
          className="desktop:w-[42%] flex flex-col items-center justify-center py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block  blurry"
          style={{
            backgroundColor: themeColors?.secondary,
            // color: "white",
          }}
          css={borderStyle(themeColors)}
        >
          <div className="flex flex-col">
            <img
              fetchpriority="high"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-version-svg"
              className="aspect-auto"
            />
            <SloganText />
          </div>
          <div className="flex justify-center">
            <img
              fetchpriority="high"
              src={imageDistributed("signInModalAsset")}
              alt="step-icon-1"
              className=" aspect-auto my-4 p-4"
            />
          </div>
          <p className="text-center  font-bold">
            <span className=" mr-1" style={{ color: themeColors?.tertiary }}>
              100%
            </span>
            <span className="text-white">{t("Bonus Up to")} </span>
            <span className="" style={{ color: themeColors?.tertiary }}>
              300€
            </span>
            <span className="text-white ml-1">{t("more")}</span>
          </p>
          <p className="text-center font-medium">
            <span className="text-md" style={{ color: themeColors?.tertiary }}>
              2.500.120.454
            </span>
            <br />
            <span className="text-white text-sm opacity-40">
              {t("Bets Wagered")}
            </span>
          </p>
        </div>
        <div
          className="desktop:w-[58%] pb-3 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view"
          css={borderStyle(themeColors)}
          style={{
            backgroundColor: themeColors?.secondary,
          }}
        >
          <div className="w-full h-full">
            <div className="h-full">
              <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
                <h1
                  className="text-xl leading-5 font-medium px-7"
                  css={textStyle(themeColors)}
                >
                  {t("Sign Up")}
                </h1>
                <MediaQuery maxWidth={959}>
                  <Modal.Header handleCloseModal={handleCloseModal} />
                </MediaQuery>
                <MediaQuery minWidth={959}>
                  <Modal.Header
                    handleCloseModal={handleCloseModal}
                    tertiaryIcon
                  />
                </MediaQuery>
              </div>
              <div className="relative bottom-5 desktop:h-full">
                <MediaQuery maxWidth={959}>
                  <div className="flex justify-center items-center my-5 py-5 mt-14">
                    <img
                      fetchpriority="high"
                      src={brandLogo()}
                      alt="logo-mobile-version"
                      className="w-60 h-auto"
                    />
                  </div>
                </MediaQuery>
                <div className="px-7 h-full flex flex-col justify-center">
                  <h1 className="text-white font-bold md:text-[40px] xs:text-3xl text-center xs:mb-3">
                    {t("Congratulations!")}
                  </h1>
                  <p className="text-white text-center w-3/4 mx-auto mb-3 xs:font-light tablet:font-bold xs:w-full">
                    {t("You have successfully joined our")}{" "}
                    <span className="" style={{ color: themeColors?.tertiary }}>
                      {t("Carbon Casino")}{" "}
                    </span>{" "}
                    {t("Enjoy your time here !")}
                  </p>
                  <MediaQuery minWidth={350} maxWidth={959}>
                    <p>
                      <img
                        fetchpriority="high"
                        src={imageDistributed("signInModalAsset")}
                        alt="step-icon-1"
                        className=" aspect-auto h-[200px] tablet:h-[300px] tablet:my-2 p-4 mx-auto"
                      />
                    </p>
                  </MediaQuery>
                  <div className="flex mb-3 w-full xs:w-full mx-auto">
                    <div className="relative -top-2 ">
                      <Checkbox
                        checked={subscribed}
                        onChange={handleSubscribeToNewsLetter}
                      />
                    </div>
                    <p className="text-white xs:text-xs desktop:text-md desktop:text-center tablet:font-bold">
                      {t(
                        "Subscribe to our newsletter and stay updated on the latest games"
                      )}
                    </p>
                  </div>
                  <div className="flex justify-center pb-7">
                    <MediaQuery minWidth={320} maxWidth={959}>
                      {isLoading ? (
                        <div className="pb-5">
                          <Spinner theme="dark" />
                        </div>
                      ) : (
                        <Button.Primary
                          size="small"
                          fullWidth
                          onClick={handleRedirectToHomePage}
                        >
                          {t("Play Now")}
                        </Button.Primary>
                      )}
                    </MediaQuery>
                    <MediaQuery minWidth={960}>
                      {isLoading ? (
                        <div className="pb-5">
                          <Spinner theme="dark" />
                        </div>
                      ) : (
                        <Button.Primary
                          size="medium"
                          fullWidth
                          onClick={handleRedirectToHomePage}
                        >
                          {t("Play Now")}
                        </Button.Primary>
                      )}
                    </MediaQuery>
                  </div>
                  <MediaQuery minWidth={960}>
                    <div className="mb-7">
                      <p className="text-xs text-white text-center">
                        {t(
                          "This site is protected by reCAPTCHA and the Google"
                        )}{" "}
                        <Link
                          to="/policy/privacy-policy"
                          style={{ color: themeColors?.tertiary }}
                        >
                          {" "}
                          {t("Privacy Policy")}
                        </Link>{" "}
                        {t("and")}{" "}
                        <Link
                          to="terms/terms-and-conditions"
                          style={{ color: themeColors?.tertiary }}
                        >
                          {t("Terms of Service")}
                        </Link>{" "}
                        {t(
                          "apply. By accessing the site I attest that I am at least 18 years old and have read the"
                        )}{" "}
                        <Link
                          to="terms/terms-and-conditions"
                          style={{ color: themeColors?.tertiary }}
                        >
                          {t("Terms of Service")}
                        </Link>
                      </p>
                    </div>
                  </MediaQuery>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

WelcomePopup.defaultProps = {
  activeService: null,
}

WelcomePopup.propTypes = {
  activeService: PropTypes.string,
}

export default WelcomePopup
