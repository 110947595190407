import React from "react"
import PropTypes from "prop-types"
import { Progress } from "@material-tailwind/react"
import { getBrandFromUrl } from "../helpers"
/** @jsxImportSource @emotion/react */

const ProgressBar = ({ value }) => {
  const brand = getBrandFromUrl()

  const barPropStyle = {
    "www.carbongaming.net": "bar-not-complete-carbon-gaming",
    "www.redemptionbits.com": "bar-not-complete-redemption-bits",
  }

  return (
    <div className="relative w-full flex items-center justify-center">
      <div className="absolute  left-50 text-base">{value}%</div>
      <Progress
        value={value}
        size="lg"
        className="rounded-xl p-[2px] h-[25px] bg-transparent border border-tertiary text-center "
        label={value}
        barProps={{
          className: `w-full text-center rounded-xl text-center text-green  text-transparent ${
            value < 100 ? barPropStyle[brand] : "bar-complete"
          }`,
        }}
      />
    </div>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
}

export default ProgressBar
