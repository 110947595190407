import popularGamesIcon from "../../assets/icons/gamesCatalog/popular-games-icon.png"
import slotGamesIcon from "../../assets/icons/gamesCatalog/slot-games-icon.png"
import crashGamesIcon from "../../assets/icons/gamesCatalog/crash-games-icon.png"
import onlineCasinoIcon from "../../assets/icons/gamesCatalog/online-casino-icon.png"
import liveGamesIcon from "../../assets/icons/gamesCatalog/latest-games-icon.png"

export default [
  {
    category: "popular-games",
    imageSource: popularGamesIcon,
    tags: "popular",
    title: "Popular Games",
    mobile: true,
  },
  {
    category: "slot-games",
    imageSource: slotGamesIcon,
    title: "Slot Games",
    tags: "slot",
    mobile: true,
  },
  {
    category: "table-games",
    imageSource: onlineCasinoIcon,
    title: "Table Games",
    tags: "table",
    mobile: true,
  },
  {
    category: "live-games",
    imageSource: liveGamesIcon,
    title: "Live Games",
    tags: "live",
    mobile: true,
  },
  {
    category: "crash-games",
    imageSource: crashGamesIcon,
    title: "Crash Games",
    tags: "crash",
    mobile: true,
  },
]
