import { GET_GAMES_FULFILLED } from "../actions/types"

const initialState = {
  games: [],
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_GAMES_FULFILLED:
      return {
        ...state,
        games: payload,
      }
    default:
      return state
  }
}
