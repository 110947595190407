import { createStore, applyMiddleware } from "redux"
import * as Sentry from "@sentry/react"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"

import rootReducer from "./reducers"
import { POST_REGISTER_CHECK_FULFILLED } from "./actions/types"

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === POST_REGISTER_CHECK_FULFILLED) {
      return {
        ...action,
        payload: {
          ...action.payload,
          password: "password-hidden",
        },
      }
    }

    return action
  },
  // Optionally pass options listed below
})

const initialState = {}

const middleware = [thunk]
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer)
)

export default store
