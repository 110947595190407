import ReactGA from "react-ga"
import { logger } from "../helpers"

const useAnalyticsEventTracker = (category = "TEST category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    logger(action)
    ReactGA.event({ category, action, label })
  }

  return eventTracker
}
export default useAnalyticsEventTracker
