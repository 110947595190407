import {
  GET_USER_FULFILLED,
  DELETE_USER,
  GET_USER_AFFILIATE_FULFILLED,
  POST_USER_BILLING_ADDRESS_FULFILLED,
  DISMISS_DEPOSIT_POPUP_FULFILLED,
  DISMISS_LEVEL_REWARD_POPUP_FULFILLED,
} from "../actions/types"

const initialState = {
  loading: false,
  is2FAActive: false,
  billingAddress: {},
  referrer: "",
  showDepositBonusPopup: false,
  affiliateInformation: { Referrals: 0, numberBet: 0, Deposits: 0 },
  showLevelRewardPopup: false,
  userLevelDetails: {},
  bonusActive: false,
  currentLevelSlug: null,
  brand: null,
  groups: [],
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case GET_USER_FULFILLED:
      return {
        ...state,
        ...payload,
      }
    case GET_USER_AFFILIATE_FULFILLED:
      return {
        ...state,
        ...payload,
      }
    case DISMISS_DEPOSIT_POPUP_FULFILLED:
      return {
        ...state,
        showDepositBonusPopup: false,
      }
    case DISMISS_LEVEL_REWARD_POPUP_FULFILLED:
      return {
        ...state,
        showLevelRewardPopup: false,
      }

    case DELETE_USER:
      return initialState
    case POST_USER_BILLING_ADDRESS_FULFILLED:
      return {
        ...state,
        billingAddress: payload,
      }
    default:
      return state
  }
}
