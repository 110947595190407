import {
  SET_LOGGED_IN,
  POST_LOGIN_FULFILLED,
  POST_REGISTER_FULFILLED,
  POST_REGISTER_CHECK_FULFILLED,
  POST_SEND_VERIFICATION_CODE_FULFILLED,
  GO_BACK_REGISTER_FORM_VIEW,
  POST_LOGOUT_FULFILLED,
  POST_AUTH_FULFILLED,
  SITE_LOAD_COMPLETE,
} from "../actions/types"

const initialState = {
  loggedIn: false,
  registerDataChecked: false,
  registered: false,
  username: "",
  email: "",
  phoneNumber: "",
  password: "",
  bonusCode: "",
  referrer: "",
  requestID: "",
  is2FAActive: false,
  kyc: {},
  isSiteLoadComplete: false,
  canRegister: true,
  canBeReferred: false,
  brand: null,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
      }

    case POST_LOGIN_FULFILLED:
      return {
        ...state,
        loggedIn: true,
        username: payload.username,
        email: payload.email,
        isVerified: payload.isVerified,
        // brand: payload.brand,
      }

    case POST_AUTH_FULFILLED:
      return {
        ...state,
        loggedIn: true,
        ...payload,
      }

    case POST_REGISTER_CHECK_FULFILLED:
      return {
        ...state,
        ...payload,
        registerDataChecked: true,
      }

    case POST_SEND_VERIFICATION_CODE_FULFILLED:
      return {
        ...state,
        ...payload,
      }

    case GO_BACK_REGISTER_FORM_VIEW:
      return {
        ...state,
        registered: false,
        registerDataChecked: false,
      }

    case POST_REGISTER_FULFILLED:
      return {
        ...state,
        registered: true,
      }

    case POST_LOGOUT_FULFILLED:
      return {
        ...state,
        loggedIn: false,
        username: "",
        email: "",
      }
    case SITE_LOAD_COMPLETE:
      return {
        ...state,
        isSiteLoadComplete: true,
      }
    default:
      return state
  }
}
