import React from "react"
import {
  Card as MTCard,
  CardHeader,
  Typography,
  // Avatar,
} from "@material-tailwind/react"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import themes from "../themes"
import { getBrandFromUrl } from "../helpers"

const borderStyle = (themeColors) => css`
  border-width: 1px; /* Equivalent to Tailwind's border-4 */
  border-style: solid; /* You need to define the style to use width in CSS */
  border-color: ${themeColors?.tertiary}; /* Dynamically set from themeColors */
`

const InfoCard = ({ content, description, image }) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand].colors

  return (
    <MTCard
      color="transparent"
      shadow={false}
      className="cursor-pointer w-full max-w-[20rem] shadow-lg px-5 banner text-center"
      css={borderStyle(themeColors)}
    >
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="mx-0 flex flex-col items-center gap-2 py-5 mt-0"
      >
        {/* <Avatar size="lg" variant="circular" src={image} alt="tania andrew" />
         */}
        <FontAwesomeIcon
          icon={image}
          className="w-7 h-7"
          style={{ color: themeColors?.tertiary }}
        />
        <div className="flex w-full flex-col gap-0.5 text-center">
          <div className="flex flex-col items-center justify-between">
            <Typography
              color="blue-gray"
              variant="p"
              className="text-xs xs:hidden"
            >
              {description}
            </Typography>
            <Typography
              variant="p"
              color="blue-gray"
              className="text-black font-normal w-full text-center"
            >
              {content}
            </Typography>
          </div>
        </div>
      </CardHeader>
    </MTCard>
  )
}

InfoCard.propTypes = {
  content: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default InfoCard
