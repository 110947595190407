import React from "react"
import PropTypes from "prop-types"
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input"

const PhoneNumberInput = ({ onChange, value }) => (
  <PhoneInput
    placeholder="Enter your phone phone number"
    international
    defaultCountry="FR"
    onChange={onChange}
    clearButton
    value={value}
    className="rounded-3xl"
  />
)

PhoneNumberInput.defaultProps = {
  onChange: Function.prototype,
}
PhoneNumberInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
}

export default PhoneNumberInput

export { isValidPhoneNumber }
