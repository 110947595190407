import React, { useContext, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import themes from "../themes"
import { getBrandFromUrl, imageDistributed } from "../helpers"

// TODO : the input in the game catalog content item is removed

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`

const CatalogContext = React.createContext(null)

export const GamesCatalogCategories = ({ children }) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors

  return (
    <ul
      className="nav nav-tabs flex desktop:flex-col xs:flex-row flex-wrap list-none  py-5 rounded-t-xl rounded-b-xl  desktop:rounded-r-none games-categories-panel"
      style={{ backgroundColor: themeColors?.secondary, color: "white" }}
      id="tabs-tabVertical"
    >
      <h2 className="font-bold text-tertiary text-xl mb-3 px-5 w-[200px]">
        <img
          src={imageDistributed("gamesCatalogTopAsset")}
          alt="games-catalog-top-asset"
        />
      </h2>
      {children}
    </ul>
  )
}
GamesCatalogCategories.propTypes = {
  children: PropTypes.element.isRequired,
}

export const GamesCatalogCategory = ({ id, children, imageSource }) => {
  const { activeCategory, setActiveCategory } = useContext(CatalogContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSelect = () => {
    setActiveCategory(id)
    searchParams.set("category", id)
    setSearchParams(searchParams)
  }

  return (
    <div
      className=" text-center desktop:w-[200px] xs:w-full"
      onClick={handleSelect}
      onKeyDown={Function.prototype}
      tabIndex={0}
      role="button"
    >
      <span
        className={`desktop:text-left
      xs:text-center
      block
      font-semibold
      ${activeCategory === id ? "text-tertiary" : "text-white"}
      text-md
      leading-tight
      hover:brightness-75
      border-y border-lightTertiary
      w-full
      px-5
      cursor-pointer
      py-3`}
        id={`tabs-${id}`}
      >
        <span className="flex items-center w-full">
          <img
            fetchpriority="high"
            src={imageSource}
            alt="popular-game-icon"
            className="w-8 h-8 mr-2"
          />
          {children}
        </span>
      </span>
    </div>
  )
}
GamesCatalogCategory.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
}

export const GamesCatalogContent = ({ children }) => {
  const brand = getBrandFromUrl()
  const themeColors = themes[brand]?.colors
  return (
    <div
      className="tab-content w-full rounded-t-xl rounded-b-xl rounded-l-none"
      id="tabs-tabContentVertical"
      style={{ backgroundColor: themeColors?.customBlue, color: "white" }}
    >
      <div className="tab-pane fade w-full py-5 px-3 text-white active show">
        {children}
      </div>
    </div>
  )
}
GamesCatalogContent.propTypes = {
  children: PropTypes.element.isRequired,
}

export const GamesCatalogContentItem = ({ children, id, title }) => {
  const { activeCategory } = useContext(CatalogContext)
  const { t } = useTranslation()
  return (
    activeCategory === id && (
      <div className="w-full">
        <div
          id="games-catalog-id"
          className="flex desktop:flex xs:flex-col  justify-between items-center mb-10"
        >
          <h2 className="desktop:w-1/2 desktop:text-2xl xs:text-md font-bold desktop:text-left xs:w-full">
            {t(title)}
          </h2>
        </div>
        <div className="w-full">
          <div
            className="overflow-y-auto overflow-x-hidden scrollbar scrollbar-thumb-tertiary scrollbar-track-auto scroll-th scrollbar-thumb-[2px] p-2"
            css={scrollbarStyle}
          >
            <div className="w-full grid grid-cols-4 grid-flow-row gap-2 justify-start">
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

GamesCatalogContentItem.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export const GamesCatalog = ({
  children,
  defaultCategory,
  activatedCategory,
}) => {
  const [activeCategory, setActiveCategory] = useState(defaultCategory)
  /*   const [searchParams, setSearchParams] = useSearchParams() */
  const context = useMemo(
    () => ({ activeCategory, setActiveCategory }),
    [activeCategory]
  )

  if (activatedCategory) {
    context.activeCategory = activatedCategory
  }

  return (
    <CatalogContext.Provider value={context}>
      <div className="flex items-start desktop:flex-row  xs:items-center">
        {children}
      </div>
    </CatalogContext.Provider>
  )
}
GamesCatalog.propTypes = {
  children: PropTypes.element.isRequired,
  defaultCategory: PropTypes.string.isRequired,
  activatedCategory: PropTypes.string.isRequired,
}

GamesCatalog.Categories = GamesCatalogCategories
GamesCatalog.Categories.Item = GamesCatalogCategory
GamesCatalog.Content = GamesCatalogContent
GamesCatalog.Content.Item = GamesCatalogContentItem
