import React from "react"
import PropTypes from "prop-types"

const Card = ({ children }) => (
  <div className="flex justify-center shadow-2xl xl:fixed xl:top-10 xl:left-auto xs:fixed xs:top-18 xs:left-0  xl:w-auto xs:w-full ">
    <div className="block xl:p-6 rounded-sm shadow-lg bg-white xl:w-[600px] xs:w-screen xs:p-3">
      {children}
    </div>
  </div>
)

Card.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Card
