import React from "react"
import PropTypes from "prop-types"
import OtpInput from "react-otp-input"

const OTPInput = ({ code, onChange, error }) => (
  <OtpInput
    value={code}
    onChange={onChange}
    numInputs={6}
    separator={<span style={{ width: "10px" }}>-</span>}
    isInputNum
    shouldAutoFocus
    hasErrored={error !== false}
    className="opt-special-input"
    inputStyle={{
      border: "1px solid transparent",
      borderRadius: "8px",
      width: "54px",
      height: "54px",
      fontSize: "20px",
      color: "#000",
      fontWeight: "400",
      outline: "none !important",
    }}
    focusStyle={{
      border: "2px solid rgba(201, 106, 255, 1)",
      outline: "none !important",
    }}
    errorStyle="error-style"
  />
)

OTPInput.defaultProps = {
  onChange: Function.prototype,
  code: 0,
  error: false,
}
OTPInput.propTypes = {
  code: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
}

export default OTPInput
