import React, { useMemo, useContext } from "react"
import PropTypes from "prop-types"
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import chevronDown from "../assets/icons/chevron-down.svg"
// import customAccordionArrow from "../assets/images/depositAssets/custom-accordion-arrow.png"
import { imageDistributed } from "../helpers"

const styleAccordionItemHeader = css`
  border-radius: 0 !important;
`
const styleAccordionItemHeaderButton = (icon) => css`
  color: #000 !important ;
  &:after {
    background-image: url(${icon}) !important ;
    background-size: 100% !important ;
  }
`

const AccordionContext = React.createContext(null)

const AccordionItemHeader = ({ children, id, cryptosAccordion }) => (
  <h2
    className="accordion-header mb-0 h-12"
    css={styleAccordionItemHeader}
    id={`heading${id}`}
  >
    <button
      className={`
      accordion-button
      relative
      w-full
      py-2
      px-5
      text-base font-md text-gray-800 
      bg-white
      after:mx-2 ${
        cryptosAccordion
          ? "flex flex-row-reverse justify-end items-center border-none collapsed-button rounded-xl"
          : "flex justify-between border-1 border-b"
      }`}
      css={
        cryptosAccordion
          ? styleAccordionItemHeaderButton(imageDistributed("customArrowUp"))
          : styleAccordionItemHeaderButton(chevronDown)
      }
      data-bs-toggle="collapse"
      data-bs-target={`#collapse${id}`}
      type="button"
    >
      {children}
    </button>
  </h2>
)
AccordionItemHeader.defaultProps = {
  cryptosAccordion: false,
}
AccordionItemHeader.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  cryptosAccordion: PropTypes.bool,
}

export const AccordionItem = ({ children }) => (
  <div
    className="accordion-item bg-white rounded-md cursor-default"
    role="button"
    tabIndex={0}
  >
    {children}
  </div>
)

AccordionItem.propTypes = {
  children: PropTypes.element.isRequired,
}

const conditionalShowStatus = (activeItem, id) =>
  activeItem === id ? "show" : ""

const AccordionItemContent = ({ children, id }) => {
  const { activeItem } = useContext(AccordionContext)
  return (
    <div
      id={`collapse${id}`}
      className={`accordion-collapse collapse ${conditionalShowStatus(
        activeItem,
        id
      )}`}
      aria-labelledby={`heading${id}`}
      data-bs-parent="#carbonAccordion"
    >
      <div className="accordion-body  px-5 text-black border-0 rounded-none">
        <p>{children}</p>
      </div>
    </div>
  )
}

AccordionItemContent.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

const contextInitState = {}
export const Accordion = ({ children, defaultActiveItem }) => {
  const context = useMemo(
    () => ({ ...contextInitState, activeItem: defaultActiveItem }),
    []
  )
  return (
    <AccordionContext.Provider value={context}>
      <div className="accordion rounded-none " id="carbonAccordion">
        {children}
      </div>
    </AccordionContext.Provider>
  )
}

Accordion.defaultProps = {
  defaultActiveItem: "",
}

Accordion.propTypes = {
  children: PropTypes.element.isRequired,
  defaultActiveItem: PropTypes.string,
}

Accordion.Item = AccordionItem
Accordion.Item.Header = AccordionItemHeader
Accordion.Item.Content = AccordionItemContent
