import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import MediaQuery from "react-responsive"
import { css } from "@emotion/react"
/** @jsxImportSource @emotion/react */
import { Modal } from "../../components/Modal"
import Input from "../../components/Input"
import Button from "../../components/Button"
import Spinner from "../../components/Spinner"
import { toast } from "../../components/Toast"
import { postLogin } from "../../actions/auth"
// import loginModalAsset from "../../assets/images/modalAssets/sign-in-modal-asset.png"
import Prompt2FAPINCard from "../../components/Prompt2FAPINCard"
import IconsButton from "../../components/IconsButton"
import Tooltip from "../../components/Tooltip"
import PageLink from "../../components/PageLink"
import errorCodes from "../../constants/error-codes"
import SloganText from "../../components/SloganText"
import { brandLogo, getBrandFromUrl, imageDistributed } from "../../helpers"
import themes from "../../themes"

// TODO: the "Need help?" cta should navigate to an explicit url"

const serviceName = "login"

const borderStyle = (themeColors) => css`
  border: 1px solid ${themeColors?.tertiary};
`

const LoginModal = ({ activeService, handleCloseModal }) => {
  // get the brand name
  const brand = getBrandFromUrl()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [usernameOrEmail, setUsernameOrEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPrompt2FAPinCard, setShowPrompt2FAPinCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRedirectToRegister = () => {
    searchParams.set("service", "register")
    setSearchParams(searchParams)
  }

  const handleRedirectToRecoveryEmail = () => {
    searchParams.set("service", "recovery-email")
    setSearchParams(searchParams)
  }

  const onChangePassword = (value) => {
    setPassword(value.target.value)
  }

  const onChangeUsername = (value) => {
    setUsernameOrEmail(value.target.value)
  }

  // Login request onMount
  const login = async (pin) => {
    const userToImpersonate = searchParams.get("user-to-impersonate")
    if (usernameOrEmail && password) {
      setIsLoading(true)
      const result = await dispatch(
        postLogin(usernameOrEmail, password, pin, userToImpersonate, brand)
      )
      if (result?.status === 200) {
        setErrors("")
        setIsLoading(false)
        searchParams.delete("service")
        setSearchParams(searchParams)
      } else if (
        result?.status === 400 &&
        result?.data.code === errorCodes["2FA_PIN_MISSING"]
      ) {
        setShowPrompt2FAPinCard(true)
      } else if (
        result?.status === 401 &&
        result?.data.code === errorCodes["2FA_PIN_INVALID"]
      ) {
        setErrors("Wrong 2FA pin.")
        setIsLoading(false)
        toast.error(t("Login error"), t("Wrong 2FA pin."))
      } else if (
        result?.status === 401 &&
        result?.data.code === "account-disabled"
      ) {
        setErrors("Account disabled, contact admin")
        setIsLoading(false)
        toast.warning(
          t("Account deactivated"),
          t("Your account is deactivated. Please contact admin!")
        )
      } else if (
        result?.status === 403 &&
        result?.data.code === "brand-mismatch"
      ) {
        setIsLoading(false)
        toast.error(
          t("This account does not exist !"),
          t(
            "This account does not exist. For assistance, please reach out to customer support."
          )
        )
      } else {
        setErrors("Wrong username/email or password.")
        setIsLoading(false)
        toast.error(t("Login error"), t("Incorrect username or password."))
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    login(undefined)
  }

  const handleSubmitPin = (value) => {
    setShowPrompt2FAPinCard(false)
    login(value)
  }

  const handleCancelPin = () => {
    setShowPrompt2FAPinCard(false)
    setIsLoading(false)
  }

  const themeColors = themes[brand]?.colors

  return (
    <Modal
      activeModal={activeService === serviceName}
      closeModal={handleCloseModal}
    >
      <div className="flex relative w-full">
        <div
          className="desktop:w-[42%] flex flex-col items-center justify-center py-12  px-10 z-50  rounded-tl-3xl rounded-bl-3xl xs:hidden desktop:block blurry"
          style={{
            backgroundColor: themeColors?.secondary,
            color: "white",
            // borderColor: themeColors?.lightTertiary,
            // borderWidth: "1px",
          }}
          css={borderStyle(themeColors)}
        >
          <div className="flex flex-col">
            <img
              fetchpriority="high"
              loading="lazy"
              decoding="async"
              src={brandLogo()}
              alt="logo-version-svg"
              className="aspect-auto"
            />
            <SloganText />
          </div>
          <div className="flex justify-center mb-2">
            <img
              fetchpriority="high"
              loading="lazy"
              decoding="async"
              src={imageDistributed("signInModalAsset")}
              alt="step-icon-1"
              className=" aspect-auto my-4 p-4"
            />
          </div>
          <p className="text-center font-medium">
            <span className=" text-md" style={{ color: themeColors?.tertiary }}>
              2.500.120.454
            </span>
            <br />
            <span className="text-white text-sm opacity-40">
              {t("Bets Wagered")}
            </span>
          </p>
        </div>
        {showPrompt2FAPinCard && (
          <Prompt2FAPINCard
            onSubmit={handleSubmitPin}
            onCancel={handleCancelPin}
          />
        )}
        <div
          className="desktop:w-[58%] desktop:pb-24 h-full register-modal-right-view desktop:rounded-tr-3xl desktop:rounded-br-3xl blurry xs:h-[680px] xs:overflow-auto modal-register-view "
          style={{
            backgroundColor: themeColors?.secondary,
            color: "white",
            // borderColor: themeColors?.lightTertiary,
            // borderWidth: "1px",
          }}
          css={borderStyle(themeColors)}
        >
          <div className="flex justify-between items-center desktop:h-auto xs:h-16 xs:bg-white  xs:w-full">
            <h1
              className="xs:text-black text-xl leading-5 font-medium px-7"
              style={{ color: themeColors?.tertiary }}
            >
              {t("Sign In")}
            </h1>
            <MediaQuery maxWidth={959}>
              <Modal.Header handleCloseModal={handleCloseModal} />
            </MediaQuery>
            <MediaQuery minWidth={959}>
              <Modal.Header handleCloseModal={handleCloseModal} tertiaryIcon />
            </MediaQuery>
          </div>
          <MediaQuery maxWidth={959}>
            <div className="flex justify-center items-center my-5 py-5">
              <img
                fetchpriority="high"
                loading="lazy"
                decoding="async"
                src={brandLogo()}
                alt="logo-mobile-version"
                className="w-60 h-auto"
              />
            </div>
          </MediaQuery>
          <div className="py-15 px-7 text-md ">
            <h1 className="text-white text-xl leading-5 font-medium xs:hidden">
              {t("Sign In with your existing account")}
            </h1>
            <div>
              <Button.Tertiary theme="light" onClick={handleRedirectToRegister}>
                <span
                  className=" text-sm text-left font-bold"
                  style={{ color: themeColors?.tertiary }}
                >
                  {t("Don't have an account ?")}
                </span>
              </Button.Tertiary>
            </div>
            <form className="mt-5">
              <div className="mb-7 w-full">
                <Input
                  autoCapitalize="none"
                  onChange={onChangeUsername}
                  type="text"
                  id="user-name-email"
                  value={usernameOrEmail}
                  label={t("Username or Email")}
                />
              </div>
              <div className="">
                <div className="relative">
                  <div className="w-full">
                    <Input
                      autoCapitalize="none"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      label={t("Password")}
                      value={password}
                      onChange={onChangePassword}
                    />
                  </div>
                  <div className="absolute bottom-[-8px] right-0 ">
                    {showPassword ? (
                      <Tooltip content="Hide password">
                        <span>
                          <IconsButton onClick={handleShowPassword}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEye}
                                className=" text-black w-5 "
                              />
                            </span>
                          </IconsButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip content="Show password">
                        <span>
                          <IconsButton onClick={handleShowPassword}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                className=" text-black w-5"
                              />
                            </span>
                          </IconsButton>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <p
                  className="text-sm text- absolute red-500"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {errors}
                </p>
                <div className="py-4 flex justify-between">
                  <Button.Tertiary
                    theme="light"
                    onClick={handleRedirectToRecoveryEmail}
                  >
                    <span className="text-sm text-white">
                      {t("Forgot your Password ?")}
                    </span>
                  </Button.Tertiary>
                  <Button.Tertiary
                    theme="light"
                    onClick={handleRedirectToRecoveryEmail}
                  >
                    <span className="text-sm text-white">
                      {t("Need help ?")}
                    </span>
                  </Button.Tertiary>
                </div>
              </div>
              <div>
                <div className="py-3">
                  {!isLoading ? (
                    <Button.Primary
                      fullWidth
                      onSubmit={handleSubmit}
                      onClick={handleSubmit}
                    >
                      {t("Play Now")}
                    </Button.Primary>
                  ) : (
                    <Spinner theme="light" />
                  )}
                </div>
              </div>
            </form>
            <div className="mt-3 pb-10">
              <p className="text-xs text-white">
                {t("This site is protected by reCAPTCHA and the Google")}{" "}
                <PageLink to="/policy/privacy-policy" classNames="tertiary">
                  {" "}
                  {t("Privacy Policy")}
                </PageLink>{" "}
                {t("and")}{" "}
                <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                  {t("Terms of Service")}
                </PageLink>{" "}
                {t(
                  "apply. By accessing the site I attest that I am at least 18 years old and have read the"
                )}{" "}
                <PageLink to="terms/terms-and-conditions" classNames="tertiary">
                  {t("Terms of Service")}
                </PageLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

LoginModal.defaultProps = {
  activeService: null,
}

LoginModal.propTypes = {
  activeService: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
}

export default LoginModal
